import React from 'react'
import GreenTick from "../../assets/icons/green-tick.png";
import { useSelector } from 'react-redux';

function GeoTagging({ reportData, step }) {
  const { calculate_location_distance } = useSelector(state => state.login.clientInfo)

  return (
    <table
      width="100%"
      style={{ border: "1px solid #b1b1b1" }}
      className="m-auto bg-white"
    >
      <tr>
        <td style={{ padding: "0px" }}>
          <table width="100%">
            <tr>
              <td style={{ padding: "15px 15px 15px 15px" }}>
                <h4>{step}. Geo Tagging</h4>
                {reportData.customers && reportData.customers.map(customer => {
                  return <>
                    {reportData.customers.length > 1 && <tr>{customer.name}</tr>}
                    <table
                      width="100%"
                      className="bg-white"
                      style={{ marginTop: "15px" }}
                    >
                      <tr>
                        <td
                          width="200"
                          style={{ padding: "0px 10px 0px 0px" }}
                        >
                          <table
                            width="215"
                            className="gray-table-rows bg-f8"
                            style={{ overflow: "wrap" }}
                          >
                            <tr>
                              <td
                                width="42%"
                                style={{ paddingRight: "0px" }}
                              >
                                Latitude
                              </td>
                              <td
                                width="2%"
                                align="center"
                                style={{ padding: "0px" }}
                              >
                                :
                              </td>
                              <td width="51%">
                                {customer.latitude}
                              </td>
                              <td
                                align="right"
                                width="5%"
                                style={{
                                  paddingRight: "10px",
                                  paddingLeft: "0px",
                                }}
                              >
                                <img src={GreenTick} width="20" alt="" />
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td
                          width="200"
                          style={{ padding: "0px 10px 0px 10px" }}
                        >
                          <table
                            width="215"
                            className="gray-table-rows bg-f8"
                          >
                            <tr>
                              <td
                                width="42%"
                                style={{ paddingRight: "0px" }}
                              >
                                Longitude
                              </td>
                              <td
                                width="2%"
                                align="center"
                                style={{ paddingRight: "0px" }}
                              >
                                :
                              </td>
                              <td width="51%">
                                {customer.longitude}
                              </td>
                              <td
                                align="right"
                                width="5%"
                                style={{
                                  paddingRight: "10px",
                                  paddingLeft: "0px",
                                }}
                              >
                                <img src={GreenTick} width="20" alt="" />
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td
                          width="313"
                          style={{ padding: "0px 0px 0px 10px" }}
                        >
                          <table
                            width="283"
                            className="gray-table-rows bg-f8"
                          >
                            <tr>
                              <td
                                width="42%"
                                style={{ paddingRight: "0px" }}
                              >
                                IP Address
                              </td>
                              <td
                                width="2%"
                                align="center"
                                style={{ padding: "0px" }}
                              >
                                :
                              </td>
                              <td width="51%">
                                {customer.ip_address}
                              </td>
                              <td
                                align="right"
                                width="5%"
                                style={{
                                  paddingRight: "10px",
                                  paddingLeft: "0px",
                                }}
                              >
                                <img src={GreenTick} width="20" alt="" />
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                    <table
                      className="gray-table-rows bg-f8"
                      width="100%"
                      style={{ marginTop: "8px" }}
                    >
                      <tr>
                        <td width="20%" style={{ paddingRight: "0px" }}>
                          Address Fetched
                        </td>
                        <td width="1%" align="center">
                          :
                        </td>
                        <td width="79%">
                          {customer.location}
                        </td>
                        <td
                          align="right"
                          width="5%"
                          style={{
                            paddingRight: "10px",
                            paddingLeft: "0px",
                          }}
                        >
                          <img src={GreenTick} width="20" alt="" />
                        </td>
                      </tr>
                    </table>

                    {calculate_location_distance ? <table
                      width="100%"
                      className="bg-white"
                      style={{ marginTop: "15px" }}
                    >
                      <tr>
                        <td
                          width="200"
                          style={{ padding: "0px 10px 0px 0px" }}
                        >
                          <table
                            width="215"
                            className="gray-table-rows bg-f8"
                            style={{ overflow: "wrap" }}
                          >
                            <tr>
                              <td
                                width="42%"
                                style={{ paddingRight: "0px" }}
                              >
                                Distance
                              </td>
                              <td
                                width="2%"
                                align="center"
                                style={{ padding: "0px" }}
                              >
                                :
                              </td>
                              <td width="51%">
                                {customer.location_distance}
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table> : null}
                    <hr
                      style={{
                        borderTop: "1px dashed #707070 !important",
                        borderBottom: 0,
                        marginTop: "20px",
                        marginBottom: "20px",
                        width: "100%",
                      }}
                    />
                  </>
                })}

              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  )
}

export default GeoTagging