import React from 'react'
import CloseIcon from "../../assets/icons/close-icon.png";
import GreenTick from "../../assets/icons/green-tick.png";
import moment from "moment";

function DlData({ reportData, documentsObject, imageZoom, step }) {
    const isMigrated = reportData?.case?.old_db_case_id ? true : false

    return (
        <table
            width="100%"
            style={{ border: "1px solid #b1b1b1" }}
            className="m-auto bg-white"
        >
            <tr>
                <td style={{ padding: '15px' }}>
                    {documentsObject["Driving License"] && (
                        <> <h4>
                            {step}. Driving License Verification
                        </h4>
                            <table
                                width="100%"
                                className="bg-white"
                                style={{ marginTop: "15px" }}
                            >
                                <tr>
                                    <td
                                        valign="top"
                                        width="180"
                                        style={{ padding: "0px 0px 0px 0px" }}
                                    >
                                        <table width="100%" className="bg-f8">
                                            <tr>
                                                <td
                                                    align="center"
                                                    style={{
                                                        padding: "20px",
                                                        paddingBottom: "10px",
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            reportData.documents[
                                                                documentsObject["Driving License"]
                                                            ].front_upload
                                                        }
                                                        crossOrigin="anonymous"
                                                        onClick={() =>
                                                            imageZoom(reportData.documents[
                                                                documentsObject["Driving License"]
                                                            ].front_upload)
                                                        }
                                                        alt=""
                                                        width="245"
                                                        className="img-fluid rounded"
                                                        style={{ cursor: "pointer" }}
                                                    />

                                                </td>
                                            </tr>
                                        </table>
                                        <table width="100%" className="bg-f8">
                                            <tr>
                                                <td
                                                    align="center"
                                                    style={{
                                                        padding: "20px",
                                                        paddingBottom: "10px",
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            reportData.documents[
                                                                documentsObject["Driving License"]
                                                            ].back_upload
                                                        }
                                                        crossOrigin="anonymous"
                                                        onClick={() =>
                                                            imageZoom(reportData.documents[
                                                                documentsObject["Driving License"]
                                                            ].back_upload)
                                                        }
                                                        alt=""
                                                        width="245"
                                                        className="img-fluid rounded"
                                                        style={{ cursor: "pointer" }}
                                                    />

                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    {reportData.documents[
                                        documentsObject["Driving License"]
                                    ].document_upload_type === "digilocker_upload" ? (
                                        <td
                                            valign="top"
                                            style={{ padding: "0px 0px 0px 10px" }}
                                        >
                                            <table
                                                className="green-table-rows bg-f8"
                                                width="560"
                                            >
                                                <tr>
                                                    <td
                                                        width="40%"
                                                        style={{
                                                            padding: "5px 20px !important",
                                                        }}
                                                    >
                                                        Driving License verification status
                                                    </td>
                                                    <td
                                                        width="2%"
                                                        align="center"
                                                        style={{ padding: "0px" }}
                                                    >
                                                        :
                                                    </td>
                                                    <td width="53%">
                                                        Driving License successfully verified by
                                                        digi-locker
                                                    </td>
                                                    <td
                                                        align="right"
                                                        width="15%"
                                                        style={{
                                                            paddingRight: "10px",
                                                            paddingLeft: "0px",
                                                        }}
                                                    >
                                                        <img
                                                            src={GreenTick}
                                                            width="20"
                                                            alt=""
                                                        />
                                                    </td>
                                                </tr>
                                            </table>
                                            <table
                                                className="gray-table-rows bg-f8"
                                                width="560"
                                                style={{ marginTop: "10px" }}
                                            >
                                                <tr>
                                                    <td
                                                        width="40%"
                                                        style={{ paddingRight: "0px" }}
                                                    >
                                                        Driving License Upload Date-Time
                                                    </td>
                                                    <td
                                                        width="2%"
                                                        align="center"
                                                        style={{ padding: "0px" }}
                                                    >
                                                        :
                                                    </td>
                                                    <td width="53%">
                                                        {isMigrated ? moment(
                                                            reportData.session.ended_at
                                                        ).format("DD MMM YYYY LT") : moment(
                                                            reportData.documents[
                                                                documentsObject["Driving License"]
                                                            ].created_at
                                                        ).format("DD MMM YYYY LT")}
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    ) : (
                                        <td
                                            valign="top"
                                            style={{ padding: "0px 0px 0px 10px" }}
                                        >
                                            <table
                                                className="gray-table-rows bg-f8"
                                                width="560"
                                            >
                                                <tr>
                                                    <td
                                                        width="40%"
                                                        style={{
                                                            padding: "5px 20px !important",
                                                        }}
                                                    >
                                                        Driving License number
                                                    </td>
                                                    <td
                                                        width="2%"
                                                        align="center"
                                                        style={{ padding: "0px" }}
                                                    >
                                                        :
                                                    </td>
                                                    <td width="43%">
                                                        {
                                                            reportData.documents[
                                                                documentsObject["Driving License"]
                                                            ].extracted_ocr.doc_id
                                                        }
                                                    </td>
                                                    <td
                                                        align="right"
                                                        width="15%"
                                                        style={{
                                                            paddingRight: "10px",
                                                            paddingLeft: "0px",
                                                        }}
                                                    >
                                                        {reportData.documents[
                                                            documentsObject["Driving License"]
                                                        ].corrected_ocr.tick_and_cross && (
                                                                <img
                                                                    src={
                                                                        reportData.documents[
                                                                            documentsObject[
                                                                            "Driving License"
                                                                            ]
                                                                        ].corrected_ocr.tick_and_cross[0]
                                                                            .isTick
                                                                            ? GreenTick
                                                                            : CloseIcon
                                                                    }
                                                                    width="20"
                                                                    alt=""
                                                                />
                                                            )}
                                                    </td>
                                                </tr>
                                            </table>
                                            <table
                                                className="gray-table-rows bg-f8"
                                                width="560"
                                            >
                                                <tr>
                                                    <td
                                                        width="40%"
                                                        style={{
                                                            padding: "5px 20px !important",
                                                        }}
                                                    >
                                                        Name as on Driving License
                                                    </td>
                                                    <td
                                                        width="2%"
                                                        align="center"
                                                        style={{ padding: "0px" }}
                                                    >
                                                        :
                                                    </td>
                                                    <td width="43%">
                                                        {
                                                            reportData.documents[
                                                                documentsObject["Driving License"]
                                                            ].extracted_ocr.name
                                                        }
                                                    </td>
                                                    <td
                                                        align="right"
                                                        width="15%"
                                                        style={{
                                                            paddingRight: "10px",
                                                            paddingLeft: "0px",
                                                        }}
                                                    >
                                                        {reportData.documents[
                                                            documentsObject["Driving License"]
                                                        ].corrected_ocr.tick_and_cross && (
                                                                <img
                                                                    src={
                                                                        reportData.documents[
                                                                            documentsObject[
                                                                            "Driving License"
                                                                            ]
                                                                        ].corrected_ocr.tick_and_cross[1]
                                                                            .isTick
                                                                            ? GreenTick
                                                                            : CloseIcon
                                                                    }
                                                                    width="20"
                                                                    alt=""
                                                                />
                                                            )}
                                                    </td>
                                                </tr>
                                            </table>
                                            <table
                                                className="gray-table-rows bg-f8"
                                                width="560"
                                                style={{ marginTop: "10px" }}
                                            >
                                                <tr>
                                                    <td
                                                        width="40%"
                                                        style={{ paddingRight: "0px" }}
                                                    >
                                                        Address fetched as on Driving License
                                                    </td>
                                                    <td
                                                        width="2%"
                                                        align="center"
                                                        style={{ padding: "0px" }}
                                                    >
                                                        :
                                                    </td>
                                                    <td width="53%">
                                                        {
                                                            reportData.documents[
                                                                documentsObject["Driving License"]
                                                            ].extracted_ocr.address
                                                        }
                                                    </td>
                                                    <td
                                                        align="right"
                                                        width="5%"
                                                        style={{
                                                            paddingRight: "10px",
                                                            paddingLeft: "0px",
                                                        }}
                                                    >
                                                        {reportData.documents[
                                                            documentsObject["Driving License"]
                                                        ].corrected_ocr.tick_and_cross && (
                                                                <img
                                                                    src={
                                                                        reportData.documents[
                                                                            documentsObject[
                                                                            "Driving License"
                                                                            ]
                                                                        ].corrected_ocr.tick_and_cross[4]
                                                                            .isTick
                                                                            ? GreenTick
                                                                            : CloseIcon
                                                                    }
                                                                    width="20"
                                                                    alt=""
                                                                />
                                                            )}
                                                    </td>
                                                </tr>
                                            </table>
                                            <table
                                                width="100%"
                                                style={{ marginTop: "8px" }}
                                            >
                                                <tr>
                                                    <td
                                                        width="260"
                                                        style={{ padding: "0px 10px 0px 0px" }}
                                                    >
                                                        <table
                                                            className="gray-table-rows bg-f8"
                                                            width="100%"
                                                        >
                                                            <tr>
                                                                <td
                                                                    width="38%"
                                                                    style={{ paddingRight: "0px" }}
                                                                >
                                                                    Date of birth
                                                                </td>
                                                                <td
                                                                    width="2%"
                                                                    align="center"
                                                                    style={{
                                                                        paddingLeft: "10px",
                                                                        padding: "10px",
                                                                    }}
                                                                >
                                                                    :
                                                                </td>
                                                                <td width="55%">
                                                                    {" "}
                                                                    {
                                                                        reportData.documents[
                                                                            documentsObject[
                                                                            "Driving License"
                                                                            ]
                                                                        ].extracted_ocr.dob
                                                                    }
                                                                </td>
                                                                <td
                                                                    align="right"
                                                                    width="5%"
                                                                    style={{
                                                                        paddingRight: "10px",
                                                                        paddingLeft: "0px",
                                                                    }}
                                                                >
                                                                    {reportData.documents[
                                                                        documentsObject[
                                                                        "Driving License"
                                                                        ]
                                                                    ].corrected_ocr
                                                                        .tick_and_cross && (
                                                                            <img
                                                                                src={
                                                                                    reportData.documents[
                                                                                        documentsObject[
                                                                                        "Driving License"
                                                                                        ]
                                                                                    ].corrected_ocr
                                                                                        .tick_and_cross[3].isTick
                                                                                        ? GreenTick
                                                                                        : CloseIcon
                                                                                }
                                                                                width="20"
                                                                                alt=""
                                                                            />
                                                                        )}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td
                                                        width="260"
                                                        style={{ padding: "0px 0px 0px 10px" }}
                                                    >
                                                        <table
                                                            className="gray-table-rows bg-f8"
                                                            width="100%"
                                                        >
                                                            <tr>
                                                                <td
                                                                    width="38%"
                                                                    style={{ paddingRight: "0px" }}
                                                                >
                                                                    Father's Name
                                                                </td>
                                                                <td width="2%" align="center">
                                                                    :
                                                                </td>
                                                                <td width="55%">
                                                                    {" "}
                                                                    {
                                                                        reportData.documents[
                                                                            documentsObject[
                                                                            "Driving License"
                                                                            ]
                                                                        ].extracted_ocr.father_name
                                                                    }
                                                                </td>
                                                                <td
                                                                    align="right"
                                                                    width="5%"
                                                                    style={{
                                                                        paddingRight: "10px",
                                                                        paddingLeft: "0px",
                                                                    }}
                                                                >
                                                                    {reportData.documents[
                                                                        documentsObject[
                                                                        "Driving License"
                                                                        ]
                                                                    ].corrected_ocr
                                                                        .tick_and_cross && (
                                                                            <img
                                                                                src={
                                                                                    reportData.documents[
                                                                                        documentsObject[
                                                                                        "Driving License"
                                                                                        ]
                                                                                    ].corrected_ocr
                                                                                        .tick_and_cross[2].isTick
                                                                                        ? GreenTick
                                                                                        : CloseIcon
                                                                                }
                                                                                width="20"
                                                                                alt=""
                                                                            />
                                                                        )}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>

                                            <table
                                                className="gray-table-rows bg-f8"
                                                width="560"
                                                style={{ marginTop: "10px" }}
                                            >
                                                <tr>
                                                    <td
                                                        width="40%"
                                                        style={{ paddingRight: "0px" }}
                                                    >
                                                        Driving License Upload Date-Time
                                                    </td>
                                                    <td
                                                        width="2%"
                                                        align="center"
                                                        style={{ padding: "0px" }}
                                                    >
                                                        :
                                                    </td>
                                                    <td width="53%">
                                                        {isMigrated ? moment(
                                                            reportData.session.ended_at
                                                        ).format("DD MMM YYYY LT") : moment(
                                                            reportData.documents[
                                                                documentsObject["Driving License"]
                                                            ].created_at
                                                        ).format("DD MMM YYYY LT")}
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    )}
                                </tr>
                            </table>
                        </>
                    )}
                </td>
            </tr>
        </table>
    )
}

export default DlData