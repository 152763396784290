import React from 'react'
import GreenTick from "../../assets/icons/green-tick.png";

function Questionaire({reportData,step}) {
  return (
    <table
              width="100%"
              style={{ border: "1px solid #b1b1b1" }}
              className="m-auto bg-white"
            >
              <tr>
                <td style={{ padding: "0px" }}>
                  <table width="100%">
                    <tr>
                      <td style={{ padding: "15px 15px 15px 15px" }}>
                        <h4>{step}. Random Questionnaire</h4>
                        {reportData.answers &&
                          reportData.answers.map((questionnaire, index) => (
                            <React.Fragment>
                              <div
                                className={
                                  (index + 1) % 12 === 0
                                    ? "html2pdf__page-break"
                                    : ""
                                }
                              ></div>

                              <table
                                className="gray-table-rows bg-f8"
                                width="100%"
                                style={{ marginTop: "15px" }}
                              >
                                <tr>
                                  <td
                                    width="30%"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    {questionnaire.questions}
                                  </td>
                                  <td width="1%" align="center">
                                    :
                                  </td>
                                  <td width="40%">{questionnaire.answer}</td>
                                  <td
                                    align="right"
                                    width="19%"
                                    style={{ padding: "0px" }}
                                  >
                                    <table width="100%">
                                      <tr>
                                        <td
                                          width="30"
                                          style={{
                                            paddingRight: "0px",
                                            padding: "0px",
                                          }}
                                        >
                                          <img
                                            src={GreenTick}
                                            width="20"
                                            alt=""
                                          />
                                        </td>
                                        <td
                                          style={{
                                            paddingRight: "10px",
                                            paddingLeft: "0px",
                                            padding: "0px",
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: "#24a749",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            Verified
                                          </span>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </React.Fragment>
                          ))}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
  )
}

export default Questionaire