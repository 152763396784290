import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'
import { UPLOAD } from '../../apiurl'
import API from '../../apiBaseConfigNew'
import cogoToast from 'cogo-toast'
import Loader from '../../components/loader/Loader'
import { Button } from "@material-ui/core";


const  Screenshots = forwardRef((props,ref) =>{
    let userInfo = useSelector(state => state.userDocs.anonymousUserConnectionInfo)
    let docsInfo = userInfo.case_details.process_details.documents
    let docItem = docsInfo.filter(item => item.doc_key === 'picture' && item.document_upload_event === 'on_call')
    const [imageSrc, setImage] = useState(null)
    const [loading, setLoading] = useState(false)
    function captureScreenshot() {
        const video = document.getElementById('video');
        const canvas = document.getElementById('canvas');
        const ctx = canvas.getContext('2d');
        // Set canvas dimensions to match the video stream
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        // Draw the current frame from the video onto the canvas
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        // Convert the canvas content to a data URL (base64-encoded image)
        const screenshotDataUrl = canvas.toDataURL('image/png');
        setImage(screenshotDataUrl)
        uploadScreenShot(screenshotDataUrl)

    }
    const uploadScreenShot = (screenShot) => {
        setLoading(true)
        if (screenShot && userInfo.id) {
            API.put(UPLOAD, {
                connection: null,
                case: userInfo.id,
                front_upload: screenShot,
                document: docItem[0].id, //payload.document,
                corrected_ocr: {},
                extracted_ocr: {},
                document_upload_event: "on_call"
            })
                .then(res => {
                    if (res.status === 200) {
                        cogoToast.success('Screenshot uploaded successfully!')
                        setLoading(false)
                        props.onUpload()

                    }
                })
                .catch(err => {
                    setLoading(false)
                })
        }
    };
    useImperativeHandle(ref, () => ({
        captureScreenshot() {
            captureScreenshot()
        }

    }));
    return (
        <div>
            {loading ? <Loader /> : null}
            <canvas id="canvas" style={{ display: 'none' }}></canvas>
            <img className='screen-shot' src={imageSrc} />
            {/* <Button variant='contained' color='secondary' className='mt-2' onClick={captureScreenshot}>Take Screenshot</Button> */}
        </div>
    )
})
export default Screenshots
