import React from "react";
import { ReactComponent as HOME_ICON } from "../../../../../assets/icons/home.svg";
import { ReactComponent as ADMIN_HOME_ICON } from "../../../../../assets/icons/admin-home.svg";
import { ReactComponent as CHECK_PAD_ICON } from "../../../../../assets/icons/check-pad.svg";
import { ReactComponent as AGENT_HEADPHONE_ICON } from "../../../../../assets/icons/agent-headphone.svg";
import { ReactComponent as CASE_MANAGEMENT_ICON } from "../../../../../assets/icons/case-management.svg";
import { ReactComponent as LiveIcon } from "../../../../../assets/icons/livesvg.svg"

import { connect } from 'react-redux';

const UPLOAD_CASES = "UPLOAD_CASES";
const UPLOAD_LIVE_CASES = "UPLOAD_LIVE_CASES";
const ADD_EXECUTIVE = "ADD_EXECUTIVE";
const AGENT_LIST = "AGENT_LIST";
const ADMIN_HOME = "ADMIN_HOME";
const CASE_MANAGEMENT = "CASE_MANAGEMENT";
const sideList = [{
  name: 'Home',
  tabName: "ADMIN_HOME",
  icon: HOME_ICON,
  isAdmin: true,
  roles: ['admin_auditor','client_superuser']
},
{
  name: 'Executive Management',
  tabName: "AGENT_LIST",
  icon: AGENT_HEADPHONE_ICON,
  roles: ['admin', 'auditor','client_superuser'],
  isAdmin: true
},
{
  name: 'Case Management',
  tabName: "CASE_MANAGEMENT",
  icon: CASE_MANAGEMENT_ICON,
  roles: ['admin_auditor','client_superuser'],
  isAdmin: true

},
{
  name: 'Upload Cases',
  tabName: "UPLOAD_CASES",
  icon: CHECK_PAD_ICON,
  roles: ['admin_auditor','client_superuser'],
  isAdmin: true

},
{
  name: 'Upload Live Cases',
  tabName: "UPLOAD_LIVE_CASES",
  icon: LiveIcon,
  roles: ['client_superuser'],
  isAdmin: true

}
]
class DashboardSidebar extends React.Component {
  render() {
    const { clientInfo, activeTab, userInfo } = this.props
    return (
      <aside
        className={this.props.expanded ? "sidebar active" : "sidebar"}
        onMouseEnter={() => this.props.expand(true)}
        onMouseLeave={() => this.props.expand(false)}
      >
        <ul>
          {sideList.map(item => {
            let { name, tabName } = item
            if (!item.roles.includes(userInfo.roleName)) return
            return <li className={activeTab === tabName ? 'active' : ''} onClick={() => this.props.sideBarChanged(tabName)}>
              <a href="#">
                <span className="icon p-0" style={{ width: "25px" }}>
                  <item.icon />
                </span>
                <span className="title">{name}</span>
              </a>
            </li>
          })}
        </ul>
      </aside>
    );
  }
}

const mapStateToProps = (state) => ({
  clientInfo: state.login.clientInfo,
  userInfo: state.login.userInfo
});

export default connect(mapStateToProps)(DashboardSidebar);
