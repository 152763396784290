import React from "react";
import LocationDetails from "../locationDetails/LocationDetails";
import PanCardDetails from "../panCardDetails/PanCardDetails";
import AadhaarCardDetails from "../aadhaarCardDetails/AadhaarCardDetails";
import PhotoLiveness from "../photoLiveness/PhotoLiveness";
import ScreenShot from "../screenShot/ScreenShot";
import Questionnaire from "../questionnaire/Questionnaire";
import UniqueCode from "../uniqueCode/UniqueCode";
import DocList from "../docList/DocList";
import { UPLOAD } from "../../apiurl";
import api from "../../apiBaseConfig";
import DrivingLicenseDetails from "../drivingLicenseDetails/DrivingLicenseDetails";
import { connect } from 'react-redux'
import API from '../../apiBaseConfigNew'
import cogoToast from "cogo-toast";
import { _ToNormal } from "../../constants";
class VerificationCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      nextDisabled: true,
      backDisabled: true,
      isLocationChecked: { isTick: false, isCancel: false },
      panCardCheckedArray: [
        { isTick: false, isCancel: false, type: null },
        { isTick: false, isCancel: false, type: null },
        { isTick: false, isCancel: false, type: null },
        { isTick: false, isCancel: false, type: null },
      ],
      aadhaarCardCheckedArray: [
        { isTick: false, isCancel: false, type: null },
        { isTick: false, isCancel: false, type: null },
        { isTick: false, isCancel: false, type: null },
        { isTick: false, isCancel: false, type: null },
      ],
      dlCheckedArray: [
        { isTick: false, isCancel: false, type: null },
        { isTick: false, isCancel: false, type: null },
        { isTick: false, isCancel: false, type: null },
        { isTick: false, isCancel: false, type: null },
        { isTick: false, isCancel: false, type: null },
      ],
      questionnaire: [],
      photoLive: {
        score: null,
        screenShot: null,
        reTryCount: 0,
        checked: null,
      },
      screenshot: props.processDoc,
      isUniqueCodeChecked: { isTick: false, isCancel: false },
      uniqueCodeValidated: false,
      caseFields: []
    };
  }

  updateRetry = (photoLive) => {
    this.setState({ photoLive });
  };

  checkForAllTrue = (arr) => {
    return arr.every((val) => val.isTick === true || val.isCancel === true);
  };

  componentDidUpdate(prevProps, prevState) {
    console.log("cdu verification card");
    this.updateNextAndBackStatus();
  }
  componentDidMount() {
    this.getCaseFields()
  }
  getCaseFields = () => {
    API.get('/case/add-case-fields/')
      .then(res => {
        if (res.status === 200 && res.data) {
          this.setState({ caseFields: res.data })
        }
      })
      .catch(err => {
        cogoToast.error('Something went wrong with case fields!')
      })
  }
  handleCheckArray = (arr) => {
    const { activeIndex } = this.state;
    if (activeIndex === 2) {
      this.setState({ panCardCheckedArray: arr });
    }
    if (activeIndex === 3) {
      this.setState({ aadhaarCardCheckedArray: arr, dlCheckedArray: arr });
    }
    let allTrue = this.checkForAllTrue(arr);
    let nextDisabled = allTrue ? false : true;
    this.setState({ nextDisabled });
  };

  hasCorrectedOcr(doc) {
    return doc.corrected_ocr && doc.corrected_ocr.doc_id;
  }

  updateNextAndBackStatus = () => {
    const {
      panCardCheckedArray,
      aadhaarCardCheckedArray,
      dlCheckedArray,
      activeIndex,
      questionnaire,
      isLocationChecked,
      photoLive,
      screenshot,
      isUniqueCodeChecked,
      uniqueCodeValidated
    } = this.state;

    let backDisabled = activeIndex === 0 ? true : false;
    let nextDisabled = true;
    if (activeIndex === 0) {
      nextDisabled = !questionnaire.reduce(
        (next, current) => next && current.answer && !current.hasError,
        true
      );
      if (this.props.clientConfig.is_unique_code && !isUniqueCodeChecked.isTick) {
        nextDisabled = true
      }
      if (this.props.clientConfig.is_unique_code && uniqueCodeValidated) {
        backDisabled = false
      }
      if (this.props.clientConfig.is_unique_code && isUniqueCodeChecked.isTick && !uniqueCodeValidated) {
        nextDisabled = false
      }
    }
    if (activeIndex === 1) {
      nextDisabled = !isLocationChecked.isTick && !isLocationChecked.isCancel;
    }

    if (activeIndex === 2) {
      let allTrue = this.checkForAllTrue(panCardCheckedArray);
      const PanData = this.props.docData.find(
        (item) => item.document_details.doc_key === "pan"
      );
      PanData &&
        this.saveTickCross(
          panCardCheckedArray,
          PanData.id,
          PanData.corrected_ocr
        );
      nextDisabled = allTrue || !PanData ? false : true;
    }
    if (activeIndex === 3) {
      const Aadhardata = this.props.docData.find(
        (item) => item.document_details.doc_key === "aadhar"
      );
      const dlData = this.props.docData.find(
        (item) => item.document_details.doc_key === "driving_license"
      );
      const thirdData = Aadhardata ? Aadhardata : dlData;
      let allTrue = this.checkForAllTrue(
        Aadhardata ? aadhaarCardCheckedArray : dlCheckedArray
      );

      thirdData &&
        this.saveTickCross(
          Aadhardata ? aadhaarCardCheckedArray : dlCheckedArray,
          thirdData.id,
          thirdData.corrected_ocr
        );
      nextDisabled = allTrue || !thirdData ? false : true;
    }
    if (activeIndex === 4 && (photoLive.score || photoLive.reTryCount > 4)) {
      nextDisabled = false;
    }
    if (activeIndex === 5) {
      nextDisabled = false;
      screenshot.forEach((photo) => {
        if (photo.doc_type === 3 && (!photo.image && photo.is_mandatory)) {
          nextDisabled = true;
        }
      });
    }
    if (activeIndex === 6) {
      nextDisabled = false;
    }
    if (
      backDisabled !== this.state.backDisabled ||
      nextDisabled !== this.state.nextDisabled
    ) {
      console.log("btn status updated", backDisabled, nextDisabled);
      this.setState({ backDisabled, nextDisabled });
    }
  };

  handleBack = () => {
    let activeIndex = this.state.activeIndex;

    if (activeIndex == 5 && !this.props.clientConfig.documents_required) {
      activeIndex = 1
    }
    else if (this.props.clientConfig.is_unique_code && this.state.uniqueCodeValidated && activeIndex == 0) {
      activeIndex = 0
      this.setState({ uniqueCodeValidated: false, nextDisabled: false })
    }
    else
      activeIndex--;
    this.setState({
      activeIndex: activeIndex,
    });
  };

  handleNext = () => {
    let activeIndex = this.state.activeIndex;

    if (activeIndex == 1 && !this.props.clientConfig.documents_required) {
      activeIndex = 5
    }
    else if (activeIndex == 0 && this.props.clientConfig.is_unique_code && !this.state.uniqueCodeValidated) {
      activeIndex = 0
      this.setState({ uniqueCodeValidated: true })
    }
    else
      activeIndex++;

    this.setState({
      activeIndex: activeIndex,
    });
  };

  saveTickCross = async (data, id, corrected_ocr) => {
    await api.patch(UPLOAD + `${id}/`, {
      corrected_ocr: {
        ...corrected_ocr,
        tick_and_cross: data,
      },
    });
  };

  handleLocationChecked = (isLocationChecked) => {
    this.setState({ isLocationChecked });
  };

  handlePhotoLivenessChecked = (photoLiveness) => {
    const { activeIndex } = this.state;
    if (photoLiveness) {
      this.setState({
        ...this.state,
        nextDisabled: false,
        photoLive: { ...photoLiveness },
      });
    }
  };

  handleScreenshot = (screenshot) => {
    console.log("screenshot", screenshot);
    let nextDisabled = false;
    screenshot.forEach((photo) => {
      if (photo.doc_type === 3 && (!photo.image && photo.is_mandatory)) {
        nextDisabled = true;
      }
    });
    this.setState({ ...this.state, screenshot: screenshot, nextDisabled });
  };

  getLivenessDoc = (pan, aadhaar) => {
    return pan && aadhaar
      ? [pan.id, aadhaar.id]
      : pan
        ? [pan.id]
        : [aadhaar.id];
  };

  handleUniqueCodeChecked = (isUniqueCodeChecked) => {
    this.setState({ isUniqueCodeChecked });

  };

  render() {
    const {
      activeIndex,
      nextDisabled,
      backDisabled,
      isLocationChecked,
      panCardCheckedArray,
      aadhaarCardCheckedArray,
      dlCheckedArray,
      questionnaire,
      photoLive,
      screenshot,
      isUniqueCodeChecked,
      uniqueCodeValidated
    } = this.state;
    console.log("questionnaire", nextDisabled);


    const docData = this.props.docData;

    const AadhaarData = docData?.find(
      (item) => item.document_details.doc_key === "aadhar"
    );
    const PanData = docData?.find(
      (item) => item.document_details.doc_key === "pan"
    );
    const dlData = docData?.find(
      (item) => item.document_details.doc_key === "driving_license" || item.document_details.doc_key === "driving license"
    );
    const livenessDoc =
      (AadhaarData || PanData) && this.getLivenessDoc(PanData, AadhaarData);
    const mandatoryDocs = (this.props.userDocsInfo || []).filter(doc => doc.is_active && doc.is_mandatory && doc.document_upload_event === "before_call")
    const CaseDetails = () => {
      return <>
        {this.state.caseFields.map(item => {
          return <div className="mt-2">
            <b>{_ToNormal(item.field_name)}</b>
            <div>{this.props.caseDetails?.[item.field_name] || ''}</div>
          </div>
        })}
        <hr />
      </>
    }
    return (
      <React.Fragment>
        {activeIndex !== 7 && (
          <h5 className="txt-blue ltr-spc-1">
            Verification Step {" " + (activeIndex + 1)}
          </h5>
        )}
        {activeIndex === 0 && <h6>All fields are compulsory</h6>}
        <hr className="dashed-brdr brdr-blue" />

        {activeIndex === 0 && (
          <>
            {this.props.clientConfig.is_unique_code && !uniqueCodeValidated ?
              <UniqueCode
                isUniqueCodeChecked={isUniqueCodeChecked}
                caseId={this.props.caseId}
                handleUniqueCodeChecked={this.handleUniqueCodeChecked}
                userId={this.props.userId}
                nextDisabled={nextDisabled}
                connectionId={this.props.connectionId}
              /> :
              <Questionnaire
                questionnaire={questionnaire}
                connectionId={this.props.connectionId}
                processId={this.props.processId}
                setQuestions={(ques) => {
                  this.updateNextAndBackStatus(ques);

                  this.setState({
                    ...this.state,
                    questionnaire: ques,
                  });
                }}
                userId={this.props.userId}
                caseId={this.props.caseId}
              />}
          </>
        )}

        {activeIndex === 1 && (
          <LocationDetails
            defaultValue={this.props.userLocation}
            isLocationChecked={isLocationChecked}
            caseId={this.props.caseId}
            handleLocationChecked={this.handleLocationChecked}
            userId={this.props.userId}
            nextDisabled={nextDisabled}
            connectionId={this.props.connectionId}
          />
        )}

        {activeIndex === 2 &&
          (PanData ? (
            <PanCardDetails
              defaultValue={
                this.hasCorrectedOcr(PanData)
                  ? PanData.corrected_ocr
                  : PanData.extracted_ocr
              }
              handleCheckArray={this.handleCheckArray}
              panCardCheckedArray={panCardCheckedArray}
              userId={this.props.userId}
              caseId={this.props.caseId}
              nextDisabled={nextDisabled}
              panData={PanData}
              CaseDetails={<CaseDetails />}
              panImage={PanData ? PanData.front_upload : ""}
              clientConfig={this.props.clientConfig}
            />
          ) : (
            <div className="border rounded p-4 bg-f9 mt-3">
              <h2 className="documents-verified-heading">
                PAN details could not be fetched
              </h2>


            </div>
          ))}

        {activeIndex === 3 &&
          (AadhaarData ? (
            <AadhaarCardDetails
              defaultValue={
                this.hasCorrectedOcr(AadhaarData)
                  ? AadhaarData.corrected_ocr
                  : AadhaarData.extracted_ocr
              }
              defaultValueDigilocker={AadhaarData.corrected_ocr.digilocker_data}
              handleCheckArray={this.handleCheckArray}
              aadhaarCardCheckedArray={aadhaarCardCheckedArray}
              userId={this.props.userId}
              aadhaarData={AadhaarData}
              caseId={this.props.caseId}
              aadhaarImage={AadhaarData ? AadhaarData.front_upload : ""}
              aadhaarImageBack={AadhaarData ? AadhaarData.back_upload : ""}
              nextDisabled={nextDisabled}
              CaseDetails={<CaseDetails />}
              clientConfig={this.props.clientConfig}
            />
          ) : dlData ? (
            <DrivingLicenseDetails
              defaultValue={
                this.hasCorrectedOcr(dlData)
                  ? dlData.corrected_ocr
                  : dlData.extracted_ocr
              }
              handleCheckArray={this.handleCheckArray}
              dlCheckedArray={dlCheckedArray}
              userId={this.props.userId}
              dlData={dlData}
              caseId={this.props.caseId}
              dlImage={dlData ? dlData.front_upload : ""}
              dlImageBack={dlData ? dlData.back_upload : ""}
              nextDisabled={nextDisabled}
            />
          ) : (
            <div className="border rounded p-4 bg-f9 mt-3">
              <h2 className="documents-verified-heading">
                Aadhar or Driving License details could not be fetched
              </h2>
            </div>
          ))}

        {activeIndex === 4 && (PanData || AadhaarData) && (
          <div>
            <PhotoLiveness
              takeScreenShot={this.props.takeScreenShot}
              doc_id={livenessDoc}
              score={photoLive.score}
              reTryCount={photoLive.reTryCount}
              checked={photoLive.checked}
              screenShot={photoLive.screenShot}
              updateRetry={this.updateRetry}
              caseId={this.props.caseId}
              userId={this.props.userId}
              connectionId={this.props.connectionId}
              photoLivenessChecked={this.handlePhotoLivenessChecked}
              processDocs={this.props.processDoc}
            />
          </div>
        )}

        {activeIndex === 5 && (
          <ScreenShot
            takeScreenShot={this.props.takeScreenShot}
            connectionId={this.props.connectionId}
            screenShot={screenshot}
            caseId={this.props.caseId}
            userId={this.props.userId}
            handleScreenshot={this.handleScreenshot}
          />
        )}
        {activeIndex === 6 && (
          <div className="border rounded p-4 bg-f9 mt-3">
            <h2 className="documents-verified-heading">
              <DocList caseId={this.props.caseId} />
            </h2>
          </div>
        )}
        {activeIndex === 7 && (
          <div className="border rounded p-4 bg-f9 mt-3">
            <h2 className="documents-verified-heading">
              All Documents Verified.
            </h2>
          </div>
        )}

        <div className="row mt-3 mb-3">
          <div className="col-md-6">
            <button
              // onClick={e => history.goBack()}
              disabled={backDisabled}
              onClick={this.handleBack}
              class={`btn btn-custom ${backDisabled ? "btn-secondary" : "btn-primary"
                } w-100-p`}
            >
              Back
            </button>
          </div>
          {activeIndex !== 7 && (
            <div className="col-md-6 text-right">
              <button
                // onClick={handleNext}
                disabled={nextDisabled}
                onClick={this.handleNext}
                className={`btn btn-custom ${nextDisabled ? "btn-secondary" : "btn-primary"
                  } w-100-p`}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  clientConfig: state.login.clientInfo,
  userDocsInfo: state?.userDocs?.anonymousUserConnectionInfo?.case_details?.process_details?.documents || []
})
export default connect(mapStateToProps)(VerificationCard);
