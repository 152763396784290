import React from "react";
import Moment from "react-moment";
import { connect } from "react-redux";

class ScheduledCases extends React.Component {
  render() {
    const { scheduledCasesData, clientInfo } = this.props;
    const { has_partners } = clientInfo

    return (
      <div
        className="tab-pane show active"
        id="scheduled"
        role="tabpanel"
        aria-labelledby="scheduled-tab"
      >
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <table className="table sml-blue-bg-header bg-sky-blue mb-1">
                  <thead className="all-head-center">
                    <tr>
                      <th width="16.66%">Case name</th>
                      <th width="16.66%">Case ID</th>
                      {has_partners ? <th width="10%">Partner</th> : null}
                      <th width="16.66%" className="text-center">
                        Scheduled Date
                      </th>
                      <th width="16.66%" className="text-center">
                        Scheduled by
                      </th>
                      <th width="16.66%" className="text-center">
                        Rescheduled date
                      </th>
                      <th width="16.66%" className="text-center">
                        Rescheduled by
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            {scheduledCasesData.map((scheduledCase) => {
              return (
                <div className="row">
                  <div className="col-md-12">
                    <div className="tbl-row">
                      <table className="table mb-0 sml-blue-bg-header table-striped odd-even-tbl roboto-regular">
                        <tbody className="all-body-center pt-7-pb-7-td">
                          <tr>
                            <td width="16.66%">{scheduledCase.name}</td>
                            <td width="16.66%">{scheduledCase.id}</td>
                            {has_partners ? <td width="10%">{scheduledCase.partner_name}</td> : null}
                            <td width="16.66%" align="center">
                              {scheduledCase.session && scheduledCase.session[
                                      scheduledCase.session.length - 1
                                    ] ? (
                                <Moment format="DD MMM YYYY LT">
                                  {
                                    scheduledCase.session[
                                      scheduledCase.session.length - 1
                                    ].scheduled_time
                                  }
                                </Moment>
                              ) : (
                                "N.A."
                              )}
                            </td>
                            <td width="16.66%" align="center">
                              {scheduledCase.scheduled_by_name
                                ? scheduledCase.scheduled_by_name
                                : "N.A."}
                            </td>
                            <td width="16.66%" align="center">
                              {scheduledCase.reschedule_date ? (
                                <Moment format="DD MMM YYYY LT">
                                  {scheduledCase.reschedule_date}
                                </Moment>
                              ) : (
                                "N.A."
                              )}
                            </td>
                            <td width="16.66%" align="center">
                              {scheduledCase.rescheduled_by_name
                                ? scheduledCase.rescheduled_by_name
                                : "N.A."}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  clientInfo: state.login.clientInfo
});
export default connect(mapStateToProps)(ScheduledCases);
