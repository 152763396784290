import React from 'react'
import Screenshots from './Screenshots';

function ScreenShotsContainer({screenshotArray,reportData, step}) {
  // if(screenshotArray.length === 0) return null
  return (
    <table
    width="100%"
    className="bg-white"
    style={{ border: "1px solid #b1b1b1" }}
  >
    <tr>
      <td style={{ padding: "0px" }}>
        <table width="100%">
          <tr>
            <td style={{ padding: "15px 15px 15px 15px" }}>
              <table width="100%">
                <tr>
                  <td style={{ padding: "0px 15px 0px 0px" }}>
                    <h4>
                    {step}. Screenshot & Liveliness
                      Check
                    </h4>
                  </td>
                </tr>
              </table>

              {screenshotArray.map((screenshot, index) => {
                return (
                  <React.Fragment>
                    <div
                      className={
                        (index + 1) % 5 === 0
                          ? "html2pdf__page-break"
                          : ""
                      }
                    />

                    <Screenshots
                      screenshotData={
                        reportData.documents[screenshot.index]
                      }
                    />
                  </React.Fragment>
                );
              })}
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  )
}

export default ScreenShotsContainer