import React from "react";
import OtpInput from 'react-otp-input';
import UserHeader from '../../userHeader/userHeader';
import './VerifyAadhaar.scss';

class VerifyAadhaar extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            otp: '',
            aadhaarUploaded: false
        }
    }
 
    handleChange = (otp) => {
        this.setState({ otp});
        if(otp.length === 4){
            this.props.IsAadhaarUploaded({aadhaarUploaded:true, otp});
        }
    }
    
    render(){
        const { otp } = this.state;
        return(
            <React.Fragment>                
                <UserHeader />
                <section className="aadhaarMainContainer">
        <div className="aadhaarSubContainer">
          <div className="aadhaar-row">
            <div >
              <h4 className="aadhaarHeaderText">Code based Upload</h4>
              <h5 className="aadhaarSubHeaderText">Please enter the code that you created at UIDAI website</h5>
            </div>
          </div>
          <div className="aadhaarOTPContainer">
          <OtpInput
                    value={otp}
                    onChange={this.handleChange}
                    numInputs={4}
                    separator={<span>&nbsp;</span>}
                    inputStyle={{backgroundColor: "#C5C5C5", 
                    border: '1px solid #C5C5C5', height: '80px', width: '58px',
                    marginLeft: '22px', color: '#5eb9ff', fontSize: '20px', fontWeight: 'bolder'     
                }}
                    // containerStyle={{backgroundColor: "red"}}
                />
            </div>
        </div>
        </section>
               
            </React.Fragment>
        )
    }
}

export default VerifyAadhaar;