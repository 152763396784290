import React from 'react';
import { ReactComponent as VideoIcon } from "../../../assets/icons/video-icon.svg";

function ThankYou(props) {

    return (
        <div className="wrapper light-blue-customer-template bg-dark-blue h-100">
        <section className="main-body m-auto" >
          <div className="container-fluid position-relative">
            <div className="row">
              <div className="col-md-12">
              <VideoIcon className="mt-4 mb-4" width="50" height="50" fill="#fff"/>
                <h4 className="text-white ltr-spc-1 text-center">Thank you!</h4>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default ThankYou;