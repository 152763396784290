import React, { Component } from "react";
import { connect } from "react-redux";
import { upload } from "../../state/user.actions";
import { ReactComponent as AadhaarPaperlessIcon } from "../../../../src/assets/icons/aadhaar-paperless.svg";
import { ReactComponent as QRIcon } from "../../../../src/assets/icons/qr-code.svg";
import { ReactComponent as DocsIcon } from "../../../../src/assets/icons/google-docs.svg";
import { ReactComponent as UploadIcon } from "../../../../src/assets/icons/upload-icon.svg";
import "react-html5-camera-photo/build/css/index.css";
import UserHeader from "../../userHeader/userHeader";

class AadhaarPaperlessOptions extends Component {
  state = {
    aadharUploaded: false,
    aadharData: {},
    panUploaded: false,
    panData: "",
    takePhoto: false,
    takeAadhaarPhoto: false,
    photoSide: "",
    documentId: 0,
    uploading: false,
    takeAadhaarPaperless: false,
  };

  setAadhaarPaperless = () => {
    this.props.setAadhaarPaperless(true);
  };

  scanQRCode = () => {
    this.props.setScanAadhaar(true);
  };

  setDigilockerAPI = () => {
    this.props.callDigilockerAPI("Aadhaar Card", {"document_name": "aadhar"});
  };

  render() {
    const { uploadTypes } = this.props;
    return (
      <React.Fragment>
        <div className="wrapper light-blue-customer-template">
          <UserHeader />
          <section className="main-body m-auto customer-flow-section">
            <div className="container-fluid mt-4">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="txt-light-blue ltr-spc-1">
                    Aadhaar Card Upload
                  </h4>
                  <h5 className="txt-light-black ltr-spc-1 mt-3 mb-3">
                    Use this section to upload your Aadhaar Card
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {uploadTypes.paperless_upload ? <div
                    className="white-box p-5-15 mt-3 pointer"
                    onClick={this.setAadhaarPaperless}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="left float-left d-flex align-items-center">
                      <span className="fingerprint-icon icon-light-blue">
                        <AadhaarPaperlessIcon width="20" />
                      </span>

                      <span className="txt-light-black fnt-sml ml-3 ltr-spc-0-5">
                        Aadhaar via OTP
                      </span>
                    </div>
                  </div> : null}
                 {uploadTypes.digilocker_upload ? <div
                    className="white-box p-5-15 mt-3 pointer"
                    onClick={this.setDigilockerAPI}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="left float-left d-flex align-items-center">
                      <span className="fingerprint-icon icon-light-blue">
                        <DocsIcon width="20" />
                      </span>

                      <span className="txt-light-black fnt-sml ml-3 ltr-spc-0-5">
                        Upload via Digilocker
                      </span>
                    </div>
                  </div> : null}
                 {uploadTypes.ocr_upload ? <div
                    className="white-box p-5-15 mt-3 pointer"
                    onClick={this.props.uploadViaBrowse}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="left float-left d-flex align-items-center">
                      <span className="fingerprint-icon icon-light-blue">
                        <UploadIcon width="20" />
                      </span>

                      <span className="txt-light-black fnt-sml ml-3 ltr-spc-0-5">
                        Aadhaar Image
                      </span>
                    </div>
                  </div> : null }
                   {/* <div
                  className="col-md-6 col-xs-6 col-lg-6 col-sm-6"
                  onClick={this.scanQRCode}
                  style={{ cursor: "pointer" }}
                >
                  <div className="border border-1 dashed-brdr brdr-light-blue rounded equal-box d-flex align-items-center p-4 bg-light justify-content-center fnt-sml flex-d-column">
                    <QRIcon width="30" />
                    <span className="txt-light-blue mt-2">
                      Aadhaar QR scanner
                    </span>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userDocs.userDocInfo,
  userConnectionInfo: state.userDocs.anonymousUserConnectionInfo,
});

const mapDispatchToProps = (dispatch) => ({
  upload: (payload) => dispatch(upload(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AadhaarPaperlessOptions);
