import React from "react";
import GreenTick from "../../assets/icons/green-tick.png";

class GeoTagging extends React.Component {
  render() {
    const { name, latitude, longitude, location, ip_address } = this.props;
    return (
      <>
        <table
          width="100%"
          style={{ marginTop: "15px", backgroundColor: "#fff" }}
        >
          <tr>
            <td>
              <h6>{name}</h6>
            </td>
          </tr>
        </table>
        <table
          width="100%"
          style={{ marginTop: "15px", backgroundColor: "#fff" }}
        >
          <tr>
            <td width="25%" style={{ padding: "0px 10px 0px 0px" }}>
              <table
                className="gray-table-rows"
                width="100%"
                style={{ backgroundColor: "#f8f8f8" }}
              >
                <tr>
                  <td width="60" style={{ paddingRight: "0px" }}>
                    Latitude
                  </td>
                  <td width="10" align="center">
                    :
                  </td>
                  <td width="70">{latitude}</td>
                </tr>
              </table>
            </td>
            <td width="25%" style={{ padding: "0px 10px 0px 10px" }}>
              <table
                className="gray-table-rows"
                width="100%"
                style={{ backgroundColor: "#f8f8f8" }}
              >
                <tr>
                  <td width="60" style={{ paddingRight: "0px" }}>
                    Longitude
                  </td>
                  <td width="10" align="center">
                    :
                  </td>
                  <td width="70">{longitude}</td>
                </tr>
              </table>
            </td>
            <td width="50%" style={{ padding: "0px 0px 0px 10px" }}>
              <table
                className="gray-table-rows"
                width="100%"
                style={{ backgroundColor: "#f8f8f8" }}
              >
                <tr>
                  <td width="80" style={{ paddingRight: "0px" }}>
                    IP Address
                  </td>
                  <td width="10" align="center">
                    :
                  </td>
                  <td width="140">{ip_address}</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
        <table
          className="gray-table-rows"
          width="100%"
          style={{ marginTop: "8px", backgroundColor: "#f8f8f8" }}
        >
          <tr>
            <td width="19%" style={{ paddingRight: "0px" }}>
              Address Fetched
            </td>
            <td width="1%" align="center">
              :
            </td>
            <td width="80%">{location}</td>
          </tr>
        </table>
        <table
          width="100%"
          style="margin-top:10px; font-size: 12px;"
          style={{ marginTop: "10px", fontSize: "12px" }}
        >
          <tr>
            <td width="30" style={{ padding: "0px", paddingRight: "0px" }}>
              <img src={GreenTick} width="20" height="19" alt="" />
            </td>
            <td
              style={{
                padding: "0px",
                paddingLeft: "0px",
                paddingRight: "10px",
              }}
            >
              <span
                style={{
                  color: "#24a749",
                  fontStyle: "italic",
                }}
              >
                Location Verified
              </span>
            </td>
          </tr>
        </table>
        <hr
          style={{
            borderTop: "1px dashed #707070 !important",
            borderBottom: "0px",
            marginTop: "20px",
            marginBottom: "20px",
            width: "100%",
          }}
        />
      </>
    );
  }
}

export default GeoTagging;
