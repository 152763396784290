import React from 'react'
import Moment from "react-moment";
import GreenTick from "../../assets/icons/green-tick.png";
import GrayTick from "../../assets/icons/gray-tick.png";

function CustomerDetails({reportData}) {
  return (
    <table width="100%" id="cutomer-details" className="m-auto mt-4 bg-ef">
              <tr>
                <td style={{ padding: "0px" }}>
                  <table width="100%">
                    <tr>
                      <td style={{ padding: "15px" }}>
                        <h4>{reportData.customers && reportData.customers.length > 1 ? 'Customers': 'Customer'} Details</h4>
                        {reportData.customers && reportData.customers.map(customer =>{
                          return <>
                          <table width="100%" style={{ marginTop: "8px" }}>
                            <tr>
                              <td
                                width="365"
                                style={{ padding: "0px 10px 0px 0px" }}
                              >
                                <table
                                  className="white-table-rows bg-white"
                                  width="365"
                                >
                                  <tr>
                                    <td
                                      width="135"
                                      style={{ paddingRight: "0px" }}
                                    >
                                      Full Name
                                    </td>
                                    <td width="10" align="center">
                                      :
                                    </td>
                                    <td width="175">
                                      {customer.name}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                              <td
                                width="365"
                                style={{ padding: "0px 0px 0px 10px" }}
                              >
                                <table
                                  className="white-table-rows bg-white"
                                  width="365"
                                >
                                  <tr>
                                    <td
                                      width="135"
                                      style={{ paddingRight: "0px" }}
                                    >
                                      Consent Date And Time
                                    </td>
                                    <td width="10" align="center">
                                      :
                                    </td>
                                    <td width="175">
                                      {customer
                                        .consent_datetime ? (
                                        <Moment format="DD MMM YYYY LT">
                                          {
                                            customer
                                              .consent_datetime
                                          }
                                        </Moment>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        {reportData.customers.length === 0 &&  <table width="100%" style={{ marginTop: "8px" }}>
                            <tr>
                              <td
                                width="365"
                                style={{ padding: "0px 10px 0px 0px" }}
                              >
                                <table
                                  className="white-table-rows bg-white"
                                  width="365"
                                >
                                  <tr>
                                    <td
                                      width="135"
                                      style={{ paddingRight: "0px" }}
                                    >
                                      Application Number
                                    </td>
                                    <td width="10" align="center">
                                      :
                                    </td>
                                    <td width="175">
                                      {customer.id}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                              <td
                                width="365"
                                style={{ padding: "0px 0px 0px 10px" }}
                              >
                                <table
                                  className="white-table-rows bg-white"
                                  width="365"
                                >
                                  <tr>
                                    <td
                                      width="135"
                                      style={{ paddingRight: "0px" }}
                                    >
                                      Process Type
                                    </td>
                                    <td width="10" align="center">
                                      :
                                    </td>
                                    <td width="175">
                                      {
                                        customer.case_details
                                          .process_details.name
                                      }
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>}
                          <table width="100%" style={{ marginTop: "8px" }}>
                            <tr>
                              <td
                                width="365"
                                style={{ padding: "0px 10px 0px 0px" }}
                              >
                                <table
                                  className="white-table-rows bg-white"
                                  width="365"
                                >
                                  <tr>
                                    <td
                                      width="135"
                                      style={{ paddingRight: "0px" }}
                                    >
                                      Mobile Number
                                    </td>
                                    <td width="10" align="center">
                                      :
                                    </td>
                                    <td width="175">
                                      {customer.mobile_number}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                              <td
                                width="365"
                                style={{ padding: "0px 0px 0px 10px" }}
                              >
                                <table
                                  className="white-table-rows bg-white"
                                  width="365"
                                >
                                  <tr>
                                    <td
                                      width="135"
                                      style={{ paddingRight: "0px" }}
                                    >
                                      Email
                                    </td>
                                    <td width="10" align="center">
                                      :
                                    </td>
                                    <td width="175">
                                      {customer.email}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                          <hr
                           style={{
                             borderTop: "1px dashed #707070 !important",
                             borderBottom: 0,
                             marginTop: "20px",
                             marginBottom: "20px",
                             width: "100%",
                           }}
                         />
                          </>
                        }) }
                        <table
                          className="white-table-rows bg-white"
                          width="100%"
                          style={{ marginTop: "8px" }}
                        >
                        {reportData.customers && reportData.customers.length === 0 && <>
                          <tr>
                            <td width="3%" style={{ paddingRight: "0px" }}>
                              Documents
                            </td>
                            <td width="1%" align="center">
                              :
                            </td>
                          </tr>
                          {reportData.documents &&
                            reportData.documents
                              .filter(
                                (doc) => !doc.document_details.is_agent_uploaded
                              )
                              .map((document) => (
                                <tr>
                                  <td
                                    width="3%"
                                    style={{ paddingRight: "0px" }}
                                  ></td>
                                  <td width="1%" align="center"></td>
                                  <td width="96%" style={{ padding: "0px" }}>
                                    <table width="100%">
                                      <tr>
                                        <td
                                          width="10"
                                          style={{
                                            paddingRight: "0px",
                                            paddingTop: "0px",
                                            paddingBottom: "3px",
                                          }}
                                        >
                                          <img
                                            src={
                                              document.is_verified
                                                ? GreenTick
                                                : GrayTick
                                            }
                                            alt=""
                                            width="15"
                                          />
                                        </td>
                                        <td
                                          style={{
                                            paddingLeft: "10px",
                                            paddingTop: "0px",
                                            paddingBottom: "3px",
                                          }}
                                        >
                                          <span>{document.document_name}</span>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              ))}
                              </>}
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
  )
}

export default CustomerDetails