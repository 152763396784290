import React from "react";
import TransferredCases from "./TransferredCases";

class TransferredCasesContainer extends React.Component {
  render() {
    const { transferredCasesData, agentAuditorList, consolidatedAgentList } =
      this.props;
    return (
      <div
        className="tab-pane show active"
        id="transfered"
        role="tabpanel"
        aria-labelledby="transfered-tab"
      >
        <div
          className="row no-gutters bg-light pt-2"
          style={{ backgroundColor: "#FBFBFB" }}
        ></div>
        <div className="row no-gutters">
          <TransferredCases
            transferredCasesData={transferredCasesData}
            handleViewDocuments={this.props.handleViewDocuments}
            agentAuditorList={agentAuditorList}
            transferCases={this.props.transferCases}
            getSearchObject={this.props.getSearchObject}
            searchKeyObject={this.props.searchKeyObject}
            handleSort={this.props.handleSort}
            handleDeleteCase={this.props.handleDeleteCase}
            consolidatedAgentList={consolidatedAgentList}
          />
        </div>
      </div>
    );
  }
}

export default TransferredCasesContainer;
