import * as React from "react";
import { ViewState } from "@devexpress/dx-react-scheduler";
import { ReactComponent as CalendarIcon } from '../../../../../assets/icons/calendar-icon.svg';
import {
  Scheduler,
  DayView,
  Appointments,
  Toolbar,
  TodayButton,
  CurrentTimeIndicator,
  DateNavigator,
  Resources
} from "@devexpress/dx-react-scheduler-material-ui";
import api from "../../../../../apiBaseConfig";
import {
  GET_CASES,
} from "../../../../../apiurl";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import classNames from "clsx";
import { IconButton } from "@material-ui/core";
import moment from "moment";


const DayViewTimeTableCell = withStyles(
  {},
  {
    name: "DayViewTimeTableCell"
  }
)(({ groupingInfo, classes, ...restProps }) => {
  return (
    <DayView.TimeTableCell
      className={classNames(classes.cellLowPriority)}
      {...restProps}
    />
  );
});
// #FOLD_BLOCK
const DayViewDayScaleCell = withStyles(
  {},
  {
    name: "DayViewDayScaleCell"
  }
)(
  ({
    groupingInfo,
    classes,
    ...restProps
    // #FOLD_BLOCK
  }) => {
    return (
      <DayView.DayScaleCell
        className={classNames(classes.headerCellLowPriority)}
        {...restProps}
      />
    );
  }
);

class DashboardCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      currentDate: moment(new Date()).format('yyyy-MM-DD'),
      currentViewName: "Day",
      data: [],
      currentPriority: 0
    };

  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    this.debounce(this.getCaseData(nextProps.caseType), 3000);
  }

  debounce = (func, delay) => {
    let debounceTimer
    return function () {
      const context = this
      const args = arguments
      clearTimeout(debounceTimer)
      debounceTimer
        = setTimeout(() => func.apply(context, args), delay)
    }
  }

  componentDidMount() {
    this.getCaseData(this.props.caseType);
  }


  getCaseData = async (caseType) => {
    const caseDataResponse = await api.get(
      GET_CASES + "?case_type=" + caseType + "&status=scheduled",
      { params: { page: 1 } }
    );
    // const totalCount = caseDataResponse.data.count / caseDataResponse.data.results;
    const data = caseDataResponse.data.results.reduce((formatedcase, caseData) => {
      const scheduleTime = caseData.session.length && caseData.session[caseData.session.length - 1].scheduled_time;
      const startDate = scheduleTime ? new Date(scheduleTime) : new Date()
      return [...formatedcase, {
        title: caseData.name,
        startDate: startDate,
        endDate: moment(startDate).add(30, 'minute').toDate(),
        id: caseData.id
      }]
    }, [])
    this.setState({ ...this.state, data: data });
  };


  currentDateChange = (currentDate) => {
    this.setState({ ...this.state, currentDate });
  };

  toggleSideBar = () => {
    this.setState({ ...this.state, open: !this.state.open })
  }

  render() {

    const { data, currentDate, resources, open } = this.state;
    // console.log(currentDate)
    return (
      <aside className={open ? ' w-30-p right-sidebar' : ' right-sidebar'} style={{ width: 0 }}>
        <div className="right-sidebar-inner">
          <IconButton className="calendar-icon" onClick={this.toggleSideBar}>
            <CalendarIcon />
          </IconButton>

          <div className="border today-schedule-box pr-3 bg-white" id="calendar_first" >
            <Paper>
              <Scheduler height={660} data={data}>
                <ViewState
                  currentDate={currentDate}
                  onCurrentDateChange={this.currentDateChange}
                />

                <DayView
                  startDayHour={1}
                  endDayHour={23}
                  timeTableCellComponent={DayViewTimeTableCell}
                  dayScaleCellComponent={DayViewDayScaleCell}
                  intervalCount={1}
                />

                <Appointments />
                {/* <Resources data={resources} /> */}
                <Toolbar />
                <CurrentTimeIndicator
                  shadePreviousCells={true}
                  shadePreviousAppointments={true}
                  updateInterval={1000}
                />
                <DateNavigator />
                <TodayButton />
              </Scheduler>
            </Paper>
          </div>

        </div>
      </aside>
    )
  }
}

export default DashboardCalendar;