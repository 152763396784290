import React from 'react'
import Each from '../../Each'
import cn from 'classnames'

export default function NavTabs({ colSize, tabs, active, onTabClick }) {
    return (
        <div className="col-md-12">
            <ul
                className="nav nav-tabs nav-tabs-plain transparent border-bottom brdr-db mt-2"
                id="myTabNested"
                role="tablist"
            >
                <Each of={tabs} render={(item, index) => {
                    return <li className="nav-item">
                        <span
                            className={cn('nav-link text-uppercase ltr-spc-1 fnt-sml', { 'active': active === item.id })
                            }
                            onClick={() => onTabClick(item.id)}
                        >
                            {item.name}
                        </span>
                    </li>
                }} />
            </ul>
        </div>
    )
}