import React, { useState, useEffect, useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import HighlightOff from '@material-ui/icons/HighlightOff';
import Send from '@material-ui/icons/Send';
import { useSelector } from 'react-redux';
import './ChimeChat.scss';
import { Tooltip } from '@material-ui/core';

function ChimeChat(props) {
  const {isChatOpen, newMessage} = props
  const [messageList, setMessageList] = useState([])
  const [message, setMessage] = useState('')
  let {name,connection} = useSelector(state => state.userDocs.anonymousUserConnectionInfo)
  if(!connection) connection = 'Agent'

  const chatsRef = useRef(null)

  useEffect(()=>{
    if('data' in newMessage){
      setMessageList(old => [...old, newMessage.data])
      scrollToBottom()
    }
  },[newMessage])

  useEffect(()=>{
    if(isChatOpen){
      scrollToBottom()
    }
  },[isChatOpen])


  const handlePressKey = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  }

  function sendMessage() {
    let sendingMessage = message
    if (sendingMessage) {
       sendingMessage = sendingMessage.replace(/ +(?= )/g, '');
       sendingMessage = sendingMessage.trim()
       if(sendingMessage.length == 0 ) return
    }
    let data = {
      connectionId:connection,
      data: message,
      createdDate: new Date().getTime(),
      nickname: props.isCustomer ? name : 'Agent'
    }
    props.sendMessage(data)
    setMessageList(old => [...old,data])
    scrollToBottom()
    setMessage('')
  }

  function scrollToBottom() {
    setTimeout(() => {
      try {
        chatsRef.current.scrollTop = chatsRef.current.scrollHeight;
      } catch (err) { }
    }, 20);
  }

  if(!isChatOpen) return null

  return (
    <div className='chat-container'>
      <div className='chatComponent'>
        <div id="chatToolbar">
          <span>Chat </span>
          <IconButton id="closeButton" onClick={() => props.close()}>
            <HighlightOff color="secondary" />
          </IconButton>
        </div>
        <div className="message-wrap" ref ={chatsRef} >
          {messageList.map((item, i) => (
            <div
              key={i}
              id="remoteUsers"
              className={
                'message' + (item.connectionId !== connection ? ' left' : ' right')
              }
            >
              <div className="msg-detail">
                <div className="msg-info">
                  <p> {item.connectionId == connection ? 'You' : item.nickname}</p>
                </div>
                <div className="msg-content">
                  <span className="triangle" />
                  <p className="text">{item.data}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div id="messageInput">
          <input
            placeholder="Send a messge"
            id="chatInput"
            value={message}
            onChange={e => setMessage(e.target.value)}
            onKeyPress={e => handlePressKey(e)}

          />
          <Tooltip title="Send message">
            <Fab size="small" id="sendButton" >
              <Send onClick={() => sendMessage()} />
            </Fab>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
export default ChimeChat

