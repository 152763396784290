import React from "react";
import ModuleDetails from "../moduleDetails/ModuleDetails";
import ModuleList from "../moduleList/ModuleList";
import ModuleUploadSuccess from "../moduleUploadSuccess/ModuleUploadSuccess";
import SURFACE_ICON from "../../../../../assets/icons/surface.svg";
import _get from "lodash/get";
import { UPLOAD_CASES } from "../../../../../apiurl";
import api from "../../../../../apiBaseConfig";
import Loader from "../../../../../components/loader/Loader";
import { Toast } from "../../../../../utils/toastutils";
import _ from "lodash";
import { connect } from "react-redux";


class ModuleUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModuleList: false,
      moduleListData: [],
      agentList: [],
      agentAuditorList: [],
      moduleUploadSuccess: false,
      selectedFile: null,
      isLoading: false,
      errorData: []
    };
  }

  fileInput = React.createRef();

  setShowModuleList = (showModuleList) => {
    this.setState({ showModuleList });
  };

  deleteModuleListData = (index) => {
    let moduleListData = this.state.moduleListData;
    moduleListData.splice(index, 1);
    this.setState({ moduleListData: moduleListData, showModuleList: moduleListData.length ? true : false });
  }

  setModuleListData = (newModuleListData) => {
    let moduleListData = this.state.moduleListData;
    debugger
    moduleListData.push(newModuleListData);
    this.setState({ moduleListData: moduleListData });
  };

  updateModuleListDataExisting = (newModuleListData, index) => {
    let moduleListData = this.state.moduleListData;
    moduleListData[index] = _.cloneDeep(newModuleListData);
    this.setState({ moduleListData: moduleListData });
  };


  receiveAgentAuditorsList = (agentList, agentAuditorList) => {
    this.setState({ agentList, agentAuditorList });
  };
  changeAgent = (value, index) => {
    const { agentList, agentAuditorList } = this.state;
    let moduleListData = this.state.moduleListData;
    let arr =
      moduleListData[index].case_type === "video_kyc"
        ? agentList
        : agentAuditorList;
    for (var i in arr) {
      if (arr[i].name === value) {
        moduleListData[index].agent = arr[i].id;
      }
    }
    this.setState({ moduleListData: moduleListData });
  };

  setModuleSuccess = (moduleUploadSuccess) => {
    this.setState({
      moduleUploadSuccess: moduleUploadSuccess,
      showModuleList: false,
      moduleListData: [],
    });
  };

  downloadSampleDocument = () => {
    window.open(this.props.clientInfo.sample_case_excel_path);
  };
  handleFileInput = (file) => {
    this.setState({ selectedFile: file });
    this.uploadCases(file);
  };
  uploadCases = async (file) => {
    try {
      this.setState({ isLoading: true });
      var formData = new FormData();

      formData.append("file", file);
      const { data } = await api.post(UPLOAD_CASES, formData);
      this.download("response.csv", data);
      console.log("cases uploaded successfully");
      Toast.success({
        title: "Cases uploaded successfully",
      });
      this.setState({ isLoading: false });
    } catch (error) {
      console.log("error in uploading cases");
      this.setState({ isLoading: false });
      Toast.error({
        title: "error in uploading cases",
      });
    }
  };
  download(filename, text) {
    var pom = document.createElement("a");
    pom.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(text)
    );
    pom.setAttribute("download", filename);

    if (document.createEvent) {
      var event = document.createEvent("MouseEvents");
      event.initEvent("click", true, true);
      pom.dispatchEvent(event);
    } else {
      pom.click();
    }
  }

  deleteCase = (index) => {
    let moduleListData = []
    moduleListData = [...this.state.moduleListData]
    moduleListData.splice(index, 1)
    this.setState({ moduleListData: moduleListData })
    if (moduleListData.length === 0) {
      this.setState({ showModuleList: false })
    }
  }

  updateModuleListData = (updatedData) => {
    let newData = [];
    let errorData = [];
    for (var i in updatedData) {
      let newObj = _.cloneDeep(updatedData[i].case_details);
      let errObj = _.cloneDeep(updatedData[i].error);
      newData.push(newObj);
      errorData.push(errObj);
    }
    this.setState({ moduleListData: newData, errorData: errorData })
  }
  render() {
    const {
      showModuleList,
      moduleListData,
      agentList,
      agentAuditorList,
      moduleUploadSuccess,
      errorData
    } = this.state;
    return (
      <React.Fragment>
        {moduleUploadSuccess ? (
          <ModuleUploadSuccess setModuleSuccess={this.setModuleSuccess} />
        ) : (
          <div class="row no-gutters">
            <div class="col-md-10 my-3">
              <h5 class="ml-30">Module Upload</h5>
            </div>
            <div class="col-md-2 my-3">
              <div
                class="custom-upload-btn"
              // onClick={() => this.props.setUploadExcel(true)}
              >
                {/* <input type="file" class="upload-input" /> */}
                <label className="custom-file-upload">
                  <input
                    type="file"
                    ref={this.fileInput}
                    onChange={(event) => {
                      this.handleFileInput(
                        _get(this.fileInput.current, "files[0]")
                      );
                    }}
                    accept={
                      "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    }
                  />
                  <span>
                    <i class="fa fa-cloud-upload-alt mr-1"></i> Upload Excel
                  </span>
                </label>
              </div>
              <div onClick={() => this.downloadSampleDocument()}>
                <img src={SURFACE_ICON} class="ml-3" width="10" alt=""></img>
                <span class="txt-light-blue2 font-italic fnt-sml-x">
                  Template Download
                </span>
              </div>
            </div>
            <div class="col-md-12">
              {showModuleList ? (
                <ModuleList
                  setShowModuleList={this.setShowModuleList}
                  moduleListData={moduleListData}
                  agentList={agentList}
                  deleteModuleListData={this.deleteModuleListData}
                  agentAuditorList={agentAuditorList}
                  changeAgent={this.changeAgent}
                  setModuleSuccess={this.setModuleSuccess}
                  deleteCase={this.deleteCase}
                  updateModuleListData={this.updateModuleListData}
                  updateModuleListDataExisting={
                    this.updateModuleListDataExisting
                  }

                  errorData={errorData}
                />
              ) : (
                <ModuleDetails
                  primaryClassName={"upload-excel-form-cntr ml-30 mb-3 pb-4"}
                  showButtons={true}
                  setShowModuleList={this.setShowModuleList}
                  setModuleListData={this.setModuleListData}
                  sendAgentAuditorsList={this.receiveAgentAuditorsList}
                />
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  clientInfo: state.login.clientInfo
});
export default connect(mapStateToProps)(ModuleUpload);
