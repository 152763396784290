import React, { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Exclamation from "../../../assets/icons/exclamation-icon.svg";
import Greentick from "../../../assets/icons/green-circle-tick.svg";
import HardwareTest from "../hardwareTest/HardwareTest";
import useGeoLocation from "./useGeoLocation";
import useSpeedTest from "./useSpeedTest";
import { ReactComponent as LocationSVG } from "../../../assets/icons/locationSVG.svg"
import { ReactComponent as AudioVideo } from "../../../assets/icons/audio-video.svg"
import { ReactComponent as NetworkSVG } from "../../../assets/icons/networkSVG.svg"
import "./Diagnostic.scss";

const Diagnostic = (props) => {
  const [isHidden, setIsHidden] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [isNotChecked, setIsNotChecked] = useState(false);
  const [videoValue, setVideoValue] = useState("");
  const [internetValue, setInternetValue] = useState("");
  const location = useGeoLocation();
  const downloadSpeed = useSpeedTest();

  console.log("downloadSpeed.....", downloadSpeed);

  const handleReCheck = () => {
    setIsHidden(true);
    setIsNotChecked(false);
  };

  return (
    <>
      <div className="wrapper light-blue-customer-template">
        <section className="main-body m-auto" style={{ width: "460px" }}>
          <div className="container-fluid mt-2">
            <div className="row">
              <div className="col-md-12">
                <div className="card border-0 box-shadow fnt-open-sans-s-bld">
                  <div className="card-header bg-light-blue border-0">
                    <span className="text-white">
                      Diagnostic Check
                    </span>
                  </div>
                  <div className="card-body bg-light-gray">
                    <div className="d-flex align-items-center justify-content-between mb-3 red">
                      <div className="d-flex align-items-center">
                        <span
                          className="text-center mr-3"
                          style={{ width: "50px" }}
                        >
                          <span className="location-icon">
                            <LocationSVG />
                          </span>
                        </span>
                        <span className="fnt-sml txt-666">
                          Checking location access
                        </span>
                      </div>
                      {/* <button
                        type="button"
                        className="btn btn-light-blue allow"
                        style={{ marginLeft: "28px" }}
                      >
                        Check
                      </button> */}
                      {location.locationValue === "" ? (
                        <CircularProgress size={20} color="secondary" />
                      ) : (
                        <img
                          src={
                            location.locationValue === "allowed"
                              ? Greentick
                              : Exclamation
                          }
                          width="16"
                          alt=""
                        />
                      )}
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <div className="d-flex align-items-center">
                        <span
                          className="text-center mr-3"
                          style={{ width: "50px" }}
                        >
                          <span className="audio-video-icon">
                            <AudioVideo />
                          </span>
                        </span>
                        <span className="fnt-sml txt-666">
                          Checking audio/video access
                        </span>
                      </div>
                      {isNotChecked ? (
                        <button
                          onClick={() => handleReCheck()}
                          type="button"
                          className="btn recheck-btn"
                          style={{ marginLeft: "9px" }}
                        >
                          Recheck
                        </button>
                      ) : null}

                      {videoValue === "" ? (
                        <CircularProgress size={20} color="secondary" />
                      ) : (
                        <img
                          src={
                            videoValue === "allowed" ? Greentick : Exclamation
                          }
                          width="16"
                          alt=""
                        />
                      )}
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <div className="d-flex align-items-center">
                        <span
                          className="text-center mr-3"
                          style={{ width: "50px" }}
                        >
                          <span className="network-icon">
                            <NetworkSVG />
                          </span>
                        </span>
                        <span className="fnt-sml txt-666">
                          Checking internet connection
                        </span>
                      </div>
                      {downloadSpeed < 500 ? (
                        <button
                          onClick={() => { }}
                          type="button"
                          className="btn recheck-btn"
                          style={{ marginLeft: "0px" }}
                        >
                          Recheck
                        </button>
                      ) : null}
                      {downloadSpeed.internetValue === "" ? (
                        <CircularProgress size={20} color="secondary" />
                      ) : (
                        <img
                          src={
                            downloadSpeed.speed > 500 &&
                              downloadSpeed.internetValue === "allowed"
                              ? Greentick
                              : Exclamation
                          }
                          width="16"
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="justify-content-center">
          {isHidden ? (
            <HardwareTest
              isHidden={isHidden}
              setIsHidden={setIsHidden}
              setIsChecked={setIsChecked}
              setIsNotChecked={setIsNotChecked}
              setVideoValue={setVideoValue}
              lang={props.lang}
            />
          ) : null}
        </div>
        {location.locationValue === "allowed" &&
          isChecked &&
          downloadSpeed.internetValue === "allowed" ? (
          <div className="d-flex justify-content-center">
            <button
              onClick={() => props.setDiagnosticCheck()}
              type="button"
              className="btn btn-light-blue next-button"
            >
              Next
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Diagnostic;