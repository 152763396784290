import React, { Component } from "react";
import "./CustomerConsent.scss";
import Card from "@material-ui/core/Card";
import { Button } from "@material-ui/core";
import HFCLIcon from "../../../assets/icons/hfcl-icon.svg";
//consent screen
class CustomerConsent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conditionsArray: [false, false],
      allChecked: false,
    };
  }

  handleConsentChange = (index) => {
    const { conditionsArray } = this.state;
    conditionsArray[index] = !conditionsArray[index];
    let allChecked = this.getAllChecked(conditionsArray);
    this.setState({ conditionsArray: conditionsArray, allChecked: allChecked });
  };

  getAllChecked = (conditionsArray) => {
    let allChecked = true;
    for (var i in conditionsArray) {
      if (!conditionsArray[i]) {
        allChecked = false;
      }
    }
    return allChecked;
  };
  render() {
    const { conditionsArray, allChecked } = this.state;
    const { isMulti } = this.props;

    return (
      <div className="wrapper light-blue-customer-template">
        <header>
          <nav className="navbar navbar-expand-lg h-100 navbar-light bh-white">
            <div className="float-left">
              {/* <a href="#" className="text-white mr-2">
                <i className="fas fa-chevron-left"></i>
              </a> */}
              <i className="fas fa-video text-white"></i>
            </div>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <a href="#" className="nav-link"></a>
                </li>
              </ul>
            </div>
            <div className="logoborder p-1 mr-1 bg-white">
              <img style={{ width: "6em", margin: "5px" }} src={HFCLIcon} alt=""></img>
            </div>
          </nav>
        </header>
        <section className="main-body m-auto">
          <div className="container-fluid mt-4">
            <div className="row">
              <div className="col-md-12">
                <h4 className="txt-light-blue ltr-spc-1">Customer consent</h4>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="border border-1 dashed-brdr brdr-light-blue rounded p-4 mb-5">
                  <div
                    className="border brdr-c5 p-4 rounded"
                    style={{ maxHeght: "300px", overflowY: "auto" }}
                  >
                    <p className="txt-light-blue">Terms &amp; Conditions</p>
                    <p className="fnt-sml txt-light-black">
                      CONSENT- I hereby give my consent to the Hero Fincorp and
                      its service partner to process my Personal and Sensitive
                      Personal Data (as given below) for the purpose of my
                      On-Boarding via Video-Customer Identification Process
                      <ul>
                        <li>
                          {" "}
                          Access to my device’s camera, microphone, and
                          geolocation
                        </li>
                        <li>
                          Processing and capturing my National Identity document
                          such as PAN & Aadhaar,
                        </li>
                        <li>
                          {" "}
                          Processing and capturing any other documents (as
                          applicable),
                        </li>
                        <li>
                          Recording of my video call and chat with the agent,
                        </li>
                        {!isMulti && (
                          <li>
                            Matching my face with the photograph on National
                            Identity
                          </li>
                        )}
                      </ul>
                    </p>
                    {!isMulti && (
                      <p className="fnt-sml txt-light-black">
                        Non-adherence to any of the above requirements would
                        lead to the failure of the Video KYC process. Please try
                        to hold your device upright and still during the call.
                        The data shared during the Video KYC process would
                        remain completely anonymous and would not be shared with
                        any third party.
                      </p>
                    )}
                  </div>
                  <div className="custom-checkbox-normal ml-3 mt-2">
                    {/* <input type="checkbox" id="accept" />
                    <label htmlFor="accept" className="txt-light-black fnt-sml">
                      I permit XX to start the Video KYC
                    </label> */}
                    {conditionsArray.map((value, key) => {
                      return (
                        <div className="d-flex align-items-center" key={key}>
                          <input
                            checked={value}
                            onChange={() => this.handleConsentChange(key)}
                            type="checkbox"
                            className="checkbox-round"
                            // style={{
                            //   background: value ? "#5eb9ff" : "white",
                            //   border: value ? "1px solid #777" : ""
                            // }}
                          ></input>
                          <label className="txt-light-black fnt-sml font-weight-bold">
                            {key === 0
                              ? 
                              <React.Fragment>
                                I have read the terms & conditions and the <a href="../privacy" target="_blank">"Privacy Notice"</a>
                              </React.Fragment>
                              
                              : 
                              <React.Fragment>
                                I provide my consent to the terms & conditions and the <a href="../privacy" target="_blank">"Privacy Notice"</a>
                              </React.Fragment>
                            }
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Button
          variant="contained"
          className="proceed-button"
          disabled={!allChecked}
          color="secondary"
          onClick={() => this.props.handleConsentChange()}
        >
          Proceed
        </Button>
      </div>
    );
  }
}
export default CustomerConsent;
