import React from "react";
import CancelWhiteIcon from "../../../../../assets/icons/cancel-white.svg";
import AddIcon from "../../../../../assets/icons/add-icon.svg";
import RemoveIcon from "../../../../../assets/icons/remove-icon.png";
import Moment from "react-moment";
import { Toast } from "../../../../../utils/toastutils";
import api from "../../../../../apiBaseConfig";
import {
  GET_CASES,
  ADD_PARTICIPANTS,
  GET_CASE_CONNECTIONS,
} from "../../../../../apiurl";
import Loader from "../../../../../components/loader/Loader";

class GroupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      participantBoxOpen: false,
      toggleAddAnotherUser: false,
      toggleDownArrow: false,
      assignedCases: props.assignedCases,
      participantListToggleArr: [],
      newUserInfo: {
        email: "",
        guarantor_coapplicant_id: "",
        mobile_number: "",
        name: "",
        participant_tag: "",
        source_system_ucic: "",
        error: { name: false, email: false, mobile: false }
      },
      anotherUserLoader: false
    };
  }

  componentDidMount = () => {
    this.getAllConnection();
    this.initializeNewUserInfo();
  };

  componentWillReceiveProps = (nextProps) => {
    this.getAllConnection();
  };

  getAllConnection = async () => {
    let assignedCases = this.props.assignedCases;
    const { data } = await api.get(
      GET_CASES +
      `?source_system_application_no=${assignedCases.source_system_application_no}`
    );
    console.log(data);
    const allConnect = data.results.reduce((acc, cur) => {
      if (cur.connections.length === 1) {
        acc = { ...acc, [cur.connections[0].connection]: cur.status };
      }
      return acc;
    }, {});
    assignedCases.connections = assignedCases.connections
      ? assignedCases.connections.map((connect) => {
        connect.caseStatus = allConnect[connect.parent_connection_id]
          ? allConnect[connect.parent_connection_id]
          : "New";
        return connect;
      })
      : [];
    this.setState({ assignedCases: assignedCases });
  };

  initializeNewUserInfo = () => {
    const { assignedCases } = this.props;
    const participants = assignedCases.connections || [];
    const participantListToggleArr = new Array(participants.length).fill(false);
    let newUserInfo = {
      email: "",
      guarantor_coapplicant_id: "",
      mobile_number: "",
      name: "",
      participant_tag: "",
      source_system_ucic: "",
      error: { name: false, email: false, mobile: false }
    };
    this.setState({
      participantListToggleArr: participantListToggleArr,
      newUserInfo: newUserInfo,
    });
  };

  handleParticipantsClick = () => {
    let participantBoxOpen = this.state.participantBoxOpen;
    this.setState({ participantBoxOpen: !participantBoxOpen });
  };

  toggleAddAnotherUser = (toggleAddAnotherUser) => {
    this.setState({ toggleAddAnotherUser });
  };

  toggleDownArrow = (key) => {
    let participantListToggleArr = this.state.participantListToggleArr;
    participantListToggleArr[key] = !participantListToggleArr[key];
    this.setState({ participantListToggleArr: participantListToggleArr });
  };

  addAnotherUser = async (e) => {
    e.preventDefault();
    if (this.props.assignedCases.connections && this.props.assignedCases.connections.length > 7) {
      if (!window.confirm("Adding more users may impact the performance and quality of the video call, do you still wish to add?")) {
        return;
      }
    }
    this.setState({ anotherUserLoader: true })
    const { assignedCases } = this.props;
    const { newUserInfo } = this.state;
    const isNewUserInfoValid = true //this.isNewUserInfoValid(newUserInfo);
    if (isNewUserInfoValid) {
      let body = {
        "participant_tag": newUserInfo.participant_tag,
        "name": newUserInfo.name,
        "email": newUserInfo.email,
        "mobile_number": newUserInfo.mobile_number
      }

      // if (newUserInfo.participant_tag !== "resparticipant") {
      //   body["source_system_ucic"] = newUserInfo.source_system_ucic;
      //   body["guarantor_coapplicant_id"] = newUserInfo.guarantor_coapplicant_id;
      // }
      try {
        await api.post(`${ADD_PARTICIPANTS}${assignedCases.id}/`, body);
        this.props.updateCaseData();
        this.setState({ anotherUserLoader: false })
      }
      catch (error) {
        this.setState({ anotherUserLoader: false })
      }
      this.initializeNewUserInfo();
    } else {
      this.setState({ anotherUserLoader: false })
      Toast.error({
        title: "Please provide valid inputs.",
      });
    }
  };
  isNewUserInfoValid = (newUserInfo) => {
    const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regexName = /^[a-zA-Z\s]*$/;
    let validEmail =
      newUserInfo.email &&
      regexEmail.test(String(newUserInfo.email).toLowerCase()) &&
      newUserInfo.email.length > 5 &&
      newUserInfo.email.length <= 60;
    let validMobileNumber =
      !isNaN(newUserInfo.mobile_number) &&
      newUserInfo.mobile_number.length === 10;
    let validName = newUserInfo.name && regexName.test(newUserInfo.name);

    let allValid =
      validName &&
      validEmail &&
      validMobileNumber &&
      newUserInfo.participant_tag !== "";
    return allValid ? allValid : false;
  };

  newUserInfo = (val, tag) => {
    let newUserInfo = this.state.newUserInfo;
    newUserInfo[tag] = val;
    // if (val === "resparticipant") {
    //   newUserInfo.source_system_ucic = "";
    //   newUserInfo.guarantor_coapplicant_id = "";
    // }
    this.setState({ newUserInfo: newUserInfo });
  };
  resetNewUser = (e) => {
    e.preventDefault();
    let newUserInfo = {
      email: "",
      guarantor_coapplicant_id: "",
      mobile_number: "",
      name: "",
      participant_tag: "",
      // source_system_ucic: "",
      error: { name: false, email: false, mobile: false }
    };
    this.setState({ newUserInfo: newUserInfo });
  };

  setParticipantRole = async (index, role) => {
    const { assignedCases } = this.props;
    this.setState({ anotherUserLoader: true })
    const participants = assignedCases.connections || [];
    const connectionId = participants[index].connection;
    try {
      await api.patch(`${GET_CASE_CONNECTIONS}${connectionId}/`, {
        participant_tag: role,
      });
      this.props.updateCaseData();
      this.setState({ anotherUserLoader: false })
    } catch (e) {
      this.setState({ anotherUserLoader: false })
    }
  };

  render() {
    const {
      participantBoxOpen,
      toggleAddAnotherUser,
      participantListToggleArr,
      newUserInfo,
      anotherUserLoader
    } = this.state;
    const { assignedCases } = this.props;
    const participants = assignedCases.connections || [];
    const lastParticipant = participants[participants.length - 1];
    return (
      <div
        className="modal fade show"
        id="cstmrDtls"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: "block", paddingRight: "15px" }}
      >
        {anotherUserLoader && <Loader
          title="Please Wait"
        />}
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ maxWidth: "1320px" }}
        >
          <div className="modal-content rounded-15">
            <div className="modal-header p-0 pr-2 pt-2 border-0 justify-content-end">
              <span
                aria-hidden="true"
                className="red-close float-right"
                onClick={() => this.props.handleModal(false, "")}
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src={CancelWhiteIcon} width="8" alt="" />
              </span>
            </div>
            <div className="modal-body p-0 cstmr-dtls-popup-box">
              <div className="ml-30 mr-30">
                <div className="row">
                  <div className="col-md-12">
                    <h5>
                      <span className="light-blue-sml-circle rounded-circle p-3 bg-purple text-white mr-2">
                        {
                          assignedCases.agent_name.split(" ")[0].charAt(0) +
                          (assignedCases.agent_name.split(" ")[1] ? assignedCases.agent_name.split(" ")[1].charAt(0) : '')
                        }
                      </span>
                      {/* <span className="txt-92">
                        {assignedCases ? assignedCases.name : ""}
                      </span> */}
                      <span class="d-inline-flex flex-column">
                        <span class="txt-666">
                          {assignedCases ? assignedCases.name : ""}
                        </span>
                        <span class="txt-blue fnt-sml roboto-regular">
                          Number of Attempts:{" "}
                          {assignedCases.reschedule_attempt +
                            assignedCases.case_attempts_by_agent}
                        </span>
                      </span>
                    </h5>
                  </div>
                </div>
                <div className="row mt-3 mb-5">
                  <div className="col-md-6">
                    <div className="row no-gutters">
                      <div className="col-md-7 basic-dtls">
                        <div className="bg-f5 rounded border brdr p-3">
                          <h6
                            className="txt-blue-dull"
                            style={{ paddingLeft: "5px" }}
                          >
                            Basic Details
                          </h6>
                          <table width="100%">
                            <tr>
                              <td width="40%">Allocation Date & time</td>
                              <td width="3%" align="center">
                                :
                              </td>
                              <td width="57%">
                                <Moment format="DD MMM YYYY LT">
                                  {assignedCases
                                    ? assignedCases.updated_at
                                    : ""}
                                </Moment>
                              </td>
                            </tr>
                            <tr>
                              <td width="40%">Line of business</td>
                              <td width="3%" align="center">
                                :
                              </td>
                              <td width="57%">
                                {assignedCases.line_of_business}
                              </td>
                            </tr>

                            {/* <tr>
                              <td width="40%">Applicant office address</td>
                              <td width="3%" align="center">
                                :
                              </td>
                              <td width="57%">
                                {assignedCases.office_address}
                              </td>
                            </tr> */}
                            <tr>
                              <td width="40%">Allocation date</td>
                              <td width="3%" align="center">
                                :
                              </td>
                              <td width="57%">Value</td>
                            </tr>
                            <tr>
                              <td width="40%">Admin Comments</td>
                              <td width="3%" align="center">
                                :
                              </td>
                              <td width="57%">
                                {
                                  assignedCases && assignedCases.admin_comment &&
                                  <div className="border p-2 text-break">
                                    {assignedCases.admin_comment}
                                  </div>
                                }
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div className="col-md-5 basic-dtls">
                        <div
                          className="bg-white rounded border brdr p-3"
                          style={{ minHeight: "211px" }}
                        >
                          <h6
                            className="txt-blue-dull"
                            style={{ paddingLeft: "5px" }}
                          >
                            Documents List
                          </h6>
                          <table width="100%">
                            {assignedCases.process_details.documents.map(
                              (document) => {
                                return (
                                  !document.is_agent_uploaded && (
                                    <tr>
                                      <td className="border-0 d-flex align-items-center">
                                        <span className="txt-blue-dull">
                                          {document.name}
                                        </span>
                                        <span className="dahsed-h-line col"></span>
                                        <span className="tick-icon-green-sml"></span>
                                      </td>
                                    </tr>
                                  )
                                );
                              }
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 note-icon-email">
                    <h6 className="txt-blue-dull">Participant List</h6>
                    <div className="row">
                      <div className="col-md-12">
                        <table className="table sml-black-bg-header bg-black mb-0 table-layout-fixed">
                          <thead className="all-head-center">
                            <tr>
                              <th width="18%" className="text-left">
                                User Name
                              </th>
                              <th width="25%" className="text-left">
                                Email
                              </th>
                              <th width="15%" className="text-left">
                                Mobile
                              </th>
                              <th width="15%" className="text-center">
                                Status
                              </th>
                              <th width="20%" className="text-center">
                                Role
                              </th>
                              <th width="13%" className="text-right"></th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                    {participants.map((participant, key) => {
                      return (
                        <div className="row" key={key}>
                          <div className="col-md-12">
                            <div className="tbl-row rounded-0">
                              <table className="table mb-0 sml-black-bg-header table-striped odd-even-tbl roboto-regular table-layout-fixed">
                                <tbody className="all-body-center pt-7-pb-7-td">
                                  <tr className="bg-fb">
                                    <td width="18%" className="text-left">
                                      {participant.name}
                                    </td>
                                    <td width="25%" className="text-left">
                                      {participant.email}
                                    </td>
                                    <td width="15%" className="text-left">
                                      {participant.mobile_number}
                                    </td>
                                    <td width="15%" align="center">
                                      <span className="status complete">
                                        {participant.caseStatus}
                                      </span>
                                    </td>
                                    <td width="20%" align="center">
                                      <select
                                        className="status none d-inline-flex align-items-center dropdown bordered dropdown-toggle dropleft"
                                        onChange={(e) =>
                                          this.setParticipantRole(
                                            key,
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option
                                          value=""
                                          selected={
                                            participant.participant_tag === ""
                                              ? true
                                              : false
                                          }
                                        >
                                          Select
                                        </option>
                                        <option
                                          value="applicant"
                                          selected={
                                            participant.participant_tag ===
                                              "applicant"
                                              ? true
                                              : false
                                          }
                                        >
                                          Applicant
                                        </option>
                                        <option
                                          value="coapplicant"
                                          selected={
                                            participant.participant_tag ===
                                              "coapplicant"
                                              ? true
                                              : false
                                          }
                                        >
                                          Co-Applicant
                                        </option>
                                        <option
                                          value="resparticipant"
                                          selected={
                                            participant.participant_tag ===
                                              "resparticipant"
                                              ? true
                                              : false
                                          }
                                        >
                                          RE's Participant
                                        </option>
                                      </select>
                                    </td>
                                    <td width="13%" align="center">
                                      <span
                                        className={
                                          participantListToggleArr[key]
                                            ? "arrow-acc active"
                                            : "arrow-acc"
                                        }
                                        onClick={() =>
                                          this.toggleDownArrow(key)
                                        }
                                      ></span>
                                    </td>
                                  </tr>
                                  <tr
                                    className={
                                      participantListToggleArr[key]
                                        ? "relative-acc active"
                                        : "relative-acc"
                                    }
                                    style={{ border: '1px solid lightgrey' }}
                                  >
                                    <td colspan="6">
                                      <table className="mb-0">
                                        <tr>
                                          <td align="left">Agent:</td>
                                          {/* <td>
                                            <span className="light-blue-sml-circle rounded-circle p-3 bg-purple text-white mr-2">
                                              {
                                                assignedCases.agent_name.split(" ")[0].charAt(0) +
                                                (assignedCases.agent_name.split(" ")[1] ? assignedCases.agent_name.split(" ")[1].charAt(0) : '')
                                              }
                                            </span>
                                          </td> */}
                                          <td align="left" className="txt-92">
                                            {assignedCases.agent_name}
                                          </td>
                                          <td align="left">Agent ID:</td>
                                          <td align="left" className="txt-92">
                                            {assignedCases.agent}
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <div className="row add-user-form-buttons">
                      <div className="col-md-10">
                        <button
                          className="btn btn-light-blue-secondary poppins-rglr mr-3 align-items-center add-btn"
                          onClick={() => this.toggleAddAnotherUser(true)}
                          style={{
                            display: toggleAddAnotherUser ? "none" : "",
                          }}
                        >
                          <img src={AddIcon} className="mr-1" alt="" />
                          New User
                        </button>
                        <button
                          className="btn btn-light-blue-secondary poppins-rglr mr-3 align-items-center remove-btn"
                          style={{
                            display: toggleAddAnotherUser ? "" : "none",
                          }}
                          onClick={() => this.toggleAddAnotherUser(false)}
                        >
                          <img src={RemoveIcon} className="mr-1" alt="" />
                          Close
                        </button>
                      </div>
                    </div>
                    <div
                      id="add-user-form"
                      className="row mt-3"
                      style={{ display: toggleAddAnotherUser ? "" : "none" }}
                    >
                      <div className="col-md-10">
                        <form action="" className="add-user-form">
                          <div className="row">
                            <div className="col-md-4 d-flex align-items-center">
                              <label className={newUserInfo.error.name ? "error txt-blue-dull" : "txt-blue-dull"} htmlFor="">
                                User name
                              </label>
                            </div>
                            <div className="col-md-8">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={newUserInfo.name}
                                  onChange={(e) => {
                                    if (!new RegExp(/^[A-Za-z]{2,60}[A-Za-z\s]{0,60}$/i).test(e.target.value)) {
                                      this.newUserInfo({ ...newUserInfo.error, name: true }, "error")
                                    } else {
                                      this.newUserInfo({ ...newUserInfo.error, name: false }, "error")
                                    }
                                    this.newUserInfo(e.target.value, "name")
                                  }
                                  }
                                  maxLength={60}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4 d-flex align-items-center">
                              <label className={newUserInfo.error.mobile ? "error txt-blue-dull" : "txt-blue-dull"} htmlFor="">
                                User Mobile Number
                              </label>
                            </div>
                            <div className="col-md-8">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={newUserInfo.mobile_number}
                                  onChange={(e) => {
                                    if (!new RegExp(/^[0-9]{10}$/i).test(e.target.value)) {
                                      this.newUserInfo({ ...newUserInfo.error, mobile: true }, "error")
                                    } else {
                                      this.newUserInfo({ ...newUserInfo.error, mobile: false }, "error")
                                    }
                                    this.newUserInfo(
                                      e.target.value,
                                      "mobile_number"
                                    )
                                  }
                                  }
                                  maxLength={10}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4 d-flex align-items-center">
                              <label className={newUserInfo.error.email ? "error txt-blue-dull" : "txt-blue-dull"} htmlFor="">
                                User Email Address
                              </label>
                            </div>
                            <div className="col-md-8">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={newUserInfo.email}
                                  onChange={(e) => {
                                    if (!new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i).test(e.target.value)) {
                                      this.newUserInfo({ ...newUserInfo.error, email: true }, "error")
                                    } else {
                                      this.newUserInfo({ ...newUserInfo.error, email: false }, "error")
                                    }
                                    this.newUserInfo(e.target.value, "email")
                                  }
                                  }
                                  maxLength={60}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4 d-flex align-items-center">
                              <label className="txt-blue-dull" htmlFor="">
                                User Role
                              </label>
                            </div>
                            <div className="col-md-8">
                              <div className="form-group">
                                <select
                                  name=""
                                  id=""
                                  className="form-control"
                                  value={newUserInfo.participant_tag}
                                  onChange={(e) =>
                                    this.newUserInfo(
                                      e.target.value,
                                      "participant_tag"
                                    )
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="applicant">Applicant</option>
                                  <option value="coapplicant">
                                    Co-Applicant
                                  </option>
                                  <option value="resparticipant">
                                    RE's Participant
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3 mb-3">
                            <div className="col-md-6">
                              <button
                                className="btn btn-custom btn-dark btn-light-black w-100-p"
                                disabled=""
                                style={{ width: "94px" }}
                                onClick={(e) => this.resetNewUser(e)}
                              >
                                Reset
                              </button>
                            </div>
                            <div className="col-md-6 text-right">
                              <button
                                className="btn btn-custom btn-light-blue w-100-p"
                                disabled={!(newUserInfo.name && newUserInfo.mobile_number && newUserInfo.email && newUserInfo.participant_tag.length)
                                  || newUserInfo.error.email || newUserInfo.error.mobile || newUserInfo.error.name}
                                style={{ width: "94px" }}
                                onClick={(e) => this.addAnotherUser(e)}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GroupModal;
