import React from "react";
import QrReader from "react-qr-reader";
import TorchIcon from "../../../../src/assets/icons/torch-icon.svg";
import ImageIcon from "../../../../src/assets/icons/image-icon.svg";
import BackArrowIcon from "../../../../src/assets/icons/back-arrow.svg";
import AadhaarPaperlessIcon from "../../../../src/assets/icons/aadhaar-paperless.svg";

export default class AadhaarQRScan extends React.Component {
  state = {
    result: "No result",
  };
  handleScan = (data) => {
    if (data) {
      this.setState({
        result: data,
      });
    }
  };
  handleError = (err) => {
    console.error(err);
  };

  setAadhaarPaperless = () => {
    this.props.setAadhaarPaperless(true);
  };

  render() {
    return (
      <div className="wrapper light-blue-customer-template">
        <div className="camera-screen h-100 ">
          <div className="header p-3 d-flex">
            <a href="#" className="text-white mr-2">
              <i className="fas fa-chevron-left"></i>
            </a>
            <span className="txt-light-blue">Scan QR Code</span>
          </div>
          <div className="qr-view-box mt-3">
            <div className="qr-view p-3">
              <QrReader
                delay={300}
                onError={this.handleError}
                onScan={this.handleScan}
                style={{ width: "100%" }}
              />
            </div>
            <div className="qr-view-side-icons">
              <img src={TorchIcon} width="26" alt="" className="mb-3" />
              <img src={ImageIcon} width="26" alt="" />
            </div>
            <span className="qr-view-caption">Scan Aadhaar Card Code</span>
          </div>
          <div className="qr-screen-footer">
            <h5 className="txt-light-black ltr-spc-1 mt-3 mb-3">
              Upload your Aadhaar card via
            </h5>
            <div className="row">
              <div className="col-6">
                <div
                  className="border border-1 dashed-brdr brdr-light-blue rounded equal-box d-flex align-items-center p-1 bg-light justify-content-center fnt-sml flex-d-column"
                  onClick={this.setAadhaarPaperless}
                >
                  <img src={AadhaarPaperlessIcon} width="20" alt="" />
                  <span className="txt-light-blue mt-2">Aadhaar Paperless</span>
                </div>
              </div>
              <div className="col-6">
                <div className="border border-1 dashed-brdr brdr-light-blue rounded equal-box d-flex align-items-center p-4 bg-primary justify-content-center fnt-sml flex-d-column">
                  <img src={BackArrowIcon} width="20" alt="" />
                  <span className="txt-light-blue mt-2">Back</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
