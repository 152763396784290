import React, { useEffect, useState } from 'react'
import API from '../../../../../../apiBaseConfigNew'
import {
  GET_AGENT_AUDITORS,
  GET_AGENTS
} from "../../../../../../apiurl";
export default function AgentDrop(props) {
  const [agentList, setAgentList] = useState([])
  const [auditorList, setAuditorList] = useState([])
  const [activeList, setActiveList] = useState([])
  const { processType, selectAgent, selectedAgent, error, processList } = props
  useEffect(() => {
    getAgentAuditorsList()
  }, [])
  useEffect(() => {
    if (agentList.length > 0 || auditorList.length > 0)
      handleActiveList()
  }, [processType])
  function handleActiveList() {
    let list = []
    if (processList.length > 0 && processType != '') {
      if (processList?.[processType]?.case_type === "video_kyc")
        list = agentList
      else if (processList?.[processType]?.case_type === "personal_discussion")
        list = auditorList
    }
    else list = []
    setActiveList(list)
  }
  const getAgentAuditorsList = async () => {
    try {
      const [agentList, agentAuditorList] = await Promise.all([
        API.get(GET_AGENTS),
        API.get(GET_AGENT_AUDITORS),
      ]);
      setAgentAuditorsList(agentList.data, agentAuditorList.data);
    } catch (error) {
      console.log("error in fetching agent list", error);
    }
  };
  function setAgentAuditorsList(agentData, auditorData) {

    let auditorList = [];
    let agentList = [];
    for (var i in auditorData) {
      let newObj = {};
      newObj.id = auditorData[i].id;
      newObj.name = auditorData[i].first_name + " " + auditorData[i].last_name;
      auditorList.push(newObj);
    }
    for (var i in agentData) {
      if (agentData[i].agent_details.role === "agent") {
        let newObj = {};
        newObj.id = agentData[i].agent_details.id;
        newObj.name = agentData[i].agent_details.first_name + " " + agentData[i].agent_details.last_name;
        agentList.push(newObj);
      }
    }
    setAgentList(agentList)
    setAuditorList(auditorList)

  };
  return (
    <div className='form-group'>
      <label>Agent</label>
      <select className='form-control' value={selectedAgent} onChange={selectAgent}>
        <option value="" selected disabled>Select Agent</option>
        {activeList.map((item, index) => {
          return <option key={index} value={item.id}>{item.name}</option>
        })}
      </select>
      {error && error.length > 0 ? <p className='form-error'>{error}</p> : null}
    </div>
  )
}
