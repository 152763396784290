import React, { useEffect, useState } from "react";
const NetworkSpeed = require("network-speed");
const testNetworkSpeed = new NetworkSpeed();

const useSpeedTest = () => {
  const [downloadSpeed, setDownloadSpeed] = useState({
    internetValue: "",
    speed: "",
  });
  // const [uploadSpeed, setUploadSpeed] = useState([]);

  useEffect(() => {
    getNetworkDownloadSpeed();
  }, []);

  async function getNetworkDownloadSpeed() {
    const baseUrl = "https://eu.httpbin.org/stream-bytes/500000";
    const fileSizeInBytes = 500000;
    const speed = await testNetworkSpeed.checkDownloadSpeed(
      baseUrl,
      fileSizeInBytes
    );

    setDownloadSpeed({
      internetValue: "allowed",
      speed: speed.kbps,
    });
  }

  // getNetworkUploadSpeed();

  // async function getNetworkUploadSpeed() {
  //   const options = {
  //     hostname: "www.google.com",
  //     port: 80,
  //     path: "/catchers/544b09b4599c1d0200000289",
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   const fileSizeInBytes = 2000000;
  //   const speed = await testNetworkSpeed.checkUploadSpeed(
  //     options,
  //     fileSizeInBytes
  //   );
  //   console.log("getNetworkUploadSpeed...", speed);
  //   setUploadSpeed(speed.kbps);
  // }
  return downloadSpeed;
};

export default useSpeedTest;