import React, { useEffect, useState, useRef } from 'react';
import './style.scss'
import cn from 'classnames'

const Dropdown = ({ options, onSelect, children, label, id, isToggle, isActive, className }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const dropRef = useRef(null)
  useEffect(()=>{
    if(isActive){
        setIsOpen(true)
    }
  },[isActive])
  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleClickOutside = (e) => {
    if (dropRef.current && !dropRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  }

  return (
    <div className={cn("custom-dropdown",className)} id={id} ref={dropRef}>
      {label ? <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
        {selectedOption || 'Select an option'}
        <i className={`arrow ${isOpen ? 'up' : 'down'}`} />
      </div> : isToggle ? null :
        <span className='custom-dropdown-toggle' onClick={() => setIsOpen(!isOpen)}>
           <span className='fa fa-ellipsis-v'  />
        </span> }
      {isOpen && !children ?
        <div className="options">
          {options && options.map((option) => (
            <div
              key={option}
              className="option"
              onClick={() => handleSelect(option)}
            >
              {option}
            </div>
          ))}
        </div> : <>
          {isOpen ?
            <div className="options" onClick={e=>setIsOpen(false)}>
              {children}
            </div> : null}

        </>

      }
    </div>
  );
};

export default Dropdown;