import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Grid, Paper, IconButton, Button } from "@material-ui/core";
import { upload, deletePan, deleteAadhar } from "../../state/user.actions";

import "react-html5-camera-photo/build/css/index.css";
import Loader from "../../../components/loader/Loader";

import "./DocumentsUpload.scss";
import _get from "lodash/get";

import AadhaarUploadOptions from "../aadhaarPaperless/AadhaarUploadOptions";
import { Toast } from "../../../utils/toastutils";
import {
  FETCH_DOCS_VIA_DIGILOCKER,
  MIGRATE_DOCS_VIA_DIGILOCKER,
} from "../../../apiurl";
import api from "../../../apiBaseConfig";
import PanUploadOptions from "./PanUploadOptions";
import DrivingLicense from "../drivingLicense/DrivingLicense";
class DocumentsUpload extends Component {
  state = {
    aadharUploaded: false,
    aadharData: {},
    dlData: {},
    panUploaded: false,
    panData: "",
    takePhoto: false,
    takeAadhaarPhoto: false,
    takeDLPhoto: false,
    photoSide: "",
    documentId: 0,
    uploading: false,
    locationAccess: true,
    digilockerAccessed: false,
    tsTransID: "",
    showAadhaarBrowseOption: false,
  };

  componentDidUpdate() {
    // debugger;
    if (
      this.props.userInfo.panData &&
      this.props.userInfo.panData.data &&
      !this.props.userInfo.panUploaded
    ) {
      this.props.history.push("/user/verify/pan");
    }
    if (
      this.props.userInfo.aadharData &&
      this.props.userInfo.aadharData.data &&
      !this.props.userInfo.aadharUploaded
    ) {
      // this.props.history.push("/user/verify/aadhaar");
      this.props.history.push("/user/verify/aadhaar_ocr");
    }
    if (
      this.props.userInfo.dlData &&
      this.props.userInfo.dlData.data &&
      !this.props.userInfo.dlUploaded
    ) {
      this.props.history.push("/user/verify/dl_ocr");
    }
  }

  componentDidMount() {
    if (!_get(this.props, "location.state.id", false)) {
      this.props.history.push("/user");
    }
    this.checkLocation();
  }

  checkLocation() {
    navigator.geolocation.getCurrentPosition(
      async function (position) {
        console.log("done");
      },
      (e) => {
        this.setState({ locationAccess: false });
      },
      { timeout: 10000 }
    );
  }

  handleTakePhoto = (data, document_upload_type) => {
    this.props.deletePan();
    this.setState({
      panUploaded: true,
      panData: data,
      takePhoto: false,
      uploading: true,
    });
    this.props.upload({
      type: "PAN",
      data,
      connection: this.props.userConnectionInfo.id,
      document_upload_type: document_upload_type,
      documentId: this.props.location.state.id,
      case: this.props.userConnectionInfo.case_details.id,
    });
  };

  handleAadhaar = async (
    data,
    side,
    documentId,
    document_upload_type,
    digilocker_data
  ) => {
    // debugger;
    const { aadharData } = this.state;
    aadharData[side] = { data: data, documentId };
    this.setState(
      {
        aadharData: aadharData,
        takeAadhaarPhoto: false,
        photoSide: "",
      },
      () => {
        if (aadharData["front"] && aadharData["back"]) {
          this.setState(
            {
              panUploaded: true,
              panData: data,
              takePhoto: false,
              uploading: true,
            },
            () =>
              this.props.upload({
                type: "AADHAR",
                data: aadharData,
                documentId: this.props.location.state.id,
                connection: this.props.userConnectionInfo.id,
                document_upload_type: document_upload_type,
                case: this.props.userConnectionInfo.case_details.id,
                digilocker_data:
                  document_upload_type === "digilocker_upload"
                    ? digilocker_data
                    : {},
              })
          );
        } else {
          // await this.props.deleteAadhar();
        }
      }
    );
  };

  handleDL = async (data, side, documentId, document_upload_type) => {
    // debugger;
    const { dlData } = this.state;
    dlData[side] = { data: data, documentId };
    this.setState(
      {
        dlData: dlData,
        takeDLPhoto: false,
        photoSide: "",
      },
      () => {
        if (dlData["front"] && dlData["back"]) {
          this.setState(
            {
              panUploaded: true,
              panData: data,
              takePhoto: false,
              uploading: true,
            },
            () =>
              this.props.upload({
                type: "DRIVING_LICENSE",
                data: dlData,
                documentId: this.props.location.state.id,
                connection: this.props.userConnectionInfo.id,
                document_upload_type: document_upload_type,
                case: this.props.userConnectionInfo.case_details.id,
              })
          );
        } else {
          // await this.props.deleteAadhar();
        }
      }
    );
  };
  static readUploadedFileAsDataURL = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
  };

  uploadFile = async (event, isAadhaar, side, documentId) => {
    event.persist();
    if (!event.target || !event.target.files) {
      return;
    }
    const file = event.target.files[0];
    if (file.type === "text/xml") {
      this.props.history.push({ pathname: "./verify/aadhaar", state: file });
    } else if (file.type.includes("image")) {
      try {
        const fileContent = await DocumentsUpload.readUploadedFileAsDataURL(
          file
        );
        if (isAadhaar) {
          this.handleAadhaar(fileContent, side, documentId, "ocr_upload", {});
        } else {
          this.handleTakePhoto(fileContent, "normal_upload");
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  uploadFileDL = async (event, side, documentId) => {
    event.persist();
    if (!event.target || !event.target.files) {
      return;
    }
    const file = event.target.files[0];
    if (file.type === "text/xml") {
      this.props.history.push({ pathname: "./verify/aadhaar", state: file });
    } else if (file.type.includes("image")) {
      try {
        const fileContent = await DocumentsUpload.readUploadedFileAsDataURL(
          file
        );
        this.handleDL(fileContent, side, documentId, "ocr_upload");
      } catch (e) {
        console.log(e);
      }
    }
  };

  handleBackNavigation = () => {
    this.props.history.goBack();
  };

  redirectToVerify = () => {
    this.props.history.push("/user/verify/aadhaar");
  };

  callDigilockerAPI = async (docName,data) => {
    const response = await api.post(FETCH_DOCS_VIA_DIGILOCKER,{...data});

    if (response.data.url) {
      this.setState({
        digilockerAccessed: true,
        tsTransID: response.data.ts_trans_id,
      });
      window.open(response.data.url);
    } else if (response.data.docs) {
      this.processDocument(docName, response.data.docs);
    }
  };

  processDocument = (docName, documents) => {
    let docNotFound = true;
    for (var i in documents) {
      if (
        documents[i].document_name === docName &&
        documents[i].document_file_image !== ""
      ) {
        docNotFound = false;
        if (docName === "Aadhaar Card") {
          this.handleAadhaar(
            documents[i].document_file_image,
            "front",
            this.props.location.state.id,
            "digilocker_upload",
            documents[i].digilocker_data
          );
          this.handleAadhaar(
            documents[i].document_file_image,
            "back",
            this.props.location.state.id,
            "digilocker_upload",
            documents[i].digilocker_data
          );
        } else if (docName === "Driving License") {
          this.handleDL(
            documents[i].document_file_image,
            "front",
            this.props.location.state.id,
            "digilocker_upload"
          );
          this.handleDL(
            documents[i].document_file_image,
            "back",
            this.props.location.state.id,
            "digilocker_upload"
          );
        } else {
          this.handleTakePhoto(
            documents[i].document_file_image,
            "digilocker_upload"
          );
        }
      }
    }
    if (docNotFound) {
      Toast.error({
        description: "Details not found in Digilocker.",
        title: "Please try another option.",
      });
    }
  };

  sendTransID = async () => {
    const { location } = this.props;
    let propReceived =
      (location && location.state && location.state.type) || "pan";
    const docName =
      propReceived === "pan"
        ? "PAN Verification Record"
        : propReceived === "driving_license"
        ? "Driving License"
        : "Aadhaar Card";
    const response = await api.post(MIGRATE_DOCS_VIA_DIGILOCKER, {
      ts_trans_id: this.state.tsTransID,
      document_name: propReceived === "pan" ? "pan" : propReceived === "driving_license" ? "driving_license" : "aadhar"
    });
    this.processDocument(docName, response.data.docs);
  };

  uploadViaBrowse = () => {
    this.setState({ showAadhaarBrowseOption: true });
  };

  takePhotoBrowseUploadAadhaar = () => {
    this.setState({ takePhoto: true });
  };

  takePhotoBrowseUploadDL = () => {
    this.setState({ takePhoto: true });
  };

  takePhotoBrowseUploadPan = () => {
    this.props.deletePan();
    this.setState({ takePhoto: true });
  };
  setPhotoSideBrowseAadhaar = () => {
    this.setState({
      takeAadhaarPhoto: true,
      photoSide: "back",
      documentId: 3,
    });
  };
  setPhotoSideBrowseDL = () => {
    this.setState({
      takeDLPhoto: true,
      photoSide: "back",
      documentId: 3,
    });
  };
  handleIconButton = () => {
    this.setState({
      ...this.state,
      takeAadhaarPhoto: false,
      takeDLPhoto: false,
      takePhoto: false,
    });
  };
  render() {
    const { classes, location } = this.props;
    const {
      takePhoto,
      aadharData,
      dlData,
      takeAadhaarPhoto,
      takeDLPhoto,
      photoSide,
      documentId,
      uploading,
      locationAccess,
      digilockerAccessed,
    } = this.state;
    let propReceived =
      (location && location.state && location.state.type) || "pan";
    const isAadhaar = propReceived !== "pan" ? true : false;
    const documentName = propReceived === "pan" ? "PAN Card" : "Aadhar Card";
    const uploadFileType = "image/*";
    
    return (
      <React.Fragment>
        {uploading && (
          <Loader title={"Fetching your details, Please wait"} />
          // "Uploading your " + propReceived + " please wait"
        )}
        {!locationAccess && (
          <div className="location-error">
            Location access blocked, allow access to device location to continue
            with your KYC process
          </div>
        )}
        {digilockerAccessed && (
          <div className="loader-container" style={{ zIndex: 999 }}>
            <p style={{ color: "#2FA5DF", fontSize: "1.8em" }}>
              Please provide access to documents via Digilocker, after logging
              in to Digi-Locker.
            </p>
            <div style={{ display: "flex" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this.setState({ digilockerAccessed: false });
                  this.sendTransID();
                }}
              >
                YES
              </Button>
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: "20px" }}
                onClick={() => {
                  this.setState({ digilockerAccessed: false });
                }}
              >
                NO
              </Button>
            </div>
          </div>
        )}
        {propReceived === "aadhar" && (
          <AadhaarUploadOptions
            docId={this.props.location.state.id}
            redirectToVerify={this.redirectToVerify}
            callDigilockerAPI={this.callDigilockerAPI}
            uploadViaBrowse={this.uploadViaBrowse}
            documentName={documentName}
            isAadhaar={isAadhaar}
            uploadFileType={uploadFileType}
            takePhotoBrowseUploadAadhaar={this.takePhotoBrowseUploadAadhaar}
            takePhotoBrowseUploadPan={this.takePhotoBrowseUploadPan}
            aadharData={aadharData}
            setPhotoSideBrowseAadhaar={this.setPhotoSideBrowseAadhaar}
            takePhoto={takePhoto}
            takeAadhaarPhoto={takeAadhaarPhoto}
            handleIconButton={this.handleIconButton}
            photoSide={photoSide}
            documentId={documentId}
            uploadFile={this.uploadFile}
            handleAadhaar={this.handleAadhaar}
            handleTakePhoto={this.handleTakePhoto}
          />
        )}

        {propReceived === "pan" && (
          <PanUploadOptions
            documentName={documentName}
            isAadhaar={isAadhaar}
            uploadFileType={uploadFileType}
            callDigilockerAPI={this.callDigilockerAPI}
            takePhotoBrowseUploadAadhaar={this.takePhotoBrowseUploadAadhaar}
            takePhotoBrowseUploadPan={this.takePhotoBrowseUploadPan}
            aadharData={aadharData}
            setPhotoSideBrowseAadhaar={this.setPhotoSideBrowseAadhaar}
            takePhoto={takePhoto}
            takeAadhaarPhoto={takeAadhaarPhoto}
            handleIconButton={this.handleIconButton}
            photoSide={photoSide}
            documentId={documentId}
            uploadFile={this.uploadFile}
            handleTakePhoto={this.handleTakePhoto}
          />
        )}

        {propReceived === "driving_license" && (
          <DrivingLicense
            docId={this.props.location.state.id}
            documentName={documentName}
            isAadhaar={isAadhaar}
            uploadFileType={uploadFileType}
            callDigilockerAPI={this.callDigilockerAPI}
            takePhotoBrowseUploadDL={this.takePhotoBrowseUploadDL}
            takePhotoBrowseUploadPan={this.takePhotoBrowseUploadPan}
            dlData={dlData}
            setPhotoSideBrowseDL={this.setPhotoSideBrowseDL}
            takePhoto={takePhoto}
            takeDLPhoto={takeDLPhoto}
            handleIconButton={this.handleIconButton}
            photoSide={photoSide}
            documentId={documentId}
            uploadFileDL={this.uploadFileDL}
            handleDL={this.handleDL}
            handleTakePhoto={this.handleTakePhoto}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userDocs.userDocInfo,
  userConnectionInfo: state.userDocs.anonymousUserConnectionInfo,
});

const mapDispatchToProps = (dispatch) => ({
  deletePan: () => dispatch(deletePan()),
  deleteAadhar: () => dispatch(deleteAadhar()),
  upload: (payload) => dispatch(upload(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsUpload);
