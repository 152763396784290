import React, { useState } from 'react'


export default function ProcessDrop(props) {
    const { processType, setProcess, error,list } = props
    // const [list, setList] = useState([{ name: 'video_kyc', label: 'VKYC' }, { name: 'personal_discussion', label: 'VPD' }])
 
    return (
        <div className='form-group'>
            <label>Process</label>
            <select className='form-control' value={processType} onChange={setProcess}>
            <option value='' selected disabled>Select Process</option>
                {list.map((item, index) => {
                    return <option key={index} value={index}>{item.name}</option>
                })}
            </select>
            {error && error.length > 0 ? <p className='form-error'>{error}</p> : null}
        </div>
    )
}
