import React from "react";

class CompletedTabs extends React.Component {
  render() {
    const { activeLowerTab } = this.props;
    return (
      <div className="col-md-6">
        <div className="ml-30">
          <ul
            className="nav nav-tabs nav-tabs-plain transparent border-bottom brdr-db mt-2"
            id="myTabNested"
            role="tablist"
          >
            <li className="nav-item" style={{ marginLeft: "0" }}>
              <span
                className={
                  activeLowerTab === 0
                    ? "nav-link active text-uppercase ltr-spc-1 fnt-sml"
                    : "nav-link text-uppercase ltr-spc-1 fnt-sml"
                }
                id="approved-tab"
                href="#approved"
                data-toggle="tab"
                onClick={() => this.props.changeActiveLowerTab(0)}
              >
                Approved
              </span>
            </li>
            <li className="nav-item">
              <span
                className={
                  activeLowerTab === 1
                    ? "nav-link active text-uppercase ltr-spc-1 fnt-sml"
                    : "nav-link text-uppercase ltr-spc-1 fnt-sml"
                }
                id="rejected-tab"
                href="#rejected"
                data-toggle="tab"
                onClick={() => this.props.changeActiveLowerTab(1)}
              >
                Rejected
              </span>
            </li>
            <li className="nav-item">
              <span
                className={
                  activeLowerTab === 2
                    ? "nav-link active text-uppercase ltr-spc-1 fnt-sml"
                    : "nav-link text-uppercase ltr-spc-1 fnt-sml"
                }
                id="pending-tab"
                href="#pending"
                data-toggle="tab"
                onClick={() => this.props.changeActiveLowerTab(2)}
              >
                Pending
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default CompletedTabs;
