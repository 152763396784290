import React, { Component } from "react";
import { connect } from "react-redux";
import { upload } from "../../state/user.actions";
import { ReactComponent as DocsIcon } from "../../../../src/assets/icons/google-docs.svg";
import { ReactComponent as UploadIcon } from "../../../../src/assets/icons/upload-icon.svg";
import "react-html5-camera-photo/build/css/index.css";
import UserHeader from "../../userHeader/userHeader";
import cogoToast from "cogo-toast";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
class DLUploadOptions extends Component {
  state = {
    uploadTypes: { ocr_upload: false, digilocker_upload: false },
    dl_number: "",
    DlModal: false
  }
  componentDidMount() {
    this.setUploadTypes()
  }
  setUploadTypes() {
    let documents = this.props?.userConnectionInfo?.case_details?.process_details?.documents
    if (documents) {
      let clientTypes = documents.filter(item => item.doc_key === "driving_license")
      this.setState({ uploadTypes: clientTypes[0] })
    }
  }
  setDigilockerAPI = () => {
    let { dl_number } = this.state
    let data = {
      "document_name": "driving_license",
      "dl_number": dl_number.trim()
    }

    if (dl_number.trim().length < 4) {
      cogoToast.error('Please Enter a valid DL number')
      return
    }
    this.setState({DlModal:false})
    this.props.callDigilockerAPI("Driving License", data);
  };

  render() {
    const { uploadTypes, DlModal } = this.state
    return (
      <React.Fragment>
        <div className="wrapper light-blue-customer-template">
          <UserHeader />
          <section className="main-body m-auto customer-flow-section">
            <div className="container-fluid mt-4">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="txt-light-blue ltr-spc-1">
                    Driving License Upload
                  </h4>
                  <h5 className="txt-light-black ltr-spc-1 mt-3 mb-3">
                    Use this section to upload your Driving License
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {uploadTypes.digilocker_upload ? <div
                    className="white-box p-5-15 mt-3 pointer"
                    onClick={()=>this.setState({DlModal:true,dl_number:''})}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="left float-left d-flex align-items-center">
                      <span className="fingerprint-icon icon-light-blue">
                        <DocsIcon width="20" />
                      </span>

                      <span className="txt-light-black fnt-sml ml-3 ltr-spc-0-5">
                        Upload via Digilocker
                      </span>
                    </div>
                  </div> : null}

                  {uploadTypes.ocr_upload ? <div
                    className="white-box p-5-15 mt-3 pointer"
                    onClick={this.props.uploadViaBrowse}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="left float-left d-flex align-items-center">
                      <span className="fingerprint-icon icon-light-blue">
                        <UploadIcon width="20" />
                      </span>

                      <span className="txt-light-black fnt-sml ml-3 ltr-spc-0-5">
                        Upload via Image
                      </span>
                    </div>
                  </div> : null}
                </div>
              </div>
            </div>
          </section>
        </div>
        <Dialog
          aria-labelledby="customized-dialog-title"
          maxWidth="md"
          open={DlModal}
        >
          <div className="align-self-end px-4">
            <IconButton
              aria-label="close"
              onClick={() => this.setState({ DlModal: false })}
            >
              <CloseIcon color="error" />
            </IconButton>
          </div>
          <DialogContent>
            <div className="row">
              <div className="col-md-12">
                Enter Your DL Details
              </div>
              <div className="col-md-12">
                <label className="txt-light-black fnt-sml">DL Number</label>
                <input className="form-control" placeholder="Enter DL Number" onChange={e => this.setState({ dl_number: e.target.value })} value={this.state.dl_number} />
              </div>
              <div className="col-md-12 pt-2 pb-4">
                <button className="btn btn-primary" onClick={e => this.setDigilockerAPI()}>Submit</button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userDocs.userDocInfo,
  userConnectionInfo: state.userDocs.anonymousUserConnectionInfo,
});

const mapDispatchToProps = (dispatch) => ({
  upload: (payload) => dispatch(upload(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DLUploadOptions);
