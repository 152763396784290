import React from "react";
import PieChart from "./PieChart";
import AgentCompletedCases from "./AgentCompletedCases";
import AgentTAT from "./AgentTAT";
import ApprovedRejectedCases from "./ApprovedRejectedCases";

class ChartsContainer extends React.Component {
  render() {
    const { adminData } = this.props;
    if(!adminData) return null
    return (

      <div>
        <div>
          <div id="myTab">
            <div id="vkyc">
              <div>
                <div>
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="row">
                            <div className="col-md-6 text-center">
                              <div className="bg-fb rounded p-2">
                                <h6
                                  className="txt-blue-2 fnt-roboto-bld mt-1 ltr-spc-1"
                                  style={{ fontSize: "14px" }}
                                >
                                  AGENT
                                </h6>
                                <h2
                                  className="txt-blue-2 fnt-roboto-bld mt-1 roboto-medium ltr-spc-1 mb-0"
                                  style={{ fontSize: "42px" }}
                                >
                                  {adminData.agent_active_cases
                                    ? adminData.agent_active_cases
                                    : "0"}
                                </h2>
                                <div className="fnt-sml txt-70">Active Cases</div>
                                <div className="pl-3 pr-3">
                                  <hr className="brdr-dashed mt-2 mb-2" />
                                </div>
                                <h2
                                  className="txt-blue-2 fnt-roboto-bld mt-1 roboto-medium ltr-spc-1 mb-0"
                                  style={{ fontSize: "42px" }}
                                >
                                  {adminData.agent_completed_cases
                                    ? adminData.agent_completed_cases
                                    : "0"}
                                </h2>
                                <div className="fnt-sml txt-70">Completed Cases</div>
                              </div>
                            </div>
                            <div className="col-md-6 text-center">
                              <div className="bg-f0 rounded p-2">
                                <h6
                                  className="txt-blue-2 fnt-roboto-bld mt-1 ltr-spc-1"
                                  style={{ fontSize: "14px" }}
                                >
                                  AUDITOR
                                </h6>
                                <h2
                                  className="txt-blue-2 fnt-roboto-bld mt-1 roboto-medium ltr-spc-1 mb-0"
                                  style={{ fontSize: "42px" }}
                                >
                                  {adminData.auditor_active_cases
                                    ? adminData.auditor_active_cases
                                    : "0"}
                                </h2>
                                <div className="fnt-sml txt-70">Pending Audit</div>
                                <div className="pl-3 pr-3">
                                  <hr className="brdr-dashed mt-2 mb-2" />
                                </div>
                                <h2
                                  className="txt-blue-2 fnt-roboto-bld mt-1 roboto-medium ltr-spc-1 mb-0"
                                  style={{ fontSize: "42px" }}
                                >
                                  {adminData.auditor_completed_cases
                                    ? adminData.auditor_completed_cases
                                    : "0"}
                                </h2>
                                <div className="fnt-sml txt-70">Audited</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <AgentTAT adminData={adminData} />
                        </div>
                        <div className="col-md-3">
                          <PieChart
                            activeCases={
                              adminData.total_active_cases
                                ? adminData.total_active_cases
                                : 0
                            }
                            completedCases={
                              adminData.total_completed_cases
                                ? adminData.total_completed_cases
                                : 0
                            }
                            deletedCases={
                              adminData.deleted_cases ? adminData.deleted_cases : 0
                            }
                            approvedCases={
                              adminData.total_approved_cases
                                ? adminData.total_approved_cases
                                : 0
                            }
                            rejectedCases={
                              adminData.total_rejected_cases
                                ? adminData.total_rejected_cases
                                : 0
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
                <div className="row d-flex mt-4">
                  <div className="col-lg-12 col-sm-12">
                    <div className="row pl-3">
                  <div className="col-md-6">
                    <ApprovedRejectedCases adminData={adminData} />
                  </div>
                  <div className="col-md-6">
                    <AgentCompletedCases adminData={adminData} />
                  </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChartsContainer;
