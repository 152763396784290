import React, { useState, useEffect } from 'react'
import Consent from './Consent'
import Policy from './Policy'
import API from '../../apiBaseConfigNew'
import { useParams } from "react-router-dom";
import UserHeader from '../../user/userHeader/userHeader';
import './Style.scss'
import UploadDocs from '../../user/screens/customer/UploadDocs'
import moment from 'moment';
import {
  storeAnonymousUserConnectionInfo,
  resetUserDocs,
  presetUpload,
} from "../../user/state/user.actions";
import { useDispatch } from 'react-redux'
import { updateClientConfig } from "../../login/state/login.actions";
import Loader from '../../components/loader/Loader';
import SessionDetails from '../../user/screens/sessionDetails/SessionDetails';


export default function CustomerLanding() {
  const [step, setStep] = useState(1)
  const [user, setUser] = useState({})
  const { userToken } = useParams();
  const [loading, setLoading] = useState(true)
  const [inValidSession, setInValidSession] = useState(false)
  const [errorMsg, setErrorMsg] = useState({ title: '', description: '' })
  const [livenessCheck, setLivenessCheck] = useState(false)
  const dispatch = useDispatch()
  const headers = {
    'AuthorizationY': `Token ${userToken}`
  }

  useEffect(() => {
    let isLiveness = localStorage.getItem('liveness')
    if (isLiveness) {
      setLivenessCheck(true)
    }
    startSession()
  }, [])

  function startSession() {
    API.post('/case/pivc/session/', {}, { headers })
      .then(res => {
        if (res.status === 200) {
          setLoading(false)
          setUser(res.data)
          getCaseDetails(res.data.id, res.data)
          getClientConfig()
        }
      })
      .catch(err => {
        console.log(err)
        if (err.response.data) {
          setErrorMsg(err.response.data)
          setInValidSession(true)
          setLoading(false)
        }
      })
  }
  function getCaseDetails(id, data) {
    API.get(`/case/pivc-case/${id}`, { headers })
      .then(res => {
        let case_details = res.data
        data.case_details = case_details
        dispatch(storeAnonymousUserConnectionInfo(data))
        dispatch(resetUserDocs())
        if (data.consent_datetime && data.connection === userToken) {
          let currentTime = moment(new Date())
          let diff = currentTime.diff(data.consent_datetime, 'minutes')
          let isLiveness = localStorage.getItem('liveness')
          if (!isLiveness && diff < 45)
            setStep(2)

        }
      })
      .catch(err => console.log(err))
  }
  function getClientConfig() {
    API.get('/client/clientconfig/', { headers })
      .then(res => {
        dispatch(updateClientConfig(res.data[0]))
      })
      .catch(err => console.log(err))
  }
  function handleStep() {
    if (step > 1) {
      setStep(old => old - 1)
      if (step === 3) {
        setTimeout(window.location.reload(), 300)
      }
    }
  }



  return (
    <div className='h-100'>
      {loading ? <Loader /> : inValidSession ? <SessionDetails errorMsg={errorMsg} /> :
        <div className='pivc-screen'>
          <UserHeader handleBackClick={e => handleStep()} />
          {step === 1 ? <Consent livenessCheck={livenessCheck} userToken={userToken} nextClick={() => setStep(2)} stopCamera={() => { }} /> : null}
          {step === 2 ? <UploadDocs isPivc nextClick={() => setStep(3)} /> : null}
          {step === 3 ? <Policy headers={headers} user={user} /> : null}
        </div>}


    </div>
  )
}
