import React from "react";
import ApprovedCases from "./ApprovedCases";
import RejectedCases from "./RejectedCases";
import PendingCases from "./PendingCases";
import SearchAndSort from "../searchAndSort/SearchAndSort";
import SearchBar from "../searchBar/SearchBar";

class CompletedCases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearchBar: false,
    };
  }

  toggleSearchClick = () => {
    const { showSearchBar } = this.state;
    this.setState({ showSearchBar: !showSearchBar });
  };

  render() {
    const {
      activeLowerTab,
      completedCasesData,
      searchKeyObject,
      consolidatedAgentList,
    } = this.props;
    const { showSearchBar } = this.state;
    const datePrefix =
      activeLowerTab === 0
        ? "Approved"
        : activeLowerTab === 1
        ? "Rejected"
        : "Completed";
    return (
      <div className="col-md-12">
        <div className="ml-30 mr-30 mt-3">
          <SearchAndSort
            toggleSearchClick={this.toggleSearchClick}
            handleSort={this.props.handleSort}
            getSearchObject={this.props.getSearchObject}
            searchKeyObject={searchKeyObject}
          />
          {showSearchBar ? (
            <SearchBar
              datePrefix={datePrefix}
              getSearchObject={this.props.getSearchObject}
              searchKeyObject={searchKeyObject}
              consolidatedAgentList={consolidatedAgentList}
            />
          ) : (
            <div className="tab-content mt-1">
              {activeLowerTab === 0 && (
                <ApprovedCases
                  approvedCasesData={completedCasesData}
                  handleViewDocuments={this.props.handleViewDocuments}
                />
              )}
              {activeLowerTab === 1 && (
                <RejectedCases
                  rejectedCasesData={completedCasesData}
                  handleViewDocuments={this.props.handleViewDocuments}
                />
              )}
              {activeLowerTab === 2 && (
                <PendingCases pendingCasesData={completedCasesData} />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CompletedCases;
