import React from "react";
import Scheduler from "../scheduler/Scheduler";
import { ReactComponent as NoteIcon } from "../../../../../assets/icons/note.svg";
import { ReactComponent as EyeIcon } from "../../../../../assets/icons/eye-icon.svg";
import { ReactComponent as SmartphoneBlueIcon } from "../../../../../assets/icons/smartphone-blue.svg";
import { ReactComponent as TransferIcon } from "../../../../../assets/icons/transfer.svg";
import { ReactComponent as CancelCallIcon } from "../../../../../assets/icons/cancel-call.svg";

class AgentActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connection: {},
      isVpd:false
    };
  }
  componentDidMount(){
    let props = this.props
    if(props?.selectedCase?.case_type && props?.selectedCase?.case_type === 'personal_discussion'){
      if(props?.selectedCase?.connections && props?.selectedCase?.connections.length > 1){
        let connections = props?.selectedCase?.connections
        let connection = connections.find(item=>item.participant_tag === 'applicant')
        if(connection && Object.keys(connection).length > 0){
          this.setState({connection},()=>{
            this.setState({isVpd:true})
          })
        }
        
      }
    }
  }
  render() {
    const {
      index,
      caseName,
      caseId,
      caseActive,
      transferClickedIndex,
      agentList,
      schedularClickedIndex,
      activeUpperTab,
      selectedCase,
    } = this.props;
    const {connection, isVpd} = this.state
    return (
      <td width="19%" className="gray-circle-btns">
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="position-relative align-top"
        >
          <span
            className="m-0 icon-light-blue calendar"
            onClick={(e) => {
              e.stopPropagation();
              this.props.handleSchedularClick(index);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="22"
              height="22"
              viewBox="0 0 22 22"
            >
              <title id="unique-id">Click here to schedule the call</title>
              <defs>
                <clipPath id="clip-Schedule_icon">
                  <rect width="22" height="22" />
                  <title>click here to schedule call </title>
                </clipPath>
              </defs>
              <g
                id="Schedule_icon"
                data-name="Schedule icon"
                clipPath="url(#clip-Schedule_icon)"
              >
                <g id="calendar" transform="translate(0.081)">
                  <g id="Group_557" data-name="Group 557">
                    <g id="Group_556" data-name="Group 556">
                      <circle
                        id="Ellipse_115"
                        data-name="Ellipse 115"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(15.919 7.919)"
                        fill="#929292"
                      />
                      <path
                        id="Path_162"
                        data-name="Path 162"
                        d="M18.426,1.706H17.317V.853a.853.853,0,1,0-1.706,0v.853H11.729V.853a.853.853,0,0,0-1.706,0v.853H6.185V.853a.853.853,0,0,0-1.706,0v.853H3.412A3.416,3.416,0,0,0,0,5.118V18.426a3.416,3.416,0,0,0,3.412,3.412H9.938a.853.853,0,1,0,0-1.706H3.412a1.708,1.708,0,0,1-1.706-1.706V5.118A1.708,1.708,0,0,1,3.412,3.412H4.478v.853a.853.853,0,0,0,1.706,0V3.412h3.839v.853a.853.853,0,0,0,1.706,0V3.412h3.881v.853a.853.853,0,0,0,1.706,0V3.412h1.109a1.708,1.708,0,0,1,1.706,1.706V9.981a.853.853,0,1,0,1.706,0V5.118A3.416,3.416,0,0,0,18.426,1.706Z"
                        fill="#929292"
                      />
                      <path
                        id="Path_163"
                        data-name="Path 163"
                        d="M275.161,270a5.161,5.161,0,1,0,5.161,5.161A5.167,5.167,0,0,0,275.161,270Zm0,8.616a3.455,3.455,0,1,1,3.455-3.455A3.459,3.459,0,0,1,275.161,278.616Z"
                        transform="translate(-258.484 -258.484)"
                        fill="#929292"
                      />
                      <path
                        id="Path_164"
                        data-name="Path 164"
                        d="M373.09,331.749h-.384v-.9a.853.853,0,0,0-1.706,0V332.6a.853.853,0,0,0,.853.853h1.237a.853.853,0,0,0,0-1.706Z"
                        transform="translate(-355.176 -315.925)"
                        fill="#929292"
                      />
                      <circle
                        id="Ellipse_116"
                        data-name="Ellipse 116"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(11.919 7.919)"
                        fill="#929292"
                      />
                      <circle
                        id="Ellipse_117"
                        data-name="Ellipse 117"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(7.919 11.919)"
                        fill="#929292"
                      />
                      <circle
                        id="Ellipse_118"
                        data-name="Ellipse 118"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(3.919 7.919)"
                        fill="#929292"
                      />
                      <circle
                        id="Ellipse_119"
                        data-name="Ellipse 119"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(3.919 11.919)"
                        fill="#929292"
                      />
                      <circle
                        id="Ellipse_122"
                        data-name="Ellipse 122"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(7.919 7.919)"
                        fill="#929292"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
         {schedularClickedIndex === index ? <div className="schedular-popup-box rounded p-3">
            <div className="row">
              <div className="col-md-6">
                <h6 className="txt-light-blue2 ltr-spc-1">Contact Details</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <hr className="brdr-ec mt-1 mb-1 dashed-brdr" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 fnt-sml txt-666 d-flex align-items-center">
                <span className="user-blue-circle rounded-circle">
                 {isVpd ? <>{connection.name.charAt(0)}</> :
                  selectedCase && selectedCase.connections.length > 0
                    ? selectedCase.connections[
                        selectedCase.connections.length - 1
                      ].name.charAt(0)
                    : ""}
                </span>
                <span style={{ marginLeft: "5px" }}>
                {isVpd ? <>{connection.name}</> :
                   selectedCase && selectedCase.connections.length > 0
                    ? selectedCase.connections[
                        selectedCase.connections.length - 1
                      ].name
                    : ""}
                </span>
              </div>
              <div className="col-md-8 fnt-sml txt-666 d-flex align-items-center">
                <div className="d-flex flex-column flex-flow-wrap">
                  <div className="col d-flex font-italic">
                    <NoteIcon width="18" className="mr-1" />
                    <span className="note-icon-email">
                    {isVpd ? <>{connection.email}</> :
                      selectedCase && selectedCase.connections.length > 0
                        ? selectedCase.connections[
                            selectedCase.connections.length - 1
                          ].email
                        : ""}
                    </span>
                  </div>
                  <div className="col d-flex" style={{ marginTop: "5px" }}>
                    <SmartphoneBlueIcon
                      width="7"
                      className="mr-1"
                      style={{ marginLeft: "3px" }}
                    />
                    <span className="mobile-number">
                    {isVpd ? <>{connection.mobile_number}</> :
                      selectedCase && selectedCase.connections.length > 0
                        ? selectedCase.connections[
                            selectedCase.connections.length - 1
                          ].mobile_number
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <hr className="brdr-ec mt-1 mb-1 dashed-brdr" />
              </div>
            </div>

            <Scheduler
              index={index}
              caseId={caseId}
              activeUpperTab={activeUpperTab}
              onScheduleComplete={(index, dateTime, customerToken) =>
                this.props.onScheduleComplete(index, dateTime, customerToken)
              }
              closeSchedulerModal={this.props.closeSchedulerModal}
              setSchedulerLoader={this.props.setSchedulerLoader}
            />
          </div> : null }
        </span>
        <span
          className={
            transferClickedIndex === index
              ? "transfer dropdown dropleft show"
              : "transfer dropdown dropleft"
          }
          onClick={(e) => {
            e.stopPropagation();
            this.props.handleTransferClick(index);
          }}
        >
          <TransferIcon
            className="dropdown-toggle"
            id="dropdownMenuButton"
            title="Click here to transfer call"
            // data-toggle="dropdown"
          />
          <div
            className={
              transferClickedIndex === index
                ? "dropdown-menu border-0 transfer-box fnt-sml show"
                : "dropdown-menu border-0 transfer-box fnt-sml"
            }
          >
            <a
              className="dropdown-item pl-2"
              href="#"
              style={{ textAlign: "left" }}
            >
              Transfer to:
            </a>
            <div className="dropdown-divider"></div>
            {agentList &&
              agentList.map((selectedAgent, agentIndex) => {
                return (
                  <a className="dropdown-item" href="#" key={selectedAgent.id}>
                    <span
                      className="light-blue-sml-circle rounded-circle bg-purple text-white"
                      style={{ width: "10px", height: "10px" }}
                    >
                      {selectedAgent
                        ? selectedAgent.first_name.charAt(0) +
                          selectedAgent.last_name.charAt(0)
                        : ""}
                    </span>
                    <span
                      onClick={() =>
                        this.props.transferCases(selectedAgent.id, caseId)
                      }
                    >
                      {selectedAgent
                        ? selectedAgent.first_name +
                          " " +
                          selectedAgent.last_name
                        : ""}
                    </span>
                  </a>
                );
              })}
          </div>
        </span>
      </td>
    );
  }
}

export default AgentActions;
