import React, { useEffect, useState } from "react";
import { Toast } from "../../../../../utils/toastutils";

export default class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchObject: {},
    };
  }
  componentDidMount() {
    this.setState({ searchObject: this.props.searchKeyObject });
  }
  updateSearchObject = (tag, val) => {
    if (tag === "agent_name") {
      const { consolidatedAgentList } = this.props;
      let searchObject = this.state.searchObject;
      searchObject["agent_fname"] = consolidatedAgentList[val].first_name;
      searchObject["agent_lname"] = consolidatedAgentList[val].last_name;
      this.setState({ searchObject: searchObject });
    } else {
      let searchObject = this.state.searchObject;
      searchObject[tag] = val;
      this.setState({ searchObject: searchObject });
    }
  };

  submitSearch = (e) => {
    e.preventDefault();
    const { searchObject } = this.state;
    let hasError = true;
    for (var i in searchObject) {
      if (searchObject[i]) {
        hasError = false;
      }
    }
    if (hasError) {
      Toast.error({
        title: "Please fill at least one search criteria.",
      });
    } else {
      this.props.getSearchObject(searchObject);
    }
  };

  resetSearch = (e) => {
    e.preventDefault();
    this.setState({ searchObject: {} });
  };
  render() {
    const { searchObject } = this.state;
    const { datePrefix, consolidatedAgentList } = this.props;

    return (
      <div className="global-search-box-cntr">
        <div className="global-search-box">
          <div>
            <div className="form-row my-3">
              <div className="col-2">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Case Name"
                  value={
                    searchObject && searchObject.case_name
                      ? searchObject.case_name
                      : ""
                  }
                  onChange={(e) =>
                    this.updateSearchObject("case_name", e.target.value)
                  }
                />
              </div>

              <div className="col-2">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Meeting ID"
                  value={
                    searchObject && searchObject.meeting_id
                      ? searchObject.meeting_id
                      : ""
                  }
                  onChange={(e) =>
                    this.updateSearchObject("meeting_id", e.target.value)
                  }
                />
              </div>
              <div className="col-4">
                <div className="form-row scheduled-date">
                  <div className="col-4">
                    <label className="heading">
                      <small>{datePrefix} Date:</small>
                    </label>
                  </div>
                  <div className="col-4" style={{ marginLeft: "-25px" }}>
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      placeholder="From:"
                      value={
                        searchObject && searchObject.date_from
                          ? searchObject.date_from
                          : ""
                      }
                      onChange={(e) =>
                        this.updateSearchObject("date_from", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-4" style={{ marginLeft: "10px" }}>
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      placeholder="To:"
                      value={
                        searchObject && searchObject.date_to
                          ? searchObject.date_to
                          : ""
                      }
                      onChange={(e) =>
                        this.updateSearchObject("date_to", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-2">
                <select
                  className="form-control form-control-sm"
                  onChange={(e) =>
                    this.updateSearchObject("agent_name", e.target.value)
                  }
                >
                  <option value="">Agent Name</option>
                  {consolidatedAgentList.map((agent, key) => {
                    return (
                      <option key={key} value={key}>
                        {`${agent.first_name} ${agent.last_name}`}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <hr />
            <div className="form-row">
              <div className="col text-right">
                <button
                  className="btn btn-secondary btn-sm"
                  style={{ marginRight: "10px" }}
                  onClick={(e) => this.resetSearch(e)}
                >
                  Reset
                </button>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={(e) => this.submitSearch(e)}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
