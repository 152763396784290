import React from 'react'
import CSVDownload from './CSVDownload'
import Charts from './Charts'

export default function PIVCHome() {
  
    return (
        <div className='container-fluid pt-4'>
            {/* <div className='row'>
                <CSVDownload />
            </div> */}
            <Charts />
        </div>
    )
}
