import React from 'react'
export default function TextBox(props) {
  const { onChange, placeholder, label, value, isRequired, disabled, readOnly, error, className,maxLength } = props

  return (
    <div className="form-group">
      {label ? <label >{label}{ isRequired && <span className='astric'>*</span>}</label> : <label for=""></label>}
      <input maxLength={maxLength ? maxLength : '60'} type="text" disabled={disabled} className="form-control" value={value || ''} onChange={e => onChange(e.target.value)} placeholder={placeholder} />
      {error && error.length > 0 ? <p className='form-error'>{error}</p> : null}
    </div>
  )
}
