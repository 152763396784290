import React from "react";
import { ReactComponent as CancelWhiteIcon } from "../../../../../assets/icons/cancel-white.svg";
import Moment from "react-moment";

class SingleUserModal extends React.Component {
  render() {
    const { assignedCases } = this.props;
    return (
      <div
        className="modal fade show"
        id="cstmrDtls"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content rounded-15">
            <div className="modal-header p-0 pr-2 pt-2 border-0 justify-content-end">
              <span
                aria-hidden="true"
                className="red-close float-right"
                data-dismiss="modal"
                aria-label="Close"
              >
                <CancelWhiteIcon
                  width="10"
                  height="10"
                  onClick={() => this.props.handleModal(false, "")}
                  style={{ cursor: "pointer" }}
                />
              </span>
            </div>
            <div className="modal-body p-0 cstmr-dtls-popup-box">
              <div className="ml-30 mr-30">
                <div className="row">
                  <div className="col-md-12">
                    <h5>
                      <span className="light-blue-sml-circle rounded-circle p-3 bg-purple text-white mr-2">
                        {assignedCases && assignedCases.connections.length > 0
                          ? assignedCases.connections[
                            assignedCases.connections.length - 1
                          ].name.charAt(0)
                          : ""}
                      </span>
                      <span class="d-inline-flex flex-column">
                        <span class="txt-666">{assignedCases && assignedCases.connections.length > 0
                          ? assignedCases.connections[
                            assignedCases.connections.length - 1
                          ].name
                          : ""}</span>
                        <span class="txt-blue fnt-sml roboto-regular">Number of Attempts: {assignedCases.reschedule_attempt + assignedCases.case_attempts_by_agent}</span>
                      </span>
                      {/* <span className="txt-92">
                        {assignedCases && assignedCases.connections.length > 0
                          ? assignedCases.connections[
                            assignedCases.connections.length - 1
                          ].name
                          : ""}
                      </span> */}
                    </h5>
                  </div>
                </div>
                <div className="row no-gutters mt-3 mb-5">
                  <div className="col-md-7 basic-dtls">
                    <div className="bg-f5 rounded border brdr p-3">
                      <h6
                        className="txt-blue-dull"
                        style={{ paddingLeft: "5px" }}
                      >
                        Basic Details
                      </h6>
                      <table width="100%">
                        <tr>
                          <td width="40%">Mobile Number</td>
                          <td width="3%" align="center">
                            :
                          </td>
                          <td width="57%">
                            {assignedCases &&
                              assignedCases.connections.length > 0
                              ? assignedCases.connections[
                                assignedCases.connections.length - 1
                              ].mobile_number
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td width="40%">Email ID</td>
                          <td width="3%" align="center">
                            :
                          </td>
                          <td width="57%">
                            {assignedCases &&
                              assignedCases.connections.length > 0
                              ? assignedCases.connections[
                                assignedCases.connections.length - 1
                              ].email
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td width="40%">Allocation Date </td>
                          <td width="3%" align="center">
                            :
                          </td>
                          <td width="57%">
                            <Moment format="DD MMM YYYY LT">
                              {assignedCases ? assignedCases.updated_at : ""}
                            </Moment>
                          </td>
                        </tr>
                        <tr>
                          <td width="40%">Process</td>
                          <td width="3%" align="center">
                            :
                          </td>
                          <td width="57%">
                            {assignedCases
                              ? assignedCases.process_details
                                ? assignedCases.process_details.name
                                : ""
                              : ""}
                          </td>
                        </tr>
                        
                       {assignedCases && assignedCases.pan_number ? <tr>
                          <td width="40%">Pan Number</td>
                          <td width="3%" align="center">
                            :
                          </td>
                          <td width="57%">
                            {assignedCases
                              ? assignedCases.pan_number
                                : ""
                              }
                          </td>
                        </tr> : null}
                        <tr>
                          <td width="40%">Admin Comments</td>
                          <td width="3%" align="center">
                            :
                          </td>
                          <td width="57%" >
                            {
                              assignedCases && assignedCases.admin_comment && 
                              <div className="border p-2">
                                {assignedCases.admin_comment}
                              </div>
                            }
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-5 basic-dtls">
                    <div
                      className="bg-white rounded border brdr p-3"
                      style={{ minHeight: "211px" }}
                    >
                      <h6
                        className="txt-blue-dull"
                        style={{ paddingLeft: "5px" }}
                      >
                        Documents List
                      </h6>
                      <table width="100%">
                        <tr>
                          <td className="border-0 d-flex align-items-center">
                            <span className="txt-blue-dull">PAN Card</span>
                            <span className="dahsed-h-line col"></span>
                            <span className="tick-icon-green-sml"></span>
                          </td>
                        </tr>
                        <tr>
                          <td className="border-0 d-flex align-items-center">
                            <span className="txt-blue-dull">Aadhar Card</span>
                            <span className="dahsed-h-line col"></span>
                            <span className="tick-icon-green-sml"></span>
                          </td>
                        </tr>
                        <tr>
                          <td className="border-0 d-flex align-items-center">
                            <span className="txt-blue-dull txt-c7">
                              Driving License
                            </span>
                            <span className="dahsed-h-line col"></span>
                            <span className="tick-icon-green-sml bg-e2"></span>
                          </td>
                        </tr>
                        <tr>
                          <td className="border-0 d-flex align-items-center">
                            <span className="txt-blue-dull txt-c7">
                              Passport
                            </span>
                            <span className="dahsed-h-line col"></span>
                            <span className="tick-icon-green-sml bg-e2"></span>
                          </td>
                        </tr>
                        <tr>
                          <td className="border-0 d-flex align-items-center">
                            <span className="txt-blue-dull txt-c7">
                              Voter ID
                            </span>
                            <span className="dahsed-h-line col"></span>
                            <span className="tick-icon-green-sml bg-e2"></span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SingleUserModal;
