import React, { useState, useEffect } from "react";
import profileimg from "../../../../../assets/images/user-icon.png";
import { Toast } from "../../../../../utils/toastutils";
import api from "../../../../../apiBaseConfig";
import {
  UPDATE_PROFILE,
  CHANGE_PASSWORD,
  GET_USER_DETAILS,
} from "../../../../../apiurl";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import upload from "../../../../../assets/icons/upload.svg";
import cameraicon from "../../../../../assets/icons/camera-icon-sml.png";
import { useDispatch, useSelector } from 'react-redux'

export default function AgentProfile() {
  const userInfo = useSelector(state => state.login.userInfo)
  const [stateData, setStateData] = useState({
    showsecurity: false,
    mobilenumberhaserror: false,
    firstname: "",
    lastname: "",
    mobilenumber: null,
    email: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    profileImage: null,
    cameraOpened: false,
    showprofilePopup: false,
    showPassword: false,
    showImagePreviewPopup: false,
    cameraPreviewImage: null,
    passwordMismatchError: false,
    passwordWeakError: false,
    isPhotoChanged:false
  })

  useEffect(()=>{
    setStateData(old=>{ return {
      ...old,
      firstname:userInfo.userFirstName,
      lastname:userInfo.userLastName,
      email:userInfo.email,
      mobilenumber:userInfo.mobilenumber,
      profileImage:userInfo.image
    }})
  },[])

 
  function profilechangehandler(e){
    let mobilenumberhaserror = stateData.mobilenumberhaserror
    if (e.target.name === "mobilenumber") {
      if (!new RegExp(/^[1-9]+[0-9]*$/i).test(e.target.value)) {
          mobilenumberhaserror= true
      }
      
        mobilenumberhaserror= e.target.value.length === 10 ? false : true
    }
    setStateData(old=>{
      return {
        ...old,
        [e.target.name]: e.target.value,
        mobilenumberhaserror
      }
    })
  }

  const updateuserProflehandler = async (e) => {
    e.preventDefault()
    const {
      firstname,
      lastname,
      mobilenumber,
      profileImage,
      cameraPreviewImage,
      isPhotoChanged
    } = stateData;
    if (stateData.mobilenumberhaserror) {
      Toast.warning({
        title: "invalid mobile number",
      });
    } else {
      try {
        let params ={  
          first_name: firstname,
          last_name: lastname,
          mobile_number: mobilenumber
        }
        if(isPhotoChanged){
          params.profile_image = profileImage
        }
        let test = await api.patch(
          `${UPDATE_PROFILE}${userInfo.userId}/`, params);
        Toast.success({
          title: "Profile updated successfully",
        });
        this.props.updateUserImage(
          test.data.image,
          test.data.first_name,
          test.data.last_name
        );
        console.log(test);
      } catch (error) {
        console.log("error in profileupdate", error);
      }
    }
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    if (this.state.newPassword !== this.state.confirmPassword) {
      // Toast.warning({
      //   title: "confirm password does not match",
      // });
      this.setState({ passwordMismatchError: true });
      this.setState({ passwordWeakError: false });
      console.log("called======");
      return;
    } else {
      this.setState({ passwordMismatchError: false });
    }
    try {
      await api.patch(`${CHANGE_PASSWORD}`, {
        old_password: this.state.currentPassword,
        new_password: this.state.newPassword,
        confirm_new_password: this.state.confirmPassword,
      });
      Toast.success({
        title: "Password changed successfully",
      });
      this.setState({ passwordWeakError: false });
    } catch (e) {
      console.log("error======", e.response.data.status);
      this.setState({ passwordWeakError: true });
    }
  };

  const handleUpload = async (event) => {
    event.persist();
    if (!event.target || !event.target.files) {
      return;
    }
    const file = event.target.files[0];
    const fileContent = await readUploadedFileAsDataURL(file);
    setStateData(old=>{return {...old,profileImage:fileContent,showprofilePopup:false,isPhotoChanged:true}})
  };

  const readUploadedFileAsDataURL = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
  };

  const previewImagePopup = (dataUri) => {
    this.setState({
      profileImage: dataUri,
      showImagePreviewPopup: true,
      cameraOpened: false,
    });
    console.log(dataUri, "datauri");
  };


  const handleUsePhoto = () => {
    setStateData(old=>{return {...old,showImagePreviewPopup:false,showprofilePopup:false}})
  };

  const retakeHandler = async () => {
    await this.setState({
      profileImage: null,
      cameraOpened: true,
      showImagePreviewPopup: false,
    });
  };

  return (
    <div className="wrapper h-100 light-blue-template">
      <section className="main-content">
        <div className="container-fluid p-0">
          <div className="row no-gutters bg-light pt-2">
            <div className="col-md-12">
              <h5 className="ml-30 mt-3 mb-3 text-uppercase txt-666">
                Settings
              </h5>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-md-12">
              <div className="ml-30 mr-30 mt-4" id="myTab">
                <div className="container-fluid" style={{ padding: "0" }}>
                  <div className="row">
                    <div className="col-md-12">
                      <ul
                        className="nav nav-tabs nav-tabs-plain border-bottom brdr-db w-100"
                        id="myTabNested"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <span
                            onClick={() =>
                              setStateData(old => { return { ...old, showsecurity: false } })
                            }
                            className="nav-link active text-uppercase ltr-spc-1"
                            id="profile-tab"
                            href="#profile"
                            data-toggle="tab"
                          >
                            Profile
                          </span>
                        </li>
                        <li className="nav-item">
                          <span
                            onClick={() =>
                              setStateData(old => { return { ...old, showsecurity: true } })
                            }
                            className="nav-link text-uppercase ltr-spc-1"
                            id="security-tab"
                            href="#security"
                            data-toggle="tab"
                          >
                            Security
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {!stateData.showsecurity && (
                    <div className="tab-content mt-1">
                      <div
                        className="tab-pane show active"
                        id="profile"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        <div className="row mt-3">
                          <div
                            className="col-md-3"
                            style={{ width: "800px", position: "relative" }}
                          >
                            <img
                              src={
                                stateData.profileImage
                                  ? stateData.profileImage
                                  : profileimg
                              }
                              className="rounded-15 img-fluid"
                              alt=""
                              onClick={() => setStateData(old=> { return {...old,showprofilePopup:true}})}
                            />
                            {stateData.showprofilePopup && (
                              <div className="profilepopupmodal">

                                <div className="modal-dialog">
                                  <div className="modal-content firstpopup">
                                    <div className="modal-header">
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() =>
                                          setStateData(old => { return { ...old, showprofilePopup: false } })
                                        }
                                      >
                                        <span aria-hidden="true">
                                          &times;
                                        </span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <div className="row">
                                        <div className="col-md-5">
                                          <div
                                            className="matt-box"
                                            style={{
                                              left: "30%",
                                              top: "20%",
                                            }}
                                          >
                                            <input
                                              type="file"
                                              id="imgdirectory"
                                              accept="image/*"
                                              onChange={(e) =>
                                                handleUpload(e)
                                              }
                                            />
                                            <label htmlFor="imgdirectory">
                                              <img src={upload} />
                                            </label>
                                          </div>
                                        </div>

                                        <div
                                          className="col-md-5"
                                          style={{ marginLeft: "10px" }}
                                        >
                                          <div
                                            className="matt-box capture"
                                            data-toggle="modal"
                                            data-target="#exampleModal"
                                            style={{ top: "23%" }}
                                            onClick={() =>
                                              setStateData(old => { return { ...old, showprofilePopup: false, cameraOpened: true } })
                                            }
                                          >
                                            <img src={cameraicon} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* </div> */}
                              </div>
                            )}
                            {stateData.cameraOpened && (
                              <div
                                className="modal camera inputimage"
                                id="exampleModal"
                                // tabIndex={-1}
                                aria-labelledby="exampleModalLabel"
                              //aria-hidden="true"
                              >
                                <div className="modal-dialog dialog_2">
                                  <div className="modal-content content_2">
                                    <div className="modal-header"></div>
                                    <div className="modal-body">
                                      <div className="photo_capture">
                                        <Camera
                                          idealFacingMode={
                                            FACING_MODES.ENVIRONMENT
                                          }
                                          idealResolution={{
                                            width: 540,
                                            height: 380,
                                          }}
                                          isImageMirror={false}
                                          isFullscreen={false}
                                          //onCameraStart = { (stream) => { cameraOpened(stream); } }
                                          onTakePhoto={(dataUri) => {
                                            previewImagePopup(
                                              dataUri,
                                              this.props.photoSide,
                                              this.props.documentId,
                                              "normal_upload"
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {stateData.showImagePreviewPopup && (
                              <div
                                className="preview"
                                tabIndex={-1}
                                id="domain3"
                              >
                                <div
                                  className="modal-dialog"
                                  style={{
                                    top: "-10px",
                                    position: "absolute",
                                    transform: "translate(-50%, -50%)",
                                  }}
                                >
                                  <div
                                    className="modal-content new thirdpopup"
                                    style={{
                                      marginTop: "650px",
                                      marginLeft: "auto",
                                    }}
                                  >
                                    <div className="modal-header">
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() =>
                                          setStateData(old => { return { ...old, showImagePreviewPopup: false } })
                                        }
                                      >
                                        <span aria-hidden="true">
                                          &times;
                                        </span>
                                      </button>
                                    </div>
                                    <div className="modal-body body_3">
                                      <div className="photo_capture">
                                        <img src={this.state.profileImage} />
                                      </div>
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-secondary retake"
                                        data-dismiss="modal"
                                        onClick={() => retakeHandler()}
                                      >
                                        Retake
                                      </button>
                                      <button
                                        type="submit"
                                        className="btn btn-primary use_photo"
                                        onClick={()=>handleUsePhoto}
                                      >
                                        Use Photo
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-md-9">
                            <div className="row">
                              <div className="col-md-12 d-flex justify-content-between align-items-center">
                                <h6 className="m-0">
                                  <b>Basic Info</b>
                                </h6>
                                <img
                                  src="img/Icon open-pencil.svg"
                                  width="16"
                                  alt=""
                                  className="cursor-pointer"
                                />
                              </div>
                            </div>
                            <hr className="blue-hr" />
                            <form className="fnt-sml input-sml">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label for="">First Name</label>
                                    <input
                                      type="text"
                                      className="form-control fnt-roboto-bld"
                                      value={stateData.firstname}
                                      name="firstname"
                                      onChange={e => profilechangehandler(e)}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label for="">Last Name</label>
                                    <input
                                      type="text"
                                      className="form-control fnt-roboto-bld"
                                      value={stateData.lastname}
                                      name="lastname"
                                      onChange={e => profilechangehandler(e)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label for="">Mobile Number</label>
                                    <input
                                      type="text"
                                      className="form-control fnt-roboto-bld"
                                      value={stateData.mobilenumber}
                                      name="mobilenumber"
                                      onChange={e => profilechangehandler(e)}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label for="">
                                      Email Address (Non Editable)
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control fnt-roboto-bld"
                                      value={stateData.email}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label for="">Designation</label>
                                    <input
                                      type="text"
                                      className="form-control fnt-roboto-bld"
                                      value="Manager"
                                      readonly=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label for="">Office Location</label>
                                    <input
                                      type="text"
                                      className="form-control fnt-roboto-bld"
                                      value="Gurgaon"
                                      readonly=""
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row mb-4">
                                <div className="col-md-12 d-flex justify-content-between">
                                  <button className="btn btn-custom  btn-light-black btn-new">
                                    Cancel
                                  </button>
                                  <button
                                    className="btn btn-light-blue"
                                    onClick={e => updateuserProflehandler(e)}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {stateData.showsecurity && (
                    <div
                      className="tab-pane"
                      id="security"
                      role="tabpanel"
                      aria-labelledby="security-tab"
                    >
                      <div className="row mt-3">
                        <div className="col-md-3" style={{ width: "800px" }}>
                          <img
                            src={
                              stateData.profileImage
                                ? stateData.profileImage
                                : profileimg
                            }
                            className="rounded-15 img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="col-md-9">
                          <div className="row">
                            <div className="col-md-12 d-flex justify-content-between align-items-center">
                              <h6 className="m-0">
                                <b>Change Password</b>
                              </h6>
                              <img
                                src="img/Icon open-pencil.svg"
                                width="16"
                                alt=""
                                className="cursor-pointer"
                              />
                            </div>
                          </div>
                          <hr className="blue-hr" />
                          <form  className="fnt-sml input-sml">
                            <div className="row flex-column">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label for="">Current Password</label>
                                  <input
                                    type="password"
                                    className="form-control fnt-roboto-bld"
                                    name="currentPassword"
                                    onChange={e => profilechangehandler}
                                  />
                                </div>
                              </div>
                              <div
                                className="col-md-6"
                                style={{ position: "relative" }}
                              >
                                <div className="form-group">
                                  <label for="">New Password</label>
                                  <input
                                    type={stateData.showPassword ? "text" : "password"}
                                    name="newPassword"
                                    onChange={e => this.profilechangehandler}
                                    className="form-control fnt-roboto-bld"
                                  />
                                  <span
                                    className="input-group-append eyeicon"
                                    onClick={() => {
                                      setStateData(old => { return { ...old, showPassword: !old.showPassword } })
                                    }}
                                  >
                                    {stateData.showPassword && (
                                      <span className="input-group-text input-group-append-right">
                                        <i className="fa fa-eye-slash" />
                                      </span>
                                    )}
                                    {!stateData.showPassword && (
                                      <span className="input-group-text input-group-append-right">
                                        <i className="fa fa-eye" />
                                      </span>
                                    )}
                                  </span>
                                  {stateData.passwordMismatchError && (
                                    <span className="text-danger">
                                      New password and Confirm password do not
                                      match
                                    </span>
                                  )}
                                  {stateData.passwordWeakError && (
                                    <span className="text-danger">
                                      Your Password must contain: Atleast 8
                                      Character, lowercase letter , uppercase
                                      letter, number and special character
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label for="">Confirm Password</label>
                                  <input
                                    type="password"
                                    className="form-control fnt-roboto-bld"
                                    name="confirmPassword"
                                    onChange={e=>profilechangehandler}
                                  />
                                  {stateData.passwordMismatchError && (
                                    <span className="text-danger">
                                      New password and Confirm password do not
                                      match
                                    </span>
                                  )}
                                  {stateData.passwordWeakError && (
                                    <span className="text-danger">
                                      Your Password must contain: Atleast 8
                                      Character, lowercase letter , uppercase
                                      letter, number and special character
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="col-md-6 d-flex justify-content-between">
                                <button className="btn btn-custom  btn-light-black btn-new">
                                  Cancel
                                </button>
                                <button
                                  className="btn btn-light-blue"
                                  onClick={e=>updatePassword}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}