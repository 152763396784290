export const ActionTypes = {
  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  LOGOUT: "LOGOUT",
  UPDATE:"UPDATE",
  GET_CLIENT_CONFIG:"GET_CLIENT_CONFIG",
  UPDATE_CLIENT_CONFIG:"UPDATE_CLIENT_CONFIG",
  UPDATE_CLIENT_NAME:"UPDATE_CLIENT_NAME"
};
