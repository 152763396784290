import React, {useEffect, useState} from "react";
import { ADMIN_SUMMARY } from "../../../../../apiurl";
import api from "../../../../../apiBaseConfig";
import ChartsContainer from "./ChartsContainer";
import Loader from "../../../../../components/loader/Loader";

export default function DataCharts(props){ 
    const { activeUpperTab, case_module } = props;
    const [stateData, setStateData] = useState({})
    const [isLoading, setIsLoading] = useState(true)
  useEffect(()=>{
    getChartsData()
  },[])

   async function getChartsData () {
    try {
      let response = await api.get(ADMIN_SUMMARY);
      setStateData(response.data)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error);

    }
  };
    const selectedCat =  case_module === 2 ? 'vpd' : activeUpperTab === 0 ? "vkyc" : "vpd";
    return (
      <div class="container-fluid p-0" style={{ marginBottom: "100px" }}>
        {isLoading ? (
          <Loader />
        ) : (
          <ChartsContainer adminData={stateData[selectedCat]} />
        )}
      </div>
    );
}
