import React, { useEffect, useState } from 'react'
import API from '../../../../../apiBaseConfigNew'
import cogoToast from 'cogo-toast'
import './Style.scss'
import { getFieldData } from './Form'
import WithInputType from './BasicInputs'
import AgentDrop from './BasicInputs/AgentDrop'
import {
  GET_PROCESSES,
  GET_CASES,
  GET_PARTNERS
} from "../../../../../apiurl";
import ProcessDrop from './BasicInputs/ProcessDrop'
import Loader from '../../../../../components/loader/Loader'
import ExcelUpload from './BasicInputs/ExcelUpload'
import { useSelector } from "react-redux";
import PartnerDrop from './BasicInputs/PartnerDrop'


export default function UploadCaseNew() {
  const [formData, setFormData] = useState([])
  const [caseName, setCaseName] = useState(getFieldData('name'))
  const [processType, setProcessType] = useState(null)
  const [processList, setProcessList] = useState([])
  const [connection, setConnection] = useState([])
  const [partners, setPartners] = useState([])
  const [partner, setPartner] = useState(null)

  const [agent, setAgent] = useState(null)
  const [basicError, setBasicError] = useState({ process: '', agent: '', partners: '' })
  const [isLoading, setLoading] = useState(false)
  let clientConfig = useSelector(state => state.login.clientInfo)

  useEffect(() => {
    getCaseFields()
    getConnectionField()
    getProcessList()
    if (clientConfig?.has_partners)
      getPartnerList()
  }, [])
  function getCaseFields() {
    API.get('/case/add-case-fields/')
      .then(res => {
        if (res.status === 200 && res.data) {
          let temp = []
          res.data.forEach(item => {
            let fieldData = { ...getFieldData(item.field_name) }
            fieldData.value = ""
            temp.push(fieldData)
          })
          setFormData(temp)
        }
      })
      .catch(err => {
        cogoToast.error('Something went wrong with case fields!')
      })
  }
  function getConnectionField() {
    let data = []
    let list = ['connection_name', 'email', 'mobile_number']
    list.forEach(item => {
      data.push({ ...getFieldData(item) })
    })
    setConnection(data)
  }
  async function getProcessList() {
    let { data } = await API.get(GET_PROCESSES);
    setProcessList(data)
  }
  async function getPartnerList() {
    let { data } = await API.get(GET_PARTNERS)
    if (data && data.length > 0) {
      setPartners(data)
    }
  }
  function handleChange(val, index) {
    let temp = [...formData]
    temp[index].value = val
    if (val.length >= 2)
      temp[index].error = ''
    if (temp[index].check) {
      let check = temp[index].check(val)
      if (check)
        temp[index].error = ''
      else
        temp[index].error = 'Invalid ' + temp[index].label
    }
    setFormData(temp)
  }
  function handleConnectionChange(val, index) {
    let temp = [...connection]
    temp[index].value = val
    if (val.length >= 2)
      temp[index].error = ''
    if (temp[index].check) {
      let check = temp[index].check(val)
      if (check)
        temp[index].error = ''
      else
        temp[index].error = 'Invalid ' + temp[index].label
    }
    setConnection(temp)
  }
  function handleNameChange(val) {
    let temp = { ...caseName }
    temp.value = val
    if (val.length >= 2)
      temp.error = ""
    setCaseName(temp)
  }
  function setProcess(e) {
    basicError.process = ''
    setBasicError(basicError)
    setProcessType(e.target.value)
    setAgent("")
  }
  function validateCaseDetails() {
    let isValid = true
    let temp = [...formData]
    debugger
    temp.map(item => {
      if ((item.isRequired && item.value.length < 2) || item.error.length > 0) {
        item.error = 'Enter valid ' + item.label
        isValid = false
      }
    })
    let connectionDetails = [...connection]
    connectionDetails.map(item => {
      if (item.isRequired && item.value.length < 2) {
        item.error = 'Enter valid ' + item.label
        isValid = false
      }
    })
    if (caseName.value.length < 2) {
      isValid = false
      caseName.error = 'Enter valid case name'
    }
    if (!processType) {
      isValid = false
      basicError.process = 'Select Process'
    }
    if (!agent) {
      isValid = false
      basicError.agent = 'Select Agent'
    }
    if (partners.length > 0 && !partner) {
      isValid = false
      basicError.partners = 'Select Partner'
    }
    setBasicError(basicError)
    setCaseName(caseName)
    setFormData(temp)
    setConnection(connectionDetails)
    if (isValid) {
      setRequestData(temp)
    }
    else cogoToast.error('Please fill all inputs with valid data!')
  }
  function handleAgent(e) {
    if (e.target.value) {
      basicError.agent = ''
      setBasicError(basicError)
    }
    setAgent(e.target.value)
  }
  function handlePartner(e) {
    if (e.target.value) {
      basicError.partners = ''
      setBasicError(basicError)
    }
    setPartner(e.target.value)
  }
  async function setRequestData(data) {
    let reqData = {}
    reqData.client = clientConfig.client
    reqData.name = caseName.value
    reqData.agent = agent
    reqData.process = processList[processType].id
    reqData.case_type = processList[processType].case_type
    reqData.connection_detail = []
    let tempConnection = {}
    connection.map(item => {
      tempConnection[item.name] = item.value
    })
    reqData.connection_detail.push(tempConnection)
    data.map(item => {
      reqData[item.name] = item.value
    })
    if (partner && partners.length > 0) reqData.partner = partner
    setLoading(true)
    // CEQPK4956K
    try {
      debugger
      let res = await API.post(GET_CASES, [reqData]);
      if (res && res.status) {
        cogoToast.success('Case Added successfully!')
        resetForm()
      }
      setLoading(false)
    }
    catch (e) {
      cogoToast.error('Something went wrong while creating case!')
      setLoading(false)

    }
  }
  function resetForm() {
    caseName.value = ''
    setCaseName(caseName)
    setConnection([])
    getCaseFields()
    getConnectionField()
    setProcessType('')
    setAgent('')
    setPartner(null)
  }
  return (
    <div className='container-fluid p-2'>
      {isLoading ? <Loader /> : null}
      <ExcelUpload setLoading={setLoading} />
      <div className='upload-form mt-2'>
        <div className='row'>
          <div className='col-md-3'>
            <WithInputType {...caseName} onChange={e => handleNameChange(e)} />
          </div>
          <div className='col-md-3'>
            <ProcessDrop processType={processType} list={processList} setProcess={setProcess} error={basicError.process} />
          </div>
          <div className='col-md-3'>
            <AgentDrop processList={processList} error={basicError.agent} processType={processType} selectedAgent={agent} selectAgent={handleAgent} />
          </div>
          <div className='col-md-3'>
            <PartnerDrop label={'Partner'} error={basicError.partners} list={partners} selected={partner} onSelect={handlePartner} />
          </div>
        </div>

        <div className='row'>
          {formData.map((item, index) => {
            return <div key={index} className='col-md-3'>
              <WithInputType {...item} onChange={e => handleChange(e, index)} />
            </div>
          })}
        </div>
        <div className='row'>
          {connection.map((item, index) => {
            return <div key={index} className='col-md-3'>
              <WithInputType {...item} onChange={e => handleConnectionChange(e, index)} />
            </div>
          })}
        </div>
        <div className='row'>
          <div className='col-md-2 mt-2'>
            <button className='btn btn-primary' onClick={validateCaseDetails}>Submit</button>
          </div>
        </div>
      </div>
    </div>

  )
}
