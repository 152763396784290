import React, { useState, useEffect, useRef } from "react";
import "./KycInstruction.scss";
import UserHeader from "../../userHeader/userHeader";
import { useHistory } from "react-router";
import { Modal } from "@material-ui/core";
import { useSelector } from 'react-redux'
import _get from "lodash/get";
import {
  CREATE_PLATFORM_TOKEN_FOR_USER,
  GET_CASE_CONNECTIONS,
  GET_CASES,
  GENERATE_UNIQUE_CODE
} from "../../../apiurl";
import api, { instance2 } from "../../../apiBaseConfig";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { withStyles } from "@material-ui/core/styles";
import MOBILE_HAND_IMAGE from "../../../assets/images/mobile-hand-icon.svg";
import Loader from "../../../components/loader/Loader";
import {
  useLocalVideo,
  LocalVideo,
  useMeetingStatus,
  useMeetingManager
} from "amazon-chime-sdk-component-library-react";
import ChimeVideoRoomComponent from "../../../components/chimeVideoRoomComponent/ChimeVideoRoomComponent";
import CloseIcon from "../../../assets/icons/close-icon.png";
import { MeetingSessionConfiguration, LogLevel } from 'amazon-chime-sdk-js';
import { Toast } from "../../../utils/toastutils";

const styles = (theme) => ({
  root: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
});

const meetinStatusMapping = {
  0: "Loading",
  1: "Succeeded",
  2: "Failed",
  3: "Ended",
};

let isMeetingEnded = false

function KycInstruction(props) {
  const history = useHistory()
  const anonymousUserConnectionInfo = useSelector(state => state.userDocs.anonymousUserConnectionInfo)
  const {is_unique_code} = useSelector(state => state.login.clientInfo)
  const {isLive} = useSelector(state => state.userDocs)
  let [isLoader, setLoader] = useState(false);
  const [locationAccess, setLocationAccess] = useState(true);
  const [kycComplete, setKycComplete] = useState(null);
  const liveTimeout = useRef(true);
  const [isChimeMeetingStarted, setChimeMeetingStarted] = useState(false);
  const [isReconnecting, setIsReconnecting] = useState(false);
  const [meetingEnded, setMeetingEnded] = useState(false);
  const [callCompleted, setCallCompleted] = useState(true);
  const meetingStatus = useMeetingStatus();
  const [isNotificationSent, setIsNotificationSent] = useState(false)
  const [timerCount,setTimerCount] = useState(0)
  const [hostSessions,setHostSessions] = useState([])
  const [currentChimeId,setCurrentChimeId] = useState(null)
  const [uniqueCode, setUniqueCode] = useState("");


  const meetingManager = useMeetingManager();
  meetingManager.logger.setLogLevel(LogLevel.OFF)

  useEffect(() => {
    getAttendee()
  }, []);

  const getAttendee = () =>{
    // checkLocation();
    meetingManager.getAttendee = async (chimeAttendeeId, externalUserId) => {
      const caseId = anonymousUserConnectionInfo.case_details.id || null ;
      const response = await api.get(`${GET_CASE_CONNECTIONS}?case=${caseId}`);
      const allUsers = response.data[0].case_details.connections;
      let temp = []
      setCurrentChimeId(chimeAttendeeId)
      // console.log('all usersss',allUsers)
      const user =
        allUsers.find((user) => {
          const connnectionDetails = user.platform_connection_details
            ? user.platform_connection_details
            : { Attendee: "" };
          temp.push(connnectionDetails.Attendee.AttendeeId)
          return connnectionDetails.Attendee.AttendeeId === chimeAttendeeId;
        }) || {};
        setHostSessions(old=>temp)
      
      return {
        name: user.name,
      };
    };
  }

  const checkLocation = (checkLiveCall) => {
    navigator.geolocation.getCurrentPosition(
      async function (position) {
        checkLiveCall && checkLiveCall();
      },
      (e) => {
        setLocationAccess(false);
      },
      { timeout: 10000 }
    );
  };

  const changeLiveStatus = async()=>{
    await api.patch(
      GET_CASES + `${anonymousUserConnectionInfo.case_details.id}/`,
      { is_live: false }
    );
  }

  const checkLiveCall = async () => {
    if (isLive){
      liveTimeout.current = true;
      const caseResponse = await api.patch(
        GET_CASES + `${anonymousUserConnectionInfo.case_details.id}/`,
        { is_live: true }
      );
      setTimeout(async () => {
        liveTimeout.current = false;
        changeLiveStatus()
      }, 90000);
    } else {
      const caseResponse = await api.patch(
        GET_CASES + `${anonymousUserConnectionInfo.case_details.id}/`,
        { customer_has_joined: true }
      );
    }
    joinSession();
  };

  function meetingDataAdapter(data){
    const str = data.split("");
    let arr = [];
    for (let i = 0; i < str.length; i++) {
      if (str[i] === "'") {
        arr.push(`"`);
      } else {
        arr.push(str[i]);
      }
    }
    const obj = JSON.parse(arr.join(""));
    return obj.Meeting
  }

  const joinSession = async () => {
    console.log('join session call')
    const connectionToken = _get(
      anonymousUserConnectionInfo,
      "connection",
      null
    );
    try {
      const response = await api.post(
        `${CREATE_PLATFORM_TOKEN_FOR_USER}${connectionToken}/`,
        {
          token: connectionToken,
        }
      );
      const sessions = _get(response.data, "case_details.session");
      const currentSession = sessions[sessions.length - 1];
      // console.log("sessions", sessions, currentSession);
      if (!response.data.platform_connection_details) {
        setLoader((isLoader = true));
        setTimeout(() => {
          joinSession();
        }, 4000);
      } else {
        setLoader(false);
        const Meeting = meetingDataAdapter(currentSession.platform_session_details)
       
        const joinData = {
          meetingInfo: Meeting,
          attendeeInfo: response.data.platform_connection_details.Attendee,
        };
        const meetingSessionConfiguration = new MeetingSessionConfiguration(joinData.meetingInfo, joinData.attendeeInfo);
        console.log("joinData", meetingSessionConfiguration);
        // Use the join API to create a meeting session
        await meetingManager.join(meetingSessionConfiguration);
        console.log("meeting joined");
        isMeetingEnded = false
        // At this point you can let users setup their devices, or start the session immediately



      // // 360p
      //   meetingSession.audioVideo.chooseVideoInputQuality(640, 360, 15);
      //   meetingSession.audioVideo.setVideoMaxBandwidthKbps(600);

      //   // 540p default
      //   meetingSession.audioVideo.chooseVideoInputQuality(960, 540, 15);
      //   meetingSession.audioVideo.setVideoMaxBandwidthKbps(1400);

      //   // 720p
      //   meetingSession.audioVideo.chooseVideoInputQuality(1280, 720, 15);
      //   meetingSession.audioVideo.setVideoMaxBandwidthKbps(1400);

        meetingManager.audioVideo.chooseVideoInputQuality(1280, 720, 30)
        meetingManager.audioVideo.setVideoMaxBandwidthKbps(1400)
        if(is_unique_code){
          try {
            let uc_Data = await api.post(`${GENERATE_UNIQUE_CODE}/`, {
              connection: anonymousUserConnectionInfo.id,
              client:anonymousUserConnectionInfo.case_details.client
            });
            setUniqueCode(uc_Data.data.unique_code);
          } catch (error) {
            console.log(error);
          }
        }
        
        meetingManager.start();
        setChimeMeetingStarted(true);

        console.log("meeting started");
      }
      console.log("response", response);
    } catch (error) {
      console.log("error====>", error);
      if (error && liveTimeout.current) {
        setLoader((isLoader = true));
        setIsNotificationSent(true)
        const timer = setTimeout(() => {
          joinSession();
        }, 8000);
      } else {
        setLoader(false);
        Toast.error({
          description: "Oops. All Agents are busy at the moment",
          title: "Waiting",
        });

      }
    }
  };
  const callAfterMeetingEnded = () => {
    if(isMeetingEnded) return
    isMeetingEnded = true
    const process = anonymousUserConnectionInfo.multiUser
      ? "personal discussion"
      : "KYC application";
    let message = `Thank you for your time. Your ${process} is complete.`;
    try {
      const connectionToken = _get(
        anonymousUserConnectionInfo,
        "connection",
        null
      );
      instance2.get(GET_CASES + `${anonymousUserConnectionInfo.case_details.id}/`,{headers:{
        AuthorizationX: `Token ${connectionToken}`
      }}).then(caseResponse=>{
        if (caseResponse.data.case_tag !== "Pending_Audit") {
          message = `Thank you for your time. Your ${process} could not be completed. Please contact your agent.`;
          setCallCompleted(false);
          setKycComplete(message);
        }
      })
      .catch(err=>{
        setKycComplete(message);
        setCallCompleted(true);
      })
      
    } catch (err) {
      setCallCompleted(true);
      console.log('after meeting end error',err);
    }
    setMeetingEnded(true);
    if(isLive)
      changeLiveStatus()
  };

  function handleReconnect(){
    console.log('reconnect......')
    if(timerCount > 3) return
    setIsReconnecting(true)
    meetingManager.audioVideo.stop()
    meetingManager.leave()
    setChimeMeetingStarted(false)
    setTimerCount(old=>old + 1)
    setTimeout(()=> joinSession(),2000)
  }
  useEffect(()=>{
    if(isNotificationSent)
     sendNotificationToAgent()
  },[isNotificationSent])
  async function sendNotificationToAgent (){
    if(isLive) return
    try{
        let {agent,id} = anonymousUserConnectionInfo.case_details
        let notification_by = anonymousUserConnectionInfo.id
        let name = anonymousUserConnectionInfo.name
        await api.post('/case/notifications/',{
            "notification_text": `${name} has started the video call and is waiting for you, click to take action.`,
            "notification_type": "Info",
            "notification_for": agent,
            "notification_by": notification_by,
            "action_tab": "scheduled",
            "case": id,
            "case_type": anonymousUserConnectionInfo.case_details.process_details.case_type
    })
    }
    catch(err){
        console.log(err)
    }
}

  return (
    <React.Fragment>
      {!locationAccess && (
        <div className="location-error">
          Location access blocked, allow access to device location to continue
          with your KYC process
        </div>
      )}
      {isLoader && <Loader title="Waiting for agent to start the  call" />}
      {meetingEnded && kycComplete ? (
        <div className="wrapper light-blue-customer-template">
          <Modal open={true}>
            <div id="kyc-modalContainer" className={props.classes.root}>
              <div className="kyc-modalText">
                {callCompleted ? (
                  <CheckCircleIcon
                    className="kyc-modalColor"
                    id="kyc-successIcon"
                  ></CheckCircleIcon>
                ) : (
                  <img
                    src={CloseIcon}
                    style={{
                      height: "75px",
                      width: "75px",
                    }}
                  />
                )}
              </div>
              <p className="kyc-modalText">{kycComplete}</p>
            </div>
          </Modal>
        </div>
      ) : (
        <div className="wrapper light-blue-customer-template">
          {is_unique_code && uniqueCode.length > 0 ? <p className="unique-code">Your random verification code : {uniqueCode}</p> : null}
          {isChimeMeetingStarted ?  (
              <ChimeVideoRoomComponent
                reconnectsession = {()=> handleReconnect()}
                leaveSession={() => {
                  meetingManager.leave();
                  callAfterMeetingEnded()
                  setMeetingEnded(true)
                }}
                isCustomer={true}
                isToolbarDissabled={kycComplete ? true : false}
                toggleCamera = {false}
                multi={anonymousUserConnectionInfo.multiUser}
                hostSessions={hostSessions}
                currentChimeId={currentChimeId}
              />
          ) : isReconnecting ?  <Loader title="Connecting..." /> : (
            <div>
              <UserHeader />
              <section class="main-body m-auto" style={{ maxWidth: "460px" }}>
                <div class="container-fluid mt-4">
                  <div class="row">
                    <div class="col-md-12">
                      <h4 class="txt-light-blue ltr-spc-1">
                        Video
                        {anonymousUserConnectionInfo.multiUser
                          ? " PD "
                          : " KYC "}
                        Instructions
                      </h4>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-12">
                      <div class="border border-1 dashed-brdr brdr-light-blue rounded equal-box d-flex align-items-center p-4 bg-white justify-content-center fnt-sml flex-d-column">
                        <img src={MOBILE_HAND_IMAGE} width="120" alt=""></img>
                        <span class="circle-sml bg-light-blue d-flex align-items-center justify-content-center text-white fnt-bld fnt-1rem mt-4">
                          1
                        </span>
                        <span class="txt-light-black mt-2">
                          You will have to allow the access to
                        </span>
                        <span class="txt-light-blue fnt-bld">
                          Geo Location, Audio & Video
                        </span>
                        <span class="circle-sml bg-light-blue d-flex align-items-center justify-content-center text-white fnt-bld fnt-1rem mt-4">
                          2
                        </span>
                        <span class="txt-light-black mt-2">
                          During the call
                        </span>
                        <span class="txt-light-blue fnt-bld">
                          Hold your device still
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3 mb-3">
                    <div
                      class={
                        anonymousUserConnectionInfo.multiUser || isLive
                          ? "col-12 text-right"
                          : "col-6 text-right"
                      }
                    >
                      <button
                        class="btn btn-custom btn-lg btn-primary bg-0f7 w-100"
                        onClick={() => checkLocation(checkLiveCall)}
                      >
                        Start Video
                        {anonymousUserConnectionInfo.multiUser
                          ? " PD "
                          : " KYC "}
                        Now
                      </button>
                    </div>
                    {!anonymousUserConnectionInfo.multiUser && !isLive &&
                      _get(
                        anonymousUserConnectionInfo,
                        "case_details.reschedule_attempt",
                        null
                      ) < 3 && (
                        <div class="col-6">
                          <button
                            class="btn btn-custom btn-light-blue w-100"
                            onClick={(e) =>
                              history.push({
                                pathname: "./reschedule",
                                state: {
                                  caseId: _get(
                                    anonymousUserConnectionInfo,
                                    "case_details.id",
                                    null
                                  ),
                                  scheduleTime: _get(
                                    anonymousUserConnectionInfo,
                                    "case_details.session_scheduled_time",
                                    null
                                  ),
                                  agent: _get(
                                    anonymousUserConnectionInfo,
                                    "case_details.agent",
                                    null
                                  ),
                                  rescheduleAttempt: _get(
                                    anonymousUserConnectionInfo,
                                    "case_details.reschedule_attempt",
                                    null
                                  ),
                                },
                              })
                            }
                          >
                            Schedule for later
                          </button>
                        </div>
                      )}
                  </div>
                </div>
                {/* </div> */}
              </section>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
}
export default (withStyles(styles)(KycInstruction));
