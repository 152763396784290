import React from "react";
import GrayTick from "../../assets/icons/gray-tick.png";
import GreenTick2 from "../../assets/icons/green-tick-2.png";
import CloseIcon from "../../assets/icons/close-icon.png";
import API from "../../apiBaseConfigNew";
import Loader from "../../components/loader/Loader";
import Moment from "react-moment";
import _ from "lodash";
import CustomerDetails from '../../components/caseReport/CustomerDetails'
import html2pdf from "html2pdf.js";
import { connect } from 'react-redux'
import CaseDetails from "../../components/caseReport/CaseDetails";
import LogoWrapper from "../../components/caseReport/LogoWrapper";
import GeoTagging from "../../components/caseReport/GeoTagging";
import PanData from "../../components/caseReport/PanData";
import AadharData from "../../components/caseReport/AadharData";
import DlData from "../../components/caseReport/DlData";
import ScreenShotsContainer from "../../components/caseReport/ScreenShotsContainer";

export const auditStatus = {
  completed: {
    status: "Pending with Admin Auditor",
    color: "grey",
    tick: GrayTick,
  },
  audited: {
    status: "Approved",
    color: "#24a749",
    tick: GreenTick2,
  },
  rejected: {
    status: "Rejected",
    color: "red",
    tick: CloseIcon,
  },
};

export const monthNames = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};
class PivcReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportData: {},
      isLoading: true,
      documentsObject: {},
      screenshotArray: [],
      enlargeImage: false,
      enlargeImageSrc: null,
      componentList :['GeoTagging','PAN Card','Aadhar Card','Driving License','ScreenShotsContainer'],
      isVPD:false,
      clientLogo:''
    };
  }
  componentDidMount() {
    this.generateReport(this.props.match.params.id);
    this.getLogoForReport()
  }
  getLogoForReport = async () =>{
    try{
      let response = await API.get('/client/client-logo/')
      let src = response.data['Base64 Client Logo'] || response.data.client_logo
      src = 'data:image/png;base64,' + src
      this.setState({clientLogo:src})
    }
    catch(e){
      console.log('logo fetching error',e)
    }
    
  }
  generateReport = async (caseId) => {
    try {
      const response = await API.get('/case/pivc/report-url/' + caseId);
      let docsObj = {};
      for (var i in response.data.report_data.documents) {
        docsObj[response.data.report_data.documents[i].document_name] = i;
      }
      let screenshotArray = [];
      for (var i in response.data.report_data.documents) {
        if ((response.data.report_data.documents[i].corrected_ocr == null || 
          Object.keys(response.data.report_data.documents[i].corrected_ocr)
            .length === 0 ) && (response.data.report_data.documents[i].extracted_ocr == null ||
          Object.keys(response.data.report_data.documents[i].extracted_ocr)
            .length === 0)
        ) {
          screenshotArray.push({
            name: response.data.report_data.documents[i].document_name,
            index: i,
          });
        }
      }
      let reportData = _.cloneDeep(response.data.report_data);
      //changing format of dob to dd-mmm-yyyy
      //Aadhar
      if (
        docsObj["Aadhar Card"] &&
        response.data.report_data.documents[docsObj["Aadhar Card"]]
          .corrected_ocr.DOB
      ) {
        const [yyyy, mm, dd] =
          response.data.report_data.documents[
            docsObj["Aadhar Card"]
          ].corrected_ocr.DOB.split("-");
        if (yyyy.length === 4) {
          reportData.documents[docsObj["Aadhar Card"]].corrected_ocr.DOB =
            dd + " " + monthNames[parseInt(mm)] + " " + yyyy;
        }
      }
      //Pan Card
      if (
        docsObj["PAN Card"] &&
        response.data.report_data.documents[docsObj["PAN Card"]].corrected_ocr
          .dob
      ) {
        const [dd, mm, yyyy] =
          response.data.report_data.documents[
            docsObj["PAN Card"]
          ].corrected_ocr.dob.split("/");
        if (yyyy.length === 4) {
          reportData.documents[docsObj["PAN Card"]].corrected_ocr.dob =
            dd + " " + monthNames[parseInt(mm)] + " " + yyyy;
        }
      }
      //Driving License
      if (
        docsObj["Driving License"] &&
        response.data.report_data.documents[docsObj["Driving License"]]
          .extracted_ocr.dob
      ) {
        const [dd, mm, yyyy] =
          response.data.report_data.documents[
            docsObj["Driving License"]
          ].extracted_ocr.dob.split("/");
        if (yyyy.length === 4) {
          reportData.documents[docsObj["Driving License"]].extracted_ocr.dob =
            dd + " " + monthNames[parseInt(mm)] + " " + yyyy;
        }
      }
      reportData.customers = []
      reportData.case.name = reportData.case.customer_name
      reportData.customers.push(reportData.case)
      //////////////////////////////////
      this.setState({
        isLoading: false,
        reportData: reportData,
        documentsObject: docsObj,
        screenshotArray: screenshotArray,
      });
      this.handleComponentList(docsObj,reportData)
    } catch (error) {
      console.log("error", error);
    }
  };
  handleComponentList=(docs,reportData)=>{
    let { componentList } = this.state
    if(!reportData.answers || reportData.answers.length === 0) {
      componentList.shift()
    }
    if(reportData.case.case_type === 'personal_discussion'){
      this.setState({isVPD:true})
      let list = ['PAN Card','Aadhar Card','Driving License']
      let i = componentList.length
      while(i--){
        if(list.includes(componentList[i])){
          componentList.splice(i,1)
      }
      }
  }
  else{
      let list = ['PAN Card','Aadhar Card','Driving License']
      let i = componentList.length
      while(i--){
        if(list.includes(componentList[i]) && !docs[componentList[i]] ){
          componentList.splice(i,1)
      }
      }
  }
    this.setState({componentList})
  }
  printReport = () => {
    const element = document.getElementById("divToPrint");
    const opt = {
      margin: 0,
      filename: `case-report-${this.props.match.params.id}.pdf`,
      html2canvas: { dpi: 300, letterRendering: true, useCORS: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().from(element).set(opt).save();
  };

  render() {
    const {
      isLoading,
      reportData,
      documentsObject,
      enlargeImage,
      enlargeImageSrc,
      screenshotArray,
      isVPD,
      clientLogo
    } = this.state;
    const { logo_path, client_details } = this.props.clientConfig
    const isHFCL = client_details.name === 'HFCL'
    
    const NameToComponentMap = {
       'GeoTagging' : GeoTagging,
       'PAN Card': PanData ,
       'Aadhar Card': AadharData ,
       'Driving License' : DlData ,
       'ScreenShotsContainer':ScreenShotsContainer
    }
    return (
      <React.Fragment>
        {isLoading ? (
          <Loader title="Generating Report. Please wait" />
        ) : (
          <div
            className="container preview-container pt-4 pb-4 mb-4 position-relative"
            id="divToPrint"
          >
            <div>
              <button
                className="btn btn-primary download-btn-positioned"
                onClick={()=>this.printReport()}
              >
                Download PDF
              </button>
              <LogoWrapper  clientLogo ={clientLogo.length > 0 ? clientLogo : logo_path}  type={reportData.case.case_type} />
            </div>
            <div className="mt-4"></div>
            <CustomerDetails reportData={reportData} />
            {isVPD ? <>
            <div className="html2pdf__page-break"></div>
            <LogoWrapper clientLogo ={clientLogo.length > 0 ? clientLogo : logo_path} type={reportData.case.case_type} />
            </> : null}
            <CaseDetails isPivc reportData={reportData} isHFCL={isHFCL} />
            <p style={{fontSize:'10px',textAlign:'center'}}>**This is system generated report</p> 
            <div className="html2pdf__page-break"></div>
            <LogoWrapper clientLogo ={clientLogo.length > 0 ? clientLogo : logo_path} type={reportData.case.case_type} />

            {this.state.componentList.map((c,index)=>{
                return <>
                <WithComponent Component={NameToComponentMap[c]} isVPD={isVPD} screenshotArray={screenshotArray || []} reportData={reportData} documentsObject={documentsObject} imageZoom={src => { this.setState({ enlargeImageSrc: src, enlargeImage: true }) }} step ={index+1} />
                {!isVPD && (c == 'GeoTagging' || c == 'Aadhar Card') ? null : <>
                <p style={{fontSize:'10px',textAlign:'center'}}>**This is system generated report</p> 
                <div className="html2pdf__page-break"></div>
                <LogoWrapper clientLogo ={clientLogo.length > 0 ? clientLogo : logo_path}  type={reportData.case.case_type} />
                </> }
                </>
            })}
            <table width="100%" style={{ backgroundColor: "#94d1fe" }}>
              <tr>
                <td style={{ padding: "0px" }}>
                  <table width="100%">
                    <tr>
                      <td style={{ padding: "15px" }}>
                        <h4 className="txt-white">Auditor Details</h4>
                        <table width="100%" style={{ marginTop: "15px" }}>
                          <tr>
                            <td
                              width="365"
                              style={{ padding: "0px 10px 0px 0px" }}
                            >
                              <table
                                className="white-table-rows bg-white"
                                width="365"
                              >
                                <tr>
                                  <td
                                    width="50%"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    Full Name
                                  </td>
                                  <td
                                    width="1%"
                                    align="center"
                                    style={{ padding: "0px" }}
                                  >
                                    :
                                  </td>
                                  <td width="49%">
                                    {reportData.case.admin_auditor_name}
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td
                              width="365"
                              style={{ padding: "0px 0px 0px 10px" }}
                            >
                              <table
                                className="white-table-rows bg-white"
                                width="365"
                              >
                                <tr>
                                  <td
                                    width="50%"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    Identification Number
                                  </td>
                                  <td
                                    width="1%"
                                    align="center"
                                    style={{ padding: "0px" }}
                                  >
                                    :
                                  </td>
                                  <td width="49%">
                                    {reportData.case.audited_by}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <table width="100%" style={{ marginTop: "8px" }}>
                          <tr>
                            <td
                              width="335"
                              style={{ padding: "0px 10px 0px 0px" }}
                            >
                              <table
                                className="white-table-rows bg-white"
                                width="365"
                              >
                                <tr>
                                  <td
                                    width="50%"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    Mobile Number
                                  </td>
                                  <td width="1%" align="center">
                                    :
                                  </td>
                                  <td width="49%">
                                    {reportData.case.admin_auditor_mobile_number
                                      ? reportData.case.admin_auditor_mobile_number
                                      : ""}
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td
                              width="335"
                              style={{ padding: "0px 0px 0px 10px" }}
                            >
                              <table
                                className="white-table-rows bg-white"
                                width="365"
                              >
                                <tr>
                                  <td
                                    width="50%"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    Official Email Address
                                  </td>
                                  <td width="1%" align="center">
                                    :
                                  </td>
                                  <td width="49%">
                                    {reportData.case.admin_auditor_email
                                      ? reportData.case.admin_auditor_email
                                      : ""}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table
              width="100%"
              className="mb-3 bg-white"
              style={{ border: "1px solid #b1b1b1", borderTop: "0px" }}
            >
              <tr>
                <td style={{ padding: "0px" }}>
                  <table width="100%">
                    <tr>
                      <td style={{ padding: "15px 15px 15px 15px" }}>
                        <h4>Audit Details</h4>
                        <table width="100%" style={{ marginTop: "15px" }}>
                          <tr>
                            <td style={{ padding: "0px 0px 0px 0px" }}>
                              <table
                                className="green-table-rows"
                                width="100%"
                                style={{ backgroundColor: "#ddf2e3" }}
                              >
                                <tr>
                                  <td
                                    width="20%"
                                    style={{
                                      paddingRight: "0px",
                                      color: auditStatus[reportData.case.status]
                                        .color
                                        ? auditStatus[reportData.case.status]
                                          .color
                                        : "",
                                    }}
                                  >
                                    Audit Status
                                  </td>
                                  <td
                                    width="1%"
                                    align="center"
                                    style={{
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    :
                                  </td>
                                  <td
                                    width="74%"
                                    style={{
                                      color: auditStatus[reportData.case.status]
                                        .color
                                        ? auditStatus[reportData.case.status]
                                          .color
                                        : "",
                                    }}
                                  >
                                    {auditStatus[reportData.case.status].status
                                      ? auditStatus[reportData.case.status]
                                        .status
                                      : ""}
                                  </td>
                                  <td
                                    align="right"
                                    width="5%"
                                    style={{
                                      paddingRight: "10px",
                                      paddingLeft: "0px",
                                    }}
                                  >
                                    <img
                                      src={
                                        auditStatus[reportData.case.status].tick
                                          ? auditStatus[reportData.case.status]
                                            .tick
                                          : ""
                                      }
                                      width="20"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <table width="100%" style={{ marginTop: "8px" }}>
                          <tr>
                            <td
                              width="359"
                              style={{ padding: "0px 10px 0px 0px" }}
                            >
                              <table
                                className="gray-table-rows bg-f8"
                                width="365"
                              >
                                <tr>
                                  <td
                                    width="38%"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    Audit completed on
                                  </td>
                                  <td
                                    width="1%"
                                    align="center"
                                    style={{
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                      padding: "0px",
                                    }}
                                  >
                                    :
                                  </td>
                                  <td width="49%">
                                    {reportData.case.audited_at ? (
                                      <Moment format="DD MMM YYYY LT">
                                        {reportData.case.audited_at}
                                      </Moment>
                                    ) : (
                                      "N.A."
                                    )}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <hr
                          style={{
                            borderTop: "1px dashed #707070 !important",
                            borderBottom: 0,
                            marginTop: "20px",
                            marginBottom: "20px",
                            width: "100%",
                          }}
                        />
                        <h4>Case Audit Details</h4>
                        <table
                          className="gray-table-rows bg-f8"
                          width="100%"
                          style={{ marginTop: "15px", marginBottom: "15px" }}
                        >
                          <tr>
                            <td width="19%" style={{ paddingRight: "0px" }}>
                              Auditor Comments
                            </td>
                            <td width="1%" align="center">
                              :
                            </td>
                            <td
                              width="80%"
                              style={{
                                color: "#006EB7",
                                fontStyle: "italic",
                                lineHeight: "18px",
                              }}
                            >
                             
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <p style={{fontSize:'10px',textAlign:'center'}}>**This is system generated report</p> 
          </div>
        )}
        {enlargeImage && (
          <img
            src={enlargeImageSrc}
            crossOrigin="anonymous"
            className="rounded border doc-enlarge"
            alt=""
            onClick={() =>
              this.setState({
                enlargeImage: !enlargeImage,
                enlargeImageSrc: ""
              })
            }
            style={{ cursor: "pointer" }}
          />
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  clientConfig: state.login.clientInfo
})
export default connect(mapStateToProps)(PivcReport);

const WithComponent =({Component, ...props}) =>{
  return <Component {...props} />
}
