import React, { useEffect } from "react";
import api from "../../apiBaseConfig";
import { GET_QUESTIONNAIRE, PUT_ANSWER } from "../../apiurl";

function Questionnaire(props) {
  useEffect(async () => {
    if (!props.questionnaire.length) {
       let response = await api.get(
          GET_QUESTIONNAIRE + "?process=" + props.processId + '&case_id='+props.caseId
        );
      const answersdata = await api.get(
        PUT_ANSWER + "?customer_id=" + props.connectionId
      );
      handleSavedAnswers(answersdata.data, response.data);
    }
  }, [props.connectionId,props.processId,props.caseId]);

  const handleSavedAnswers = (answers, questions) => {
    let questionsData = questions;
    for (var i in answers) {
      for (var j in questionsData) {
        if (answers[i].question == questionsData[j].id)
          questionsData[j].answer = answers[i].answer;
        questionsData[j].hasError = false;
      }
    }
    props.setQuestions(questionsData);
  };
  const saveAnswer = async (e, index) => {
    let stateQues = props.questionnaire;
    stateQues[index].answer = e;
    stateQues[index].hasError = false;
    props.setQuestions(stateQues);
    await api.put(PUT_ANSWER, {
      question: stateQues[index].id,
      customer: props.connectionId,
      answer: e,
    });
  };

  const regexCheck = (value, index) => {
    let regex = props.questionnaire[index].regex;
    if (regex) {
      if (new RegExp(regex).test(value)) {
        saveAnswer(value, index);
      } else {
        let stateQues = props.questionnaire;
        stateQues[index].hasError = true;
        props.setQuestions(stateQues);
      }
    } else {
      saveAnswer(value, index);
    }
  };


  return (
    <div class="border rounded p-4 bg-f9">
      {props.questionnaire.map((ques, index) => (
        <React.Fragment>
          <h6
            class={
              ques.hasError
                ? "text-danger mb-1 ltr-spc-1"
                : "txt-light-blue mb-1 ltr-spc-1"
            }
          >
            {ques.question_text}
          </h6>
          <div class="custom-radio-tabular fnt-sml mb-2">
            {ques.question_type === "radio" &&
              ques.question_choices.map((choice) => (
                <React.Fragment>
                  <input
                    type="radio"
                    value={Object.keys(choice)[0]}
                    checked={Object.keys(choice)[0] === ques.answer}
                    name={ques.id}
                    id={`${ques.id}${Object.keys(choice)[0]}`}
                    onChange={(e) => saveAnswer(e.currentTarget.value, index)}
                  />
                  <label for={`${ques.id}${Object.keys(choice)[0]}`}>
                    {Object.values(choice)[0]}
                  </label>
                </React.Fragment>
              ))}
            {
              ques.question_type === "textbox" && (
                <textarea
                  placeholder={ques.placeholder}
                  onBlur={(e) => regexCheck(e.currentTarget.value, index)}
                  rows={2}
                  class="form-control "
                  defaultValue={ques.answer}
                />
              )
            }
            {ques.question_type === "dropdown" && (
              <select
                defaultValue={ques.answer}
                onChange={(e) => saveAnswer(e.currentTarget.value, index)}
                class="form-control form-control-sm fnt-sml"
                id="exampleFormControlSelect"
              >
                <option value={""}>Select </option>
                {ques.question_choices && ques.question_choices.map((choice) => (
                  <option
                    key={`${ques.id}${Object.keys(choice)[0]}`}
                    value={Object.keys(choice)[0]}
                  >
                    {Object.values(choice)[0]}
                  </option>
                ))}
              </select>
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}

export default Questionnaire;
