import React from 'react'

import TextBox from './TextBox';
import DateInput from './DateInput';
import TimeInput from './TimeInput';

const Components ={
    'text':TextBox,
    'date':DateInput,
    'datetime':TimeInput,


    // 2:TextArea,
    // 3:DropDown
  }
  
   const WithInputType = (props) =>{
    if(!Components[props.type]){
      return <TextBox {...props} />
    }
    let ComponentName = Components[props.type];
    return <ComponentName {...props}/>
  } 
  export default WithInputType
