import React from "react";

class NIDImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enlargeImage: false,
    };
  }
  render() {
    const { enlargeImage } = this.state;
    const { doc } = this.props;
    return (
      <td width="38.5%" style={{ paddingLeft: "0px" }}>
        <img
          src={doc.front_upload}
          alt=""
          width="100"
          style={{ marginLeft: "15px", cursor: "pointer" }}
          crossOrigin="anonymous"
          onClick={() =>
            this.setState({
              enlargeImage: !enlargeImage,
            })
          }
          className="img-fluid rounded"
        />
        {enlargeImage && (
          <img
            src={doc.front_upload}
            className="rounded border doc-enlarge"
            alt=""
            crossOrigin="anonymous"
            onClick={() =>
              this.setState({
                enlargeImage: !enlargeImage,
              })
            }
            style={{ cursor: "pointer" }}
          />
        )}
      </td>
    );
  }
}

export default NIDImage;
