import "date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import React, { useEffect, useState } from "react";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import moment from "moment";
import api from "../../../../../apiBaseConfig";
import {
  GET_CASES,
  GET_CASE_CONNECTIONS,
  CREATE_SESSION_DB,
  UPDATE_CASE_SESSION_DB,
} from "../../../../../apiurl";
import _get from "lodash/get";
import CustomTimePicker from "./customTimePicker";

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#27abec",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#27abec",
      },
    },
  },
});

export default function Scheduler(props) {
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [dateSelected, setDateSelected] = useState(false);
  const [timeSelected, setTimeSelected] = useState(false);
  const [hasError, setHasError] = useState(false);
  const setDateString = (e, type) => {
    if (type === "date") {
      setDateSelected(true);
      setDate(moment(e).format());
    } else {
      setTimeSelected(true);
      // setTime(moment(e).format());
      setTime(e);

    }
  };

  useEffect(() => {
    if (dateSelected && timeSelected) {
      let hasErrorVal =
        // moment(new Date(`${date.split("T")[0]}T${time.split("T")[1]}`)).diff(
        //   new Date()
        // ) <= 0;
        moment(new Date(`${date.split("T")[0]}T${time}`)).diff(
          new Date()
        ) <= 0;  

      setHasError(hasErrorVal);
    }
  }, [date, time]);

  const handleSchedule = async () => {
    try {
      if (date && time) {
        let hasErrorVal =
          moment(new Date(`${date.split("T")[0]}T${time}`)).diff(
            new Date()
          ) <= 0;
        if (hasErrorVal) {
          setHasError(hasErrorVal);
          return;
        }

        const dateTime = `${date.split("T")[0]}T${time}`;
        props.setSchedulerLoader(true);
        // setLoading(true);
        const scheduledCase = await api.patch(`${GET_CASES}${props.caseId}/`, {
          status: "scheduled",
        });
        await api.post(CREATE_SESSION_DB, {
          case: props.caseId,
          scheduled_time: dateTime,
        });
        const customers = await api.get(
          `${GET_CASE_CONNECTIONS}?case=${props.caseId}`
        );
        const customerToken = _get(customers, "data[0].connection");
        props.setSchedulerLoader(false);
        // setLoading(false);
        props.onScheduleComplete(props.index, dateTime, customerToken);
        props.closeSchedulerModal();
      }
    } catch (error) {
      console.log("error in updating schedule time", error);
      props.setSchedulerLoader(false);
      // setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={materialTheme}>
      {/* {isLoading && <Loader title={"Loading" + " please wait"} />} */}
      <div className="row mt-2">
        <div className="col-md-12">
          <h6 className="txt-light-blue2 ltr-spc-1 fnt-sml">
            Select date &amp; Time for Video{" "}
            {props.activeUpperTab === 0 ? "KYC" : "PD"}
          </h6>
        </div>
      </div>
      <div className="row fnt-sml" style={{ marginTop: "5px" }}>
        <div class="col-4" style={{ minWidth: "60%" }}>
          <div class="position-relative">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disablePast
                format="dd/MM/yyyy"
                label="Select Date"
                color="secondary"
                value={date}
                onChange={(e) => setDateString(e, "date")}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div class="col-4" style={{ minWidth: "40%" }}>
            <CustomTimePicker UId={props.index} handleChange={e=>setDateString(e,'time')} label="Time(24h)" value={time} />
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <button
          className="schedule-button"
          onClick={() => handleSchedule()}
          style={{
            background:
              dateSelected && timeSelected && !hasError ? "#60c1f1" : "#a9a9a9",
            border:
              dateSelected && timeSelected && !hasError
                ? "1px solid #27abec"
                : "1px solid #696969",
          }}
          disabled={!(dateSelected && timeSelected && !hasError)}
        >
          Schedule
        </button>
      </div>
      {hasError && (
        <p style={{ color: "red", textAlign:'left', marginTop:5 }}>
          Please select a valid time.
        </p>
      )}
    </ThemeProvider>
  );
}
