import React from 'react'

export default function TimeInput(props) {
    const { onChange, placeholder, label, value, isRequired, disabled, readOnly, error, className } = props

    return (
        <div className="form-group">
            {label ? <label >{label}{isRequired == 1 && <span className='astric'>*</span>}</label> : <label for=""></label>}
            <input type="datetime-local" disabled={disabled} className="form-control" value={value || ''} onChange={e => onChange(e.target.value)} placeholder={placeholder} />
            {error && error.length > 0 ? <p className='form-error'>{error}</p> : null}
        </div>
    )
}
