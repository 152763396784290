import React from "react";
import GreenTick from "../../assets/icons/green-tick.png";
import NIDImage from "./NIDImage";

class NationalIdentification extends React.Component {
  render() {
    const { connection } = this.props;
    return (
      <>
        {connection.documents.map((doc, index) => {
          return (
            <>
              {doc.document.key !== "screenshot" && (
                <table
                  className="gray-table-rows"
                  width="750"
                  style={{
                    backgroundColor: "#f8f8f8",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                  key={index}
                >
                  <tr>
                    <td width="30.5%" style={{ paddingRight: "0px" }}>
                      {connection.name}
                      <br />
                      {doc.document.name}
                    </td>
                    <NIDImage doc={doc} />
                    <td align="right" width="31%" style={{ padding: "0px" }}>
                      <table width="100%">
                        <tr>
                          <td
                            width="30"
                            style={{ padding: "0px", paddingRight: "0px" }}
                          >
                            <img src={GreenTick} width="20" alt="" />
                          </td>
                          <td
                            style={{
                              padding: "0px",
                              paddingLeft: "0px",
                              paddingRight: "10px",
                            }}
                          >
                            <span
                              style={{ color: "#24a749", fontStyle: "italic" }}
                            >
                              Document uploaded successfully
                            </span>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              )}
            </>
          );
        })}
      </>
    );
  }
}

export default NationalIdentification;
