import React from "react";
import { useHistory } from "react-router";
import "../screens/uploadDocs/UploadDocs.scss";
import {useSelector} from 'react-redux'

function UserHeader({handleBackClick}) {
  const history = useHistory();
  const {logo_path} = useSelector(state=>state.login.clientInfo)
  const backClicked = () => {
    console.log("backClicked");
    if(handleBackClick)
      handleBackClick(history)
    else   
     history.goBack();
  };
  return (
    <header>
      <nav className="navbar navbar-expand-lg h-100 navbar-light p-0 pl-2 pr-1">
        <span
          onClick={backClicked}
          style={{ color: "#007bff" }}
          className="pointer"
        >
          <i className="fa fa-arrow-left"></i>
        </span>
     
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <a href="#" className="nav-link"></a>
            </li>
          </ul>
        </div>
        <div className="dashboard-client-image" style={{backgroundImage:`url(${logo_path})`}}>
        </div>
      </nav>
    </header>
  );
}

export default UserHeader;
