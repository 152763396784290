import React, { Component } from "react";
import api from "../../../apiBaseConfig";
import {
  CREATE_SESSION_PLATFORM,
  CREATE_PLATFORM_TOKEN_FOR_USER,
  GET_CASES,
  GET_VIDEO_URL,
  GET_DOCUMENTS,
  FIND_LIVE_CASE,
  SEND_EMAIL,
  CREATE_SESSION_DB,
  GET_AUDITOR_CASES,
  CREATE_SESSION,
  GET_DOCUMENT_CHOICE,
  GET_PROCESSES,
  SIGNED_VIDEO_URL,
} from "../../../apiurl";
import "./AgentDashboard.scss";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import { connect } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";
import Scheduler from "./components/scheduler/Scheduler";
import _get, { constant } from "lodash";
import { Modal } from "@material-ui/core";
import { Toast } from "../../../utils/toastutils";
import Loader from "../../../components/loader/Loader";
import { logout } from "./../../../login/state/login.actions";
import DashboardHeader from "./components/dashboardHeader/DashboardHeader";
import UpperTab from "./components/upperTab/UpperTab";
import CasesContainer from "./components/casesContainer/CasesContainer";
import DashboardCalendar from "./components/dashboardCalendar/DashboardCalendar";
import DashboardSidebar from "./components/dashboardSidebar/DashboardSidebar";
import LiveCaseModal from "./components/liveCaseModal/LiveCaseModal";
import AgentProfile from "../../../agent/screens/agentDashboard/components/agentProfile";

const styles = (theme) => ({
  table: {
    minWidth: 400,
  },
  root: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontSize: 14,
    fontWeight: "bold",
    align: "left",
  },
  body: {
    fontSize: 14,
    align: "left",
  },
}))(TableCell);
class AgentDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mySessionId: "Sessionf",
      caseDataResponseArray: [],
      assignedCases: [],
      scheduledCases: [],
      completedCases: [],
      activeCase: {},
      // isDocumentModalOpen: false,
      isLiveCaseModalOpen: false,
      isLiveCaseToggleOn: false,
      page: 0,
      totalCount: 1,
      rowsPerPage: 25,
      anchorEl: null,
      sideBarExpanded: false,
      activeTab: 0,
      activeUpperTab: 0,
      profileOptionClicked: false,
      caseType: "video_kyc",
      status: "assigned",
      searchKeyObject: {},
      sortingOrder: "-created_at",
      ordering: "created_at_order",
      processChoices: [],
      documentChoices: [],
      processFilter: null,
      applyFilter: false,
      showFilter: false,
      documentFilter: null,
      liveCaseData: {},
      isSchedulerLoading: false,
      videoCallSessionId: null,
      showAgentProfile:false
    };
    this.OPENVIDU_SERVER_URL = this.props.openviduServerUrl
      ? this.props.openviduServerUrl
      : "https://video.authbridge.com";
    this.OPENVIDU_SERVER_SECRET = this.props.openviduSecret
      ? this.props.openviduSecret
      : "secret";
  }

  createSession = (sessionId) => {
    return new Promise((resolve, reject) => {
      var data = {
        case_id: +sessionId,
      };
      api
        .post(CREATE_SESSION_PLATFORM, data)
        .then((response) => {
          console.log("CREATE SESION", response.data);
          const sessionId = response.data.id;
          if (sessionId) {
            this.setState({ videoCallSessionId: sessionId });
          }
          resolve(response.data);
        })
        .catch((response) => {
          var error = Object.assign({}, response);
          // console.log(error.response.detail);

          if (error.response && error.response.status === 409) {
            resolve(null);
          } else {
            console.log(error);
            console.warn("Error in creating session with platform Server");
          }
        });
    });
  };

  createToken = (caseId) => {
    return new Promise((resolve, reject) => {
      api
        .post(CREATE_PLATFORM_TOKEN_FOR_USER + caseId + "/", {})
        .then((response) => {
          // console.log("TOKEN", response);
          // this.setState({tokenObject:response.data})
          resolve(response.data.token);
        })
        .catch((error) => {
          console.log(error.response.data.message);
          return reject(error);
        });
    });
  };

  createAndStartSession = async (caseId, agentName, platform_type) => {
    const platform_session_details = await this.createSession(caseId);
    const tokenObject = await this.createToken(caseId);

    this.props.history.push({
      pathname: "/video/dashboard",
      state: {
        ...this.state,
        type: "agent",
        caseId: caseId,
        userId: this.props.userInfo.userId,
        caseName: agentName,
        sessionId: caseId,
        platform_session_details,
        tokenObject,
        platform_type,
        videoCallSessionId: this.state.videoCallSessionId,
      },
    });
  };

  getActiveTabNumber = (status) => {
    switch (status) {
      case "assigned":
        return 0;
      case "scheduled":
        return 1;
      case "completed":
        return 2;
    }
  };

  getActiveUpperTabNumber = (caseType) => {
    switch (caseType) {
      case "video_kyc":
        return 0;
      case "personal_discussion":
        return 1;
    }
  };

  async componentDidMount() {
    const { userInfo, clientInfo } = this.props;
    let {case_module} = clientInfo
    const historyState = this.props.history.location.state || {};
    this.setState({ isLoading: true });
    this.getDocChoices();
    this.getProcessChoices();
    const caseIdSignedURL = historyState.caseId || null;
    if (userInfo.isAuditor && !userInfo.isAgent) {
      this.changeActiveTab(2);
      // await this.getCaseData("video_kyc", "completed");
    } else if (userInfo.isAuditor && userInfo.isAgent) {
      this.changeActiveTab(2);
    } else {
      const status = historyState.status || "assigned";
      const caseType = userInfo.isAdminAuditor ? 'pivc' : historyState.caseType || "video_kyc";
      this.setState({
        status,
        caseType,
        activeTab: this.getActiveTabNumber(status),
        activeUpperTab: this.getActiveUpperTabNumber(caseType),
      });
      await this.getCaseData(caseType, status);
    }
    if(case_module == 2){
      this.changeActiveUpperTab(1)
    }
    const intervalId = setInterval(
      () => this.getCaseData(this.state.caseType, this.state.status),
      30000
    );
    this.setState({
      refreshCasesIntervalId: intervalId,
    });
  }

  callSignedUrl = async (caseId) => {
    const res2 = await api.get(`${SIGNED_VIDEO_URL}${caseId}`);
  };
  componentWillUnmount() {
    console.log("unmount agent dashboard");
    if (this.state.refreshCasesIntervalId) {
      clearInterval(this.state.refreshCasesIntervalId);
    }
    if (this.state.signedUrlIntervalId) {
      clearInterval(this.state.signedUrlIntervalId);
    }
  }

  getDocChoices = async () => {
    let { data } = await api.get(GET_DOCUMENT_CHOICE);
    this.setState({ documentChoices: data });
  };

  getProcessChoices = async () => {
    let { data } = await api.get(GET_PROCESSES);
    this.setState({ processChoices: data });
  };

  handleFilter = (key, e) => {
    this.setState({ [key]: e.target.value });
  };

  sendMailToAllUsers = async (caseId) => {
    try {
      this.setState({
        isLoading: true,
        loaderTitle: "Please wait, Sending Email",
      });
      await api.post(`${SEND_EMAIL}${caseId}/`);
      this.setState({ isLoading: false });
      Toast.success({
        title: "Email sent successfully",
      });
    } catch (error) {
      console.log("error", error);
      this.setState({ isLoading: false });
      Toast.success({
        title: "Error while sending the email",
      });
    }
  };
  handleChange = (event, value) => {
    const { caseType, activeTab } = this.state;
    let status =
      activeTab === 0
        ? "assigned"
        : activeTab === 1
        ? "scheduled"
        : "completed";
    this.setState(
      {
        ...this.state,
        page: value,
        isLoading: true,
        assignedCases: [],
        scheduledCases: [],
        completedCases: [],
      },
      () => this.getCaseData(caseType, status)
    );
  };

  handleWatchVideo = async (caseID) => {
    // const cases = this.state.caseDataResponseArray;

    try {
      const response = await api.get(`${GET_VIDEO_URL}${caseID}/`);
      const url = response.data.pre_signed_url;
      if (url) {
        window.open(url);
      } else {
        Toast.info({
          description: "video is still being processed",
          title: "Please wait",
        });
      }
      console.log("video url ", url);
    } catch (error) {
      console.log("error in fetching video url", error);
    }
  };

  handleViewDocuments = async (selectedCase) => {
    const response = await api.get(`${GET_DOCUMENTS}?case=${selectedCase.id}`);
    const documents = response.data.data.map((document) => {
      return {
        frontUpload: document.front_upload,
        backUpload: document.back_upload,
      };
    });

    documents.forEach((document) => {
      // console.log(document.frontUpload);
      if (document.frontUpload) {
        window.open(document.frontUpload);
      }
      if (document.backUpload) {
        window.open(document.backUpload);
      }
    });
    // this.setState({
    //   isDocumentModalOpen: true,
    //   activeCase: selectedCase
    // });
  };

  handleMenuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = async () => {
    // console.log("loging out");
    this.props.logout();
    this.props.history.push("./login");
    this.handleMenuClose();
  };

  handleSchedulerOpen = (index) => {
    const cases = this.state.caseDataResponseArray;
    cases[index].isSchedulerOpen = !cases[index].isSchedulerOpen;
    this.setState({ caseDataResponseArray: cases });
  };

  onScheduleComplete = (index, dateTime, customerToken) => {
    const cases = this.state.caseDataResponseArray;
    cases[index].isScheduleComplete = true;
    cases[index].scheduled_time = dateTime;
    cases[index].customerToken = customerToken;
    this.setState({ caseDataResponseArray: cases });
    // this.sendMailToAllUsers(cases[index].id);
    this.updateCasesAfterSchedule();
    // this.createNewSession();
  };

  updateCasesAfterSchedule = () => {
    const { caseType, status } = this.state;
    this.setState({ isLoading: true, loaderTitle: "" });
    this.getCaseData(caseType, status);
  };

  getCaseData = async (caseType, status, searchKeyObject = {}) => {
    let gteParam =
      status === "scheduled"
        ? "session_scheduled_time__gte"
        : `${status}_at__gte`;
    let lteParam =
      status === "scheduled"
        ? "session_scheduled_time__lte"
        : `${status}_at__lte`;
    const { userInfo } = this.props;
    const {
      sortingOrder,
      ordering,
      applyFilter,
      documentFilter,
      processFilter,
    } = this.state;
    let caseDataResponse = [];
    if (status === "completed" && userInfo.isAuditor && !userInfo.isAgent) {
      const case_status = this.state.searchKeyObject.case_status
        ? this.state.searchKeyObject.case_status
        : "completed&status=audited&status=rejected";
      caseDataResponse = await api.get(
        GET_AUDITOR_CASES + "?case_type=" + caseType + "&status=" + case_status,

        {
          params: {
            page: this.state.page + 1,
            name__icontains: this.state.searchKeyObject.case_name
              ? this.state.searchKeyObject.case_name
              : "",
            connection_name: this.state.searchKeyObject.applicant_name
              ? this.state.searchKeyObject.applicant_name
              : "",
            id: this.state.searchKeyObject.meeting_id
              ? this.state.searchKeyObject.meeting_id
              : "",
            connection_email: this.state.searchKeyObject.applicant_email
              ? this.state.searchKeyObject.applicant_email
              : "",
            connection_mobile: this.state.searchKeyObject.applicant_number
              ? this.state.searchKeyObject.applicant_number
              : "",
          
            agent_fname: this.state.searchKeyObject.agent_fname
              ? this.state.searchKeyObject.agent_fname
              : "",
            agent_lname: this.state.searchKeyObject.agent_lname
              ? this.state.searchKeyObject.agent_lname
              : "",
            [gteParam]: this.state.searchKeyObject.date_from
              ? this.state.searchKeyObject.date_from + "T00:00:00.0"
              : "",
            [lteParam]: this.state.searchKeyObject.date_to
              ? this.state.searchKeyObject.date_to + +"T23:59:59.0"
              : "",
            [ordering]: sortingOrder,
            process: applyFilter ? processFilter : null,
            document_key: applyFilter ? documentFilter : null,
          },
        }
      );
    } else {
      const case_status = this.state.searchKeyObject.case_status
        ? this.state.searchKeyObject.case_status
        : "completed&status=audited&status=rejected";
      let apiUrl =
        status === "completed"
          ? GET_CASES + "?case_type=" + caseType + "&status=" + case_status
          : GET_CASES +
            "?case_type=" +
            caseType +
            "&status=" +
            (this.state.searchKeyObject.case_status
              ? this.state.searchKeyObject.case_status
              : status);
      caseDataResponse = await api.get(apiUrl, {
        params: {
          page: this.state.page + 1,
          name__icontains: this.state.searchKeyObject.case_name
            ? this.state.searchKeyObject.case_name
            : "",
          connection_name: this.state.searchKeyObject.applicant_name
            ? this.state.searchKeyObject.applicant_name
            : "",
          id: this.state.searchKeyObject.meeting_id
            ? this.state.searchKeyObject.meeting_id
            : "",
          connection_email: this.state.searchKeyObject.applicant_email
            ? this.state.searchKeyObject.applicant_email
            : "",
          connection_mobile: this.state.searchKeyObject.applicant_number
            ? this.state.searchKeyObject.applicant_number
            : "",
        
          agent_fname: this.state.searchKeyObject.agent_fname
            ? this.state.searchKeyObject.agent_fname
            : "",
          agent_lname: this.state.searchKeyObject.agent_lname
            ? this.state.searchKeyObject.agent_lname
            : "",
          [gteParam]: this.state.searchKeyObject.date_from
            ? this.state.searchKeyObject.date_from + "T00:00:00.0"
            : "",
          [lteParam]: this.state.searchKeyObject.date_to
            ? this.state.searchKeyObject.date_to + "T23:59:59.0"
            : "",
          [ordering]: sortingOrder,
          process: applyFilter ? processFilter : null,
          document_key: applyFilter ? documentFilter : null,
        },
      });
    }

    if (status === "assigned") {
      let assignedCases = caseDataResponse && caseDataResponse.data.results;
      this.setState({ assignedCases: assignedCases, isLoading: false });
    }
    if (status === "scheduled") {
      let scheduledCases = caseDataResponse && caseDataResponse.data.results;
      this.setState({ scheduledCases: scheduledCases, isLoading: false });
    }
    if (status === "completed") {
      let completedCases = caseDataResponse && caseDataResponse.data.results;
      this.setState({ completedCases: completedCases, isLoading: false });
    }
    // const totalCount = caseDataResponse.data.count / caseDataResponse.data.results;
    this.setState({
      caseDataResponseArray: caseDataResponse.data.results,
      // rowsPerPage:caseDataResponse.data.results.length,
      totalCount: caseDataResponse.data.count,
    });
  };

  changeActiveTab = (activeTab) => {
    const { caseType } = this.state;
    let status =
      activeTab === 0
        ? "assigned"
        : activeTab === 1
        ? "scheduled"
        : "completed";
    this.setState(
      {
        activeTab: activeTab,
        status: status,
        page: 0,
        isLoading: true,
        showFilter: false,
        applyFilter: false,
        searchKeyObject: {},
      },
      async () => {
        await this.getCaseData(caseType, status);
        this.setState({ isLoading: false });
      }
    );
  };

  changeActiveUpperTab = (
    activeUpperTab,
    activeTabPriority,
    searchKeyObject = {}
  ) => {
    const { userInfo } = this.props;
    let caseType =
      activeUpperTab === 0
        ? "video_kyc"
        : activeUpperTab === 1
        ? "personal_discussion"
        : "";
    console.log("UserInfo", userInfo);

    let status =
      userInfo && userInfo.isAuditor && !userInfo.isAgent
        ? "completed"
        : "assigned";
    let activeTab = userInfo && userInfo.isAuditor && !userInfo.isAgent ? 2 : 0;
    status =
      activeTabPriority === 0
        ? "assigned"
        : activeTabPriority === 1
        ? "scheduled"
        : activeTabPriority === 3
        ? "completed"
        : status;
    if (userInfo.isAuditor && userInfo.isAgent && activeUpperTab === 0) {
      activeTab = 2;
      status = "completed";
    }
    this.setState(
      {
        activeUpperTab: activeUpperTab,
        activeTab:
          activeTabPriority !== undefined ? activeTabPriority : activeTab,
        caseType: caseType,
        status: status,
        showFilter: false,
        processFilter: null,
        applyFilter: false,
        documentFilter: null,
        isLoading: true,
        page: 0,
        searchKeyObject,
      },
      () => this.getCaseData(caseType, status)
    );
  };
  handleProfileOptions = () => {
    const { profileOptionClicked } = this.state;
    this.setState({ profileOptionClicked: !profileOptionClicked });
  };
  handleProfile = () => {
    this.setState({showAgentProfile:true,});
  };


  fetchUpdatedCases = () => {
    const { caseType, status } = this.state;
    this.setState({ isLoading: true }, () =>
      this.getCaseData(caseType, status)
    );
  };

  handleSearchKeyChanged = (searchKeyObject) => {
    this.setState({ searchKeyObject: searchKeyObject, isLoading: true }, () => {
      const { caseType, status } = this.state;
      this.getCaseData(caseType, status);
    });
  };

  applyFilter = (applyFilter) => {
    const { caseType, status } = this.state;
    this.setState({ applyFilter: applyFilter }, () =>
      this.getCaseData(caseType, status)
    );
  };

  handleSort = () => {
    const { sortingOrder, status } = this.state;
    let newSortingOrder;
    let ordering;
    switch (status) {
      case "assigned":
        ordering = "created_at_order";
        newSortingOrder =
          sortingOrder === "created_at" ? "-created_at" : "created_at";
        break;
      case "scheduled":
        ordering = "session_scheduled_time_order";
        newSortingOrder =
          sortingOrder === "session_scheduled_time"
            ? "-session_scheduled_time"
            : "session_scheduled_time";
        break;
      case "completed":
        ordering = "completed_at_order";
        newSortingOrder =
          sortingOrder === "completed_at" ? "-completed_at" : "completed_at";
        break;
    }
    this.setState(
      { sortingOrder: newSortingOrder, isLoading: true, ordering: ordering },
      () => {
        const { caseType, status } = this.state;
        this.getCaseData(caseType, status);
      }
    );
  };

  findLiveCase = async () => {
    try {
      const findLiveCase = this.findLiveCase.bind(this);
      if (!this.state.isLiveCaseModalOpen && this.state.isLiveCaseToggleOn) {
        const response = await api.get(FIND_LIVE_CASE);
       if (response.data.id) {
          this.setState({
            isLiveCaseModalOpen: true,
            liveCaseData: response.data,
          });
          setTimeout(() => {
            this.setState(
              {
                isLiveCaseModalOpen: false,
              },
              findLiveCase
            );
          }, 60000);
        }
         else if (this.state.isLiveCaseToggleOn) {
          setTimeout(() => {
            findLiveCase();
          }, 500);
        }
        if(!response || !response.data){
          this.setState({isLiveCaseToggleOn:false})
        }
      }
    } catch (error) {
      console.log("error", error, error.response.status);
      if (error.response.status === 404 && this.state.isLiveCaseToggleOn) {
        const findLiveCase = this.findLiveCase.bind(this);
        setTimeout(() => {
          findLiveCase();
        }, 10000);
      }
      if (error.response.status === 400) {
        this.setState({ isLiveCaseToggleOn: false });
      }
    }
  };

  handleNotificationClick = (activeUpperTab, activeTab, caseId) => {
    this.changeActiveUpperTab(activeUpperTab, activeTab, {
      meeting_id: caseId,
    });
  };

  handleLiveCaseToggleChange = (isLiveCaseToggleOn) => {
    this.setState(
      {
        isLiveCaseToggleOn,
      },
      () => {
        if (this.state.isLiveCaseToggleOn) {
            this.findLiveCase();
        }
      }
    );
  };

  setSchedulerLoader = (isSchedulerLoading) => {
    this.setState({ isSchedulerLoading });
  };
  render() {
    const { classes, userInfo, clientInfo  } = this.props;
    let { case_module } = clientInfo;
    const {
      assignedCases,
      scheduledCases,
      completedCases,
      isLoading,
      activeTab,
      activeUpperTab,
      profileOptionClicked,
      processFilter,
      documentChoices,
      showFilter,
      processChoices,
      documentFilter,
      caseType,
      sideBarExpanded,
      isSchedulerLoading,
      status,
    } = this.state;
    return (
      <React.Fragment>
        <div class="wrapper light-blue-template">
          {isLoading && <Loader title={this.state.loaderTitle} />}
          {isSchedulerLoading && <Loader title={"Loading" + " please wait"} />}
          <section>
            <div className="wrapper h-100 light-blue-template">
              <DashboardHeader
                profileOptionClicked={profileOptionClicked}
                handleProfileOptions={this.handleProfileOptions}
                handleLogout={this.handleLogout}
                handleNotificationClick={this.handleNotificationClick}
                userInfo={this.props.userInfo}
                handleProfile={()=>this.handleProfile()}
              />
              <DashboardSidebar
                expanded={sideBarExpanded}
                expand={(e) =>
                  this.setState({ ...this.state, sideBarExpanded: e })
                }
                sideBarChanged={(e) => this.setState({ showAgentProfile:false  })}
              />
              <section
                className={
                  sideBarExpanded ? "ml240 main-content" : "main-content"
                }
              >
                {this.state.showAgentProfile ? <AgentProfile /> :
                <div className="container-fluid p-0">
                  <div className="row no-gutters bg-light pt-2">
                    <div className="col-md-12">
                      <ul
                        className="nav nav-tabs big-nav-tabs-2 ml-30 mt-3 border-0"
                        id="myTab"
                      >
                        {(case_module == 1 || case_module == 3) ? <UpperTab
                          tabKey={0}
                          activeUpperTab={activeUpperTab}
                          changeActiveUpperTab={this.changeActiveUpperTab}
                          upperTabText={"VKYC"}
                        /> : null}
                        {(!userInfo.isAgent || userInfo.isAuditor) && (case_module == 2 || case_module == 3) ?
                          <UpperTab
                            tabKey={1}
                            activeUpperTab={activeUpperTab}
                            changeActiveUpperTab={this.changeActiveUpperTab}
                            upperTabText={"Group Discussion"}
                          /> : null
                        }
                        {/* {userInfo && userInfo.isAgent && (
                          <UpperTab
                            tabKey={2}
                            activeUpperTab={activeUpperTab}
                            changeActiveUpperTab={this.changeActiveUpperTab}
                            upperTabText={"Live Calls"}
                          />
                        )} */}
                      </ul>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-12">
                      <div className="tab-content ml-30 mr-30 mt-4" id="myTab">
                        <div className="tab-pane fade show active" id="vkyc">
                          {activeUpperTab === 0 && (
                            <CasesContainer
                              activeUpperTab={activeUpperTab}
                              activeTab={activeTab}
                              handleFilter={this.handleFilter}
                              applyFilter={this.applyFilter}
                              hideAgentTabs={
                                this.props.userInfo.isAuditor &&
                                this.props.userInfo.isAgent
                              }
                              showFilter={showFilter}
                              updateCaseData={() => {
                                this.setState({
                                  isLoading: true,
                                  loaderTitle: "",
                                });
                                this.getCaseData(caseType, status);
                              }}
                              handleShowFilter={() =>
                                this.setState({ showFilter: !showFilter })
                              }
                              documentChoices={documentChoices}
                              processChoices={processChoices}
                              assignedCases={assignedCases}
                              scheduledCases={scheduledCases}
                              completedCases={completedCases}
                              changeActiveTab={this.changeActiveTab}
                              createAndStartSession={this.createAndStartSession}
                              handleViewDocuments={this.handleViewDocuments}
                              handleSchedulerOpen={this.handleSchedulerOpen}
                              onScheduleComplete={this.onScheduleComplete}
                              userInfo={this.props.userInfo}
                              handleWatchVideo={this.handleWatchVideo}
                              fetchUpdatedCases={this.fetchUpdatedCases}
                              handleSearchKeyChanged={
                                this.handleSearchKeyChanged
                              }
                              // caseNameSearchKey={this.state.caseNameSearchKey}
                              searchKeyObject={this.state.searchKeyObject}
                              handleSort={this.handleSort}
                              handleLiveCaseToggleChange={
                                this.handleLiveCaseToggleChange
                              }
                              isLiveCaseToggleOn={this.state.isLiveCaseToggleOn}
                              setSchedulerLoader={this.setSchedulerLoader}
                            />
                          )}
                          {activeUpperTab === 1 && (
                            <CasesContainer
                              activeUpperTab={activeUpperTab}
                              activeTab={activeTab}
                              assignedCases={assignedCases}
                              handleFilter={this.handleFilter}
                              applyFilter={this.applyFilter}
                              handleShowFilter={() =>
                                this.setState({ showFilter: !showFilter })
                              }
                              showFilter={showFilter}
                              updateCaseData={() => {
                                this.setState({
                                  isLoading: true,
                                  loaderTitle: "",
                                });
                                this.getCaseData(caseType, status);
                              }}
                              documentChoices={documentChoices}
                              processChoices={processChoices}
                              scheduledCases={scheduledCases}
                              completedCases={completedCases}
                              changeActiveTab={this.changeActiveTab}
                              createAndStartSession={this.createAndStartSession}
                              handleViewDocuments={this.handleViewDocuments}
                              handleSchedulerOpen={this.handleSchedulerOpen}
                              onScheduleComplete={this.onScheduleComplete}
                              userInfo={this.props.userInfo}
                              handleWatchVideo={this.handleWatchVideo}
                              fetchUpdatedCases={this.fetchUpdatedCases}
                              handleSearchKeyChanged={
                                this.handleSearchKeyChanged
                              }
                              // caseNameSearchKey={this.state.caseNameSearchKey}
                              searchKeyObject={this.state.searchKeyObject}
                              handleSort={this.handleSort}
                              handleLiveCaseToggleChange={
                                this.handleLiveCaseToggleChange
                              }
                              isLiveCaseToggleOn={this.state.isLiveCaseToggleOn}
                              setSchedulerLoader={this.setSchedulerLoader}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div> }
                <TablePagination
                  count={this.state.totalCount}
                  page={this.state.page}
                  classes={{ root: "pagination-border" }}
                  rowsPerPageOptions={[this.state.rowsPerPage]}
                  rowsPerPage={this.state.rowsPerPage}
                  onChangePage={this.handleChange}
                />
              </section>
              <DashboardCalendar caseType={caseType} />
            </div>
          
            <hr></hr>
          </section>
        

            <Modal open={this.state.isLiveCaseModalOpen}>
            <LiveCaseModal
              liveCaseData={this.state.liveCaseData}
              createAndStartSession={this.createAndStartSession}
              closeLiveCallModal={() =>
                this.setState({ isLiveCaseModalOpen: false })
              }
              onLiveCallRejection={() =>
                this.setState({
                  isLiveCaseToggleOn: false,
                  isLiveCaseModalOpen: false,
                })
              }
            />
          </Modal>      
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.login.userInfo,
  clientInfo : state.login.clientInfo
});
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AgentDashboard));
