import { PAN_CHECK, EMAIL_CHECK, MOBILE_CHECK, NAME_CHECK, NUM_CHECK } from '../../../../../constants'
let formFields = {
    "name": {
        "name": "name",
        "label": "Case Name",
        "placeholder": "Enter Case Name",
        "type": "text",
        "isRequired": true,
        "isValid": false,
        "value": "",
        "error": "",
        "check": NAME_CHECK
    },
    "customer_name": {
        "name": "customer_name",
        "label": "Customer Name",
        "placeholder": "Enter customer name",
        "type": "text",
        "isRequired": true,
        "isValid": false,
        "value": "",
        "error": "",
        "check": NAME_CHECK
    },
    "pan_number": {
        "name": "pan_number",
        "label": "Pan Number",
        "placeholder": "Enter Pan Number",
        "type": "text",
        "isRequired": true,
        "isValid": false,
        "value": "",
        "error": "",
        "check": PAN_CHECK
    },
    "date_of_birth": {
        "name": "date_of_birth",
        "label": "Date of Birth",
        "placeholder": "01/02/2000",
        "type": "date",
        "isRequired": true,
        "isValid": false,
        "value": "",
        "error": ""
    },
    "business_manager_name": {
        "name": "business_manager_name",
        "label": "Business Manager Name",
        "placeholder": "Enter manager name",
        "type": "text",
        "isRequired": false,
        "isValid": false,
        "value": "",
        "error": "",
        "check": NAME_CHECK
    },
    "business_manager_mobile_no": {
        "name": "business_manager_mobile_no",
        "label": "Business Manager Mobile No",
        "placeholder": "9000000000",
        "type": "text",
        "isRequired": false,
        "isValid": false,
        "maxLength": 10,
        "value": "",
        "error": "",
        "check": MOBILE_CHECK
    },
    "source_system": {
        "name": "source_system",
        "label": "Source System",
        "placeholder": "Source system",
        "type": "text",
        "isRequired": true,
        "isValid": false,
        "value": "",
        "error": ""
    },
    "source_system_application_no": {
        "name": "source_system_application_no",
        "label": "Source System Application No",
        "placeholder": "Source System Application No",
        "type": "text",
        "isRequired": true,
        "isValid": false,
        "value": "",
        "error": ""
    },
    "line_of_business": {
        "name": "line_of_business",
        "label": "Line of Business",
        "placeholder": "Name of Business",
        "type": "text",
        "isRequired": true,
        "isValid": false,
        "value": "",
        "error": ""
    },
    "lead_id": {
        "name": "lead_id",
        "label": "Lead Id",
        "placeholder": "Lead Id",
        "type": "text",
        "isRequired": true,
        "isValid": false,
        "value": "",
        "error": ""
    },
    "source_system_ucic": {
        "name": "source_system_ucic",
        "label": "Source System Ucic",
        "placeholder": "Source System Ucic",
        "type": "text",
        "isRequired": false,
        "isValid": false,
        "value": "",
        "error": "",
        "maxLength": 6,
        "check": NUM_CHECK
    },
    "guarantor_coapplicant_id": {
        "name": "guarantor_coapplicant_id",
        "label": "Guarantor Coapplicant Id",
        "placeholder": "Guarantor Coapplicant Id",
        "type": "text",
        "isRequired": false,
        "isValid": false,
        "value": "",
        "error": ""
    },
    "vkyc_initiate_time": {
        "name": "vkyc_initiate_time",
        "label": "Vkyc Initiate Time",
        "placeholder": "",
        "type": "datetime",
        "isRequired": true,
        "isValid": false,
        "value": "",
        "error": ""
    },
    "email": {
        "name": "email",
        "label": "Email",
        "placeholder": "abc@example.com",
        "type": "text",
        "isRequired": true,
        "isValid": false,
        "value": "",
        "error": "",
        "check": EMAIL_CHECK
    },
    "customer_email": {
        "name": "customer_email",
        "label": "Customer Email",
        "placeholder": "abc@example.com",
        "type": "text",
        "isRequired": true,
        "isValid": false,
        "value": "",
        "error": "",
        "check": EMAIL_CHECK
    },
    "mobile_number": {
        "name": "mobile_number",
        "label": "Mobile No.",
        "placeholder": "0000000000",
        "type": "text",
        "isRequired": true,
        "maxLength": 10,
        "isValid": false,
        "value": "",
        "error": "",
        "check": MOBILE_CHECK
    },
    "customer_mobile_number": {
        "name": "customer_mobile_number",
        "label": "Customer Mobile No.",
        "placeholder": "0000000000",
        "type": "text",
        "isRequired": true,
        "maxLength": 10,
        "isValid": false,
        "value": "",
        "error": "",
        "check": MOBILE_CHECK
    },
    "connection_name": {
        "name": "name",
        "label": "Connection Name",
        "placeholder": "Connection Name",
        "type": "text",
        "isRequired": true,
        "isValid": false,
        "value": "",
        "error": "",
        "check": NAME_CHECK
    }
}
export const getFieldData = (name) => {
    if (formFields[name]) {
        return formFields[name]
    }
}