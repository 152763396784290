import React from "react";
import { ReactComponent as TransferIcon } from "../../../../../assets/icons/transfer.svg";
import Moment from "react-moment";
import SearchAndSort from "../searchAndSort/SearchAndSort";
import SearchBar from "../searchBar/SearchBar";
import DeleteIcon from "@material-ui/icons/Delete";
import { connect } from 'react-redux'

class TransferredCases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transferClickedIndex: null,
      showSearchBar: false,
    };
  }

  handleTransferClick = (index) => {
    let transferClickedIndex = this.state.transferClickedIndex;
    if (index === transferClickedIndex) {
      transferClickedIndex = null;
    } else {
      transferClickedIndex = index;
    }
    this.setState({
      transferClickedIndex: transferClickedIndex,
    });
  };

  toggleSearchClick = () => {
    const { showSearchBar } = this.state;
    this.setState({ showSearchBar: !showSearchBar });
  };

  render() {
    const {
      transferredCasesData,
      agentAuditorList,
      searchKeyObject,
      consolidatedAgentList,
      clientInfo
    } = this.props;
    const { has_partners } = clientInfo
    const { transferClickedIndex, showSearchBar } = this.state;
    return (
      <div className="col-md-12">
        <div className="ml-30 mr-30 mt-3">
          <SearchAndSort
            toggleSearchClick={this.toggleSearchClick}
            handleSort={this.props.handleSort}
            getSearchObject={this.props.getSearchObject}
            searchKeyObject={searchKeyObject}
          />
          {showSearchBar ? (
            <SearchBar
              datePrefix={"Transferred"}
              getSearchObject={this.props.getSearchObject}
              searchKeyObject={searchKeyObject}
              consolidatedAgentList={consolidatedAgentList}
            />
          ) : (
            <div className="row mt-1">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <table className="table sml-blue-bg-header bg-sky-blue mb-1">
                      <thead className="all-head-center">
                        <tr>
                          <th width="20%">Case name</th>
                          <th width="20%">Case ID</th>
                          {has_partners ? <th width="10%">Partner</th> : null}
                          <th width="20%" className="text-center">
                            Transfer by
                          </th>
                          <th width="20%" className="text-center">
                            Transfer to
                          </th>
                          <th width="20%" className="text-center">
                            Actions
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
                {transferredCasesData.map((transferredCase, index) => {
                  const agentList =
                    transferredCase.process_details.case_type === "video_kyc"
                      ? consolidatedAgentList
                      : agentAuditorList;
                  return (
                    <div className="row">
                      <div className="col-md-12">
                        <div className="tbl-row">
                          <table className="table mb-0 sml-blue-bg-header table-striped odd-even-tbl roboto-regular">
                            <tbody className="all-body-center pt-7-pb-7-td">
                              <tr>
                                <td width="20%">{transferredCase.name}</td>
                                <td width="20%">{transferredCase.id}</td>
                                {has_partners ? <td width="10%">{transferredCase.partner_name}</td> : null}
                                <td width="20%" align="center">
                                  {transferredCase.transfer_by_name
                                    ? transferredCase.transfer_by_name
                                    : ""}
                                </td>
                                <td width="20%" align="center">
                                  {transferredCase.agent_name}
                                </td>
                                <td width="20%" align="center">
                                  <span
                                    className={
                                      transferClickedIndex === index
                                        ? "transfer dropdown dropleft show"
                                        : "transfer dropdown dropleft"
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.handleTransferClick(index);
                                    }}
                                  >
                                    <TransferIcon
                                      className="dropdown-toggle"
                                      id="dropdownMenuButton"
                                      title="Click here to transfer case"
                                    // data-toggle="dropdown"
                                    />
                                    <div
                                      className={
                                        transferClickedIndex === index
                                          ? "dropdown-menu border-0 transfer-box fnt-sml show"
                                          : "dropdown-menu border-0 transfer-box fnt-sml"
                                      }
                                    >
                                      <a
                                        className="dropdown-item pl-2"
                                        href="#"
                                        style={{ textAlign: "left" }}
                                      >
                                        Transfer to:
                                      </a>
                                      <div className="dropdown-divider"></div>
                                      {(transferClickedIndex === index && agentList.length > 0) ?
                                        agentList.map(
                                          (selectedAgent, agentIndex) => {
                                            if (selectedAgent.id == transferredCase.agent) return null
                                            return (
                                              <a
                                                className="dropdown-item"
                                                href="#"
                                                key={selectedAgent.id}
                                              >
                                                <span
                                                  className="light-blue-sml-circle rounded-circle bg-purple text-white"
                                                  style={{
                                                    width: "10px",
                                                    height: "10px",
                                                  }}
                                                >
                                                  {selectedAgent
                                                    ? selectedAgent.first_name.charAt(
                                                      0
                                                    ) +
                                                    selectedAgent.last_name.charAt(
                                                      0
                                                    )
                                                    : ""}
                                                </span>
                                                <span
                                                  onClick={() =>
                                                    this.props.transferCases(
                                                      selectedAgent,
                                                      transferredCase
                                                    )
                                                  }
                                                >
                                                  {selectedAgent
                                                    ? selectedAgent.first_name +
                                                    " " +
                                                    selectedAgent.last_name
                                                    : ""}
                                                </span>
                                              </a>
                                            );
                                          }
                                        ) : null}
                                    </div>
                                  </span>
                                  <span style={{ marginLeft: "10px" }}>
                                    <DeleteIcon
                                      color="error"
                                      title="Click here to cancel case"
                                      fontSize="small"
                                      onClick={() =>
                                        this.props.handleDeleteCase(
                                          transferredCase
                                        )
                                      }
                                    />
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  clientInfo: state.login.clientInfo
});
export default connect(mapStateToProps)(TransferredCases);
