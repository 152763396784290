import React from "react";
import { CircularProgress } from "@material-ui/core";
import "./Loader.scss";

function Loader(props) {
  return (
    <div className={props.full ? 'loader-container-full': "loader-container"} style={{ zIndex: 999 }}>
      <CircularProgress color="secondary" {...props} />
      {props.title ? (
        <p style={{ color: "#2FA5DF", fontSize: props.fontSize || "1.8em" }}>{props.title}</p>
      ) : (
        ""
      )}
    </div>
  );
}

export default Loader;
