import React, { Component } from "react";
import "./ClientInfo.css";
import VerificationCard from "../verificationCard/VerificationCard";
import api from "../../apiBaseConfig";
import { UPLOAD } from "../../apiurl";
import userImage from "../../assets/images/user-icon.png";
const InfoField = ({ label, value }) => {
  return (
    <div className="label-value-container">
      <div className="label"> {label}</div>
      <div className="label-value"> {value}</div>
    </div>
  );
};
export default class ClientInfo extends Component {
  constructor(props) {
    super(props);
    console.log("props in clientInfo", props);
    this.state = {
      docData: null,
      customerJoind: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (
      nextProps.customerJoind !== this.state.customerJoind
    ) {
      this.setState({ customerJoind: nextProps.customerJoind });
      if (nextProps.customerJoind)
        this.getDocData();
    }
  }

  async getDocData() {
    const response = await api.get(`${UPLOAD}?case=${this.props.sessionId}`);
    if (response) {
      this.setState({
        ...this.state,
        customerJoind: true,
        docData: response.data.data.filter(
          (item) => item.case === this.props.sessionId
        ),
      });
    }
  }

  style3 = {
    marginLeft: "20px",
  };


  render() {
    const { docData, customerJoind } = this.state;
    return (
      <div className="bg-light h-90 p-2 rounded  brdr-d4 left-gray-box">
        <div className="bg-primary p-2 rounded">
          <div className="row">
            <div className="col d-flex align-items-center ml-2">
              <img
                src={userImage}
                alt=""
                className="float-left mr-4 ml-2"
                style={this.style3}
              />
              <div className="text-white"> {this.props.caseName} </div>
            </div>
          </div>
        </div>
        <div className="inner clientTxt">
          {customerJoind ? (
            <VerificationCard
              docData={docData}
              processDoc={this.props.processDoc}
              userLocation={this.props.userLocation}
              processId={this.props.processId}
              caseId={this.props.sessionId}
              userId={this.props.userId}
              connectionId={this.props.connectionId}
              takeScreenShot={this.props.takeScreenShot}
              caseDetails={this.props.caseDetails}
            />
          ) : (
            <div className="documents-verified">
              <h2 className="documents-verified-heading">
                Waiting for Customer to join.
              </h2>
            </div>
          )}
        </div>
      </div>
    );
  }
}
