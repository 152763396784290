import React from "react";
import Moment from "react-moment";
import RecordingIcon from "../../../../../assets/icons/recording-icon.png";
import DocumentIcon from "../../../../../assets/icons/document-icon.png";
import SingleUserModal from "../singleUserModal/SingleUserModal";
import GroupModal from "../groupModal/GroupModal";
import { GET_CASES, GET_AGENT_LIST_WRT_TYPE } from "../../../../../apiurl";
import api from "../../../../../apiBaseConfig";
import AgentActions from "../agentActions/AgentActions";
import Loader from "../../../../../components/loader/Loader";
import { Toast } from "../../../../../utils/toastutils";
import { connect } from "react-redux"

class AssignedCases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      schedularClickedIndex: null,
      transferClickedIndex: null,
      showModal: false,
      activeIndex: "",
      agentList: [],
      assignedCases: [],
      isLoading: true,
      loaderTitle: "",
    };
  }

  async componentDidMount() {
    let agentList = await this.getAgentList();
    this.setState({ agentList: agentList });
    const { assignedCases } = this.props;
    // let schedularClickedIndex = null;
    // let transferArray = new Array(assignedCases.length).fill(false);
    this.setState({ assignedCases: assignedCases, isLoading: false });
  }

  handleSchedularClick = (index) => {
    let schedularClickedIndex = this.state.schedularClickedIndex;
    if (index === schedularClickedIndex) {
      schedularClickedIndex = null;
    } else if (this.props.assignedCases[index].connections.find(participant => participant.participant_tag === "applicant") || this.props.activeUpperTab === 0) {
      schedularClickedIndex = index;
    } else {
      Toast.warning({
        description: 'Please Select Applicant',
        title: "Warning",
      })
    }
    this.setState({
      schedularClickedIndex: schedularClickedIndex,
      transferClickedIndex: null,
    });
  };

  handleTransferClick = (index) => {
    let transferClickedIndex = this.state.transferClickedIndex;
    if (index === transferClickedIndex) {
      transferClickedIndex = null;
    } else {
      transferClickedIndex = index;
    }
    this.setState({
      transferClickedIndex: transferClickedIndex,
      schedularClickedIndex: null,
    });
  };

  handleModal = (showModal, activeIndex) => {
    // this.props.modalOpen(showModal);
    this.setState({ showModal, activeIndex });
  };

  transferCases = async (agentId, caseId) => {
    this.setState({ isLoading: true, loaderTitle: "Transferring case" });
    try {
      await api.patch(`${GET_CASES}${caseId}/`, {
        agent: agentId,
      });
      this.setState({ isLoading: false, loaderTitle: "" });
    } catch (e) {
      this.setState({ isLoading: false, loaderTitle: "" });
    }
  };

  getAgentList = async () => {
    const agentList = await api.get(`${GET_AGENT_LIST_WRT_TYPE}`);
    return agentList.data;
  };

  closeSchedulerModal = () => {
    // const {scheduledCases} = this.props;
    let schedularClickedIndex = null;
    this.setState({ schedularClickedIndex: schedularClickedIndex });
  };

  render() {
    const { activeUpperTab, userInfo, assignedCases, searchKeyObject, categoryColor, clientInfo } = this.props;
    const { has_partners } = clientInfo

    const {
      schedularClickedIndex,
      transferClickedIndex,
      showModal,
      activeIndex,
      agentList,
      isLoading,
      loaderTitle,
    } = this.state;
    return (
      <React.Fragment>
        {isLoading && <Loader title={loaderTitle} />}
        <div
          className="tab-pane caseContainer show active"
          id="assigned"
          role="tabpanel"
          aria-labelledby="assigned-tab"
        >
          <div className="row mt-2">
            <div className="col-md-10">
              <table className="table text-center sml-blue-header mb-0">
                <thead>
                  <tr>

                    <th width="5%">Photo</th>
                    <th width="10%">Meeting ID</th>
                    <th width="13%">
                      {activeUpperTab === 1 ? "Client name" : "Customer Name"}
                    </th>
                    {has_partners ? <th width="10%">Partner</th> : null}
                    <th width="15%">Assigned Date</th>
                    <th width="11%">Process</th>

                    <th width="12%">Category</th>
                    <th width="17%">Comments</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div class="col-md-2">
              <table class="table sml-blue-header mb-0 text-left">
                <thead>
                  <tr>
                    <th width="20%">Actions</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>

          {Object.keys(searchKeyObject).length !== 0 && assignedCases &&
            assignedCases.length === 0 &&
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
              <h4>No case found</h4>
            </div>
          }
          {assignedCases && assignedCases.map((selectedCase, index) => (
            <div className="row" key={index}>
              <div className="col-md-10">
                <div className="tbl-row">
                  <table className="table text-center mb-0 sml-blue-header">
                    <tbody className="pointer">
                      <tr
                        data-toggle="modal"
                        data-target="#cstmrDtls"
                        onClick={() => this.handleModal(true, index)}
                      >

                        <td width="5%">
                          <span className="user-blue-circle rounded-circle">
                            {selectedCase.name
                              ? selectedCase.name.charAt(0)
                              : ""}
                          </span>
                        </td>
                        <td width="10%">{selectedCase.id}</td>
                        <td width="13%">{selectedCase.name}</td>
                        {has_partners ? <td width="10%">{selectedCase?.partner_name}</td> : null}

                        <td width="15%">
                          <span className="txt-light-blue font-italic">
                            <Moment format="DD MMM YYYY LT">
                              {selectedCase.assigned_at}
                            </Moment>
                          </span>
                        </td>
                        <td width="11%">
                          {selectedCase.process_details
                            ? selectedCase.process_details.name
                            : ""}
                        </td>

                        <td width="12%">
                          <span
                            className={"status"}
                            style={{ backgroundColor: categoryColor[selectedCase.case_tag], color: categoryColor[selectedCase.case_tag] ? categoryColor[selectedCase.case_tag].slice(0, -2) : "" }}
                          >
                            {selectedCase.case_tag.includes('_') ? "" : selectedCase.case_tag}
                          </span>
                        </td>
                        <td width="17%" className="text-break">
                          <span className="font-italic">
                            {selectedCase.case_history.filter(
                              (history) => history.type === "comment"
                            ).length !== 0
                              ? selectedCase.case_history.filter(
                                (history) => history.type === "comment"
                              )[
                                selectedCase.case_history.filter(
                                  (history) => history.type === "comment"
                                ).length - 1
                              ].re_text
                              : ""}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-2">
                {/* <div class="pt-3"> */}
                <table class="table mb-0 sml-blue-header">
                  <tbody>
                    <tr>
                      <AgentActions
                        index={index}
                        handleSchedularClick={this.handleSchedularClick}
                        schedularClickedIndex={schedularClickedIndex}
                        caseName={selectedCase.name}
                        activeUpperTab={activeUpperTab}
                        caseId={selectedCase.id}
                        caseActive={selectedCase.is_active}
                        onScheduleComplete={this.props.onScheduleComplete}
                        closeSchedulerModal={this.closeSchedulerModal}
                        transferClickedIndex={transferClickedIndex}
                        handleTransferClick={this.handleTransferClick}
                        agentList={agentList}
                        handleModal={this.handleModal}
                        transferCases={this.transferCases}
                        selectedCase={selectedCase}
                        setSchedulerLoader={this.props.setSchedulerLoader}
                      />
                    </tr>
                  </tbody>
                </table>
                {/* </div> */}
              </div>
            </div>
          ))}
        </div>
        {showModal && activeUpperTab === 0 && (
          <SingleUserModal
            handleModal={this.handleModal}
            assignedCases={assignedCases[activeIndex]}
          />
        )}

        {showModal && activeUpperTab !== 0 && (
          <GroupModal
            handleModal={this.handleModal}
            updateCaseData={this.props.updateCaseData}
            assignedCases={assignedCases[activeIndex]}
          />
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  clientInfo: state.login.clientInfo
});

export default connect(mapStateToProps)(AssignedCases);
