export const ActionTypes = {
  UPLOAD: "UPLOAD",
  UPLOAD_AADHAR: "UPLOAD_AADHAR",
  UPLOAD_PAN: "UPLOAD_PAN",
  DELETE_AADHAR: "DELETE_AADHAR",
  DELETE_PAN: "DELETE_PAN",
  UPLOAD_FAIL: "UPLOAD_FAIL",
  VARIFY_PAN: "VARIFY_PAN",
  VARIFY: "VARIFY",
  STORE_USER_CONNECTION_INFO: "STORE_USER_CONNECTION_INFO",
  UPDATE_USER_LOCATION: "UPDATE_USER_LOCATION",
  PAN_INVALID: "PAN_INVALID",
  RESET_USER_DOCS: "RESET_USER_DOCS",
  PRESET_UPLOAD: "PRESET_UPLOAD",
  AADHAR_INVALID: "AADHAR_INVALID",
  VARIFY_AADHAR: "VARIFY_AADHAR",
  UPLOAD_DL: "UPLOAD_DL",
  VARIFY_DL: "VARIFY_DL",
  DELETE_DL: "DELETE_DL",
  DL_INVALID: "DL_INVALID",
  IS_LIVE: "IS_LIVE"
};
