import axios from "axios";
import _get from "lodash/get";
import { logout } from "./login/state/login.actions";
import { store } from "./index";
import { Toast } from "./utils/toastutils";


const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    const state = store.getState();
    const accessToken = _get(state, "login.userInfo.accessToken");
    const anonymousUserToken = _get(
      state,
      "userDocs.anonymousUserConnectionInfo.connection",
      null
    );
    const caseType = _get(
      state,
      "userDocs.anonymousUserConnectionInfo.case_type",
      null
    )
    if(config.headers.AuthorizationY) return config
    if (anonymousUserToken) {
      if(caseType && caseType === 'pivc'){
        config.headers.AuthorizationY = `Token ${anonymousUserToken}`;
      }
      else
       config.headers.AuthorizationX = `Token ${anonymousUserToken}`;
    } else if(accessToken) {
      config.headers.Authorization = `Token ${accessToken}`;
    }
    // console.log("api request config", config);
    return config;
  },
  function (error) {
    // console.log("error in api request", error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // console.log("request successful , response ", response);
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && error.response.status === 401) {
      Toast.error({
        description: "Account logged- in from another device",
        title: "Access Denied",
      });
      store.dispatch(logout());

    }
    return Promise.reject(error);
  }
);

export default instance;


