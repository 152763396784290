import React, { useState, useRef, forwardRef, useEffect, useImperativeHandle } from 'react';
import cogoToast from 'cogo-toast';

const VideoRecorder = forwardRef(({ className, onRecordStart, onRecordStop, onCameraAccess }, ref) => {
    const [isRecording, setIsRecording] = useState(false);
    const [stream, setStream] = useState(null)
    const [audioRecorder, setAudioRecorder] = useState(null)
    const [CameraAndMicAccess, setCameraAndMicAccess] = useState(false)
    const videoRef = useRef(null);
    const [videoBlob, setVidoeBlob] = useState(null)
    const [audioBlob, setAudioBlob] = useState(null)

    useEffect(() => {
        startCamera()
        return () => {
            stopRecording()
            stopCamera()
            if (videoRef.current) {
                videoRef.current.srcObject = null;
            }
        }
    }, [videoRef])
    useEffect(() => {
        if (audioBlob && videoBlob) {
            onRecordStop({
                video_blob: videoBlob,
                audio_blob: audioBlob
            })
        }
    }, [videoBlob, audioBlob])
    const stopCamera = () => {
        if (stream) {
            stream.getTracks().forEach((track) => {
                if (track.readyState === "live") {
                    track.stop();
                }
            });
        }
    }
    const startCamera = async () => {
        try {
            const Video_Stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            setCameraAndMicAccess(true)
            onCameraAccess(true)
            setStream(Video_Stream)
            if (videoRef.current) {
                videoRef.current.src = null
                videoRef.current.srcObject = Video_Stream;
                videoRef.current.controls = false
                videoRef.current.muted = true
            }
        }
        catch (error) {
            console.error('Error accessing the camera and/or microphone:', error);
            cogoToast.error('Please provide access to camera and microphone')
        }
    }

    const startRecording = async () => {
        setVidoeBlob(null)
        try {
            startAudioRecording()
            const chunks = [];
            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    chunks.push(event.data);
                }
            };
            mediaRecorder.onstop = () => {

                const recordedBlob = new Blob(chunks, { type: 'video/mp4' });
                const reader = new FileReader();

                reader.onloadend = function () {
                    const blobData = reader.result.split(',')[1];
                    setVidoeBlob(blobData)

                };

                reader.readAsDataURL(recordedBlob);
                const recordedUrl = URL.createObjectURL(recordedBlob);
                videoRef.current.srcObject = null
                videoRef.current.src = recordedUrl
                videoRef.current.controls = true
                videoRef.current.muted = false

            };

            mediaRecorder.start();
            setIsRecording(true);
            onRecordStart()
        } catch (error) {
            console.error('Error accessing the camera and/or microphone:', error);
        }
    };

    const stopRecording = () => {
        if (stream) {
            stream.getTracks().forEach((track) => {
                track.stop();
            });
        }
        stopAudioRecording()
        setIsRecording(false);
    };

    function handleCameraBtn() {
        if (!isRecording) {
            startRecording()
        }
        else
            stopRecording()
    }
    const startAudioRecording = () => {
        setAudioBlob(null)
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                const recorder = new MediaRecorder(stream);
                setAudioRecorder(recorder);

                const chunks = [];
                recorder.ondataavailable = (event) => {
                    if (event.data.size > 0) {
                        chunks.push(event.data);
                    }
                };

                recorder.onstop = () => {
                    const audioBlob = new Blob(chunks, { type: 'audio/mp3' });
                    const reader = new FileReader();
                    reader.onloadend = function () {
                        const blobData = reader.result.split(',')[1];
                        setAudioBlob(blobData)

                    };
                    reader.readAsDataURL(audioBlob);
                };

                recorder.start();
            })
            .catch((error) => {
                console.error('Error accessing the microphone:', error);
            });
    };
    const stopAudioRecording = () => {
        if (audioRecorder && audioRecorder.state !== 'inactive') {
            audioRecorder.stop();
        }
    };

    useImperativeHandle(ref, () => ({
        handleCameraBtn() {
            handleCameraBtn()
        },
        startCamera() {
            startCamera()
        }

    }));
    if (!CameraAndMicAccess) return null
    return (
        <>
            <div className='row d-flex justify-content-center'>
                <div>
                    <video id="video" className={className} ref={videoRef} autoPlay muted></video>
                </div>
            </div>

        </>

    );
})

export default VideoRecorder



