import React from "react";
import GreenTick from "../../assets/icons/green-tick.png";

class ScreenshotImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enlargeImage: false,
    };
  }
  render() {
    const { enlargeImage } = this.state;
    const { doc, connectionName, index } = this.props;

    return (
      <>
        {doc.document.key === "screenshot" && (
          <tr>
            <td width="30.5%" style={{ paddingRight: "0px" }}>
              {connectionName}
              <br />
              {doc.document.name}
            </td>
            <td width="38.5%" style={{ paddingLeft: "0px" }}>
              <img
                src={doc.front_upload}
                alt=""
                width="100"
                style={{ marginLeft: "15px", cursor: "pointer" }}
                crossOrigin="anonymous"
                onClick={() =>
                  this.setState({
                    enlargeImage: !enlargeImage,
                  })
                }
                className="img-fluid rounded"
              />
              {enlargeImage && (
                <img
                  src={doc.front_upload}
                  className="rounded border doc-enlarge"
                  alt=""
                  crossOrigin="anonymous"
                  onClick={() =>
                    this.setState({
                      enlargeImage: !enlargeImage,
                    })
                  }
                  style={{ cursor: "pointer" }}
                />
              )}
            </td>
            <td align="right" width="31%" style={{ padding: "0px" }}>
              <table width="100%">
                <tr>
                  <td
                    width="30"
                    style={{ padding: "0px", paddingRight: "0px" }}
                  >
                    <img src={GreenTick} width="20" alt="" />
                  </td>
                  <td
                    style={{
                      padding: "0px",
                      paddingLeft: "0px",
                      paddingRight: "10px",
                    }}
                  >
                    <span style={{ color: "#24a749", fontStyle: "italic" }}>
                      Screenshot captured successfully
                    </span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        )}
      </>
    );
  }
}

export default ScreenshotImage;
