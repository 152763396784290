import React from "react";
import {
  Card,
  CardContent,
  Button,
  InputBase,
  Modal,
  Paper,
} from "@material-ui/core";
import keyIcon from "../../assets/icons/key.svg";
import OtpInput from "react-otp-input";
import { withStyles } from "@material-ui/core/styles";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import LoginHeader from "../loginHeader/LoginHeader";
import "./ResetPassword.scss";
import API from "../../apiBaseConfigNew";
import { FORGOT_PASSWORD } from "../../apiurl";
import { ErrorExtract } from "../../constants";
const styles = (theme) => ({
  root: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
});

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      newPassword: "",
      confirmedPassword: "",
      passwordMismatch: false,
      otp: "",
      loading: false,
      userEmail: "",
    };
  }

  passwordReset = async () => {
    const {
      newPassword,
      otp,
      confirmedPassword,
    } = this.state;
    let passwordMismatch = newPassword !== confirmedPassword;
    this.setState({ passwordMismatch });
    if (passwordMismatch) {
      return;
    }
    let modalOpen =
      newPassword.length !== 0 &&
      otp.length !== 0 &&
      confirmedPassword === newPassword;
    if (modalOpen) {
      this.setState({ loading: true })
      API.patch(FORGOT_PASSWORD, {
        email: this.props.location.state,
        otp: otp,
        password: newPassword,
        confirm_password: newPassword,
      }).then(res => {
        this.setState({ modalOpen, loading: false });
      })
        .catch(err => {
          ErrorExtract(err.response.data)
          this.setState({ loading: false });

        })

    }
  }


  redirectToLoginScreen = () => {
    this.props.history.push("/login");
  };

  setNewPassword = (newPassword) => {
    this.setState({ newPassword });
  };

  confirmNewPassword = (confirmedPassword) => {
    this.setState({ confirmedPassword });
  };

  setOTP = (otp) => {
    this.setState({ otp });
  };

  setEmail = (userEmail) => {
    this.setState({ userEmail });
  };

  render() {
    const { classes } = this.props;
    const { modalOpen, passwordMismatch, otp } = this.state;
    const body = (
      <div id="modalContainer" className={classes.root}>
        <div className="modalText">
          <ThumbUpIcon className="modalColor" id="successIcon"></ThumbUpIcon>
        </div>
        <p className="modalText">New password updated successfully</p>
        <div className="modalText">
          <Button
            variant="contained"
            color="primary"
            className="modalBackground"
            onClick={this.redirectToLoginScreen}
          >
            Go back to the login screen
          </Button>
        </div>
      </div>
    );
    return (
      <React.Fragment>
        {/* <LoginHeader /> */}
        <section className="main-content pr-0">
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div className="col-md-12">
                <div className="ml-30 mr-30 mt-4">
                  <div className="container-fluid" style={{ padding: 0 }}>
                    <div className="d-flex justify-content-center mb-3 row">
                      <div className="col-md-4">
                        <div className="change-password-form">
                          <div className="form-group">
                            <label>ENTER OTP </label>
                            <div className="input-group">
                              <OtpInput
                                onChange={(e) => this.setOTP(e)}
                                value={otp}
                                numInputs={6}
                                isInputNum={true}
                                separator={<span>&nbsp;</span>}
                                inputStyle={{
                                  width: "5rem",
                                  border: "1px solid #C5C5C5",
                                  height: "50px",
                                  width: "35px",
                                  marginLeft: "22px",
                                  color: "#5eb9ff",
                                  fontSize: "20px",
                                  fontWeight: "bolder",
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label>ENTER NEW PASSWORD </label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text bg-white">
                                  <img src={keyIcon} alt="Key" />
                                </span>
                              </div>
                              <input
                                onChange={(e) =>
                                  this.setNewPassword(e.target.value)
                                }
                                required={true}
                                type="password"
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label>cONFIRM PASSWORD </label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text bg-white">
                                  <img src={keyIcon} alt="Key" />
                                </span>
                              </div>
                              <input
                                onChange={(e) =>
                                  this.confirmNewPassword(e.target.value)
                                }
                                required={true}
                                type="password"
                                className="form-control"
                              />
                            </div>
                            {passwordMismatch && (
                              <small
                                className="txt-gray roboto-italic"
                                style={{ color: "red" }}
                              >
                                New password and Confirm password do not match
                              </small>
                            )}
                          </div>

                          <div className="justify-content-between d-flex">
                            <button
                              disabled={this.state.loading}
                              onClick={this.redirectToLoginScreen}
                              className="btn btn-sm btn-dark w-25"
                            >
                              Back
                            </button>

                            <button
                              onClick={this.passwordReset}
                              className="btn btn-block btn-sm primary-btn w-50"
                            >
                              {this.state.loading && (
                                <span className="spinner-border spinner-border-sm mr-1"></span>
                              )}
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <Card className="cardMain" variant="outlined">
                    <CardContent>
                        <h2 style={{ textAlign: 'start', fontWeight: 'normal' }}>Reset Password</h2>
                        <Paper variant="outlined" className="formTextPaper">
                            <InputBase
                                className="textField"
                                placeholder="OTP" type="password"
                                onChange={(e) => this.setOTP(e.target.value)}
                            ></InputBase>
                        </Paper>
                        <Paper variant="outlined" className="formTextPaper">
                            <InputBase
                                className="textField"
                                placeholder="Email address" type="text"
                                onChange={(e) => this.setEmail(e.target.value)}
                            ></InputBase>
                        </Paper>
                        <Paper variant="outlined" className="formTextPaper">
                            <InputBase
                                className="textField"
                                placeholder="New Password" type="password"
                                onChange={(e) => this.setNewPassword(e.target.value)}
                            ></InputBase>
                        </Paper>
                        <Paper variant="outlined" className="formTextPaper">
                            <InputBase
                                className="textField"
                                placeholder="Confirm Password" type="password"
                                onChange={(e) => this.confirmNewPassword(e.target.value)}
                            ></InputBase>
                        </Paper>

                        {passwordMismatch && <p className="mismatchError">The given password doesn't match</p>}
                        <Button variant="contained" color="primary" className="doneButton"
                            onClick={this.passwordReset}
                            disabled={passwordMismatch}>Done</Button>


                    </CardContent>
                </Card> */}
        <Modal open={modalOpen}>{body}</Modal>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ResetPassword);
