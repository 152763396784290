import { ActionTypes } from "./login.types";

export const login = payload => ({
  type: ActionTypes.LOGIN,
  payload: payload
});

export const loginSuccess = payload => ({
  type: ActionTypes.LOGIN_SUCCESS,
  payload: payload
});

export const loginFail = () => ({
  type: ActionTypes.LOGIN_FAIL
});

export const logout = () => ({
  type: ActionTypes.LOGOUT
});
export const update = (payload) => ({
  type: ActionTypes.UPDATE,
  payload:payload
});

export const getClientConfig = (payload) => ({
  type: ActionTypes.GET_CLIENT_CONFIG,
  payload:payload
});
export const updateClientConfig = (payload) => ({
  type: ActionTypes.UPDATE_CLIENT_CONFIG,
  payload:payload
});
export const updateClientName = (payload) => ({
  type: ActionTypes.UPDATE_CLIENT_NAME,
  payload:payload
});

