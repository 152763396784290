import React from 'react'

export default function PartnerDrop(props) {
    const { selected, onSelect, error, list, label } = props
    if (list && list.length > 0) {
        return (
            <div className='form-group'>
                <label>{label}<span className='astric'>*</span></label>
                <select className='form-control' value={selected} onChange={onSelect}>
                    <option value='' selected disabled>Select {label}</option>
                    {list.map((item, index) => {
                        return <option key={index} value={item.id}>{item.name}</option>
                    })}
                </select>
                {error && error.length > 0 ? <p className='form-error'>{error}</p> : null}
            </div>
        )
    }
    else return null
}
