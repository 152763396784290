import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { varify } from "../../state/user.actions";
import UserHeader from "../../userHeader/userHeader";
import { useHistory } from "react-router";
import VerificationInput from "../../../components/verificationInput/VerificationInput";
import "./VerifyPan.scss";
import Loader from "../../../components/loader/Loader";
import { useSelector } from "react-redux";

function VerifyPan({ userInfo, varify }) {
  const history = useHistory();
  let clientConfig = useSelector(state => state.login.clientInfo)
  const userToken = useSelector(state => state.userDocs.anonymousUserConnectionInfo.connection);
  const case_type = clientConfig.case_module === 5 ? 'pivc' : ''
  const [valid, setValid] = useState(false);
  const [inValid, setInValid] = useState(false);
  const [loading, setloading] = useState(false);
  const [doc, setDoc] = useState("");
  const isReadOnly = !clientConfig.is_ocr_data_editable
  const [state, setState] = useState({
    Name: "",
    "Father's Name": "",
    PAN: "",
    DOB: "",
  });

  useEffect(() => {
    if (state.Name && state["Father's Name"] && state.PAN && state.DOB) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [state]);

  const handleNext = () => {
    console.log({
      ...userInfo[doc].data,
      name: state["Name"],
      father_name: state["Father's Name"],
      doc_id: state["PAN"],
      dob: state["DOB"],
    });
    setInValid(false);
    setloading(true);
    varify({
      id: userInfo[doc].id,
      type: doc,
      case_type: case_type,
      upload_type: userInfo[doc].document_upload_type,
      doc_id: state["PAN"],
      data: {
        ...userInfo[doc].data,
        name: state["Name"],
        father_name: state["Father's Name"],
        doc_id: state["PAN"],
        dob: state["DOB"],
      },
    });
  };

  useEffect(() => {
    // debugger;
    let docUploaded =
      history.location.pathname === "/user/verify/pan"
        ? "panUploaded"
        : "aadharUploaded";
    let docType =
      history.location.pathname === "/user/verify/pan" ? "pan" : "aadhar";
    setDoc(`${docType}Data`);
    if (
      userInfo[`${docType}Data`].document_upload_type === "digilocker_upload"
    ) {
      setValid(true);
    }
    console.log("user1");
    if (userInfo[docUploaded]) {
      if (case_type === 'pivc')
        history.push('/user/pivc/' + userToken);
      else
        history.push("/user");
    }
    if (!(userInfo && userInfo[`${docType}Data`].image)) {
      history.push("/user/docsupload");
    }
    if (userInfo[`${docType}Invalid`]) {
      setInValid(true);
      setloading(false);
    } else {
      setInValid(false);
    }
  }, [userInfo]);

  return (
    <React.Fragment>
      {loading && <Loader title={"Validating your details, please wait"} />}
      {userInfo && doc && (
        <div className="wrapper light-blue-customer-template">
          <UserHeader />
          <section className="main-body m-auto customer-flow-section">
            <div className="container-fluid mt-4">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="txt-light-blue ltr-spc-1">
                    {doc === "panData" ? "PAN" : "AADHAAR"} Upload
                  </h4>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="border border-1 dashed-brdr  rounded p-4 m mb-2">
                    <img
                      src={userInfo[doc].image}
                      alt=""
                      className="img-fluid rounded"
                    />
                    {userInfo[doc].document_upload_type === "normal_upload" && (
                      <React.Fragment>
                        <div className="txt-81 fnt-sml mt-2 ltr-spc-1 mt-3 mb-3">
                          Information Extracted from Image
                        </div>
                        <div className="row mb-2">
                          <div className="col-md-12">
                            <VerificationInput
                              preInput="Name"
                              onChange={({ type, data }) => {
                                setState({ ...state, [type]: data });
                              }}
                              inputValueDefault={
                                userInfo[doc].data
                                  ? userInfo[doc].data.name
                                  : ""
                              }
                              isTick={state.Name}
                              width={"100%"}
                              verificationKey={0}
                              readOnly={isReadOnly}
                              customFlag={true}
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-md-12">
                            <VerificationInput
                              preInput="Father's Name"
                              onChange={({ type, data }) => {
                                setState({ ...state, [type]: data });
                              }}
                              isTick={state["Father's Name"]}
                              inputValueDefault={
                                userInfo[doc].data
                                  ? userInfo[doc].data.father_name
                                  : ""
                              }
                              width={"100%"}
                              verificationKey={1}
                              readOnly={isReadOnly}
                              customFlag={true}
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-md-12">
                            {/* <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6"> */}
                            <VerificationInput
                              preInput="PAN"
                              onChange={({ type, data }) => {
                                setState({ ...state, [type]: data });
                              }}
                              isTick={state.PAN}
                              inputValueDefault={
                                userInfo[doc].data
                                  ? userInfo[doc].data.doc_id
                                  : ""
                              }
                              width={"100%"}
                              verificationKey={2}
                              readOnly={isReadOnly}
                              customFlag={true}
                            />
                          </div>
                          {inValid && false && (
                            <div className="row mb-2 w-100 errorTxt">
                              <span className=" fnt-sml text-center col-md-12">
                                <i>&nbsp;PAN detail not valid</i>
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="row mb-2">
                          <div className="col-md-12">
                            {/* <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6"> */}
                            <VerificationInput
                              preInput="DOB"
                              onChange={({ type, data }) => {
                                setState({ ...state, [type]: data });
                              }}
                              isTick={state.DOB}
                              inputValueDefault={
                                userInfo[doc].data ? userInfo[doc].data.dob : ""
                              }
                              width={"100%"}
                              verificationKey={3}
                              readOnly={isReadOnly}
                              customFlag={true}
                            />
                          </div>
                        </div>
                        {valid && !inValid && (
                          <div className="success-msg d-flex align-items-center mt-2">
                            <span className="tick-icon-green-sml mr-1"></span>
                            <span className="txt-green fnt-sml">
                              <i>
                                PAN information has been successfully uploaded
                              </i>
                            </span>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-3 mb-3">
                <div className="col-md-12  d-flex justify-content-between">
                  <button
                    onClick={(e) => history.goBack()}
                    className="btn btn-custom btn-dark btn-light-black w-100-p"
                  >
                    ReTry
                  </button>
                  <button
                    disabled={!valid}
                    onClick={handleNext}
                    className="btn btn-custom btn-light-blue w-100-p"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.userDocs.userDocInfo,
});

const mapDispatchToProps = (dispatch) => ({
  varify: (payload) => dispatch(varify(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPan);
