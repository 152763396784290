import React, { Component } from "react";
import "./UploadCases.scss";
import ModuleUpload from "../moduleUpload/ModuleUpload";
import UploadExcel from "../uploadExcel/UploadExcel";

export default class UploadCases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadExcel: false,
    };
  }
  setUploadExcel = (uploadExcel) => {
    this.setState({ uploadExcel });
  };

  render() {
    const { uploadExcel } = this.state;
    return (
      <div className="container-fluid p-0">
        {uploadExcel ? (
          <UploadExcel setUploadExcel={this.setUploadExcel} />
        ) : (
          <ModuleUpload setUploadExcel={this.setUploadExcel} />
        )}
      </div>
    );
  }
}
