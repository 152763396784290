import React from "react";
import ActiveTabs from "./ActiveTabs";
import ActiveCases from "./ActiveCases";

class ActiveCasesContainer extends React.Component {
  changeActiveLowerTab = (activeLowerTab) => {
    this.props.updateActiveCasesData(activeLowerTab);
  };
  render() {
    const {
      activeCasesData,
      activeCasesLowerTab,
      agentAuditorList,
      agentList,
      consolidatedAgentList
    } = this.props;
    return (
      <div
        className="tab-pane show active"
        id="active"
        role="tabpanel"
        aria-labelledby="active-tab"
      >
        <div
          className="row no-gutters bg-light pt-2"
          style={{ backgroundColor: "#FBFBFB" }}
        >
          <ActiveTabs
            activeLowerTab={activeCasesLowerTab}
            changeActiveLowerTab={this.changeActiveLowerTab}
          />
        </div>

        <div className="row no-gutters">
          <ActiveCases
            activeLowerTab={activeCasesLowerTab}
            activeCasesData={activeCasesData}
            handleViewDocuments={this.props.handleViewDocuments}
            getSearchObject={this.props.getSearchObject}
            searchKeyObject={this.props.searchKeyObject}
            handleSort={this.props.handleSort}
            agentAuditorList={agentAuditorList}
            agentList={agentList}
            transferCases={this.props.transferCases}
            handleDeleteCase={this.props.handleDeleteCase}
            consolidatedAgentList={consolidatedAgentList}
          />
        </div>
      </div>
    );
  }
}

export default ActiveCasesContainer;
