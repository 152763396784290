import React from "react";
import Moment from "react-moment";
import { connect } from 'react-redux'

class RejectedCases extends React.Component {
  render() {
    const { rejectedCasesData, clientInfo } = this.props;
    const { has_partners } = clientInfo
    return (
      <div
        className="tab-pane show active"
        id="rejected"
        role="tabpanel"
        aria-labelledby="rejected-tab"
      >
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <table className="table sml-blue-bg-header bg-sky-blue mb-1">
                  <thead className="all-head-center">
                    <tr>
                      <th width="16.6%">Case name</th>
                      <th width="16.6%">Case ID</th>
                      {has_partners ? <th width="10%">Partner</th> : null}
                      <th width="16.6%" className="text-center">
                        Completed Date
                      </th>
                      <th width="16.6%" className="text-center">
                        Completed by
                      </th>
                      <th width="16.6%" className="text-center">
                        Rejection date
                      </th>
                      <th width="16.6%" className="text-center">
                        Rejected by
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            {rejectedCasesData.map((rejectedCase) => {
              return (
                <div className="row">
                  <div className="col-md-12">
                    <div className="tbl-row">
                      <table className="table mb-0 sml-blue-bg-header table-striped odd-even-tbl roboto-regular">
                        <tbody className="all-body-center pt-7-pb-7-td">
                          <tr>
                            <td width="16.6%">{rejectedCase.name}</td>
                            <td width="16.6%">{rejectedCase.id}</td>
                            {has_partners ? <td width="10%">{rejectedCase.partner_name}</td> : null}

                            <td width="16.6%" align="center">
                              <Moment format="DD MMM YYYY LT">
                                {rejectedCase.completed_at}
                              </Moment>
                            </td>
                            <td width="16.6%" align="center">
                              {rejectedCase.agent_name}
                            </td>
                            <td width="16.6%" align="center">
                              {
                                rejectedCase.audited_at ?
                                  <Moment format="DD MMM YYYY LT">
                                    {rejectedCase.audited_at}
                                  </Moment> :
                                  ""
                              }
                            </td>
                            <td width="16.6%" align="center">
                              {rejectedCase.audited_by_name
                                ? rejectedCase.audited_by_name
                                : ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  clientInfo: state.login.clientInfo
});
export default connect(mapStateToProps)(RejectedCases);
