import React, { useEffect, useState } from "react";
import api from "../../apiBaseConfig";
import { UPLOAD } from "../../apiurl";
import _get from "lodash/get";
import CircularProgress from "@material-ui/core/CircularProgress";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Avatar } from "@material-ui/core";

function MultiScreenShot(props) {
  const [enlarge, setEnlarge] = useState(null);

  useEffect(async () => {
    const res = await api.get(`${UPLOAD}?case=${props.caseId}`);
    let subscriber = props.subscriber;
    for (var i in res.data.data) {
      for (var j in subscriber) {
        for (var k in subscriber[j].screenshot) {
          for(var l in subscriber[j].screenshot[k]){
            if (
              subscriber[j].screenshot[k][l].userId ==
                res.data.data[i].connection &&
              subscriber[j].screenshot[k][l].id ==
                res.data.data[i].document_details.id
            ) {
              subscriber[j].screenshot[k][l].image =
                res.data.data[i].front_upload;
            }
          }
        }
      }
    }
    props.handleScreenshot(subscriber);
  }, []);

  const uploadScreenShot = async (docId, screenShot, connection) => {
    if (screenShot && props.caseId) {
      let response = await api.put(UPLOAD, {
        connection: connection,
        case: props.caseId,
        front_upload: screenShot,
        document: docId, //payload.donullLiteralTypeAnnotationcument,
        corrected_ocr: {},
        extracted_ocr: {},
        document_upload_event: "on_call",
      });
    }
  };

  const handleClick = (userIndex, key, index) => {
    if (index === undefined) {
      index = props.subscriber[userIndex].screenshot[key].findIndex(doc => !doc.image);
    }
    if (index !== -1) {
      takeScreenShot(userIndex, key, index);
    }
  };

  const takeScreenShot = (userIndex, key, docIndex) => {
    let subscriber = props.subscriber;
    let capturedShot = props.takeScreenShot(subscriber[userIndex]);
    subscriber[userIndex].screenshot[key][docIndex].image = capturedShot;
    uploadScreenShot(
      subscriber[userIndex].screenshot[key][docIndex].id,
      capturedShot,
      subscriber[userIndex].userId
    );
    props.handleScreenshot(subscriber);
  };
  return (
    <div id="accordion">
      {props.subscriber.map((user, userIndex) => (
        <div class="card mb-1">
          <div class="card-header p-0 bg-white" id="headingOne">
            <h5 class="mb-0">
              <button
                class="btn btn-link text-dark py-1 pr-4 fnt-8rem btn-block text-left collapsed"
                data-toggle="collapse"
                data-target={`#collapseOne${userIndex}`}
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                {user.nickname}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.563"
                  height="4.837"
                  viewBox="0 0 9.563 4.837"
                >
                  <defs></defs>
                  <g transform="translate(0)">
                    <g transform="translate(0 0)">
                      <path
                        class="a"
                        d="M9.454,131.241a.374.374,0,0,1-.528,0l-3.88-3.872a.374.374,0,0,0-.529,0l-3.88,3.872a.374.374,0,1,1-.528-.529l3.88-3.871a1.122,1.122,0,0,1,1.585,0l3.88,3.872A.374.374,0,0,1,9.454,131.241Z"
                        transform="translate(0 -126.513)"
                      />
                    </g>
                  </g>
                </svg>
              </button>
            </h5>
          </div>

          <div
            id={`collapseOne${userIndex}`}
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div class="card-body py-2 px-3">
              <div class="fnt-sml-x customer-name-blue">{user.location}</div>
              {Object.keys(user.screenshot).map((key) => {
                return (
                  <div className="mt-3 border dashed-brdr rounded p-2 bg-white">
                    <div
                      id="collapseOne"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div class="card-body py-2 pl-3 pr-2">
                        <div class="d-flex align-items-center dashed-border-bottom pb-2 mb-3">
                          {user.screenshot[key][0].image ? (
                            <React.Fragment>
                              <img
                                src={user.screenshot[key][0].image}
                                className="rounded-circle border mr-1"
                                onMouseEnter={() =>
                                  setEnlarge(
                                    user.screenshot[key][0].index + "main"
                                  )
                                }
                                onMouseLeave={() => setEnlarge(null)}
                                width="30"
                                height="30"
                                alt={user.screenshot[key][0].name}
                              />
                              {enlarge ===
                                user.screenshot[key][0].index + "main" && (
                                <img
                                  src={user.screenshot[key][0].image}
                                  className="rounded-circle border enlarge"
                                  alt=""
                                />
                              )}
                            </React.Fragment>
                          ) : (
                            <Avatar>
                              <PhotoCameraIcon />
                            </Avatar>
                          )}
                          <div class="fnt-sml-x txt-gray ml-2 flex-grow-1 w-100">
                            {key}
                          </div>
                          <a
                            role="button"
                            onClick={() => handleClick(userIndex, key)}
                            class="fnt-sml-x ml-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="14"
                              viewBox="0 0 28.571 25"
                              fill="#007bff"
                            >
                              <path
                                d="M189.259,199.479a3.571,3.571,0,1,1-5.051,0,3.571,3.571,0,0,1,5.051,0"
                                transform="translate(-172.448 -188.612)"
                              ></path>
                              <path
                                d="M25,34.093H22.532l-1.787-3.571H7.824L6.039,34.1l-2.464,0A3.578,3.578,0,0,0,.01,37.669L0,51.95a3.576,3.576,0,0,0,3.571,3.572H25a3.575,3.575,0,0,0,3.571-3.571V37.665A3.575,3.575,0,0,0,25,34.093ZM14.285,51.058a7.143,7.143,0,1,1,7.143-7.143,7.151,7.151,0,0,1-7.143,7.143Z"
                                transform="translate(0 -30.522)"
                              ></path>
                            </svg>
                          </a>
                        </div>
                        <div class="justify-content-start row mb-2 m-0">
                          {user.screenshot[key].map(
                            (doc, itemIndex) =>
                              doc.image && (
                                <div
                                  class="col d-flex align-items-center mb-2"
                                  style={{ minWidth: "33%", maxWidth: "33%" }}
                                >
                                  <img
                                    src={doc.image}
                                    className="rounded-circle border mr-1"
                                    onMouseEnter={() => setEnlarge(doc.id)}
                                    onMouseLeave={() => setEnlarge(null)}
                                    width="20"
                                    height="20"
                                    alt={doc.name}
                                  />
                                  {enlarge === doc.id && (
                                    <img
                                      src={doc.image}
                                      className="rounded-circle border enlarge"
                                      alt=""
                                    />
                                  )}
                                  <div class="fnt-sml-x txt-gray ml-2 flex-grow-1 w-100">
                                    {doc.name}
                                    <RefreshIcon
                                      style={{ width: "15px" }}
                                      class="ml-1 pointer"
                                      onClick={() =>
                                        handleClick(userIndex, key, itemIndex)
                                      }
                                    />
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
export default MultiScreenShot;
