import React, { useEffect, useState } from "react";
import cogoToast from "cogo-toast";
import api from "../../../../../apiBaseConfig";

function UploadLiveCases(props) {
  const [data, setData] = useState({
    customer_name: "",
    // case_type: "",
    customer_email: "",
    customer_mobile_number: "",
    office_address: "",
  });

  const [caseList, setCaseList] = useState([]);

  // useEffect(() => {
  //   getCaseDropdownData();
  // }, []);

  async function getCaseDropdownData() {
    try {
      let response = await api.get(`/client/processes/?case_type=video_kyc`);
      response.data.push({ name: "pradeep", id: 2 });
      setCaseList(response.data);
      setData((old) => {
        return { ...old, case_type: response.data[0].id };
      });
    } catch (error) {
      console.log("error in fetching agent list", error);
    }
  }

  const handleChange = (e) => {
    let { name, value } = e.target;
    setData((old) => {
      return { ...old, [name]: value };
    });
  };

  const checkForAlphabetsOnly = (val) => {
    return new RegExp(/^[ A-Za-z]{2,60}[A-Za-z\s]{0,60}$/i).test(val);
  };

  const checkForAlphanumericAndSpecialCharacters = (val) => {
      return new RegExp(
        /^[ A-Za-z0-9_@./#&+-]{2,60}[ A-Za-z0-9_@./#&+-]{0,60}$/i
      ).test(val)
  };


  const checkForNumeric = (val) => {
    return new RegExp(/^[1-9]+[0-9]*$/i).test(val);
  };

  const checkForEmail = (val) => {
    return new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    ).test(val);
  };

  const submitModuleDetails = async () => {
    let flag = false;
    if (
      data.customer_name.trim().length === 0 ||
      !checkForAlphabetsOnly(data.customer_name)
    ) {
      cogoToast.error("Please enter a valid name");
      flag = true;
    }

    if (
      data.office_address.trim() === "" ||
      !checkForAlphanumericAndSpecialCharacters(data.office_address)
    ) {
      cogoToast.error("Please enter a valid address");
      flag = true;
    }

    if (data.customer_email === "" || !checkForEmail(data.customer_email)) {
      cogoToast.error("Please enter a valid email");
      flag = true;
    }

    if (
      data.customer_mobile_number === "" ||
      !checkForNumeric(data.customer_mobile_number)
    ) {
      cogoToast.error("Please enter a valid number");
      flag = true;
    }

    if (flag) return;

    try {
      let response = await api.post(`/case/live/create-case-connection/`, data);

      if (response.data.case_id) {
        cogoToast.success("Case uploaded successfully.");
        handleClear();
      }
    } catch (error) {
      console.log("error in posting live cases", error);
    }
  };

  const handleClear = () => {
    setData({
      customer_name: "",
      // case_type: "",
      customer_email: "",
      customer_mobile_number: "",
      office_address: "",
    });
  };

  return (
    <React.Fragment>
      <div>
        <form>
          <div className="row px-3 pt-3">
            <div className="col-md-4">
              <div className="form-group">
                <label>
                  <small>Customer Name</small>
                </label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Enter Customer Name"
                  maxLength={60}
                  value={data.customer_name}
                  name="customer_name"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>

            <div className="col-md-4" style={{ visibility: "hidden" }}>
              <div className="form-group"></div>
            </div>
          </div>

          <div className="row px-3 pt-3">
            <div className="col-md-4">
              <div className="form-group">
                <label>
                  <small>Office address</small>
                </label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Enter Your Office address"
                  maxLength={60}
                  value={data.office_address}
                  name="office_address"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>
                  <small>Connection Email</small>
                </label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Example@abc.com"
                  value={data.customer_email}
                  name="customer_email"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>
                  <small>Connection Mobile Number</small>
                </label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="000-0000-000"
                  maxLength={10}
                  // disabled={!showButtons}
                  value={data.customer_mobile_number}
                  name="customer_mobile_number"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      {
        <div className="col-md-12 text-center" style={{ marginBottom: "50px" }}>
          <button
            type="submit"
            className="btn btn-secondary my-4 btn-sm"
            onClick={() => handleClear()}
            style={{ marginRight: "10px" }}
          >
            Clear All
          </button>
          <button
            type="submit"
            className="btn btn-primary my-4 btn-sm"
            onClick={() => submitModuleDetails()}
          >
            Submit
          </button>
        </div>
      }
    </React.Fragment>
  );
}

export default UploadLiveCases;
