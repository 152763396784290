export const LOGIN = "/user/log-in/";
export const ROLE_ID = "/user/role-options/";
export const CLIENT = "/shared/clients/";
export const SET_CLIENT = "/user/set-client-role/";
export const LOGOUT = "/user/log-out/";
export const CREATE_SESSION_PLATFORM = "/case/session/";
export const CREATE_PLATFORM_TOKEN_FOR_USER = "/case/connection/";
export const COMPLETE_SESSION = "/case/session/";
export const INCOMPLETE_SESSION = "/case/session/incomplete/";
export const GET_CASES = "/case/cases/";
export const GET_CASE_CONNECTIONS = "case/connections/";
export const GET_CASE_CONNECTIONS_BLOCK = "case/blocking-connections/";
export const UPLOAD = "/case/documents-upload/";
export const GENERATE_UNIQUE_CODE = "/case/generate-unique-code";
export const UPDATE_LIVE_AGENT = "case/live/update-agent/";
export const GET_DOCUMENTS = "/case/documents-upload/";
export const OCR = "/case/ocr/";
export const UPDATEDOCUMENT = "/case/documents-upload/";
export const VERIFYPAN = "/case/validation/pan/";
export const VERIFYPAN2 = "/case/pan-verify/";
export const FACEMATCH = "/case/facematch/";
export const GET_VIDEO_URL = "/case/signed-video-url/";
export const SEND_EMAIL = "/case/send-email/";
export const GET_CAPTCHA = "/case/paperless/aadhar/captcha/";
export const SEND_OTP = "/case/paperless/aadhar/otp/";
export const SEND_AADHAAR_CODE = "/case/paperless/aadhar/details/";
export const CASE_HISTORY = "/case/case-history/";
export const GET_USER_DETAILS = "/user/details/";
export const CREATE_SESSION_DB = "/case/sessions/";
export const UPDATE_CASE_SESSION_DB = "/case/sessions/";
export const GET_QUESTIONNAIRE = "/case/questions/";
export const PUT_ANSWER = "/case/answers/";
export const GET_AGENT_LIST = "/user/agents/";
export const GET_AGENT_LIST_WRT_TYPE = "/user/users-list/";
export const GET_AUDITOR_CASES = "/case/agent-cases/";
export const UPLOAD_CASES = "/case/upload-excel/";
export const GET_AGENTS = "/user/agents/";
export const GET_AUDITORS = "/user/auditors/";
export const GET_AGENT_AUDITORS = "/user/agent-auditors-list/";
export const GET_NOTIFICATION = "/case/notifications/";
export const FORGOT_PASSWORD = "/user/forgot-password/";
export const CHANGE_PASSWORD = "/user/change-password/";
export const GENERATE_REPORT = "/case/report-url/";
export const NOTES = "/case/notes/";
export const GET_PROCESSES = "/client/processes/";
export const GET_DOCUMENT_CHOICE = "/client/document-name-choices/";
export const FIND_LIVE_CASE = "/case/live/find-case/";
export const RESCHEDULE_SAME_AGENT = "/case/live/reschedule-same-agent/";
export const AUTO_RESCHEDULE = "/case/live/auto-reschedule/";
export const RESCHEDULE_SAME_AGENT_NORMAL =
  "/case/reschedule-same-agent-normal/";
export const RESCHEDULE_NORMAL = "/case/customer-reschedule-normal/";
export const RESCHEDULE = "/case/live/customer-reschedule/";
export const CSV_DOWNLOAD = "/case/csv";
export const MULTI_QUES = "/case/questions-hero/";
export const SAVE_LOCATION = "/case/geotagging/";
export const CUSTOMER_CONSENT = "/case/customer-consent/";
export const VERIFY_VOTER = "/case/validation/voter/";
export const VERIFY_DL = "/case/validation/driving-license/";
export const ADD_PARTICIPANTS = "/case/participants/";
export const IP_SPOOFING = "/case/ip-spoofing-status/";
export const ADD_EXECUTIVE = "/user/register/";
export const FETCH_DOCS_VIA_DIGILOCKER = "/case/digilocker/fetch-documents/";
export const MIGRATE_DOCS_VIA_DIGILOCKER =
  "/case/digilocker/migrate-documents/";
export const DASBOARD_AGENT_LIST = "/user/agent-dashboard-list/";
export const DASBOARD_AUDITOR_LIST = "/user/auditor-dashboard-list/";
export const DASBOARD_AGENTAUDITOR_LIST = "/user/agentauditor-dashboard-list/";
export const ACTIVATE_USER = "/user/activate/";
export const DEACTIVATE_USER = "/user/deactivate/";
export const AGENT_AUDITOR = "/user/agent-auditor/";
export const SIGNED_VIDEO_URL = "/case/signed-video-url/";
export const ADMIN_SUMMARY = "/case/admin-summary/";
export const UPDATE_PROFILE = "/user/update/";
export const GET_PARTNERS = '/client/partners/'
