import React from 'react'
import { useSelector } from 'react-redux'

function PrivacyPolicy() {
    let {clientName} = useSelector(state => state.login)
    return (

        <div className="wrapper light-blue-customer-template privacy-policy-page page-bg-img">
            <header>
                <nav className="navbar navbar-expand-lg h-100 navbar-light bh-white">
                    <div className="float-left">
                        {/* <a href="#" className="text-white mr-2">
                            <i className="fas fa-chevron-left"></i>
                        </a> */}
                        <i className="fas fa-video text-white"></i>
                    </div>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active">
                                <a href="#" className="nav-link"></a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
            <section className="m-auto">
                <div className="container">
                    <div className="row mt-3 mb-3">
                        <div className="col-md-12 col-sm-12 col-12">
                            <div className="overlap-box">
                                <div className="inner-box">
                                    <div className="privacy_policy_cntr">
                                        <div className="heading-section mb-3 text-center">
                                            <p className="heading">Privacy Notice</p>
                                            <label className="version">(v2.0 Last Updated on : 30-03-2021)</label>
                                        </div>
                                        <div className="content-section">
                                            <div className="accordion" id="privacyPolicyAccordion">
                                                <div className="card">
                                                    <div className="card-header" id="headingOne">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                Introduction
                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>Data Processor is committed to protecting the privacy and confidentiality of Personal Data it collects. The purpose of this Privacy Notice is to explain how Data Processor Research Services Private Limited &amp; its subsidiaries (collectively referred to as “<strong>Data Processor</strong>”) collect, process, store, use, and protect the Personal Data (as defined herein after) for providing Video-Customer Identification Process (V-CIP) service via this V-KYC application.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingTwo">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                Applicability of this policy
                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>This policy is applicable on the Personal Data collected for customer onboarding via Video-Customer Identification Process (V-CIP) on behalf of the client.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingThree">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                Definitions
                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p><strong>V-CIP:</strong> Video-based Customer identification Process envisaged by RBI for Customer identification.</p>
                                                            <p><strong>Personal Data:</strong> means any information that relates to a natural person, which, either directly or indirectly, in combination with other information is capable of identifying such person.</p>
                                                            <p><strong>Sensitive Personal Data:</strong> Sensitive Personal Data is a specific set of “special category” Personal Data. (Such as – Financial information, medical records, Biometric data etc.)</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingFour">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                What type of Personal Data is collected during V-CIP?
                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>Personal Data is collected/received to Onboard customers using RBI compliant V-CIP solution.</p>
                                                            <p>The Personal Data collected will include:</p>
                                                            <ul>
                                                                <li>Individual name, Date of Birth</li>
                                                                <li>Contact number (mobile), E-mail ID.</li>
                                                                <li>Address details.</li>
                                                                <li>Identity details/ document (passport/Driving License/PAN card/Voter card/Aadhaar).</li>
                                                                <li>IP address, GPS</li>
                                                                <li>Audio-video recording</li>
                                                            </ul>
                                                            <p>Sensitive Personal Data will include:</p>
                                                            <ul>
                                                                <li>Facial image capture for face recognition.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingFive">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                                Why is Personal Data collected/obtained?
                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>Personal Data is collected for providing V-CIP for paperless and presence less customer onboarding on behalf of our clients. Any Personal Data collected is processed fairly and lawfully.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingSix">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                                How do we obtain Personal Data?
                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>Personal Data is obtained</p>
                                                            <ul>
                                                                <li>during audio-video interaction with the customer</li>
                                                                <li>by extracting the information from the Verification documents shared by the customer</li>
                                                                <li>from the photograph taken by the customer</li>
                                                                <li>by screenshot and</li>
                                                                <li>by OTP based Aadhar eKYC (where authorized).</li>
                                                            </ul>
                                                            <p>Personal Data is also obtained after accessing the Customer’s device during the process, post the consent from the customer.</p>
                                                            <ul>
                                                                <li>Customer’s location is confirmed by Geolocation tagging,</li>
                                                                <li>Camera is accessed for video and</li>
                                                                <li>microphone is accessed for the audio of the customer.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingSeven">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                                To whom the Personal Data collected is disclosed/ transferred?
                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>Agents from Data Processor’s client have access to the Personal Data as communicated by such Entity pursuant to a written contract between Data Processor and the Client.</p>
                                                            <p>Personal data collected is also accessed by Data Processor authorized third-party service provider for providing the support in aiding the V-CIP process. Data Processor has NDA and privacy agreements with such vendors.</p>
                                                            <p>Personal data is also shared with the verification source/authorized intermediary who aid in verification of the individual's identity.</p>
                                                            <p>Data can also be disclosed to the concerned authorities in following conditions-</p>
                                                            <ul>
                                                                <li>Where there is an obligation by law to disclose Personal Data.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingEight">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                                How do we protect the Personal Data we hold?
                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>Data Processor is ISO/IEC 27001:2013 certified and has appropriate technical and organizational information security measures in line with this international standard and applicable privacy or any other applicable regulations.</p>
                                                            <p>All our infrastructure is hosted on Amazon Web Services (AWS), a cloud computing platform with end-to-end security and privacy features built in. We have legal contract and confidentiality agreement signed with them. The access on AWS server is limited to authorized personnel only.</p>
                                                            <p>Any Personal Data/Sensitive Personal Data is classNameified as Confidential (PI) as per Data Processor information classNameification policy. Risk assessment activity is conducted periodically and based on assessment, suitable security controls are identified and implemented to protect Personal Data/ Sensitive Personal Data from any unauthorized disclosure, access, loss, misuse, or alteration.</p>
                                                            <p>The various security measures consist of privacy controls such as notification &amp; consent purpose limitation, data minimization, Personnel Security including background checks &amp; Privacy awareness, Physical security and IT security controls including but not limited to access controls, system security, network security, communication security, application security, encryption, multi-factor authentication, vulnerability assessments, log monitoring, Incident Management.</p>
                                                            <p>Following are the controls that Data Processor ensures in accordance with the RBI Guidelines-</p>
                                                            <ul>
                                                                <li>End to End encryption.</li>
                                                                <li>SSL encryptions for client-server communication.</li>
                                                                <li>Video interaction is securely stored under encryption protocols by AWS.</li>
                                                                <li>API integrations also use encryption protocols.</li>
                                                                <li>Aadhar number is redacted for data privacy purposes.</li>
                                                                <li>Artificial intelligence and face matching technology for accurate face match to ensure customer is the same person in the ID.</li>
                                                                <li>During offline Aadhar verification, it is ensured that the XML file is not older than 3 days.</li>
                                                                <li>Geo-tagging is done to ensure Customer is in India.</li>
                                                                <li>Movement based Liveness check.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingNine">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                                How to withdraw consent or request to update/delete any Personal Data?
                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>The verification services provided via this application are instantaneous. So, once the Personal Data is shared, it is processed instantly. Customer may choose to not share their details if they do not want to proceed further with the process.</p>
                                                            <p>In all other cases to withdraw consent or request to update/delete any Personal Data, individual whose data is being processed by Data Processor needs to speak to the entity they are engaging with. On receiving such requests from the entity (which is Data Processor’s client also), Data Processor will immediately act upon the same.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingTen">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                                                Children’s Privacy
                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseTen" className="collapse" aria-labelledby="headingTen" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>Data Processor neither knowingly collect personally identifiable data from anyone under the age of 18 (minors) nor provide any services to them.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingEleven">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                                How long the Personal Data is retained?
                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseEleven" className="collapse" aria-labelledby="headingEleven" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>Data is retained as per the regulatory and statutory requirement and/or the contractual obligations of Data Processor with Data Processor’s Clients.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingTwelve">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                                Changes to this Privacy Notice
                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseTwelve" className="collapse" aria-labelledby="headingTwelve" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>Data Processor may review and update this Privacy Notice from time to time. To let you know about the latest revision, we will update the revision date on top of this page.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingThirteen">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                                                Contact Us
                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseThirteen" className="collapse" aria-labelledby="headingThirteen" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>If you wish to contact Data Processor for any privacy related query/concern, please contact {clientName}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PrivacyPolicy
