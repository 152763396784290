import React from 'react'
import { JSONForm } from './Form'
import { useState, useReducer, useEffect } from 'react'
import Each from '../Each'
import API from '../apiBaseConfigNew'
import Loader from '../components/loader/Loader'
import { useDispatch, useSelector } from 'react-redux'
import WithInputType from '../admin/screens/adminDashboard/components/uploadCasesNew/BasicInputs'
import {
    GET_PROCESSES,
} from "../apiurl";
import _ from 'lodash'
import cogoToast from 'cogo-toast'
import { ErrorExtract } from '../constants'
function reducer(state, action) {
    if (action.type === 'single') {
        state[action.index].value = action.value
        state[action.index].error = ''
        return [...state]
    }
    else if (action.type === 'reset') {
        let temp = _.cloneDeep(JSONForm)
        return [...temp]
    }
    else
        return [...action.payload]


}
export default function CaseCreationPIVC() {
    const [file, setFile] = useState(null)
    const [consent_text, setconsent_text] = useState('')
    const [loading, setLoading] = useState(false)
    const [processList, setProcessList] = useState([])
    const [state, dispatch] = useReducer(reducer, _.cloneDeep(JSONForm))
    const userInfo = useSelector(state => state.login.userInfo)
    const { client } = useSelector(state => state.login.clientInfo)
    function handleFormChaange(value, index) {
        dispatch({ index, value, type: 'single' })
    }
    useEffect(() => {
        // getCaseFields()
        getProcessList()
    }, [])
    function getCaseFields() {
        API.get('/case/add-case-fields/')
            .then(res => {
                if (res.status === 200 && res.data) {
                    let temp = []
                    debugger
                }
            })
            .catch(err => {
                cogoToast.error('Something went wrong with case fields!')
            })
    }
    async function getProcessList() {
        let { data } = await API.get(GET_PROCESSES);
        setProcessList(data)
    }
    function handleValidation() {
        let isValid = true
        setLoading(true)
        state.map(item => {
            if (item.isRequired && item.value.length === 0) {
                item.error = 'Enter valid details!'
                isValid = false
            }
            else if (item.value.length > 0 && item.check) {
                if (!item.check(item.value)) {
                    item.error = 'Enter valid details!'
                    isValid = false
                }
            }
            else item.error = ''
        })
        if (!file) {
            isValid = false
            cogoToast.error('Please upload policy document!')
        }
        if (consent_text.length < 2) {
            isValid = false
            setTimeout(()=> cogoToast.error('Please enter consent text!'),300)
        }
        if (!isValid)
            cogoToast.error('Please enter all mandatory details with valid data!')
        dispatch({ payload: state });
        setLoading(false)

        if (isValid) submitCase(state)

    }
    function handleFileUpload(e) {
        setFile(e.target.files[0])
    }
    function submitCase(state) {
        setLoading(true)
        let form = new FormData()
        form.append('client', client)
        form.append('process', processList[0].id)
        state.forEach(element => {
            form.append([element.name], element.value)
        })
        form.append('policy_document', file)
        form.append('consent_text', consent_text)
        form.append('admin_auditor', userInfo.userId)
        API.post('/case/pivc-case/', form)
            .then(res => {
                setLoading(false)
                if (res) cogoToast.success('Case created successfully!')
                dispatch({ index: null, value: null, type: 'reset' })
                setFile(null)
                setconsent_text('')
            })
            .catch(err => {
                setLoading(false)
                if (err.response.status === 400) {
                    ErrorExtract(err.response.data[0].error)
                }
                // cogoToast.error('Pivc case with this application number already exists!')
                else cogoToast.error('Something went wrong while creating case!')
            })

    }
    return (
        <>
            {loading ? <Loader /> : null}
            <div className='p-3 upload-form'>
                <div className="row mb p-3 justify-content-end">
                    {/* <div>
                        <label className='link-btn' htmlFor='uploadExcel' >Upload Excel</label>
                        <input type='file' id='uploadExcel' />
                    </div> */}
                </div>
                <div className='row '>
                    <Each of={state} render={(item, index) => {
                        return <div className='col-md-3'>
                            <WithInputType {...item} onChange={e => handleFormChaange(e, index)} />
                        </div>
                    }} />

                </div>
                <hr />
                <div className='row'>
                    <div className='col-md-12'>
                        <p className='mb-1'><b>Upload Policy Document<span className='astric'>*</span></b></p>
                        <div className='col-md-4 pl-0'>
                            <label className='btn btn-primary p-2' htmlFor='upload' >Upload Document</label>
                            <input type='file' id={'upload'} accept='application/pdf' onChange={handleFileUpload} />
                            {file && file.name ? <span className='ml-2'>{file.name}</span> : null}
                        </div>

                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-12'>
                        <label><b>Consent Text</b><span className='astric'>*</span></label>
                        <textarea value={consent_text} onChange={e => setconsent_text(e.target.value)} placeholder='Text to be Shown on the screen' className='form-control' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 mt-2 mb-5'>
                        <button className='btn btn-primary' onClick={handleValidation}>Submit</button>
                    </div>
                </div>
            </div>
        </>
    )
}
