import React from "react";

import ScreenshotImage from "./ScreenshotImage";

class Screenshots extends React.Component {
  render() {
    const { connection } = this.props;
    return (
      <>
        <table
          className="gray-table-rows"
          width="750"
          style={{
            backgroundColor: "#f8f8f8",
            marginTop: "15px",
            marginBottom: "15px",
          }}
        >
          {connection.documents.map((doc, index) => {
            return (
              <ScreenshotImage
                doc={doc}
                index={index}
                connectionName={connection.name}
              />
            );
          })}
        </table>
      </>
    );
  }
}

export default Screenshots;
