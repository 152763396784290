import React from "react";

class UpperTab extends React.Component {
  render() {
    const { activeUpperTab } = this.props;
    return (
      <div className="col-md-6">
        <ul
          className="nav nav-tabs theme-2 big-nav-tabs-2 ml-30 mt-3 border-0"
          id="myTab"
        >
          <li className={activeUpperTab === 0 ? "nav-item active" : "nav-item"}>
            <span
              className={activeUpperTab === 0 ? "nav-link active" : "nav-link"}
              href="#active"
              data-toggle="tab"
              onClick={() => this.props.changeUpperTab(0)}
            >
              <span className="d-flex p-3 justify-content-center align-items-center text-white mb-3">
                <span className="tab-text ltr-spc-1">Active</span>
              </span>
            </span>
          </li>
          <li className={activeUpperTab === 1 ? "nav-item active" : "nav-item"}>
            <span
              className={activeUpperTab === 1 ? "nav-link active" : "nav-link"}
              href="#completed"
              data-toggle="tab"
              onClick={() => this.props.changeUpperTab(1)}
            >
              <span className="d-flex p-3 justify-content-center align-items-center text-white mb-3">
                <span className="tab-text ltr-spc-1">Completed</span>
              </span>
            </span>
          </li>
          <li className={activeUpperTab === 2 ? "nav-item active" : "nav-item"}>
            <span
              className={activeUpperTab === 2 ? "nav-link active" : "nav-link"}
              href="#transfered"
              data-toggle="tab"
              onClick={() => this.props.changeUpperTab(2)}
            >
              <span className="d-flex p-3 justify-content-center align-items-center text-white mb-3">
                <span className="tab-text ltr-spc-1">Transferred</span>
              </span>
            </span>
          </li>
          {/* <li className={activeUpperTab === 3 ? "nav-item active" : "nav-item"}>
            <span
              className={activeUpperTab === 3 ? "nav-link active" : "nav-link"}
              href="#cancelled"
              data-toggle="tab"
              onClick={() => this.props.changeUpperTab(3)}
            >
              <span className="d-flex p-3 justify-content-center align-items-center text-white mb-3">
                <span className="tab-text ltr-spc-1">Cancelled</span>
              </span>
            </span>
          </li> */}
        </ul>
      </div>
    );
  }
}

export default UpperTab;
