import React, { useEffect, useState } from "react";
import VerificationInput from "../verificationInput/VerificationInput";
import { CASE_HISTORY } from "../../apiurl";
import api from "../../apiBaseConfig";
import { useSelector } from 'react-redux'



export default function LocationDetails(props) {
  const {
    defaultValue,
    isLocationChecked,
    nextDisabled,
    connectionId
  } = props;
  const { calculate_location_distance } = useSelector(state => state.login.clientInfo)
  const [distance, setDistance] = useState(null)

  function handleIsChecked(isLocationChecked) {
    submitComment(`Location Details - Location checked - ${JSON.stringify(isLocationChecked)}`)
    props.handleLocationChecked(isLocationChecked);
  };
  useEffect(() => {
    if (calculate_location_distance)
      getDistance()
  }, [])

  function getDistance() {
    api.post('/case/location-distance/', { connection_id: connectionId })
      .then(res => {
        if (res.status === 200) {
          setDistance(res.data.detail)
        }
      })
      .catch(err => console.log(err))
  }

  const submitComment = async comment => {
    const caseId = props.caseId;
    const userId = props.userId;
    const body = {
      case: caseId,
      user: userId,
      type: "Action",
      re_text: comment
    };
    try {
      const res = await api.post(CASE_HISTORY, body);
    } catch (e) {
      console.log(e);
    }
  }




  return (
    <div className="border rounded p-4 bg-f9 mt-3">
      <h6 className="txt-light-blue dashed-title mb-3 ltr-spc-1">
        Location Details
      </h6>
      <VerificationInput
        preInput={"Location"}
        inputValueDefault={defaultValue}
        handleIsChecked={handleIsChecked}
        isTick={isLocationChecked.isTick}
        isCancel={isLocationChecked.isCancel}
        readOnly={true}
      />
      {calculate_location_distance ? <p className="mt-2"><b>Distance : </b>{distance}</p> : null}

      {!nextDisabled && (
        <div className="success-msg d-flex align-items-center mt-2">
          <span className="tick-icon-green-sml mr-1"></span>
          <span className="txt-green fnt-sml">
            <i>Location details has been successfully verified</i>
          </span>
        </div>
      )}
    </div>
  );

}

