import { ActionTypes } from "./user.types";

const initialState = {
  userDocInfo: {
    aadharUploaded: false,
    aadharData: {},
    aadharInvalid: false,
    panData: {},
    panUploaded: false,
    panInvalid: false,
    dlUploaded: false,
    dlData: {},
    dlInvalid: false,    
  },
  anonymousUserConnectionInfo: {
    id: null,
    case: {},
    name: null,
    connection: null,
    created_at: null,
    updated_at: null,
    isTokenValid: false,
    isConsent: false,
    multiUser: false,
  },
  userLocation: {},
  isLive: false,
};

export default function rootReducer(state = initialState, { type, payload }) {
   switch (type) {
    case ActionTypes.UPLOAD_AADHAR:
      return {
        ...state,
        userDocInfo: {
          ...state.userDocInfo,
          aadharData: payload,
          aadharUploaded: payload.aadharUploaded,
          aadharInvalid: false,
        },
      };
    case ActionTypes.UPLOAD_DL:
      return {
        ...state,
        userDocInfo: {
          ...state.userDocInfo,
          dlData: payload,
          dlUploaded: payload.dlUploaded,
          dlInvalid: false,
        },
      };
    case ActionTypes.PRESET_UPLOAD:
      return {
        ...state,
        userDocInfo: {
          ...state.userDocInfo,
          [payload]: true,
        },
      };
    case ActionTypes.UPLOAD_PAN:
      return {
        ...state,
        userDocInfo: {
          ...state.userDocInfo,
          panData: payload,
          panInvalid: false,
        },
      };
    case ActionTypes.PAN_INVALID:
      return {
        ...state,
        userDocInfo: {
          ...state.userDocInfo,
          panInvalid: payload,
        },
      };
    case ActionTypes.AADHAR_INVALID:
      return {
        ...state,
        userDocInfo: {
          ...state.userDocInfo,
          aadharInvalid: payload,
        },
      };
    case ActionTypes.DL_INVALID:
      return {
        ...state,
        userDocInfo: {
          ...state.userDocInfo,
          dlInvalid: payload,
        },
      };
    case ActionTypes.VARIFY_PAN:
      return {
        ...state,
        userDocInfo: {
          ...state.userDocInfo,
          panData: {
            ...state.userDocInfo.panData,
            data: payload,
          },
          panUploaded: true,
        },
      };
    case ActionTypes.VARIFY_AADHAR:
      return {
        ...state,
        userDocInfo: {
          ...state.userDocInfo,
          aadharData: {
            ...state.userDocInfo.aadharData,
            data: payload,
          },
          aadharUploaded: true,
        },
      };
    case ActionTypes.VARIFY_DL:
      return {
        ...state,
        userDocInfo: {
          ...state.userDocInfo,
          dlData: {
            ...state.userDocInfo.dlData,
            data: payload,
          },
        },
        dlUploaded: true,
      };
    case ActionTypes.UPLOAD_FAIL:
      return {
        ...state,
      };
    case ActionTypes.DELETE_AADHAR:
      return {
        ...state,
        userDocInfo: {
          ...state.userDocInfo,
          aadharData: {},
          aadharUploaded: false,
        },
      };
    case ActionTypes.DELETE_DL:
      return {
        ...state,
        userDocInfo: {
          ...state.userDocInfo,
          dlData: {},
          dlUploaded: false,
        },
      };
    case ActionTypes.DELETE_PAN:
      return {
        ...state,
        userDocInfo: {
          ...state.userDocInfo,
          panData: {},
          panUploaded: false,
        },
      };
    case ActionTypes.STORE_USER_CONNECTION_INFO:
      return {
        ...state,
        anonymousUserConnectionInfo: payload,
      };
    case ActionTypes.RESET_USER_DOCS:
      return {
        ...state,
        userDocInfo: {
          aadharUploaded: false,
          aadharData: {},
          aadharInvalid: false,
          panData: {},
          panUploaded: false,
          panInvalid: false,
          dlUploaded: false,
          dlData: {},
          dlInvalid: false,
        },
      };
    case ActionTypes.UPDATE_USER_LOCATION:
      return {
        ...state,
        userLocation: payload,
      };

      case ActionTypes.IS_LIVE:
      return {
        ...state,
        isLive: payload,
      };
    default:
      return state;
  }
}
