import React, { useState } from "react";
import "./ScheduleKyc.scss";
import UserHeader from "../../userHeader/userHeader";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";
import moment from "moment";

const CssTextField = withStyles({
  root: {
    "& input": {
      color: "white"
    },
    "& label": {
      color: "#fff"
    },
    "& label.Mui-focused": {
      color: "#fff"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff"
      },
      "&:hover fieldset": {
        borderColor: "#fff"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff"
      }
    }
  }
})(TextField);

function ScheduleKyc(props) {
  const [dateTime, setDateTime] = useState(moment().format());
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const setDateString = (e, type) => {
    if (type === "date") {
      setDate(e.target.value);
      let dateLocal = e.target.value.split("-");
      setDateTime(
        moment(dateTime)
          .set({ year: dateLocal[0], month: dateLocal[1], date: dateLocal[2] })
          .format()
      );
    } else {
      setTime(e.target.value);
      let timeLocal = e.target.value.split(":");
      setDateTime(
        moment(dateTime)
          .set({ hour: timeLocal[0], minute: timeLocal[1] })
          .format()
      );
    }
  };

  return (
    <div className="scheduleContainer">
      <UserHeader />
      <section className="mainContainer">
        <div className="subContainer">
          <div className="row">
            <VideoCallIcon
              style={{ color: "#fff", fontSize: 60, marginLeft: "-10px" }}
            />
          </div>
          <div className="row">
            <h4 className="scheduleHeader">
              You choose to Schedule the call for later{" "}
            </h4>
          </div>
          <div className="row mrT">
            <p className="scheduleTxt">Please Specify the date & time </p>
          </div>
          <div className="row mrT">
            <CssTextField
              onChange={e => setDateString(e, "date")}
              variant="outlined"
              inputprops={{ min: moment(new Date()).format("YYYY-MM-DD") }}
              color="secondary"
              label="Date"
              type="date"
              inputlabelprops={{
                shrink: true
              }}
            />
            <CssTextField
              onChange={e => setDateString(e, "time")}
              variant="outlined"
              label="Time"
              type="time"
              inputlabelprops={{
                shrink: true
              }}
            />
          </div>
          {date && time && (
            <div className="row scheduleButton">
              <Button variant="contained" color="secondary">
                Schedule
              </Button>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default ScheduleKyc;
