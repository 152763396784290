import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Loader from "../../../../../components/loader/Loader";

class AgentTAT extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      options: {},
      selectedVal: "",
      selectedCase: "all",

    };
  }
  componentDidMount() {
    this.updateChart('all',"today");
    
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.adminData !== this.props.adminData) {
      this.setState({ selectedVal: "today" });
      this.updateChart('all',"today");
    }
  }

  updateChart = (caseType,key) => {
    this.setState({ isLoading: true, selectedVal: key, selectedCase:caseType });
    const { adminData } = this.props;
    
    const approved = adminData[key]["approved"].count;
    const rework = adminData[key]["rework"].count;
    const rejected = adminData[key]["rejected"].count;


    const approvedData = this.getSeriesData(approved);
    const reworkData = this.getSeriesData(rework);
    const rejectedData = this.getSeriesData(rejected);
    const finalSeriesData = this.getFinalSeriesData(caseType,approvedData,reworkData,rejectedData)
    const categoriesData = this.getCategoriesData(caseType === 'all' ? approved : adminData[key][caseType].count);

    this.setOptions(finalSeriesData, categoriesData);
  
  };

  getSeriesData = (keyTATData) => {
    const seriesData = [];
    for (var i in keyTATData) {
      seriesData.push(keyTATData[i]);
    }
    return seriesData;
  };

  getCategoriesData = (keyTATData) => {
    const categoriesData = [];
    for (var i in keyTATData) {
      categoriesData.push(i);
    }
    return categoriesData;
  };

  setOptions = (finalSeriesData, categoriesData) => {
    const options = {
      chart: {
        type: "line",
        height: 150,
        width: 400,
        spacingTop: 5,
        spacingBottom: 5,
        spacingLeft: 0,
        spacingRight: 5,
      },
      title: {
        style: { display: "none" },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        line: {
          dashStyle: "ShortDash",
        },
      },
      legend: {
        enabled: false,
      },
      series:finalSeriesData,
      tooltip: {
        formatter: function () {
          return `${this.series.name} : ${this.y}`;
        },
      },
      yAxis: {
        title: {
          text: "Cases",
        },
      },
      xAxis: {
        categories: categoriesData,
        // labels:{style: {
        //   width:"auto",
        //   height:"auto",
        // }
      // },
      },
    };
    this.setState({ options: options, isLoading: false });
  };
  getFinalSeriesData = (cases, approvedData,reworkData, rejectedData) => {
    let seriesArr = [];
    switch (cases) {
      case "all":
        seriesArr = [
          {
            name: "Approved Cases",
            data: approvedData,
            color: "green",
          },
          {
            name: "Rework Cases",
            data: reworkData,
            color: "blue",
          },
          {
            name: "Rejected Cases",
            data: rejectedData,
            color: "red",
          }
          
        ];
        break;
      case "approved":
        seriesArr = [
          {
            name: "Approved Cases",
            data: approvedData,
            color: "green",
          },
        ];
        break;
      case "rejected":
        seriesArr = [
          {
            name: "Rejected Cases",
            data: rejectedData,
            color: "red",
          },
        ];
        break;
      case "rework":
        seriesArr = [
          {
            name: "Rework Cases",
            data: reworkData,
            color: "blue",
          },
        ];
        break;  
      default:
        seriesArr = [
          {
            name: "Approved Cases",
            data: approvedData,
            color: "green",
          },
          {
            name: "Rework Cases",
            data: reworkData,
            color: "blue",
          },
          {
            name: "Rejected Cases",
            data: rejectedData,
            color: "red",
          },
          
        ];
    }
    return seriesArr;
  };
  render() {
    const { options, isLoading, selectedVal, selectedCase } = this.state;
    return (
        <div className="card" >
          <div
            className="card-header d-flex justify-content-between align-items-center pt-2 pb-2 pl-3 pr-3"
            style={{ backgroundColor: "#fbfbfb" }}
          >
            <span className="text-uppercase txt-blue-dull fnt-sml">
              Auditor
            </span>
            <select
              name=""
              value={selectedCase}
              class="form-control form-control-sm fnt-sml"
              style={{ width: "120px" }}
              onChange={(e) => this.updateChart(e.target.value, selectedVal)}
            >
              <option
                value="all"
              >
                All Cases
              </option>
              <option value="approved">Approved Cases</option>
              <option value="rework">Rework Cases</option>
              <option value="rejected">Rejected Cases</option>
            </select>
            <select
              name=""
              id=""
              className="form-control form-control-sm fnt-sml"
              style={{ width: "120px" }}
              value={selectedVal}
              onChange={(e) => this.updateChart(selectedCase, e.target.value)}
            >
              <option value="today">Today</option>
              <option value="seven_days">Last 7 days</option>
              <option value="thirty_days">Last 30 days</option>
              <option value="ninety_days">Last 90 days</option>
              <option value="greater_than_ninety">
                Greater than last 90 days
              </option>
            </select>
          </div>
          <div
            className="card-body text-center fnt-sml"
            style={{
              height: "250px",
              backgroundColor: "fbfbfb",
            }}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <HighchartsReact highcharts={Highcharts} options={options} />
            )}
          </div>
        </div>
    );
  }
}

export default AgentTAT;
