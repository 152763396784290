import React, { useState } from "react";
import RECEIVE_CALL_ICON from "../../../../../assets/icons/receive-call-icon.gif";
import REJECT_CALL_ICON from "../../../../../assets/icons/reject-call-icon.gif";
import "./LiveCaseModal.scss";
import _get from "lodash/get";
import api from "../../../../../apiBaseConfig";
import { GET_CASES, UPDATE_LIVE_AGENT } from "../../../../../apiurl";
import { useSelector } from "react-redux";



export default function LiveCaseModal({
  liveCaseData,
  createAndStartSession,
  onLiveCallRejection,
  closeLiveCallModal,
}) {
  const userInfo = useSelector((state) => state.login.userInfo);
  console.log("loginreducer", userInfo);
  const customerName = _get(liveCaseData, "connections[0].name");
  const caseId = _get(liveCaseData, "id");
  const platformType = _get(liveCaseData, "platform_type");

  const [loading, setLoading] = useState(false);
  const handleCallReceived = async () => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      await api.post(`${UPDATE_LIVE_AGENT}${caseId}/`, {
        agent: userInfo.userId,
      });
      const agentName = userInfo.userFirstName + " " + userInfo.userLastName;
      createAndStartSession(caseId, agentName, platformType);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      closeLiveCallModal();
    }
  };

  return (
    <div class="row livecase-container">
      <div class="col-md-12 m-auto">
        <div class="call-sec d-flex justify-content-between mt-150 align-items-center">
          <div
            class="user-blue-circle user-blue-circle-big rounded-circle position-absolute"
            style={{ top: "-60px", left: "45%" }}
          >
            {customerName[0]}
          </div>
          {!loading && (
            <React.Fragment>
              <img
                style={{ cursor: "pointer" }}
                src={RECEIVE_CALL_ICON}
                alt=""
                onClick={() => handleCallReceived()}
              />
              <span class="font-italic fnt-sml">
                {customerName}{" "}
                <span class="txt-light-blue">
                  {" "}
                  has uploaded the documents and joined the call.
                </span>
              </span>
              <img
                style={{ cursor: "pointer" }}
                src={REJECT_CALL_ICON}
                alt=""
                onClick={onLiveCallRejection}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}
