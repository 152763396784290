import React from "react";
import CancelledCases from "./CancelledCases";

class CancelledCasesContainer extends React.Component {
  render() {
    const { cancelledCasesData, agentAuditorList } = this.props;
    return (
      <div
        className="tab-pane show active"
        id="cancelled"
        role="tabpanel"
        aria-labelledby="cancelled-tab"
      >
        <div
          className="row no-gutters bg-light pt-2"
          style={{ backgroundColor: "FBFBFB" }}
        ></div>
        <div className="row no-gutters">
          <CancelledCases
            cancelledCasesData={cancelledCasesData}
            handleViewDocuments={this.props.handleViewDocuments}
            agentAuditorList={agentAuditorList}
            transferCases={this.props.transferCases}
            getSearchObject={this.props.getSearchObject}
            searchKeyObject={this.props.searchKeyObject}
            handleSort={this.props.handleSort}
          />
        </div>
      </div>
    );
  }
}

export default CancelledCasesContainer;
