import React, { Component } from "react";
import { connect } from "react-redux";
import { upload } from "../../state/user.actions";
import DLUploadOptions from "./DLUploadOptions";
import DLBrowseUpload from "./DLBrowseUpload";

class DrivingLicense extends Component {
  constructor(props){
    super(props);
    this.state ={
      locationAccess: true,
      uploadAadhaarViaBrowse: false,
    }
  }

  componentDidMount() {
    this.checkLocation();
  }
 
  checkLocation() {
    navigator.geolocation.getCurrentPosition(
      async function (position) {
        console.log("done");
      },
      (e) => {
        this.setState({ locationAccess: false });
      },
      { timeout: 10000 }
    );
  }

  redirectToVerify = () => {
    this.props.redirectToVerify();
  };

  uploadViaBrowse = () => {
    this.setState({ uploadAadhaarViaBrowse: true });
  };
  render() {
    const { locationAccess, uploadAadhaarViaBrowse } = this.state;
    
    return (
      <React.Fragment>
        {!locationAccess && (
          <div className="location-error">
            Location access blocked, allow access to device location to continue
            with your KYC process
          </div>
        )}
        {uploadAadhaarViaBrowse ? (
          <DLBrowseUpload
            uploadFileType={this.props.uploadFileType}
            isAadhaar={this.props.isAadhaar}
            callDigilockerAPI={this.props.callDigilockerAPI}
            takePhotoBrowseUploadDL={this.props.takePhotoBrowseUploadDL}
            dlData={this.props.dlData}
            setPhotoSideBrowseDL={this.props.setPhotoSideBrowseDL}
            documentName={this.props.documentName}
            takePhoto={this.props.takePhoto}
            takeDLPhoto={this.props.takeDLPhoto}
            uploadFileDL={this.props.uploadFileDL}
            handleDL={this.props.handleDL}
            handleTakePhoto={this.props.handleTakePhoto}
          />
        ) : (
          <DLUploadOptions
            callDigilockerAPI={this.props.callDigilockerAPI}
            uploadViaBrowse={this.uploadViaBrowse}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userDocs.userDocInfo,
  userConnectionInfo: state.userDocs.anonymousUserConnectionInfo,
});

const mapDispatchToProps = (dispatch) => ({
  upload: (payload) => dispatch(upload(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrivingLicense);
