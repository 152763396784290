import React from "react";
import HFCLIcon from "../../../../src/assets/icons/hfcl-icon.svg";
import { useSelector } from 'react-redux'


function UserSession() {
  const {logo_path} = useSelector(state => state.login.clientInfo)
  let {clientName} = useSelector(state => state.login)
    return (
      <div className="wrapper light-blue-customer-template">
        <header className="bg-blue-version2">
          <nav className="navbar navbar-expand-lg h-100 navbar-light bh-white">
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <a href="#" className="nav-link"></a>
                </li>
              </ul>
            </div>
          </nav>
        </header>
        <section className="main-body m-auto" style={{ maxWidth: "100%" }}>
          <div className="container-fluid mt-4 mb-4">
            <div className="row">
              <div className="col-md-12 text-center">
                <img
                  src={logo_path}
                  className="img-fluid mx-auto my-5 d-block"
                  alt=""
                  style={{width: "300px"}}
                />
                <h4 className="text-dark font-weight-bold ltr-spc-0">{clientName}</h4>
                <h5 className="txt-light-black mt-3 mb-3 ltr-spc-0">
                  Thank you for verifying with us! Please move back to your VKYC window.
                </h5>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}
export default UserSession
