import { ActionTypes } from "./user.types";

export const uploadAadhar = (payload) => ({
  type: ActionTypes.UPLOAD_AADHAR,
  payload: payload,
});

export const uploadPan = (payload) => ({
  type: ActionTypes.UPLOAD_PAN,
  payload: payload,
});

export const upload = (payload) => ({
  type: ActionTypes.UPLOAD,
  payload: payload,
});

export const uploadFail = () => ({
  type: ActionTypes.UPLOAD_FAIL,
});

export const panValidity = (payload) => ({
  type: ActionTypes.PAN_INVALID,
  payload: payload,
});

export const varifyPan = (payload) => ({
  type: ActionTypes.VARIFY_PAN,
  payload: payload,
});

export const varify = (payload) => ({
  type: ActionTypes.VARIFY,
  payload: payload,
});

export const verifyAadhaar = (payload) => ({
  type: ActionTypes.VARIFY_AADHAR,
  payload: payload,
});

export const verifyDL = (payload) => ({
  type: ActionTypes.VARIFY_DL,
  payload: payload,
});

export const deleteAadhar = () => ({
  type: ActionTypes.DELETE_AADHAR,
});

export const deletePan = () => ({
  type: ActionTypes.DELETE_PAN,
});

export const storeAnonymousUserConnectionInfo = (payload) => ({
  type: ActionTypes.STORE_USER_CONNECTION_INFO,
  payload: payload,
});

export const presetUpload = (payload) => ({
  type: ActionTypes.PRESET_UPLOAD,
  payload: payload,
});

export const resetUserDocs = () => ({
  type: ActionTypes.RESET_USER_DOCS,
});

export const updateUserLocation = (payload) => ({
  type: ActionTypes.UPDATE_USER_LOCATION,
  payload: payload,
});

export const isLives = (payload) => ({ 
  type: ActionTypes.IS_LIVE,
  payload: payload,
});
