import React from "react";

class ActiveTabs extends React.Component {
  render() {
    const { activeLowerTab } = this.props;
    return (
      <div className="col-md-6">
        <div className="ml-30">
          <ul
            className="nav nav-tabs nav-tabs-plain transparent border-bottom brdr-db mt-2"
            id="myTabNested"
            role="tablist"
          >
            <li className="nav-item" style={{ marginLeft: "0" }}>
              <span
                className={
                  activeLowerTab === 0
                    ? "nav-link active text-uppercase ltr-spc-1 fnt-sml"
                    : "nav-link text-uppercase ltr-spc-1 fnt-sml"
                }
                id="assigned-tab"
                href="#assigned"
                data-toggle="tab"
                onClick={() => this.props.changeActiveLowerTab(0)}
              >
                Assigned
              </span>
            </li>
            <li className="nav-item">
              <span
                className={
                  activeLowerTab === 1
                    ? "nav-link active text-uppercase ltr-spc-1 fnt-sml"
                    : "nav-link text-uppercase ltr-spc-1 fnt-sml"
                }
                id="scheduled-tab"
                href="#scheduled"
                data-toggle="tab"
                onClick={() => this.props.changeActiveLowerTab(1)}
              >
                Scheduled
              </span>
            </li>
            <li className="nav-item">
              <span
                className={
                  activeLowerTab === 2
                    ? "nav-link active text-uppercase ltr-spc-1 fnt-sml"
                    : "nav-link text-uppercase ltr-spc-1 fnt-sml"
                }
                id="reassigned-tab"
                href="#reassigned"
                data-toggle="tab"
                onClick={() => this.props.changeActiveLowerTab(2)}
              >
                Reassigned
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default ActiveTabs;
