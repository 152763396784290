import React, { useEffect, useState } from 'react'
import API from '../../apiBaseConfigNew'
import Loader from '../../components/loader/Loader'
import CasesChart from './CasesChart'
import cogoToast from 'cogo-toast'

export default function Charts() {
    const [data, setData] = useState(null)
    const [isData, setIsData] = useState(false)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getAdminSummary()
    }, [])
    function getAdminSummary() {

        API.get('case/pivc/admin-summary/')
            .then(res => {
                if (res.data.pivc) {
                    setIsData(true)
                    setData(res.data.pivc)
                }
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                cogoToast.error('Error in fetching data!')
            })
    }
    return (
        <>
            {loading ? <Loader /> : isData ?
                <div className="row">
                    <div className="col-lg-12 col-sm-12">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="row">
                                    <div className="col-md-6 text-center">
                                        <div className="bg-fb rounded p-2">

                                            <h2
                                                className="txt-blue-2 fnt-roboto-bld mt-1 roboto-medium ltr-spc-1 mb-0"
                                                style={{ fontSize: "42px" }}
                                            >
                                                {data.agent_active_cases
                                                    ? data.agent_active_cases
                                                    : "0"}
                                            </h2>
                                            <div className="fnt-sml txt-70">Active Cases</div>
                                            <div className="pl-3 pr-3">
                                                <hr className="brdr-dashed mt-2 mb-2" />
                                            </div>
                                            <h2
                                                className="txt-blue-2 fnt-roboto-bld mt-1 roboto-medium ltr-spc-1 mb-0"
                                                style={{ fontSize: "42px" }}
                                            >
                                                {data.customer_completed_cases
                                                    ? data.customer_completed_cases
                                                    : "0"}
                                            </h2>
                                            <div className="fnt-sml txt-70">Completed Cases</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-center">
                                        <div className="bg-f0 rounded p-2">
                                            <h2
                                                className="txt-blue-2 fnt-roboto-bld mt-1 roboto-medium ltr-spc-1 mb-0"
                                                style={{ fontSize: "42px" }}
                                            >
                                                {data.auditor_active_cases
                                                    ? data.auditor_active_cases
                                                    : "0"}
                                            </h2>
                                            <div className="fnt-sml txt-70">Pending Audit</div>
                                            <div className="pl-3 pr-3">
                                                <hr className="brdr-dashed mt-2 mb-2" />
                                            </div>
                                            <h2
                                                className="txt-blue-2 fnt-roboto-bld mt-1 roboto-medium ltr-spc-1 mb-0"
                                                style={{ fontSize: "42px" }}
                                            >
                                                {data.auditor_completed_cases
                                                    ? data.auditor_completed_cases
                                                    : "0"}
                                            </h2>
                                            <div className="fnt-sml txt-70">Audited</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <CasesChart data={data} />
                            </div>
                            
                        </div>
                    </div>
                </div> : null }
        </>
    )
}
