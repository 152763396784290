import React from "react";
import OtpInput from "react-otp-input";
import UserHeader from "../../userHeader/userHeader";
import api from "../../../apiBaseConfig";
import { SEND_OTP, SEND_AADHAAR_CODE } from "../../../apiurl";
import Loader from "../../../components/loader/Loader";
import { Button } from "@material-ui/core";
import { upload } from "../../state/user.actions";
import { connect } from "react-redux";

class AadhaarPaperlessUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aadhaarNumber: "",
      otp: "",
      aadhaarCode: "",
      hasAadhaarNumber: false,
      hasAadhaarCode: false,
      ts_trans_id:'',
      resendOn: false,
      loading: false,
    };
  }

  callOTPApi = async () => {
    this.setState({
      ...this.state,
      resendOn: false,
      loading: true,
    });
    const {  aadhaarNumber } = this.state;
    try {
      const response = await api.post(SEND_OTP, {
        doc_number: aadhaarNumber,
      });
      if (response.status == 200 && response.data.tsTransID) {
        debugger
        this.setState({
          hasAadhaarNumber: true,
          loading: false,
          ts_trans_id:response.data.tsTransID
        });
        setTimeout(() => {
          this.setState({
            ...this.state,
            resendOn: true,
          });
        }, 30000);
      }
    } catch (e) {
      this.setState({
        loading: false,
      });
    }
  };

  async callAadhaarCodeApi(otp) {
    const { ts_trans_id } = this.state;
    this.setState({ loading: true});
    try {
      const response = await api.post(SEND_AADHAAR_CODE, {
        ts_trans_id: ts_trans_id,
        otp: otp,
        document: this.props.docId,
        connection: this.props.userConnectionInfo.id,
        case: this.props.userConnectionInfo.case_details.id,
      });
      this.setState({
        loading: false
      });
      if (response) {
        // this.props.upload({
        //   type: "AADHAR",
        //   data: response.data,
        //   documentId: this.props.docId,
        //   connection: this.props.userConnectionInfo.id,
        //   case: this.props.userConnectionInfo.case_details.id
        // });
        this.props.redirectToVerify();
      }
    } catch (e) {
      this.setState({
        loading: false,
        otp: "",
        aadhaarCode: "",
        ts_trans_id:''
      });
    }
  }

  getAadhaarNumber = (aadhaarNumber) => {
    const { captchaConfirmed } = this.state;
    if (aadhaarNumber.length === 12 && captchaConfirmed) {
      this.setState({ hasAadhaarNumber: true });
    }
    this.setState({ aadhaarNumber });
  };

  checkAadharLength = (aadhaarNumber) => {
    if (aadhaarNumber.length !== 12) {
      this.setState({ isAadharLengthError: true });
    } else {
      this.setState({ isAadharLengthError: false });
    }
  };


  handleOTPChange = (otp) => {
    this.setState({ otp });
    if (otp.length === 6) {
      this.callAadhaarCodeApi(otp);
    }
  };

  confirmCaptcha = () => {
    const { aadhaarNumber } = this.state;
    if (aadhaarNumber.length === 12 ) {
      this.callOTPApi();
    }
  };

  handleAadhaarCode = (aadhaarCode) => {
    if (aadhaarCode.length === 4) {
      this.callAadhaarCodeApi(aadhaarCode);
    }
    this.setState({ aadhaarCode });
  };
  render() {
    const {
      hasAadhaarNumber,
      otp,
      loading,
      resendOn,
    } = this.state;
 
    return (
      <React.Fragment>
        {loading && <Loader />}
        <div class="wrapper light-blue-customer-template">
          <UserHeader />
          <section class="main-body m-auto" style={{ maxWidth: "460px" }}>
            <div class="container-fluid mt-4 position-relative">
              <div class="row">
                <div class="col-md-12">
                  <h4 class="txt-light-blue ltr-spc-1">
                    Aadhaar Paperless Upload
                  </h4>
                  <h5 class="txt-light-black ltr-spc-1 mt-3 mb-3">
                    Enter your 12 digit Aadhaar number to begin
                  </h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 aadhaar-no-box-2 mt-3">
                  <input
                    type="text"
                    class="form-control brdr-c5-opacity ltr-spc-12 fnt-bld"
                    style={{ height: "45px" }}
                    onChange={(e) => this.getAadhaarNumber(e.target.value)}
                    onBlur={(e) => this.checkAadharLength(e.target.value)}
                    maxLength={12}
                  />
                  {this.state.isAadharLengthError && (
                    <span className="text-danger">
                      Please enter a valid Aadhaar number
                    </span>
                  )}
                </div>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  {hasAadhaarNumber ? (
                    <Button
                      disabled={!resendOn}
                      variant="contained"
                      className="proceed-button ml-4"
                      color="secondary"
                      onClick={() => {
                        resendOn && this.confirmCaptcha();
                      }}
                    >
                      Resend
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="proceed-button"
                      color="secondary"
                      onClick={this.confirmCaptcha}
                    >
                      Confirm
                    </Button>
                  )}
                </div>

                {hasAadhaarNumber && (
                  <div class="col-md-12 otp-heading ">
                    <h5 class="txt-light-black ltr-spc-1 mt-3 mb-3">
                      Please type the OTP sent on the registered mobile number
                    </h5>
                  </div>
                )}

                {hasAadhaarNumber && (
                  <div class="col-md-12 otp-box-2 d-flex align-items-center text-center justify-content-center mt-3">
                    <OtpInput
                      value={otp}
                      onChange={this.handleOTPChange}
                      numInputs={6}
                      isInputNum={true}
                      separator={<span>&nbsp;</span>}
                      inputStyle={{ height: "45px", width: "30px" }}
                    />
                  </div>
                )}
               
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userDocs.userDocInfo,
  userConnectionInfo: state.userDocs.anonymousUserConnectionInfo,
});

const mapDispatchToProps = (dispatch) => ({
  upload: (payload) => dispatch(upload(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AadhaarPaperlessUpload);
