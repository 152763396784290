// /*************************************************************************
//  * Notification wrapper
//  * Contain three methods for showing success, warn, info and error notification
//  * All kind of notification will be sent through these methods
//  ************************************************************************/

import React from "react";
import cogoToast from "cogo-toast";

function template(props) {
  return (
    <div className="custom-toast">
      <div className={`toast-content ${props.toastContentClass}`}>
        {props.description}
      </div>
    </div>
  );
}

/**
 *   Library params , type and default values
 * 
 *   	Params	                Type                                        Default                                                 
        hideAfter              Number in Seconds                    3 (Can be 0 to disable auto-hiding of the toast)
        position              'top-left', 'top-center',             
                              'top-right','bottom-left',                  'top-center'
                              'bottom-center', 'bottom-right' 
                                
        heading                  String	                                         ''
        renderIcon             Function  Icon Based on the Type 
        bar                    Object                                             Based on the Type
                 { size: '2px', style: 'solid/dashed/dotted', color: '#hex' })
                                	
        onClick(hide)                Function	                                       null
 * @export
 * @class Toast
 */
export class Toast {
  static register(elem) {
    this.currentScope = elem;
  }

  static openFlag() {
    this.currentScope.addFlag();
  }

  /**
   * @param  {object} params
   * showing notification according to type of notification
   */
  static notification = params => {
    Toast[params.type](params);
  };

  /**
   * Shows success notification
   * @param  {string} message
   * @param  {Object} params={}
   */
  static success(params) {
    cogoToast.success(
      template({
        description: params.description,
        toastContentClass: "success-toast"
      }),
      {
        heading:
          params.title ||
          "" /* Hiding the heading as it is not required in current design */,
        hideAfter: params.hideAfter || 4,
        position: params.position || "top-right",
        bar: { size: "2px", style: "solid", color: "green" },
        onClick: hide => {
          // hide();
        },
        renderIcon: () => {
          return (
            <div className={`toast-icon success-icon`}>
              {" "}
              {/* <img
                alt="success"
                src={require("./../Assets/Images/Toast-Icons/success-toast.svg")}
              /> */}
            </div>
          );
        }
      }
    );
  }

  /**
   * Shows error notification
   * @param  {string} message
   * @param  {Object} params={}
   */
  static error(params) {
    // overriding toast params
    cogoToast.error(
      template({
        description: params.description,
        toastContentClass: "error-toast"
      }),
      {
        heading:
          params.title ||
          "" /* Hiding the heading as it is not required in current design */,
        hideAfter: params.hideAfter || 4,
        position: params.position || "top-right",
        bar: { size: "2px", style: "solid", color: "red" },
        renderIcon: () => {
          return (
            <div className="toast-icon error-icon">
              {" "}
              {/* <img
                alt="error"
                src={require("./../Assets/Images/Toast-Icons/error-toast.svg")}
              /> */}
            </div>
          );
        },
        onClick: hide => {
          // hide();
        }
      }
    );
  }

  /**
   * Shows warn notification
   * @param  {string} message
   * @param  {Object} params={}
   */
  static warning(params) {
    // overriding toast params
    cogoToast.warn(
      template({
        description: params.description,
        toastContentClass: "warning-toast"
      }),
      {
        heading:
          params.title ||
          "" /* Hiding the heading as it is not required in current design */,
        hideAfter: params.hideAfter || 4,
        position: params.position || "top-right",
        bar: { size: "2px", style: "solid", color: "#ffc107" },
        renderIcon: () => {
          return (
            <div className="toast-icon warning-icon">
              {" "}
              {/* <img
                alt="warning"
                src={require("./../Assets/Images/Toast-Icons/warning-toast.svg")}
              /> */}
            </div>
          );
        },
        onClick: hide => {
          // hide();
        }
      }
    );
  }

  /**
   * Shows info notification
   * @param  {string} message
   * @param  {Object} params={}
   */
  static info(params) {
    // overriding toast params
    cogoToast.info(
      template({
        description: params.description,
        toastContentClass: "info-toast"
      }),
      {
        heading:
          params.title ||
          "" /* Hiding the heading as it is not required in current design */,
        hideAfter: params.hideAfter || 4,
        position: params.position || "top-right",
        bar: { size: "2px", style: "solid", color: "#17a2b8" },
        renderIcon: () => {
          return (
            <div className="toast-icon info-icon">
              {/* <img
                alt="info"
                src={require("./../Assets/Images/Toast-Icons/info-toast.svg")}
              /> */}
            </div>
          );
        },
        onClick: hide => {
          // hide();
        }
      }
    );
  }
}
