import React, { useState } from "react";
import VideoRecorder from "react-video-recorder";
import "./HardwareTest.scss";
import api from '../../../apiBaseConfig'

const HardwareTest = ({
  setIsHidden,
  setIsChecked,
  setIsNotChecked,
  setVideoValue,
  lang,
}) => {
  const [btnEnable, setBtnEnable] = useState(false);
  const [testData, setTestData] = useState("")

  const handleClickedOk = () => {
    setIsHidden(false);
    setIsChecked(true);
    setVideoValue("allowed");
  };

  const handleClickedNotOk = () => {
    setIsHidden(false);
    setIsChecked(false);
    setIsNotChecked(true);
    setVideoValue("blocked");
  };



  const takeScreenShot =()=>{
    return
    var video = document.getElementsByTagName('video')[0];
    var canvas = document.createElement('canvas');

    canvas.width = video.clientWidth;
    canvas.height = video.clientHeight;
    var context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
  
    // Get the image data from the canvas
    var imageData = canvas.toDataURL("image/jpeg");
    api.post('/case/liveness/',{image_data:imageData})
    .then(res=>{
      if(res === undefined)
       debugger
    })
    .catch(err=>{
      debugger
      console.log(err)
    })
    setTestData(imageData)
    // Set the canvas dimensions to match the video dimensions
  
  }

  return (
    <>
      <div className="hardware"  id="video-tester">
        <VideoRecorder
          isFlipped={false}
          // isOnInitially
          countdownTime={3000}
          timeLimit={5000}
          renderDisconnectedView={() => null}
          mimeType="video/webm"
          wrapperClassName="hardware-test"
          onStopRecording = {()=>takeScreenShot()}
          constraints={{
            audio: true,
            video: {
              // width: { exact: 480, ideal: 480 },
              // height: { exact: 640, ideal: 640 },
              // aspectRatio: { exact: 0.7500000001, ideal: 0.7500000001 },
              // resizeMode: "crop-and-scale",
            },
          }}
          onRecordingComplete={ async (videoBlob) => {
            // Do something with the video...
            setBtnEnable(true);
            // console.log("videoBlob", videoBlob);
            // window.open(videoBlob,'_blank')
          }}
        />
      </div>
      {btnEnable ? (
        <div className="d-flex justify-content-center">
          <button
            onClick={() => handleClickedOk()}
            type="button"
            className="btn btn-light-blue left-button"
          >
            Ok
          </button>
          <button
            onClick={() => handleClickedNotOk()}
            type="button"
            className="btn right-button"
          >
            Not Ok
          </button>
        </div>
      ) : null}
      {/* <img src={testData} /> */}
    </>
  );
};
export default HardwareTest;