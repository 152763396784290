import { put, takeEvery } from "redux-saga/effects";
import { ActionTypes } from "./login.types";
import instance from "../../apiBaseConfig";
import { SET_CLIENT, GET_USER_DETAILS } from "../../apiurl";

export function* login({ payload }) {
  try {
    const response = yield instance.get(GET_USER_DETAILS);
    response.data.isAgent = false
    response.data.isAuditor = false
    response.data.isClientSuperuser = false;
    response.data.isAdminAuditor = false;

    switch (response.data.role) {
      case "agent":
        response.data.isAgent = true; break;
      case "agent_auditor":
        response.data.isAgent = true;
        response.data.isAuditor = true; break;
      case "auditor":
        response.data.isAuditor = true; break;
      case "admin_auditor":
        response.data.isClientSuperuser = true;
        response.data.isAdminAuditor = true; break;
      case "client_superuser":
        response.data.isClientSuperuser = true; break;
    }
    yield put({
      type: ActionTypes.LOGIN_SUCCESS,
      payload: {
        ...response.data,
      },
    });
  } catch (error) {
    console.log("Error during login", error);
    yield put({
      type: ActionTypes.LOGIN_FAIL,
    });
  }
}

export function* getClientConfig({ payload, type }) {
  try {
    const response = yield instance.get('/client/clientconfig/');
    yield put({
      type: ActionTypes.UPDATE_CLIENT_CONFIG,
      payload: {
        ...response.data[0],
      },
    })
  }
  catch (e) {
    console.log("Error during login", e);
  }
}

export default function* () {
  yield takeEvery(ActionTypes.LOGIN, login);
  yield takeEvery(ActionTypes.GET_CLIENT_CONFIG, getClientConfig);

}
