import React from "react";
import AssignedCases from "./AssignedCases";
import ScheduledCases from "./ScheduledCases";
import ReassignedCases from "./ReassignedCases";
import SearchAndSort from "../searchAndSort/SearchAndSort";
import SearchBar from "../searchBar/SearchBar";

class ActiveCases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearchBar: false,
    };
  }

  toggleSearchClick = () => {
    const { showSearchBar } = this.state;
    this.setState({ showSearchBar: !showSearchBar });
  };
  render() {
    const {
      activeLowerTab,
      activeCasesData,
      searchKeyObject,
      agentAuditorList,
      consolidatedAgentList
    } = this.props;
    const { showSearchBar } = this.state;
    const datePrefix =
      activeLowerTab === 0
        ? "Assigned"
        : activeLowerTab === 1
        ? "Scheduled"
        : "Reassigned";
    return (
      <div className="col-md-12">
        <div className="ml-30 mr-30 mt-3">
          <SearchAndSort
            toggleSearchClick={this.toggleSearchClick}
            handleSort={this.props.handleSort}
            getSearchObject={this.props.getSearchObject}
            searchKeyObject={searchKeyObject}
          />
          {showSearchBar ? (
            <SearchBar
              datePrefix={datePrefix}
              getSearchObject={this.props.getSearchObject}
              searchKeyObject={searchKeyObject}
              agentAuditorList={agentAuditorList}
              consolidatedAgentList={consolidatedAgentList}
            />
          ) : (
            <div className="tab-content mt-1">
              {activeLowerTab === 0 && (
                <AssignedCases
                  assignedCasesData={activeCasesData}
                  handleViewDocuments={this.props.handleViewDocuments}
                  agentAuditorList={agentAuditorList}
                  transferCases={this.props.transferCases}
                  handleDeleteCase={this.props.handleDeleteCase}
                  consolidatedAgentList={consolidatedAgentList}
                />
              )}
              {activeLowerTab === 1 && (
                <ScheduledCases
                  scheduledCasesData={activeCasesData}
                  handleViewDocuments={this.props.handleViewDocuments}
                />
              )}
              {activeLowerTab === 2 && (
                <ReassignedCases
                  reassignedCasesData={activeCasesData}
                  handleViewDocuments={this.props.handleViewDocuments}
                  agentAuditorList={agentAuditorList}
                  transferCases={this.props.transferCases}
                  handleDeleteCase={this.props.handleDeleteCase}
                  consolidatedAgentList={consolidatedAgentList}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ActiveCases;
