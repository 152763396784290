import React, { useState, useEffect } from "react";
import "./ToggleButton.scss";

export default function ToggleButton({ onToggleChange, isToggleOn }) {
  console.log("isToggleOn", isToggleOn);
  return (
    <label class="switch m-0">
      <input
        type="checkbox"
        checked={isToggleOn}
        onChange={() => {
          console.log("on change trig");
          onToggleChange(!isToggleOn);
        }}
      />
      <span class="slider round"></span>
    </label>
  );
}
