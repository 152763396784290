import React from "react";
import moment from "moment";
import {
  GET_CASES,
  CASE_HISTORY,
  GENERATE_REPORT,
} from "../../../../../apiurl";
import api from "../../../../../apiBaseConfig";
import Loader from "../../../../../components/loader/Loader";
import Feedback from "../../../../../components/feedback/feedback";
import AgentCompletedCases from "./AgentCompletedCases";
import AuditorCompletedCases from "./AuditorCompletedCases";

class CompletedCases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      casesDataArray: [],
      isLoading: true,
      isFeedback: false,
      currentIndex: null,
      currentCaseId: null,
      currentAction: null,
    };
  }

  componentDidMount() {
    const { completedCases } = this.props;
    this.cloneCompletedCasesData(completedCases);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.completedCases !== this.props.completedCases) {
      this.cloneCompletedCasesData(nextProps.completedCases);
    }
  }
  cloneCompletedCasesData = (completedCases) => {
    let casesDataArray = [...completedCases];
    for (var i in casesDataArray) {
      casesDataArray[i]["selected"] = false;
      casesDataArray[i]["approved"] =
        casesDataArray[i].status === "audited" ? true : false;
      casesDataArray[i]["rejected"] =
        casesDataArray[i].status === "rejected" ? true : false;
      casesDataArray[i]["reassigned"] =
        casesDataArray[i].status === "assigned" ? true : false;
    }
    this.setCasesDataArray(casesDataArray, false);
  };

  setCasesDataArray = (casesDataArray, isLoading) => {
    this.setState({ casesDataArray, isLoading });
  };
  handleOptions = (index) => {
    const { casesDataArray } = this.state;
    let casesDataArrayClone = [...casesDataArray];
    casesDataArrayClone.forEach((caseData, i) => {
      i !== index && (caseData.selected = false);
      return caseData;
    });
    casesDataArrayClone[index].selected = !casesDataArrayClone[index].selected;
    this.setCasesDataArray(casesDataArrayClone, false);
  };

  handleApprove = async (index, caseId) => {
    const { userInfo } = this.props;
    let auditedTime = moment().format();
    let auditorId = userInfo.userId;
    await api.patch(`${GET_CASES}${caseId}/`, {
      audited_at: auditedTime,
      audited_by: auditorId,
      status: "audited",
    });
    const { casesDataArray } = this.state;
    let casesDataArrayClone = [...casesDataArray];
    casesDataArrayClone[index].approved = true;
    casesDataArrayClone[index].rejected = false;
    casesDataArrayClone[index].reassigned = false;
    this.setCasesDataArray(casesDataArrayClone, false);
  };

  handleCommentBeforeAction = (index, caseId, action) => {
    this.setState({
      isFeedback: true,
      currentIndex: index,
      currentCaseId: caseId,
      currentAction: action,
    });
  };
  handleReject = async (index, caseId) => {
    const { userInfo } = this.props;
    let auditedTime = moment().format();
    let auditorId = userInfo.userId;
    await api.patch(`${GET_CASES}${caseId}/`, {
      audited_at: auditedTime,
      audited_by: auditorId,
      status: "rejected",
    });
    const { casesDataArray } = this.state;
    let casesDataArrayClone = [...casesDataArray];
    casesDataArrayClone[index].approved = false;
    casesDataArrayClone[index].rejected = true;
    casesDataArrayClone[index].reassigned = false;
    // this.setCasesDataArray(casesDataArrayClone, false);
    this.props.fetchUpdatedCases();
  };

  handleReassign = async (index, caseId) => {
    const { userInfo } = this.props;
    let auditedTime = moment().format();
    let auditorId = userInfo.userId;
    await api.patch(`${GET_CASES}${caseId}/`, {
      audited_at: auditedTime,
      audited_by: auditorId,
      status: "assigned",
    });
    const { casesDataArray } = this.state;
    let casesDataArrayClone = [...casesDataArray];
    casesDataArrayClone[index].approved = false;
    casesDataArrayClone[index].rejected = false;
    casesDataArrayClone[index].reassigned = true;
    // this.setCasesDataArray(casesDataArrayClone, false);
    this.props.fetchUpdatedCases();
  };

  submitComment = async (comment) => {
    const { currentIndex, currentCaseId, currentAction } = this.state;
    const { userInfo } = this.props;
    const body = {
      case: currentCaseId,
      user: userInfo.userId,
      type: "comment",
      re_text: comment,
    };
    try {
      const res = await api.post(CASE_HISTORY, body);
      this.setState({ isFeedback: false });
      if (currentAction === "rejected") {
        this.handleReject(currentIndex, currentCaseId);
      } else {
        this.handleReassign(currentIndex, currentCaseId);
      }
    } catch (e) {
      console.log(e);
    }
  };

  generateReport = async (caseId) => {
    // const response = await api.get(GENERATE_REPORT + caseId)
    // this.generateFile(response.data,"case_detail");
    // console.log()
    window.open(
      process.env.REACT_APP_BASE_API_URL + GENERATE_REPORT + caseId,
      "_blank"
    );
  };

  generateFile = (content, fileName) => {
    console.log("content", content); // here at console if i copy the code and use online tool(https://base64.guru/converter/decode/pdf) it shows the correct pdf
    const blob = new Blob([content], { type: "application/pdf" });
    console.log(blob);
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    console.log(link.href);
    link.download = fileName;
    link.click();
  };

  render() {
    const { activeUpperTab, userInfo, searchKeyObject } = this.props;
    const { casesDataArray, isLoading, isFeedback } = this.state;
    return (
      <React.Fragment>
        {isLoading && <Loader />}
        {isFeedback && (
          <div data-toggle="modal" data-target="#exampleModal">
            <Feedback
              isFeedback={isFeedback}
              closeModal={() => this.setState({ isFeedback: false })}
              callback={this.submitComment}
              id={"#exampleModal"}
              isAuditor={true}
            />
          </div>
        )}
        {userInfo && userInfo.isAgent && !userInfo.isAuditor && (
          <AgentCompletedCases
            activeUpperTab={activeUpperTab}
            casesDataArray={casesDataArray}
            handleViewDocuments={this.props.handleViewDocuments}
            searchKeyObject={searchKeyObject}
          />
        )}
        {userInfo && userInfo.isAuditor && !userInfo.isAgent && (
          <AuditorCompletedCases
            activeUpperTab={activeUpperTab}
            casesDataArray={casesDataArray}
            handleViewDocuments={this.props.handleViewDocuments}
            handleWatchVideo={this.props.handleWatchVideo}
            handleOptions={this.handleOptions}
            handleApprove={this.handleApprove}
            handleCommentBeforeAction={this.handleCommentBeforeAction}
            generateReport={this.generateReport}
            searchKeyObject={searchKeyObject}
          />
        )}
        {userInfo && userInfo.isAuditor && userInfo.isAgent && (
          <AuditorCompletedCases
            activeUpperTab={activeUpperTab}
            casesDataArray={casesDataArray}
            handleViewDocuments={this.props.handleViewDocuments}
            handleWatchVideo={this.props.handleWatchVideo}
            handleOptions={this.handleOptions}
            handleApprove={this.handleApprove}
            handleCommentBeforeAction={this.handleCommentBeforeAction}
            generateReport={this.generateReport}
            searchKeyObject={searchKeyObject}
          />
        )}
      </React.Fragment>
    );
  }
}

export default CompletedCases;
