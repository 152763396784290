import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Loader from "../../../../../components/loader/Loader";

class AgentCompletedCases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      options: {},
      selectedVal: "",
    };
  }

  componentDidMount() {
    this.updateCompletedCasesChart("today");
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.adminData !== this.props.adminData) {
      this.setState({ selectedVal: "today" });
      this.updateCompletedCasesChart("today");
    }
  }

  updateCompletedCasesChart = (key) => {
    this.setState({ isLoading: true, selectedVal: key });
    const { adminData } = this.props;
    const keyCountData = adminData[key]["completed"].count;
    const seriesData = this.getSeriesData(keyCountData);
    const categoriesData = this.getCategoriesData(keyCountData);
    this.setOptions(seriesData, categoriesData);
  };

  getSeriesData = (keyCountData) => {
    const seriesData = [];
    for (var i in keyCountData) {
      seriesData.push(keyCountData[i]);
    }
    return seriesData;
  };

  getCategoriesData = (keyCountData) => {
    const categoriesData = [];
    for (var i in keyCountData) {
      categoriesData.push(i);
    }
    return categoriesData;
  };

  setOptions = (seriesData, categoriesData) => {
    const options = {
      chart: {
        type: "line",
        height: 120,
        width: 500,
        spacingTop: 5,
        spacingBottom: 5,
        spacingLeft: 0,
        spacingRight: 5,
      },
      title: {
        style: { display: "none" },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        line: {
          dashStyle: "ShortDash",
        },
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: "Completed Cases",
          data: seriesData,
        },
      ],
      tooltip: {
        formatter: function () {
          return `${this.series.name} : ${this.y}`;
        },
      },
      yAxis: {
        title: {
          text: "Cases",
        },
      },
      xAxis: {
        categories: categoriesData,
      },
    };
    this.setState({ options: options, isLoading: false });
  };
  render() {
    const { options, isLoading, selectedVal } = this.state;
    return (
        <div class="card">
          <div class="card-header bg-white d-flex justify-content-between align-items-center pt-2 pb-2 pl-3 pr-3">
            <span class="text-uppercase txt-blue-dull fnt-sml">
              Agent ( Completed Cases )
            </span>
            <select
              name=""
              id=""
              class="form-control form-control-sm fnt-sml"
              style={{ width: "120px" }}
              onChange={(e) => this.updateCompletedCasesChart(e.target.value)}
            >
              <option
                value="today"
                selected={selectedVal === "today" ? true : false}
              >
                Today
              </option>
              <option value="seven_days">Last 7 days</option>
              <option value="thirty_days">Last 30 days</option>
              <option value="ninety_days">Last 90 days</option>
              <option value="greater_than_ninety">
                Greater than last 90 days
              </option>
            </select>
          </div>
          <div
            class="card-body text-center fnt-sml"
            style={{ height: "160px" }}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <HighchartsReact highcharts={Highcharts} options={options} />
            )}
          </div>
        </div>
    );
  }
}

export default AgentCompletedCases;
