import React, { useEffect, useState } from 'react'
import './CustomTimePicker.scss'
import { Modal,TextField, Button,InputAdornment } from "@material-ui/core";
import AccessTimeIcon from '@material-ui/icons/AccessTime';

export default function CustomTimePicker({label, value, handleChange,UId }) {
  const [isOpen, setIsOpen] = useState(false)
  const [hours,setHours] = useState("00")
  const [minutes,setMinutes] = useState("00")
  const [activeTab1, setActiveTab1] = useState(false)
  const [activeTab2, setActiveTab2] = useState(false)


  const hoursList = ["00","01","02","03","04","05","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"]

  useEffect(()=>{
   if((!value || value.length === 0 ) && isOpen) resetTime()
   else if(isOpen) {
    let time = value.split(":")
    setHours(time[0])
    setMinutes(time[1])
   }
  },[isOpen])
  function setTime(){
    let timeString = hours+':'+minutes;
    handleChange(timeString)
    setIsOpen(false)
  }

  function resetTime(){
    let currentTime = new Date()
    handleHours(currentTime.getHours())
    handleMinutes(currentTime.getMinutes().toString())
  }

  function handleHours(value){
    let result = new RegExp(/^\d+$/).test(value)
    if((!result && value.length> 0) || value > 23) return
    if(value<=9 && value > 0 && value.length<2) value = '0'+value
    if(value.length === 3){
       value = value.slice(1,3)
      }
    if(value == 0) value ="00"  
    setHours(value)
  }
  function handleMinutes(value){
    let result = new RegExp(/^\d+$/).test(value)
    if((!result && value.length> 0) || value > 59) return
    if(value<=9 && value > 0 && value.length<2) value = '0'+value
    if(value.length === 3){
       value = value.slice(1,3)
      }
    if(value == 0) value ="00"
    setMinutes(value)
  }
  function createArray(N) {
    return Array.from({length: N}, (_, index) => {
      if(index < 10) return '0'+index
      else return index
    });
}

function handleTime(e){
  let result = new RegExp(/^([01]\d|2[0-3]):([0-5]\d)$/).test(e.target.value)
  if(result) handleChange(e.target.value)
  else handleChange(value)
}

  return (
    <div className='customPickerWrapper'>
      <div className='input-container'>
      <TextField InputLabelProps={{ shrink: value && value.length > 0 }} InputProps={{
            endAdornment: <InputAdornment position="end" className='cursor-pointer' onClick={e=>setIsOpen(true)}><AccessTimeIcon /></InputAdornment>,
          }} fullWidth  label={label} onClick={e=>setIsOpen(true)} onChange={e=>handleTime(e)} variant="standard" type='text' value={value} />
      </div>
      <Modal open={isOpen} onEscapeTextFieldKeyDown={e=>setIsOpen(false)}>
        <div className='customPickerModal'>
          <div className='customTimePicker'>
            <div className='time-container'>
            <div className='hours'>
            <input className='btn btn-primary' onBlur={e=>setActiveTab1(false)} onFocus={e=>setActiveTab1(true)} onChange={e=>handleHours(e.target.value)} maxLength={3} value={hours} />
            <div className={activeTab1 ? 'timeList show' : 'timeList'} role='group'>
             <ul>
            { hoursList.map((h,i)=>{
              return <li className={h=== hours ? 'active' : ''} role='option' onMouseDown={e=>setHours(h)} key={i}>{h}</li>
             })}
             </ul>
            </div>
            </div>
            <span className='text-white'>:</span>
            <div className='hours'>
            <input className='btn btn-primary' onBlur={e=>setActiveTab2(false)} onFocus={e=>setActiveTab2(true)} onChange={e=>handleMinutes(e.target.value)} maxLength={3} value={minutes} />
            <div className={activeTab2 ? 'timeList show' : 'timeList'} role='group'>
             <ul>
            { createArray(60).map((m,i)=>{
              return <li className={m=== minutes ? 'active' : ''} role='option' onMouseDown={e=>setMinutes(m)} key={i}>{m}</li>
             })}
             </ul>
            </div>
            </div>
            </div>
          </div>
          <div className='picker-footer'>
          <Button className='primary' variant='text' onClick={()=>setIsOpen(false)}>Cancel</Button>
          <Button className='primary' variant='text' onClick={()=>resetTime()}>Clear</Button>
          <Button className='primary' variant='text' onClick={()=>setTime()}>Ok</Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
