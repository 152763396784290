import React from "react";
import { ReactComponent as UploadIcon } from "../../../../src/assets/icons/upload-icon.svg";
import { ReactComponent as CameraIcon } from "../../../../src/assets/icons/camera-icon.svg";
import { Dialog, DialogContent } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import UserHeader from "../../userHeader/userHeader";
import { connect } from "react-redux";
import { upload, deletePan, deleteAadhar } from "../../state/user.actions";
class DLBrowseUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      frontUploaded: false,
      backUploaded: false,
      takeDLFrontPhoto: false,
      takeDLBackPhoto: false,
    };
  }
  static readUploadedFileAsDataURL = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
  };

  uploadFileDL = async (event, side, documentId) => {
    event.persist();
    if (!event.target || !event.target.files) {
      return;
    }
    const file = event.target.files[0];
    if (file.type === "text/xml") {
      this.props.history.push({ pathname: "./verify/aadhaar", state: file });
    } else if (file.type.includes("image")) {
      try {
        const fileContent = await DLBrowseUpload.readUploadedFileAsDataURL(
          file
        );
        console.log('this',this.props)
        this.props.handleDL(fileContent, side, documentId, "ocr_upload");
        if (side === "front") {
          this.setState({ frontUploaded: true });
        } else {
          this.setState({ backUploaded: true });
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  handleTakePicture = (dataUri, side, documentId, uploadType) => {
    this.props.handleDL(dataUri, side, documentId, uploadType);
    if (side === "front") {
      this.setState({
        takeDLBackPhoto: false,
        takeDLFrontPhoto: false,
        frontUploaded: true,
      });
    } else {
      this.setState({
        takeDLBackPhoto: false,
        takeDLFrontPhoto: false,
        backUploaded: true,
      });
    }
  };
  render() {
    const { uploadFileType } = this.props;
    const { frontUploaded, backUploaded, takeDLFrontPhoto, takeDLBackPhoto } =
      this.state;
    return (
      <>
        <div className="wrapper light-blue-customer-template">
          <UserHeader />
          <section className="main-body m-auto customer-flow-section">
            <div className="container-fluid mt-4">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="txt-light-blue ltr-spc-1">
                    Driving License Upload
                  </h4>
                  <h5 className="txt-light-black ltr-spc-1 mt-3 mb-3">
                    Use this section to upload your driving license
                  </h5>
                </div>
              </div>
              <h4 className="aadhaar-heading">1. Upload Front photo</h4>
              <div className="row">
                <div className="col-md-6 col-xs-6 col-lg-6 col-sm-6">
                  <label
                    htmlFor="file-upload"
                    className="border border-1 dashed-brdr brdr-light-blue rounded equal-box d-flex align-items-center p-4 bg-light justify-content-center fnt-sml flex-d-column"
                    style={{ cursor: "pointer" }}
                  >
                    <UploadIcon width="30" />
                    <div>
                      <span className="txt-light-blue mt-2">
                        Browse to upload
                      </span>
                    </div>
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    accept={uploadFileType}
                    onChange={(e) => this.uploadFileDL(e, "front", 2)}
                  ></input>
                </div>

                <div
                  className="col-md-6 col-xs-6 col-lg-6 col-sm-6"
                  onClick={(e) => {
                    this.setState({ takeDLFrontPhoto: true });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="border border-1 dashed-brdr brdr-light-blue rounded equal-box d-flex align-items-center p-4 bg-light justify-content-center fnt-sml flex-d-column">
                    <CameraIcon width="30" />
                    <span className="txt-light-blue mt-2">Click a picture</span>
                  </div>
                </div>

                <br />
                <br />
              </div>
              {frontUploaded && (
                <p style={{ color: "#21A947", marginTop: "10px" }}>
                  Front photo uploaded
                </p>
              )}
              <h4 className="aadhaar-heading">2. Upload back photo</h4>

              <div className="row mt-5">
                <div className="col-md-6 col-xs-6 col-lg-6 col-sm-6">
                  <label
                    htmlFor="file-upload-2"
                    className="border border-1 dashed-brdr brdr-light-blue rounded equal-box d-flex align-items-center p-4 bg-light justify-content-center fnt-sml flex-d-column"
                    style={{ cursor: "pointer" }}
                  >
                    <UploadIcon width="30" />
                    <div>
                      <span className="txt-light-blue mt-2">
                        Browse to upload
                      </span>
                    </div>
                  </label>
                  <input
                    id="file-upload-2"
                    type="file"
                    accept={uploadFileType}
                    onChange={(e) => this.uploadFileDL(e, "back", 3)}
                  ></input>
                </div>
                <div
                  className="col-md-6 col-xs-6 col-lg-6 col-sm-6"
                  onClick={(e) => {
                    // this.props.deletePan();
                    this.setState({ takeDLBackPhoto: true });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="border border-1 dashed-brdr brdr-light-blue rounded equal-box d-flex align-items-center p-4 bg-light justify-content-center fnt-sml flex-d-column">
                    <CameraIcon width="30" />
                    <span className="txt-light-blue mt-2">Click a picture</span>
                  </div>
                </div>
              </div>
              {backUploaded && (
                <p style={{ color: "#21A947", marginTop: "10px" }}>
                  Back photo uploaded
                </p>
              )}
            </div>
          </section>
        </div>
        <Dialog
          aria-labelledby="customized-dialog-title"
          maxWidth="md"
          open={takeDLFrontPhoto || takeDLBackPhoto}
        >
          <div className="align-self-end px-4">
            <IconButton
              aria-label="close"
              onClick={(e) => {
                // this.props.deletePan();
                this.setState({
                  takeDLFrontPhoto: false,
                  takeDLBackPhoto: false,
                });
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <DialogContent>
            {takeDLFrontPhoto && (
              <Camera
                idealFacingMode={FACING_MODES.ENVIRONMENT}
                idealResolution={{ width: 640, height: 480 }}
                isImageMirror={false}
                isFullscreen={false}
                onTakePhoto={(dataUri) =>
                  this.handleTakePicture(dataUri, "front", 2, "ocr_upload")
                }
              />
            )}

            {takeDLBackPhoto && (
              <Camera
                idealFacingMode={FACING_MODES.ENVIRONMENT}
                idealResolution={{ width: 640, height: 480 }}
                isImageMirror={false}
                isFullscreen={false}
                onTakePhoto={(dataUri) =>
                  this.handleTakePicture(dataUri, "back", 3, "ocr_upload")
                }
              />
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userDocs.userDocInfo,
  userConnectionInfo: state.userDocs.anonymousUserConnectionInfo,
});

const mapDispatchToProps = (dispatch) => ({
  deletePan: () => dispatch(deletePan()),
  deleteAadhar: () => dispatch(deleteAadhar()),
  upload: (payload) => dispatch(upload(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DLBrowseUpload);
