import React, { useState, useEffect } from "react";
import "../customerConsent/CustomerConsent.scss";
import { Button } from "@material-ui/core";
import {
    CUSTOMER_CONSENT,
    SAVE_LOCATION,
    IP_SPOOFING,
} from "../../../apiurl";
import {
    storeAnonymousUserConnectionInfo,
    updateUserLocation,
} from "../../state/user.actions";
import api from "../../../apiBaseConfig";
import { Shield } from "shield-js-fp";
import { Toast } from "../../../utils/toastutils";
import _get from "lodash/get";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router";
import Diagnostic from '../diagnostic/Diagnostic'
import Loader from "../../../components/loader/Loader";

//consent screen
export default function Consent(props) {
    const history = useHistory();
    let userInfo = useSelector(state => state.userDocs.anonymousUserConnectionInfo)
    const {isLive} = useSelector(state => state.userDocs)
    const dispatch = useDispatch()
    const [conditionsArray, setConditionsArray] = useState([false])
    const [allChecked, setAllChecked] = useState(false)
    const [isLivenessChecked, setIsLivenessChecked] = useState(true)
    const [loading, setLoading] = useState(false)

    const { isMulti } = props;
    let process = userInfo.case_details.process_details.case_type === "video_kyc" ? 'Video KYC' : 'Personal discussion'
    let {clientName} = useSelector(state => state.login)

    function handleConsentChange(index) {
        let old = [...conditionsArray]
        old[index] = !old[index]
        setConditionsArray(old)
        getAllChecked(old);
    };

    function getAllChecked(arr) {
        let allChecked = true;
        for (var i in arr) {
            if (!arr[i]) {
                allChecked = false;
            }
        }
        setAllChecked(allChecked)
    };
    const tagGeolocation = async (latitude, longitude) => {
        const response = await api.post(SAVE_LOCATION, {
            latitude: latitude,
            longitude: longitude,
        });
    };
    function submitConsent() {
        const location = {};
        setLoading(true)
        navigator.geolocation.getCurrentPosition(
            async function (position) {
                location["latitude"] = position.coords.latitude;
                location["longitude"] = position.coords.longitude;

                try {
                    await tagGeolocation(
                        position.coords.latitude,
                        position.coords.longitude
                    );
                    const res = await api.post(IP_SPOOFING,{});
                    setLoading(false)
                    if(!res || res.status!== 200){
                        Toast.error({
                                    description: "Connection is not secure.",
                                    title: "Error",
                                });
                                return;
                    }
                 
                    sendNotificationToAgent()
                    await api.post(CUSTOMER_CONSENT);
                    props.handleNext()

                } catch (e) {
                    setLoading(false)
                    console.log("location failed");
                }
                dispatch(updateUserLocation(location));

                userInfo.isConsent = true;
                dispatch(storeAnonymousUserConnectionInfo(userInfo));
            },
            (e) => {
                setLoading(false)
                Toast.error({
                    description:
                        "Please provide your location access to continue further",
                    title: "Error",
                });
            },
            { timeout: 10000 }
        );
    };
   async function sendNotificationToAgent (){
       if(isLive) return
        try{
            let {agent,id} = userInfo.case_details
            let notification_by = userInfo.id
            let name = userInfo.name
            await api.post('/case/notifications/',{
                "notification_text": `${name} has started the ${process}, click to take action.`,
                "notification_type": "Info",
                "notification_for": agent,
                "notification_by": notification_by,
                "action_tab": "scheduled",
                "case": id,
                "case_type": userInfo.case_details.process_details.case_type
        })
        }
        catch(err){
            console.log(err)
        }
       
    }

    return (
        <>
       {loading ? <Loader /> : null}
       { isLivenessChecked ? 
        <div className="wrapper light-blue-customer-template">
            <section className="main-body m-auto">
                <div className="container-fluid mt-4">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="txt-light-blue ltr-spc-1">Customer consent</h4>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-12">
                            <div className="border border-1 dashed-brdr brdr-light-blue rounded p-4 mb-5">
                                <div
                                    className="border brdr-c5 p-4 rounded"
                                    style={{ maxHeght: "300px", overflowY: "auto" }}
                                >
                                    <p className="txt-light-blue">Terms &amp; Conditions</p>
                                    <p className="fnt-sml txt-light-black">
                                        CONSENT- I hereby give my consent to the {clientName === 'HFCL Paytm' ? 'HFCL' : `${clientName}`} and
                                        its service partner to process my Personal and Sensitive
                                        Personal Data (as given below) for the purpose of my
                                        On-Boarding via Video-Customer Identification Process
                                        <ul>
                                            <li>
                                                Access to my device’s camera, microphone, and
                                                geolocation
                                            </li>
                                            <li>
                                                Processing and capturing my National Identity document
                                                such as PAN & Aadhaar,
                                            </li>
                                            <li>
                                                Processing and capturing any other documents (as
                                                applicable),
                                            </li>
                                            <li>
                                                Recording of my video call and chat with the agent,
                                            </li>
                                            {!isMulti && (
                                                <li>
                                                    Matching my face with the photograph on National
                                                    Identity
                                                </li>
                                            )}
                                        </ul>
                                    </p>
                                    {!isMulti && (
                                        <p className="fnt-sml txt-light-black">
                                            Non-adherence to any of the above requirements would
                                            lead to the failure of the {process} process. Please try
                                            to hold your device upright and still during the call.
                                            The data shared during the {process} process would
                                            remain completely anonymous and would not be shared with
                                            any third party.
                                        </p>
                                    )}
                                </div>
                                <div className="custom-checkbox-normal ml-3 mt-2">

                                    {conditionsArray.map((value, key) => {
                                        return (
                                            <div className="d-flex align-items-center" key={key}>
                                                <input
                                                    checked={value}
                                                    onChange={() => handleConsentChange(key)}
                                                    type="checkbox"
                                                    className="checkbox-round"

                                                ></input>
                                                <label className="txt-light-black fnt-sml font-weight-bold">
                                                    {key === 0
                                                        ?
                                                        <React.Fragment>
                                                            I have read the terms & conditions and the <a href={clientName === 'HFCL Paytm' ? 'https://www.herofincorp.com/sites/default/files/Data-Privacy-Policy.pdf' : "../privacy"} target="_blank">"Privacy Notice" </a>
                                                            so here I provide my consent.
                                                        </React.Fragment>

                                                        :
                                                        <React.Fragment>
                                                            I provide my consent to the terms & conditions and the <a href={clientName === 'HFCL Paytm' ? 'https://www.herofincorp.com/sites/default/files/Data-Privacy-Policy.pdf' : "../privacy"} target="_blank">"Privacy Notice"</a>
                                                        </React.Fragment>
                                                    }
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Button
                variant="contained"
                className="proceed-button mb-5"
                disabled={!allChecked}
                color="secondary"
                onClick={() => submitConsent()}
            >
                Proceed
            </Button>
        </div> : <Diagnostic setDiagnosticCheck={()=>setIsLivenessChecked(true)}  />}
        </>
    );
}
