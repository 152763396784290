import React, { useEffect, useState } from "react";
import "./AdminDashboard.scss";
import AddExecutive from "./components/addExecutive/AddExecutive";
import UploadCases from "./components/uploadCases/UploadCases";
import UploadCaseNew from "./components/uploadCasesNew/UploadCaseNew";
import UploadLiveCases from "./components/uploadLiveCases/UploadLiveCases";
import AgentList from "./components/agentList/AgentList";
import { logout } from "./../../../login/state/login.actions";
import DashboardHeader from "../../../agent/screens/agentDashboard/components/dashboardHeader/DashboardHeader";
import DashboardSidebar from "../../../agent/screens/agentDashboard/components/dashboardSidebar/DashboardSidebar";
import Home from "./components/home/Home";
import CaseManagement from "./components/caseManagement/CaseManagement";
import { useDispatch, useSelector } from 'react-redux'
import AgentProfile from "../../../agent/screens/agentDashboard/components/agentProfile";
import CaseCreationPIVC from "../../../PIVC/CaseCreation";
import PIVCPolicy from '../../../PIVC/Customer/Policy'
import CustomerLanding from "../../../PIVC/Customer/CustomerLanding";
import PIVCCases from "../../../PIVC/Dashboard/PIVCCases";
import PIVCHome from "../../../PIVC/Dashboard/PIVCHome";
import UploadLiveHFCL from "./components/uploadLiveCases/UploadLiveHFCL";


const UPLOAD_CASES = "UPLOAD_CASES";
const UPLOAD_LIVE_CASES = "UPLOAD_LIVE_CASES";
const ADD_EXECUTIVE = "ADD_EXECUTIVE";
const AGENT_LIST = "AGENT_LIST";
const ADMIN_HOME = "ADMIN_HOME";
const CASE_MANAGEMENT = "CASE_MANAGEMENT";


export default function AdminDashboard(props) {
    const userInfo = useSelector(state => state.login.userInfo)
    let clientConfig = useSelector(state => state.login.clientInfo)
    let { is_additional_field_require, client_details } = clientConfig
    const dispatch = useDispatch();
    const [stateData, setStateData] = useState({
        activeTab: ADMIN_HOME,
        profileOptionClicked: false,
        sideBarExpanded: false,
        showAgentProfile: false
    })


    function handleProfileOptions() {
        setStateData(current => { return { ...current, profileOptionClicked: !current.profileOptionClicked } })
    };


    const handleLogout = async () => {
        dispatch(logout())
    };
    function handleProfile() {
        setStateData(current => { return { ...current, showAgentProfile: true, activeTab: 'none' } })
    }


    return (
        <div>
            <div className="wrapper h-100 light-blue-template">
                <DashboardHeader
                    profileOptionClicked={stateData.profileOptionClicked}
                    handleProfileOptions={handleProfileOptions}
                    handleLogout={handleLogout}
                    userInfo={userInfo}
                    handleProfile={handleProfile}
                />
                <DashboardSidebar
                    expanded={stateData.sideBarExpanded}
                    expand={(e) =>
                        setStateData(current => { return { ...current, sideBarExpanded: e } })
                    }
                    activeTab={stateData.activeTab}
                    sideBarChanged={(e) => setStateData(current => { return { ...current, activeTab: e, showAgentProfile: false } })}
                    isAdmin={true}
                />
                <section
                    className={
                        stateData.sideBarExpanded ? "ml240 main-content" : "main-content"
                    }
                >
                    {stateData.activeTab === UPLOAD_CASES && <>{clientConfig.case_module === 5 ? <CaseCreationPIVC /> : is_additional_field_require ? <UploadCaseNew /> : <UploadCases />} </>}

                    {stateData.activeTab === UPLOAD_LIVE_CASES && <>{client_details?.name === 'HFCL Paytm' ? <UploadLiveHFCL /> : <UploadLiveCases />}</>}
                    {stateData.activeTab === ADD_EXECUTIVE && <AddExecutive />}
                    {stateData.activeTab === ADMIN_HOME && <>{clientConfig.case_module === 5 ? <PIVCHome /> : <Home />} </>}
                    {stateData.activeTab === AGENT_LIST && <AgentList />}
                    {stateData.activeTab === CASE_MANAGEMENT && <>{clientConfig.case_module === 5 ? <PIVCCases /> : <CaseManagement />}</>}
                    {stateData.showAgentProfile && <AgentProfile />}
                </section>

            </div>
        </div>
    );
}
