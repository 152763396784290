import React, { Component } from "react";
import USER_IMAGE from "../../assets/images/user-icon.png";
import api from "../../apiBaseConfig";
import { GET_DOCUMENTS } from "../../apiurl";
import _get from "lodash/get";
import _groupBy from "lodash/groupBy";
import RefreshIcon from "@material-ui/icons/Refresh";
import Loader from "../../components/loader/Loader";
import Questionnaire from "../questionnaire/Questionnaire";

export default class MultiUserDocList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userArray: [],
      questionnaire: [],
    };
  }

  handleDocumentLinkClicked = (documentArray) => {
    documentArray.forEach((documentLink) => {
      window.open(documentLink, "_blank");
    });
  };

  componentDidMount() {
    this.fetchDocumentList();
  }

  fetchDocumentList = async () => {
    try {
      this.setState({ isLoading: true });
      const response = await api.get(
        `${GET_DOCUMENTS}?case=${this.props.sessionId}`
      );
      this.setState({ isLoading: false });

      const userArray = [];
      const users = _groupBy(response.data.data, "connection_details.id");
      console.log({ users });
      Object.values(users).forEach((documents) => {
        const userName = _get(documents[0], "connection_details.name", "");
        const documentArray = [];
        documents.forEach((document) => {
          if (document.front_upload) {
            documentArray.push(document.front_upload);
          }
          if (document.back_upload) {
            documentArray.push(document.back_upload);
          }
        });

        userArray.push({ userName, documentArray });
      });

      this.setState({ userArray });
    } catch (error) {
      console.log("error", error);
      this.setState({ isLoading: false });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.refreshMultiDocList !== prevProps.refreshMultiDocList) {
      this.fetchDocumentList();
    }
  }

  render() {
    const { userArray, questionnaire } = this.state;
    return (
      <div class="steps-box step2 mb-2">
        {this.state.isLoading && (
          <Loader title={"Please wait. Uploading documents"} />
        )}

        <div className="d-flex justify-content-between align-items-center">
          <h5 class="txt-blue ltr-spc-1">Documents Uploaded</h5>
          <RefreshIcon onClick={() => this.fetchDocumentList()} />
        </div>
        <hr class="dashed-brdr brdr-blue"></hr>
        {userArray.map((user) => (
          <div class="border bg-white rounded brdr-eb p-3 cstm-shdo d-flex align-items-center mb-1">
            {/* <img src="img/Customer6.png" class="rounded-circle" width="40" height="40" alt=""></img> */}
            <div class="col">
              {/* <div class="fnt-sml-x txt-gray">CEO</div> */}
              <div class="fnt-8rem customer-name-blue">{user.userName}</div>
            </div>
            <div
              class="col"
              onClick={() => this.handleDocumentLinkClicked(user.documentArray)}
            >
              <span class="fnt-8rem pointer customer-name-blue">
                Document Uploaded
              </span>
            </div>
          </div>
        ))}

        {/* <hr class="dashed-brdr brdr-blue"></hr>
                    <Questionnaire
                      questionnaire={questionnaire}
                      connectionId={this.props.connectionId}
                      setQuestions={(ques) => this.setState({ ...this.state, questionnaire: ques })}
                      userId={this.props.userId}
                      caseId={this.props.sessionId}
                    /> */}
      </div>
    );
  }
}
