import React from "react";
import VerificationInput from "../verificationInput/VerificationInput";
import { CASE_HISTORY } from "../../apiurl";
import api from "../../apiBaseConfig";

class PanCardDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      panCardDetailsArray: [],
      enlarge: false
    };
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      panCardDetailsArray: [
        {
          preInput: "Name",
          defaultValue: this.props.defaultValue
            ? this.props.defaultValue.name
            : ""
        },
        {
          preInput: "Father's Name",
          defaultValue: this.props.defaultValue
            ? this.props.defaultValue.father_name
            : ""
        },
        {
          preInput: "PAN",
          defaultValue: this.props.defaultValue
            ? this.props.defaultValue.doc_id
            : ""
        },
        {
          preInput: "DOB",
          defaultValue: this.props.defaultValue
            ? this.props.defaultValue.dob
            : ""
        }
      ]
    });
  }

  handleIsCheckedForPan = (value, index) => {
    let panCardCheckedArray = [...this.props.panCardCheckedArray];
    value.type = this.state.panCardDetailsArray[index].preInput;
    panCardCheckedArray[index] = value;
    if (this.props.panData.document_upload_type === "digilocker_upload") {
      panCardCheckedArray = [
        { ...value, type: "Verify Pan Card" }]
    }
    this.submitComment(`PAN Card Details - ${panCardCheckedArray[index].type} - ${JSON.stringify(value)}`)
    this.props.handleCheckArray(panCardCheckedArray);
  };

  submitComment = async comment => {
    const caseId = this.props.caseId;
    const userId = this.props.userId;
    const body = {
      case: caseId,
      user: userId,
      type: "Action",
      re_text: comment
    };
    try {
      const res = await api.post(CASE_HISTORY, body);
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { panImage, nextDisabled, panCardCheckedArray, CaseDetails, clientConfig } = this.props;
    const { panCardDetailsArray, enlarge } = this.state;
    const { show_case_details } = clientConfig
    return (
      <div className="border rounded p-4 bg-f9">
        <h6 className="txt-light-blue dashed-title mb-3 ltr-spc-1">
          PAN Verification
        </h6>
        {show_case_details ? <> {CaseDetails}</> : null}
        <div className="ml-5 mr-5 p-2 text-center bg-e1 rounded mb-3">
          <img src={panImage}
            onMouseEnter={() => this.setState({ enlarge: true })}
            onMouseLeave={() => this.setState({ enlarge: false })}
            className="img-fluid rounded" alt="" />

          {
            enlarge &&
            <img
              src={panImage}
              className="rounded border doc-enlarge"
              alt=""
            />
          }
        </div>
        {this.props.panData.document_upload_type === "normal_upload" ?
          panCardDetailsArray.map((panCardInput, key) => {
            return (
              <VerificationInput
                preInput={panCardInput.preInput}
                inputValueDefault={panCardInput.defaultValue}
                handleIsChecked={this.handleIsCheckedForPan}
                isTick={panCardCheckedArray ? panCardCheckedArray[key].isTick : false}
                isCancel={panCardCheckedArray ? panCardCheckedArray[key].isCancel : false}
                inputKey={key}
                readOnly={true}
              />
            );
          })
          :
          <div className="row mt-2">
            <div className="d-flex flex-fill">
              <p className="txt-70 ml-2">Verified</p>
              <div className="col d-flex justify-content-end align-items-center">
                <div className="custom-radio d-flex justify-content-between">
                  <input
                    type="radio"
                    style={{ appearance: "none" }}
                    defaultChecked={panCardCheckedArray[0].isTick === true}
                    onChange={e =>
                      this.handleIsCheckedForPan(
                        { isTick: true, isCancel: false },
                        0
                      )
                    }
                    name="dob"
                    id="dobTrue"
                  />
                  <label for="dobTrue" className="mr-2"></label>

                  <input
                    type="radio"
                    name="dob"
                    id="dobFalse"
                    defaultChecked={panCardCheckedArray[0].isCancel === true}
                    style={{ appearance: "none" }}
                    onChange={e =>
                      this.handleIsCheckedForPan(
                        { isTick: false, isCancel: true },
                        0
                      )
                    }
                  />
                  <label for="dobFalse" className="cancel-icon"></label>
                </div>
              </div>
            </div>
          </div>
        }

        {!nextDisabled && (
          <div className="success-msg d-flex align-items-center mt-2">
            <span className="tick-icon-green-sml mr-1"></span>
            <span className="txt-green fnt-sml">
              <i>PAN card has been successfully verified</i>
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default PanCardDetails;
