import { PAN_CHECK, EMAIL_CHECK, MOBILE_CHECK, NAME_CHECK, NUM_CHECK } from '../constants'

export const JSONForm  = [
        {
            "label": "Insurance Application Number",
            "name": "application_number",
            "isValid": false,
            "value": "",
            "check": null,
            "isRequired": true,
            "placeholder": "Enter Policy number",
            "error": "",
            "type": "text"
        },
        {
            "label": "Name",
            "name": "customer_name",
            "isValid": false,
            "value": "",
            "check": NAME_CHECK,
            "isRequired": true,
            "placeholder": "John",
            "error": "",
            "type": "text"
        },
        {
            "label": "Mobile Number",
            "name": "mobile_number",
            "isValid": false,
            "value": "",
            "check": MOBILE_CHECK,
            "isRequired": true,
            "placeholder": "000-0000-000",
            "error": "",
            "type": "text",
            "maxLength": 10
        },
        {
            "label": "Email",
            "name": "email",
            "isValid": false,
            "value": "",
            "check": EMAIL_CHECK,
            "isRequired": true,
            "placeholder": "abc@example.com",
            "error": "",
            "type": "text"
        },
        {
            "label": "Nominee Name",
            "name": "nominee_name",
            "isValid": false,
            "value": "",
            "check": NAME_CHECK,
            "isRequired": false,
            "placeholder": "Enter nominee name",
            "error": "",
            "type": "text"
        },
        {
            "label": "Product Name",
            "name": "product_name",
            "isValid": false,
            "value": "",
            "check": NAME_CHECK,
            "isRequired": false,
            "placeholder": "Enter Product name",
            "error": "",
            "type": "text"
        },
        {
            "label": "Product Option",
            "name": "product_option",
            "isValid": false,
            "value": "",
            "check": NAME_CHECK,
            "isRequired": false,
            "placeholder": "Enter Product Option",
            "error": "",
            "type": "text"
        },
        {
            "label": "Product Category",
            "name": "product_category",
            "isValid": false,
            "value": "",
            "check": NAME_CHECK,
            "isRequired": false,
            "placeholder": "Enter Product Option",
            "error": "",
            "type": "text"
        },
        {
            "label": "Total Premium Amount",
            "name": "total_premium_amount",
            "isValid": false,
            "value": "",
            "check": NUM_CHECK,
            "isRequired": false,
            "placeholder": "Enter Product Option",
            "error": "",
            "type": "text"
        },
        {
            "label": "Policy Term",
            "name": "policy_term",
            "isValid": false,
            "value": "",
            "check": NUM_CHECK,
            "isRequired": false,
            "placeholder": "Enter Product Option",
            "error": "",
            "type": "text"
        },
        {
            "label": "Premium Frequency",
            "name": "premium_frequency",
            "isValid": false,
            "value": "",
            "check": NUM_CHECK,
            "isRequired": false,
            "placeholder": "Enter Product Option",
            "error": "",
            "type": "text"
        },
        {
            "label": "Premium Term",
            "name": "premium_term",
            "isValid": false,
            "value": "",
            "check": NUM_CHECK,
            "isRequired": false,
            "placeholder": "Policy Term",
            "error": "",
            "type": "text"
        },
        {
            "label": "Maturity Benefit/Sum Assured",
            "name": "maturity_benefit_or_sum_assured",
            "isValid": false,
            "value": "",
            "check": NUM_CHECK,
            "isRequired": false,
            "placeholder": "Sum Assured",
            "error": "",
            "type": "text"
        },
        {
            "label": "Annualized Premium",
            "name": "annualized_premium",
            "isValid": false,
            "value": "",
            "check": NUM_CHECK,
            "isRequired": false,
            "placeholder": "Annualized Premium",
            "error": "",
            "type": "text"
        }
    ]

    
