import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { varify } from "../../state/user.actions";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import UserHeader from "../../userHeader/userHeader";
import { useHistory } from "react-router";
import { Button } from "@material-ui/core";
import VerificationInputAadhaar from "./VerificationInputAadhaar";
import "../verifyPan/VerifyPan.scss";
import Loader from "../../../components/loader/Loader";

class VerifyBack extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verifiedBack: {
        Address: "",
      },
      nextDisabled: true,
    };
  }
  verifyDetails = ({ type, data }) => {
    let verifiedBack = this.state.verifiedBack;
    verifiedBack[type] = data;
    let nextDisabled = false;
    for (var i in verifiedBack) {
      if (verifiedBack[i] === "") {
        nextDisabled = true;
      }
    }
    this.setState({
      verifiedBack: verifiedBack,
      nextDisabled: nextDisabled,
    });
  };

  handleNext = () => {
    this.props.handleBackVerified(this.state.verifiedBack);
  };
  render() {
    const { userInfo } = this.props;
    const { nextDisabled } = this.state;
    return (
      <React.Fragment>
        {/* {loading && <Loader title={"Validating your details, please wait"} />} */}
        {userInfo && userInfo.aadharData && (
          <div className="wrapper light-blue-customer-template">
            <UserHeader />
            <section className="main-body m-auto customer-flow-section">
              <div className="container-fluid mt-4">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="txt-light-blue ltr-spc-1">
                      Aadhaar Back Upload
                    </h4>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="border border-1 dashed-brdr  rounded p-4 m mb-2">
                      <img
                        src={userInfo.aadharData.image.back.data}
                        alt=""
                        className="img-fluid rounded"
                      />
                      {userInfo.aadharData.document_upload_type ===
                        "ocr_upload" && (
                        <React.Fragment>
                          <div className="txt-81 fnt-sml mt-2 ltr-spc-1 mt-3 mb-3">
                            Information Extracted from Image
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-12">
                              <VerificationInputAadhaar
                                preInput="Address"
                                onChange={this.verifyDetails}
                                defaultValue={
                                  userInfo.aadharData.data
                                    ? userInfo.aadharData.data.address
                                    : ""
                                }
                                width={"100%"}
                                verificationKey={0}
                                readOnly={false}
                                digilockerData={false}
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-3 mb-3">
                  <div className="col-md-12  d-flex justify-content-between">
                    <button
                      onClick={() => this.props.updateFrontVerified(false)}
                      className="btn btn-custom btn-dark btn-light-black w-100-p"
                      style={{ visibility: "hidden" }}
                    >
                      Back
                    </button>
                    <button
                      disabled={nextDisabled}
                      onClick={this.handleNext}
                      className="btn btn-custom btn-light-blue w-100-p"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userDocs.userDocInfo,
});

const mapDispatchToProps = (dispatch) => ({
  varify: (payload) => dispatch(varify(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyBack);
