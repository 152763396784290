import React, { Component } from "react";
import { connect } from "react-redux";
import { upload } from "../../state/user.actions";
import AadhaarPaperlessUpload from "./AadhaarPaperlessUpload";
import AadhaarPaperlessOptions from "./AadhaarPaperlessOptions";
import AadhaarQRScan from "./AadhaarQRScan";
import AadhaarBrowserUpload from "./AadhaarBrowseUpload";

class AadhaarUploadOptions extends Component {
  state = {
    takeAadhaarPaperless: false,
    scanAadhaar: false,
    locationAccess: true,
    uploadAadhaarViaBrowse: false,
    uploadTypes: {ocr_upload:false,digilocker_upload:false,paperless_upload:false, qr_scan:false}
  };

  componentDidMount() {
    this.checkLocation();
    this.setUploadTypes()
  }
  setUploadTypes(){
    let documents = this.props?.userConnectionInfo?.case_details?.process_details?.documents
    if(documents){
      let clientTypes = documents.filter(item=>item.doc_key === "aadhar")
       this.setState({uploadTypes:clientTypes[0]})
    }
  }

  checkLocation() {
    navigator.geolocation.getCurrentPosition(
      async function (position) {
        console.log("done");
      },
      (e) => {
        this.setState({ locationAccess: false });
      },
      { timeout: 10000 }
    );
  }

  setAadhaarPaperless = (takeAadhaarPaperless) => {
    this.setState({ takeAadhaarPaperless });
  };

  setScanAadhaar = (scanAadhaar) => {
    this.setState({ scanAadhaar });
  };

  redirectToVerify = () => {
    this.props.redirectToVerify();
  };

  uploadViaBrowse = () => {
    this.setState({ uploadAadhaarViaBrowse: true });
  };
  render() {
    const {
      takeAadhaarPaperless,
      scanAadhaar,
      locationAccess,
      uploadAadhaarViaBrowse,
    } = this.state;
    console.log('this.state', this.props)
    

    return (
      <React.Fragment>
        {!locationAccess && (
          <div className="location-error">
            Location access blocked, allow access to device location to continue
            with your KYC process
          </div>
        )}
        {takeAadhaarPaperless ? (
          <AadhaarPaperlessUpload
            docId={this.props.docId}
            redirectToVerify={this.redirectToVerify}
          />
        ) : scanAadhaar ? (
          <AadhaarQRScan setAadhaarPaperless={this.setAadhaarPaperless} />
        ) : uploadAadhaarViaBrowse ? (
          <AadhaarBrowserUpload
            uploadFileType={this.props.uploadFileType}
            isAadhaar={this.props.isAadhaar}
            callDigilockerAPI={this.props.callDigilockerAPI}
            takePhotoBrowseUploadAadhaar={
              this.props.takePhotoBrowseUploadAadhaar
            }
            aadharData={this.props.aadharData}
            setPhotoSideBrowseAadhaar={this.props.setPhotoSideBrowseAadhaar}
            documentName={this.props.documentName}
            takePhoto={this.props.takePhoto}
            takeAadhaarPhoto={this.props.takeAadhaarPhoto}
            uploadFile={this.props.uploadFile}
            handleAadhaar={this.props.handleAadhaar}
            handleTakePhoto={this.props.handleTakePhoto}
          />
        ) : (
          <AadhaarPaperlessOptions
            setAadhaarPaperless={this.setAadhaarPaperless}
            setScanAadhaar={this.setScanAadhaar}
            callDigilockerAPI={this.props.callDigilockerAPI}
            uploadViaBrowse={this.uploadViaBrowse}
            uploadTypes = {this.state.uploadTypes}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userDocs.userDocInfo,
  userConnectionInfo: state.userDocs.anonymousUserConnectionInfo,
});

const mapDispatchToProps = (dispatch) => ({
  upload: (payload) => dispatch(upload(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AadhaarUploadOptions);
