import React from "react";
import Moment from "react-moment";
import { connect } from "react-redux"

class AgentCompletedCases extends React.Component {
  render() {
    const { activeUpperTab, casesDataArray, searchKeyObject, clientInfo } = this.props;
    const { has_partners } = clientInfo

    return (
      <div className="tab-pane show caseContainer active" id="completed">
        <div className="row mt-2">
          <div className="col-md-12">
            <table className="table sml-blue-header mb-0">
              <thead>
                <tr>
                  <th width="3%">
                  </th>
                  <th width="5%">Photo</th>
                  <th width="10%">Meeting Id</th>
                  <th width="13%">{activeUpperTab === 1 ? 'Client name' : 'Customer Name'}</th>
                  {has_partners ? <th width="10%">Partner</th> : null}
                  <th width="15%">
                    Completed Date
                            </th>
                  <th width="8%">Process</th>
                  <th width="8%">Status</th>
                  <th width="10%"
                    style={{
                      display: activeUpperTab === 1 ?
                        'none' : ''
                    }}
                  >Documents List</th>
                  <th width="10%">
                    Agent's Comments
                            </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        {Object.keys(searchKeyObject).length !== 0 && 
          casesDataArray.length === 0 &&
          <div style={{textAlign: 'center', marginTop: '50px'}}>
            <h4>No case found</h4>
          </div>
          }
        {casesDataArray.length > 0 && casesDataArray.map((selectedCase, index) => (
          <div className="row" key={index}>
            <div className="col-md-12">
              <div className="tbl-row">
                <table className="table mb-0 sml-blue-header">
                  <tbody>
                    <tr>
                      <td width="3%">
                      </td>
                      <td width="5%">
                        <span className="user-blue-circle rounded-circle">
                          {selectedCase.name ? selectedCase.name.charAt(0) : ""}
                        </span>
                      </td>
                      <td width="10%">{selectedCase.id}</td>
                      <td width="13%">{selectedCase.name}</td>
                      {has_partners ? <td width="10%">{selectedCase?.partner_name}</td> : null}
                      <td width="15%">
                        <span className="txt-light-blue font-italic">
                          <Moment format="DD MMM YYYY LT">
                            {
                              selectedCase.completed_at
                            }
                          </Moment>
                        </span>
                      </td>
                      <td width="8%">
                        {selectedCase.process_details ?
                          selectedCase.process_details.name :
                          ""
                        }
                      </td>
                      <td width="8%"
                        style={{
                          color: selectedCase.status === "rejected" ?
                            'red' : (selectedCase.status === "audited" ? 'green' : '#E6B01A')
                        }}
                      >
                        {selectedCase ?
                          (selectedCase.status === 'completed' ?
                            'Pending with Auditor' :
                            (selectedCase.status === 'audited' ?
                              'Approved' : 'Rejected')
                          )
                          : ''}
                      </td>
                      <td width="10%"
                        style={{
                          display: activeUpperTab === 1 ?
                            'none' : ''
                        }}
                      >
                        <span className="txt-light-blue font-italic"
                          onClick={() =>
                            this.props.handleViewDocuments(
                              selectedCase
                            )
                          }
                        ><u style={{ cursor: 'pointer' }}>Document list</u></span>
                      </td>
                      <td width="10%" className="text-break">
                        <div className="font-italic">
                          {
                            selectedCase.case_history.filter(history => history.type === "comment").length !== 0 ?
                              selectedCase.case_history.filter(history => history.type === "comment")[selectedCase.case_history.filter(history => history.type === "comment").length - 1].re_text :
                              ''
                          }
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ))}

      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  clientInfo: state.login.clientInfo
});
export default connect(mapStateToProps)(AgentCompletedCases);