import React from 'react'
import GreenTick from "../../assets/icons/green-tick.png";
import CloseIcon from "../../assets/icons/close-icon.png";


export default function UniqueCode({reportData,step}) {
  return (
    <table
              width="100%"
              style={{ border: "1px solid #b1b1b1" }}
              className="m-auto bg-white"
            >
              <tr>
                <td style={{ padding: "0px" }}>
                  <table width="100%">
                    <tr>
                      <td style={{ padding: "15px 15px 15px 15px" }}>
                        <h4>{step}. Unique Code Verification</h4>
                        {reportData["unique code"] &&
                          reportData["unique code"].unique_code && (
                            <table
                              className="gray-table-rows bg-f8"
                              width="100%"
                              style={{ marginTop: "15px" }}
                            >
                              <tr>
                                <td width="30%" style={{ paddingRight: "0px" }}>
                                  Unique Code
                                </td>
                                <td width="1%" align="center">
                                  :
                                </td>
                                <td width="40%">
                                  {reportData["unique code"].unique_code}
                                </td>
                                <td
                                  align="right"
                                  width="19%"
                                  style={{ padding: "0px" }}
                                >
                                  <table width="100%">
                                    <tr>
                                      <td
                                        width="30"
                                        style={{
                                          paddingRight: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        <img
                                          src={
                                            reportData["unique code"]
                                              .is_unique_code_verified
                                              ? GreenTick
                                              : CloseIcon
                                          }
                                          width="20"
                                          alt=""
                                        />
                                      </td>
                                      <td
                                        style={{
                                          paddingRight: "10px",
                                          paddingLeft: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontStyle: "italic",
                                            color: reportData["unique code"]
                                              .is_unique_code_verified
                                              ? "#24a749"
                                              : "red",
                                          }}
                                        >
                                          {reportData["unique code"]
                                            .is_unique_code_verified
                                            ? "Verified"
                                            : "Not verified"}
                                        </span>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          )}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
  )
}
