import React from "react";
import { ReactComponent as VKYCIcon } from '../../../../../assets/icons/VKYC.svg';
import { ReactComponent as GroupCallIcon } from '../../../../../assets/icons/group-call.svg';
import { ReactComponent as LiveCallsIcon } from '../../../../../assets/icons/live-calls.svg';

class UpperTab extends React.Component {
  render() {
    const { tabKey, activeUpperTab, upperTabText } = this.props;
    return (
      <li className='nav-item'
        onClick={() => this.props.changeActiveUpperTab(tabKey)}
      >
        <span className={tabKey === activeUpperTab ? "nav-link active" : "nav-link"} id="vkyc" href="#vkyc">
          <span className="d-flex p-3  align-items-center text-white mb-3">
            <span className="calendar-icon">
              {tabKey === 0 ? <VKYCIcon /> : (tabKey === 1 ? <GroupCallIcon /> : <LiveCallsIcon />)}
            </span>
            <span className="tab-text ltr-spc-1 ml-2">{upperTabText}</span>
          </span>
        </span>
      </li>
    )
  }
}

export default UpperTab;