import React from "react";
import AadhaarConfirmation from "../aadhaarConfirmation/AadhaarConfirmation";
import VerifyAadhaar from "../verifyAadhaar/VerifyAadhaar";
import { connect } from "react-redux";
import { uploadAadhar } from "../../state/user.actions";

class AadhaarVerificationMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aadhaarUploaded: false,
      otp: ""
    };
  }


  componentDidUpdate() {
    // if(this.props.userInfo.aadharUploaded && this.state.aadhaarUploaded) {
       this.props.uploadAadhar({})
      if(this.state.aadhaarUploaded) {
      this.props.history.push("../../user");
    }
  }
    handleAadhaarUpload = ({aadhaarUploaded, otp}) => {
        this.setState({aadhaarUploaded, otp});
        // this.props.uploadAadhar({code: otp, data:this.props.location.state})
        // this.props.uploadAadhar({data:this.props.location.state})
        // setTimeout(()=> {
        //     this.props.history.push('../../user');
        // },2000)
    }
    render(){
        const { aadhaarUploaded } = this.state;
        return(
            <React.Fragment>
                {/* {aadhaarUploaded ? <AadhaarConfirmation/> : 
                <VerifyAadhaar 
                IsAadhaarUploaded={this.handleAadhaarUpload}/>
                } */}
        <AadhaarConfirmation IsAadhaarUploaded={this.handleAadhaarUpload} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.userDocs.userDocInfo
});

const mapDispatchToProps = dispatch => ({
  uploadAadhar: payload => dispatch(uploadAadhar(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AadhaarVerificationMain);
