import React, { useEffect, useState } from 'react'
import UserHeader from '../../userHeader/userHeader'
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { updateClientConfig, updateClientName } from "../../../login/state/login.actions";
import { instance2 } from "../../../apiBaseConfig";
import Loader from '../../../components/loader/Loader';
import _get from "lodash/get";

import cogoToast from 'cogo-toast';

export default function LiveWithSchedule() {
    const { userToken } = useParams();
    const dispatch = useDispatch()
    const [showLoader, setLoader] = useState(true);
    
    useEffect(() => {
      getClientDetails()
    }, [])


    
    async function startCall(){
        let config = {
            headers: {
              AuthorizationX: `Token ${userToken}`,
            },
          };
        let res = await instance2.post('/case/live/schedule-with-live/',{},config)
        if(res && res.status === 200){
            cogoToast.info(res.data.detail)
            let redirectUrl = window.location.origin
            window.location.href = redirectUrl + '/user/live/' + userToken
        }

    }

    async function getClientDetails(){
        setLoader(true)
        const response = await instance2.get(
          `/client/clients/`,
          {
            headers: {
              AuthorizationX: `Token ${userToken}`,
            },
          }
        );
        if(response){
        let payload = {}
        payload.name = response.data[0].name
        dispatch(updateClientName(payload))
        getConfig(response.data[0].id)
        setLoader(false)}
      
      }
     async function getConfig(id){
      setLoader(true)
      const response = await instance2.get(
        `/client/clientconfig/`,
        {
          headers: {
            AuthorizationX: `Token ${userToken}`,
          },
        }
      );
      if(response){
        setLoader(false)
        dispatch(updateClientConfig(response.data[0]))
      }
     } 
    return (
        <>
            {showLoader ? <Loader /> :
                <div className="wrapper light-blue-customer-template">
                    <UserHeader />
                    <div className='d-flex justify-content-center' >
                       <button className='btn btn-success p-3 mt-5' onClick={e=>startCall()}>Start Call Now</button>
                    </div>
                </div>
            }
        </>
    )
}
