import React from 'react'
import VKYCLogo from "../../assets/icons/v-kyc-logo.png";
import VPDLogo from "../../assets/icons/vpdlogo.png";
function LogoWrapper({type,clientLogo}) {
  return (
    <div className="pt-4 pb-4">
    <table width="100%" className="m-auto">
      <tr>
        <td>
          <img src={type === 'personal_discussion' ? VPDLogo : VKYCLogo} alt="" width="130" />
        </td>
        <td align="right">
          <img  src={clientLogo} alt="" width="100" />
        </td>
      </tr>
    </table>
  </div>
  )
}

export default LogoWrapper