import React from "react";
import VerifyFront from "./VerifyFront";
import VerifyBack from "./VerifyBack";
import { connect } from "react-redux";
import { verifyDL } from "../../state/user.actions";
import Loader from "../../../components/loader/Loader";
import { UPDATEDOCUMENT } from "../../../apiurl";
import api from "../../../apiBaseConfig";

class VerifyDLOCR extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      frontVerified: false,
      verifiedDetails: {},
      loading: false,
    };
  }

  updateFrontVerified = (frontVerified, verifiedFront) => {
    let verifiedDetails = { ...verifiedFront };
    this.setState({
      frontVerified: frontVerified,
      verifiedDetails: verifiedDetails,
    });
  };

  handleBackVerified = (verifiedBack) => {
    const { userInfo } = this.props;
    let verifiedDetailsUpdated = {
      ...this.state.verifiedDetails,
      ...verifiedBack,
    };
    this.setState({ loading: true });
    const ocrData = {
      ...userInfo.aadharData.data,
      name: verifiedDetailsUpdated["Name"],
      gender: verifiedDetailsUpdated["Gender"],
      doc_id: verifiedDetailsUpdated["Aadhaar"],
      yob: verifiedDetailsUpdated["YOB"],
      address: verifiedDetailsUpdated["Address"],
    };
    this.verifyDLOCR(ocrData);
  };

  verifyDLOCR = async (ocrData) => {
    const { userInfo } = this.props;
    console.log("props.........", this.props);
    try {
      let resp = await api.patch(UPDATEDOCUMENT + `${userInfo.dlData.id}/`, {
        corrected_ocr: ocrData,
        is_verified: true,
      });
      this.props.history.push("/user");
    } catch (err) {
      console.log(err);
    }
    this.setState({ loading: false });
  };

  handleNextDigilocker = () => {
    this.verifyDLOCR(this.props.userInfo.dlData);
  };
  retry = () => {
    this.props.history.goBack();
  };

  render() {
    const { frontVerified, verifiedDetails, nextDisabled, loading } =
      this.state;
    return (
      <>
        {loading && <Loader title={"Validating your details, please wait"} />}
        {!frontVerified ? (
          <VerifyFront
            updateFrontVerified={this.updateFrontVerified}
            verifiedDetails={verifiedDetails}
            verifyDetails={this.verifyDetails}
            nextDisabled={nextDisabled}
            retry={this.retry}
            handleNextDigilocker={this.handleNextDigilocker}
          />
        ) : (
          <VerifyBack
            verifiedDetails={verifiedDetails}
            updateFrontVerified={this.updateFrontVerified}
            handleBackVerified={this.handleBackVerified}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userDocs.userDocInfo,
});

const mapDispatchToProps = (dispatch) => ({
  verifyDL: (payload) => dispatch(verifyDL(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyDLOCR);
