import React, { Component } from "react";
import USER_IMAGE from "../../assets/images/user-icon.png";
import api from "../../apiBaseConfig";
import { GET_DOCUMENTS } from "../../apiurl";
import _get from "lodash/get";
import _groupBy from "lodash/groupBy";
import RefreshIcon from "@material-ui/icons/Refresh";
import Loader from "../../components/loader/Loader";

export default class DocList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentArray: [],
    };
  }

  handleDocumentLinkClicked = (doc) => {
    if (doc.front_upload) {
      window.open(doc.front_upload, "_blank");
    }
    if (doc.back_upload) {
      window.open(doc.back_upload, "_blank");
    }

  };

  componentDidMount() {
    this.fetchDocumentList();
  }

  fetchDocumentList = async () => {
    try {
      this.setState({ isLoading: true });
      const response = await api.get(
        `${GET_DOCUMENTS}?case=${this.props.caseId}`
      );
      this.setState({ isLoading: false });
      const documentArray = response.data.data.filter(doc => !doc.document_details.is_agent_uploaded && doc.document_upload_event === "on_call" && doc.document_details.doc_type !==3);

      this.setState({ documentArray });
    } catch (error) {
      console.log("error", error);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { documentArray } = this.state;
    return (
      <div class="steps-box step2 mb-2" style={{position: "relative"}}>
        {this.state.isLoading && (
          <Loader title={"Please wait. Uploading documents"} fontSize={"small"} />
        )}

        <div className="d-flex justify-content-between align-items-center">
          <h5 class="txt-blue ltr-spc-1">Documents Uploaded</h5>
          <RefreshIcon onClick={() => this.fetchDocumentList()} />
        </div>
        <hr class="dashed-brdr brdr-blue"></hr>
        {documentArray.map((doc) => (
          <div class="border bg-white rounded brdr-eb p-2 cstm-shdo d-flex align-items-center mb-1">
            {/* <img src="img/Customer6.png" class="rounded-circle" width="40" height="40" alt=""></img> */}
            <div class="col">
              {/* <div class="fnt-sml-x txt-gray">CEO</div> */}
              <div class="fnt-8rem customer-name-blue">{doc.document_details.name_display || doc.document_details.name}</div>
            </div>
            <div
              class="col"
              onClick={() => this.handleDocumentLinkClicked(doc)}
            >
              <div class="fnt-8rem customer-name-blue">Download</div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
