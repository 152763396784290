import React from "react";
import VPDLogo from "../../assets/icons/vpdlogo.png";
import ABLogo from "../../assets/icons/ab-logo.png";
import GreenTick from "../../assets/icons/green-tick.png";
import GrayTick from "../../assets/icons/gray-tick.png";
import GreenTick2 from "../../assets/icons/green-tick-2.png";
import CloseIcon from "../../assets/icons/close-icon.png";
import api from "../../apiBaseConfig";
import { GENERATE_REPORT } from "../../apiurl";
import GeoTagging from "./GeoTagging";
import NationalIdentification from "./NationalIdentification";
import Screenshots from "./Screenshots";
import _ from "lodash";
import Loader from "../loader/Loader";
import Moment from "react-moment";
import html2pdf from "html2pdf.js";

export const auditStatus = {
  completed: {
    status: "Pending with Auditor",
    color: "grey",
    tick: GrayTick,
  },
  audited: {
    status: "Approved",
    color: "#24a749",
    tick: GreenTick2,
  },
  rejected: {
    status: "Rejected",
    color: "red",
    tick: CloseIcon,
  },
};
class PDReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportData: {},
      isLoading: true,
    };
  }
  componentDidMount() {
    this.generateReport(this.props.match.params.id);
  }

  generateReport = async (caseId) => {
    try {
      let resp = await api.get(GENERATE_REPORT + caseId);
      let reportData = _.cloneDeep(resp.data.report_data);
      this.setState({ reportData: reportData, isLoading: false });
      console.log("reportdat", reportData);
    } catch (err) {
      console.log(err);
    }
  };
  printReport = () => {
    const element = document.getElementById("divToPrint");
    const opt = {
      margin: 0,
      filename: `pd-report-${this.props.match.params.id}.pdf`,
      html2canvas: { dpi: 300, letterRendering: true, useCORS: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().from(element).set(opt).save();
  };
  render() {
    const { isLoading, reportData } = this.state;
    return (
      <>
        {isLoading ? (
          <Loader title="Generating Report. Please wait" />
        ) : (
          <div
            className="container preview-container pt-4 pb-2 mb-4 position-relative"
            id="divToPrint"
          >
            <div>
              <button
                className="btn btn-primary download-btn-positioned"
                onClick={this.printReport}
              >
                Download PDF
              </button>
              <table width="100%" className="m-auto">
                <tr>
                  <td>
                    <img src={VPDLogo} alt="" width="130" />
                  </td>
                  <td align="right">
                    <img src={ABLogo} alt="" width="150" />
                  </td>
                </tr>
              </table>
            </div>
            <div className="mt-4"></div>
            <table width="100%" className="m-auto mt-4 bg-ef">
              <tr>
                <td style={{ padding: "0" }}>
                  <table width="100%">
                    <tr>
                      <td style={{ padding: "15px" }}>
                        <h4>Call Details</h4>
                        <table
                          className="white-table-rows"
                          width="100%"
                          style={{ marginTop: "15px", backgroundColor: "#fff" }}
                        >
                          <tr>
                            <td width="20%" style={{ paddingRight: "0px" }}>
                              Conference ID
                            </td>
                            <td width="1%" align="center">
                              :
                            </td>
                            <td width="79%">{reportData.case.id}</td>
                          </tr>
                        </table>
                        <table
                          className="white-table-rows"
                          width="100%"
                          style={{ marginTop: "8px", backgroundColor: "#fff" }}
                        >
                          <tr>
                            <td width="20%" style={{ paddingRight: "0px" }}>
                              Agent ID
                            </td>
                            <td width="1%" align="center">
                              :
                            </td>
                            <td width="79%">{reportData.case.agent.id}</td>
                          </tr>
                        </table>

                        <table width="100%" style={{ marginTop: "8px" }}>
                          <tr>
                            <td
                              width="365"
                              style={{ padding: "0px 10px 0px 0px" }}
                            >
                              <table
                                className="white-table-rows"
                                width="365"
                                style={{ backgroundColor: "#fff" }}
                              >
                                <tr>
                                  <td
                                    width="135"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    Call start time
                                  </td>
                                  <td width="10" align="center">
                                    :
                                  </td>
                                  <td width="175">
                                    <Moment format="DD MMM YYYY LT">
                                      {reportData.case.assigned_at}
                                    </Moment>
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td
                              width="365"
                              style={{ padding: "0px 0px 0px 10px" }}
                            >
                              <table
                                className="white-table-rows"
                                width="365"
                                style={{ backgroundColor: "#fff" }}
                              >
                                <tr>
                                  <td
                                    width="135"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    Call end time
                                  </td>
                                  <td width="10" align="center">
                                    :
                                  </td>
                                  <td width="175">
                                    <Moment format="DD MMM YYYY LT">
                                      {reportData.case.completed_at}
                                    </Moment>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table
              width="100%"
              className="m-auto bg-white"
              style={{ border: "1px solid #b1b1b1", borderTop: "0px" }}
            >
              <tr>
                <td style={{ padding: "0" }}>
                  <table width="100%">
                    <tr>
                      <td style={{ padding: "15px 15px 15px 15px" }}>
                        <h4>Case Details</h4>
                        <table width="100%" style={{ marginTop: "15px" }}>
                          <tr>
                            <td
                              width="365"
                              style={{ padding: "0px 10px 0px 0px" }}
                            >
                              <table
                                className="gray-table-rows"
                                width="365"
                                style={{ backgroundColor: "#f8f8f8" }}
                              >
                                <tr>
                                  <td
                                    width="125"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    PD Assigned on
                                  </td>
                                  <td
                                    width="10"
                                    align="center"
                                    style={{
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    :
                                  </td>
                                  <td width="185">
                                    <Moment format="DD MMM YYYY LT">
                                      {reportData.case.assigned_at}
                                    </Moment>
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td
                              width="365"
                              style={{ padding: "0px 0px 0px 10px" }}
                            >
                              <table
                                className="gray-table-rows"
                                width="365"
                                style={{ backgroundColor: "#f8f8f8" }}
                              >
                                <tr>
                                  <td
                                    width="125"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    PD Scheduled on
                                  </td>
                                  <td width="10" align="center">
                                    :
                                  </td>
                                  <td width="185">
                                    {reportData.session.created_at ? (
                                      <Moment format="DD MMM YYYY LT">
                                        {reportData.session.created_at}
                                      </Moment>
                                    ) : (
                                      "N.A."
                                    )}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <table width="100%" style={{ marginTop: "10px" }}>
                          <tr>
                            <td
                              width="365"
                              style={{ padding: "0px 10px 0px 0px" }}
                            >
                              <table
                                className="gray-table-rows"
                                width="365"
                                style={{ backgroundColor: "#f8f8f8" }}
                              >
                                <tr>
                                  <td
                                    width="125"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    PD Completed on
                                  </td>
                                  <td
                                    width="10"
                                    align="center"
                                    style={{
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    :
                                  </td>
                                  <td width="185">
                                    <Moment format="DD MMM YYYY LT">
                                      {reportData.case.completed_at}
                                    </Moment>
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td
                              width="365"
                              style={{ padding: "0px 0px 0px 10px" }}
                            >
                              <table
                                className="gray-table-rows"
                                width="365"
                                style={{ backgroundColor: "#f8f8f8" }}
                              >
                                <tr>
                                  <td
                                    width="125"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    Case Name
                                  </td>
                                  <td width="10" align="center">
                                    :
                                  </td>
                                  <td width="185">{reportData.case.name}</td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <table width="100%" style={{ marginTop: "8px" }}>
                          <tr>
                            <td
                              width="365"
                              style={{ padding: "0px 10px 0px 0px" }}
                            >
                              <table
                                className="gray-table-rows"
                                width="365"
                                style={{ backgroundColor: "#f8f8f8" }}
                              >
                                <tr>
                                  <td
                                    width="125"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    Process Type
                                  </td>
                                  <td
                                    width="10"
                                    align="center"
                                    style={{
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    :
                                  </td>
                                  <td width="185">{reportData.case.process}</td>
                                </tr>
                              </table>
                            </td>
                            <td
                              width="365"
                              style={{ padding: "0px 0px 0px 10px" }}
                            >
                              <table
                                className="green-table-rows"
                                width="365"
                                style={{ backgroundColor: "#ddf2e3" }}
                              >
                                <tr>
                                  <td
                                    width="125"
                                    style={{
                                      paddingRight: "0px",
                                      color: "#24a749",
                                    }}
                                  >
                                    Case Status
                                  </td>
                                  <td width="10" align="center">
                                    :
                                  </td>
                                  <td width="185" style={{ padding: "0" }}>
                                    <table width="100%">
                                      <tr>
                                        <td
                                          width="5"
                                          style={{
                                            padding: "0px",
                                            paddingRight: "0px",
                                          }}
                                        >
                                          {/* <img
                                            src={GreenTick2}
                                            width="20"
                                            alt=""
                                          /> */}
                                        </td>
                                        <td
                                          style={{
                                            padding: "0px",
                                            paddingLeft: "10px",
                                          }}
                                        >
                                          <span style={{ color: "#24a749" }}>
                                            {reportData.case.status}
                                          </span>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <hr
                          style={{
                            borderTop: "1px dashed #707070 !important",
                            borderBottom: "0px",
                            marginTop: "20px",
                            marginBottom: "20px",
                            width: "100%",
                          }}
                        />
                        <h4>SPOC Details</h4>
                        <table width="100%" style={{ marginTop: "15px" }}>
                          <tr>
                            <td
                              width="365"
                              style={{ padding: "0px 10px 0px 0px" }}
                            >
                              <table
                                className="gray-table-rows"
                                width="365"
                                style={{ backgroundColor: "#f8f8f8" }}
                              >
                                <tr>
                                  <td
                                    width="125"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    Full Name
                                  </td>
                                  <td
                                    width="10"
                                    align="center"
                                    style={{
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    :
                                  </td>
                                  <td width="185">
                                    {reportData.case.connections[0].name}
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td
                              width="365"
                              style={{ padding: "0px 0px 0px 10px" }}
                            >
                              <table
                                className="gray-table-rows"
                                width="365"
                                style={{ backgroundColor: "#f8f8f8" }}
                              >
                                <tr>
                                  <td
                                    width="135"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    Mobile Number
                                  </td>
                                  <td width="10" align="center">
                                    :
                                  </td>
                                  <td width="185">
                                    {
                                      reportData.case.connections[0]
                                        .mobile_number
                                    }
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <table
                          className="gray-table-rows"
                          width="100%"
                          style={{
                            marginTop: "10px",
                            backgroundColor: "#f8f8f8",
                          }}
                        >
                          <tr>
                            <td
                              width="100%"
                              style={{ padding: "0px 10px 0px 0px" }}
                            >
                              <tr>
                                <td width="135" style={{ paddingRight: "0px" }}>
                                  Email Address
                                </td>
                                <td width="10">:</td>
                                <td width="185">
                                  {reportData.case.connections[0].email}
                                </td>
                              </tr>
                            </td>
                          </tr>
                        </table>
                        <hr
                          style={{
                            borderTop: "1px dashed #707070 !important",
                            borderBottom: "0px",
                            marginTop: "20px",
                            marginBottom: "20px",
                            width: "100%",
                          }}
                        />
                        <h4>Agent Details</h4>
                        <table width="100%" style={{ marginTop: "15px" }}>
                          <tr>
                            <td
                              width="365"
                              style={{ padding: "0px 10px 0px 0px" }}
                            >
                              <table
                                className="gray-table-rows"
                                width="365"
                                style={{ backgroundColor: "#f8f8f8" }}
                              >
                                <tr>
                                  <td
                                    width="125"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    Full Name
                                  </td>
                                  <td
                                    width="10"
                                    align="center"
                                    style={{
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    :
                                  </td>
                                  <td width="185">
                                    {reportData.case.agent.full_name}
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td
                              width="365"
                              style={{ padding: "0px 0px 0px 10px" }}
                            >
                              <table
                                className="gray-table-rows"
                                width="365"
                                style={{ backgroundColor: "#f8f8f8" }}
                              >
                                <tr>
                                  <td
                                    width="135"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    Identification Number
                                  </td>
                                  <td width="10" align="center">
                                    :
                                  </td>
                                  <td width="185">
                                    {reportData.case.agent.id}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <table width="100%" style={{ marginTop: "8px" }}>
                          <tr>
                            <td
                              width="365"
                              style={{ padding: "0px 10px 0px 0px" }}
                            >
                              <table
                                className="gray-table-rows"
                                width="365"
                                style={{ backgroundColor: "#f8f8f8" }}
                              >
                                <tr>
                                  <td
                                    width="125"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    Mobile Number
                                  </td>
                                  <td
                                    width="10"
                                    align="center"
                                    style={{
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    :
                                  </td>
                                  <td width="185">
                                    {reportData.case.agent.mobile_number}
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td
                              width="365"
                              style={{ padding: "0px 0px 0px 10px" }}
                            >
                              <table
                                className="gray-table-rows"
                                width="365"
                                style={{ backgroundColor: "#f8f8f8" }}
                              >
                                <tr>
                                  <td
                                    width="135"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    Email Address
                                  </td>
                                  <td width="10" align="center">
                                    :
                                  </td>
                                  <td width="185">
                                    {reportData.case.agent.email}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <div className="html2pdf__page-break"></div>
            <div className="pt-4 pb-4">
              <table width="100%" className="m-auto">
                <tr>
                  <td>
                    <img src={VPDLogo} alt="" width="130" />
                  </td>
                  <td align="right">
                    <img src={ABLogo} alt="" width="150" />
                  </td>
                </tr>
              </table>
            </div>
            <table width="100%" className="bg-ef">
              <tr>
                <td style={{ padding: "0" }}>
                  <table width="100%">
                    <tr>
                      <td style={{ padding: "15px" }}>
                        <h4>
                          Verification Step 1: National Identification Document
                          Upload
                        </h4>
                        {reportData.case.connections.map(
                          (connection, index) => {
                            return (
                              <NationalIdentification
                                connection={connection}
                                key={index}
                              />
                            );
                          }
                        )}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table
              width="100%"
              style={{
                backgroundColor: "#fff",
                border: "1px solid #b1b1b1",
                borderTop: "0",
              }}
            >
              <tr>
                <td style={{ padding: "0" }}>
                  <table width="100%">
                    <tr>
                      <td style={{ padding: "15px 15px 15px 15px" }}>
                        <h4>VERIFICATION STEP 2: RANDOM QUESTIONNAIRE</h4>
                        {reportData.case.connections[0].answers &&
                          reportData.case.connections[0].answers.map(
                            (questionarrie, index) => {
                              return (
                                <>
                                  <div
                                    className={
                                      (index + 1) % 12 === 0
                                        ? "html2pdf__page-break"
                                        : ""
                                    }
                                  ></div>
                                  <table
                                    className="gray-table-rows"
                                    width="100%"
                                    style={{
                                      marginTop: "15px",
                                      backgroundColor: "#f8f8f8",
                                    }}
                                    key={index}
                                  >
                                    <tr>
                                      <td
                                        width="320"
                                        style={{ paddingRight: "0px" }}
                                      >
                                        {questionarrie.question}
                                      </td>
                                      <td
                                        width="10"
                                        align="center"
                                        style={{ padding: "0" }}
                                      >
                                        :
                                      </td>
                                      <td>{questionarrie.answer}</td>
                                      <td
                                        align="right"
                                        width="5%"
                                        style={{ padding: "0" }}
                                      >
                                        <table width="100%">
                                          <tr>
                                            <td
                                              width="30"
                                              style={{
                                                padding: "0px",
                                                paddingRight: "0px",
                                              }}
                                            >
                                              <img
                                                src={GreenTick}
                                                width="20"
                                                alt=""
                                              />
                                            </td>
                                            <td
                                              style={{
                                                padding: "0px",
                                                paddingLeft: "0px",
                                                paddingRight: "10px",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  color: "#24a749",
                                                  fontStyle: "italic",
                                                }}
                                              >
                                                Verified
                                              </span>
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </>
                              );
                            }
                          )}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <div className="html2pdf__page-break"></div>
            <div className="pt-4 pb-4">
              <table width="100%">
                <tr>
                  <td>
                    <img src={VPDLogo} alt="" width="130" />
                  </td>
                  <td align="right">
                    <img src={ABLogo} alt="" width="150" />
                  </td>
                </tr>
              </table>
            </div>
            <table
              width="100%"
              style={{ backgroundColor: "#fff", border: "1px solid #b1b1b1" }}
            >
              <tr>
                <td style={{ padding: "0" }}>
                  <table width="100%">
                    <tr>
                      <td style={{ padding: "15px 15px 15px 15px" }}>
                        <h4 style={{ marginBottom: "15px" }}>
                          VERIFICATION STEP 3: GEO TAGGING
                        </h4>
                        {reportData.case.connections.map((customer, index) => {
                          return (
                            <GeoTagging
                              name={customer.name}
                              latitude={customer.latitude}
                              longitude={customer.longitude}
                              location={customer.location}
                              ip_address={customer.ip_address}
                              key={index}
                            />
                          );
                        })}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <div className="html2pdf__page-break"></div>
            <div className="pt-4 pb-4">
              <table width="100%">
                <tr>
                  <td>
                    <img src={VPDLogo} alt="" width="130" />
                  </td>
                  <td align="right">
                    <img src={ABLogo} alt="" width="150" />
                  </td>
                </tr>
              </table>
            </div>
            <table
              width="100%"
              style={{ backgroundColor: "#fff", border: "1px solid #b1b1b1" }}
            >
              <tr>
                <td style={{ padding: "0" }}>
                  <table width="100%">
                    <tr>
                      <td style={{ padding: "15px 15px 15px 15px" }}>
                        <h4>Verification Step 4: SCREENSHOT CAPTURE</h4>
                        {reportData.case.connections &&
                          reportData.case.connections.map(
                            (connection, index) => {
                              return (
                                <>
                                  <Screenshots
                                    connection={connection}
                                    key={index}
                                  />
                                  {index + 1 !==
                                    reportData.case.connections.length && (
                                    <div className="html2pdf__page-break" />
                                  )}
                                </>
                              );
                            }
                          )}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <div className="html2pdf__page-break"></div>
            <div className="pt-4 pb-4">
              <table width="100%">
                <tr>
                  <td>
                    <img src={VPDLogo} alt="" width="130" />
                  </td>
                  <td align="right">
                    <img src={ABLogo} alt="" width="150" />
                  </td>
                </tr>
              </table>
            </div>
            <table
              width="100%"
              style={{ backgroundColor: "#fff", border: "1px solid #b1b1b1" }}
            >
              <tr>
                <td style={{ padding: "0" }}>
                  <table width="100%">
                    <tr>
                      <td style={{ padding: "15px 15px 15px 15px" }}>
                        <table
                          className="gray-table-rows"
                          width="100%"
                          style={{
                            marginTop: "15px",
                            backgroundColor: "#f8f8f8",
                          }}
                        >
                          <tr>
                            <td width="19%" style={{ paddingRight: "0px" }}>
                              Notes
                            </td>
                            <td width="1%" align="center">
                              :
                            </td>
                            <td
                              width="80%"
                              style={{
                                color: "#1c6697",
                                fontStyle: "italic",
                                lineHeight: "18px",
                              }}
                            >
                              {reportData?.case?.notes[0]?.note}
                            </td>
                          </tr>

                          <tr>
                            <td width="19%" style={{ paddingRight: "0px" }}>
                              Agent Comments
                            </td>
                            <td width="1%" align="center">
                              :
                            </td>
                            <td
                              width="80%"
                              style={{
                                color: "#1c6697",
                                fontStyle: "italic",
                                lineHeight: "18px",
                              }}
                            >
                              {reportData.case.case_history.filter(
                                (history) => history.type === "comment"
                              ).length !== 0
                                ? reportData.case.case_history.filter(
                                    (history) => history.type === "comment"
                                  )[
                                    reportData.case.case_history.filter(
                                      (history) => history.type === "comment"
                                    ).length - 1
                                  ].re_text
                                : ""}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <div className="html2pdf__page-break"></div>
            <div className="pt-4 pb-4">
              <table width="100%">
                <tr>
                  <td>
                    <img src={VPDLogo} alt="" width="130" />
                  </td>
                  <td align="right">
                    <img src={ABLogo} alt="" width="150" />
                  </td>
                </tr>
              </table>
            </div>
            <table width="100%" style={{ backgroundColor: "#94d1fe" }}>
              <tr>
                <td style={{ padding: "0" }}>
                  <table width="100%">
                    <tr>
                      <td style={{ padding: "15px" }}>
                        <h4 style={{ color: "#fff" }}>Auditor Details</h4>
                        <table width="100%" style={{ marginTop: "15px" }}>
                          <tr>
                            <td
                              width="365"
                              style={{ padding: "0px 10px 0px 0px" }}
                            >
                              <table
                                className="white-table-rows"
                                width="100%"
                                style={{ backgroundColor: "#fff" }}
                              >
                                <tr>
                                  <td
                                    width="50%"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    Full Name
                                  </td>
                                  <td
                                    width="1%"
                                    align="center"
                                    style={{ padding: "0" }}
                                  >
                                    :
                                  </td>
                                  <td width="49%">
                                    {reportData.case.audited_by
                                      ? reportData.case.audited_by.full_name
                                      : ""}
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td
                              width="365"
                              style={{ padding: "0px 0px 0px 10px" }}
                            >
                              <table
                                className="white-table-rows"
                                width="100%"
                                style={{ backgroundColor: "#fff" }}
                              >
                                <tr>
                                  <td
                                    width="50%"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    Identification Number
                                  </td>
                                  <td
                                    width="1%"
                                    align="center"
                                    style={{ padding: "0" }}
                                  >
                                    :
                                  </td>
                                  <td width="49%">
                                    {reportData.case.audited_by
                                      ? reportData.case.audited_by.id
                                      : ""}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <table width="100%" style={{ marginTop: "8px" }}>
                          <tr>
                            <td
                              width="335"
                              style={{ padding: "0px 10px 0px 0px" }}
                            >
                              <table
                                className="white-table-rows"
                                width="100%"
                                style={{ backgroundColor: "#fff" }}
                              >
                                <tr>
                                  <td
                                    width="50%"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    Mobile Number
                                  </td>
                                  <td width="1%" align="center">
                                    :
                                  </td>
                                  <td width="49%">
                                    {reportData.case.audited_by
                                      ? reportData.case.audited_by.mobile_number
                                      : ""}
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td
                              width="335"
                              style={{ padding: "0px 0px 0px 10px" }}
                            >
                              <table
                                className="white-table-rows"
                                width="100%"
                                style={{ backgroundColor: "#fff" }}
                              >
                                <tr>
                                  <td
                                    width="50%"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    Official Email Address
                                  </td>
                                  <td width="1%" align="center">
                                    :
                                  </td>
                                  <td width="49%">
                                    {reportData.case.audited_by
                                      ? reportData.case.audited_by.email
                                      : ""}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table
              width="100%"
              style={{
                backgroundColor: "#fff",
                border: "1px solid #b1b1b1",
                borderTop: "0",
              }}
            >
              <tr>
                <td style={{ padding: "0" }}>
                  <table width="100%">
                    <tr>
                      <td style={{ padding: "15px 15px 15px 15px" }}>
                        <h4>Audit Details</h4>
                        <table width="100%" style={{ marginTop: "15px" }}>
                          <tr>
                            <td style={{ padding: "0px 0px 0px 0px" }}>
                              <table
                                className="green-table-rows"
                                width="100%"
                                style={{ backgroundColor: "#ddf2e3" }}
                              >
                                <tr>
                                  <td
                                    width="20%"
                                    style={{
                                      paddingRight: "0px",
                                      color: auditStatus[reportData.case.status]
                                        .color
                                        ? auditStatus[reportData.case.status]
                                            .color
                                        : "",
                                    }}
                                  >
                                    Audit Status
                                  </td>
                                  <td
                                    width="1%"
                                    align="center"
                                    style={{
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    :
                                  </td>
                                  <td
                                    width="74%"
                                    style={{
                                      color: auditStatus[reportData.case.status]
                                        .color
                                        ? auditStatus[reportData.case.status]
                                            .color
                                        : "",
                                    }}
                                  >
                                    {auditStatus[reportData.case.status].status
                                      ? auditStatus[reportData.case.status]
                                          .status
                                      : ""}
                                  </td>
                                  <td
                                    align="right"
                                    width="5%"
                                    style={{
                                      paddingRight: "10px",
                                      paddingLeft: "0px",
                                    }}
                                  >
                                    <img
                                      src={
                                        auditStatus[reportData.case.status].tick
                                          ? auditStatus[reportData.case.status]
                                              .tick
                                          : ""
                                      }
                                      width="20"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <table width="100%" style={{ marginTop: "8px" }}>
                          <tr>
                            <td
                              width="359"
                              style={{ padding: "0px 10px 0px 0px" }}
                            >
                              <table
                                className="gray-table-rows"
                                width="359"
                                style={{ backgroundColor: "#f8f8f8" }}
                              >
                                <tr>
                                  <td
                                    width="45%"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    Audit completed on
                                  </td>
                                  <td
                                    width="1%"
                                    align="center"
                                    style={{
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                    }}
                                    style={{ padding: "0" }}
                                  >
                                    :
                                  </td>
                                  <td width="54%">
                                    {reportData.case.audited_at ? (
                                      <Moment format="DD MMM YYYY LT">
                                        {reportData.case.audited_at}
                                      </Moment>
                                    ) : (
                                      "N.A."
                                    )}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <hr
                          style={{
                            borderTop: "1px dashed #707070 !important",
                            borderBottom: "0px",
                            marginTop: "20px",
                            marginBottom: "20px",
                            width: "100%",
                          }}
                        />
                        <h4>Case Audit Details</h4>
                        {/* <table width="100%" style={{ marginTop: "15px" }}>
                          <tr>
                            <td style={{ padding: "0px 0px 0px 0px" }}>
                              <table
                                className="green-table-rows"
                                width="100%"
                                style={{ backgroundColor: "#ddf2e3" }}
                              >
                                <tr>
                                  <td
                                    align="right"
                                    width="3%"
                                    style={{ paddingRight: "10px" }}
                                  >
                                    <img src={GreenTick2} width="20" alt="" />
                                  </td>
                                  <td
                                    width="23%"
                                    style={{
                                      paddingRight: "0px",
                                      color: "#24a749",
                                    }}
                                  >
                                    Verification Step 1
                                  </td>
                                  <td
                                    align="right"
                                    width="74%"
                                    style={{
                                      color: "#24a749",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    Auditor comments ok
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <table width="100%" style={{ marginTop: "8px" }}>
                          <tr>
                            <td style={{ padding: "0px 0px 0px 0px" }}>
                              <table
                                className="green-table-rows"
                                width="100%"
                                style={{ backgroundColor: "#ddf2e3" }}
                              >
                                <tr>
                                  <td
                                    align="right"
                                    width="3%"
                                    style={{ paddingRight: "10px" }}
                                  >
                                    <img src={GreenTick2} width="20" alt="" />
                                  </td>
                                  <td
                                    width="23%"
                                    style={{
                                      paddingRight: "0px",
                                      color: "#24a749",
                                    }}
                                  >
                                    Verification Step 2
                                  </td>
                                  <td
                                    align="right"
                                    width="74%"
                                    style={{
                                      color: "#24a749",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    Auditor comments ok
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table> */}
                        <table
                          className="gray-table-rows"
                          width="100%"
                          style={{
                            marginTop: "15px",
                            marginBottom: "15px",
                            backgroundColor: "#f8f8f8",
                          }}
                        >
                          <tr>
                            <td width="19%" style={{ paddingRight: "0px" }}>
                              Auditor Comments
                            </td>
                            <td width="1%" align="center">
                              :
                            </td>
                            <td
                              width="80%"
                              style={{
                                color: "#1c6697",
                                fontStyle: "italic",
                                lineHeight: "18px",
                              }}
                            >
                              {reportData.audited_by_comment}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
        )}
      </>
    );
  }
}

export default PDReport;
