import React, { useEffect, useState } from 'react'
import api from "../../../../../apiBaseConfig";
import cogoToast from 'cogo-toast'
import '../uploadCasesNew/Style.scss'
import { getFieldData } from '../uploadCasesNew/Form'
import WithInputType from '../uploadCasesNew/BasicInputs'
import {
    GET_PROCESSES,
    GET_CASES,
    GET_PARTNERS
} from "../../../../../apiurl";
import ProcessDrop from '../uploadCasesNew/BasicInputs/ProcessDrop'
import Loader from '../../../../../components/loader/Loader'
import { useSelector } from "react-redux";
import PartnerDrop from '../uploadCasesNew/BasicInputs/PartnerDrop'

function UploadLiveHFCL(props) {
    const [processType, setProcessType] = useState(null)
    const [processList, setProcessList] = useState([])
    const [basicFields, setBasicFields] = useState([])
    const [partners, setPartners] = useState([])
    const [partner, setPartner] = useState(null)
    const [formData, setFormData] = useState([])

    const [basicError, setBasicError] = useState({ process: '', partners: '' })
    const [loading, setLoading] = useState(false)
    let clientConfig = useSelector(state => state.login.clientInfo)

    useEffect(() => {
        getCaseFields()
        getBasicField()
        getProcessList()
        if (clientConfig?.has_partners)
            getPartnerList()
    }, [])
    function getCaseFields() {
        api.get('/case/add-case-fields/')
            .then(res => {
                if (res.status === 200 && res.data) {
                    let temp = []
                    res.data.forEach(item => {
                        let fieldData = { ...getFieldData(item.field_name) }
                        fieldData.value = ""
                        temp.push(fieldData)
                    })
                    setFormData(temp)
                }
            })
            .catch(err => {
                cogoToast.error('Something went wrong with case fields!')
            })
    }
    function getBasicField() {
        let data = []
        let list = ['customer_name', 'customer_email', 'customer_mobile_number']
        list.forEach(item => {
            data.push({ ...getFieldData(item) })
        })
        setBasicFields(data)
    }
    async function getProcessList() {
        let { data } = await api.get(GET_PROCESSES);
        setProcessList(data.filter(item=>item.case_type ===  'video_kyc'))
    }
    async function getPartnerList() {
        let { data } = await api.get(GET_PARTNERS)
        if (data && data.length > 0) {
            setPartners(data)
        }
    }

    function handleChange(val, index) {
        let temp = [...formData]
        temp[index].value = val
        if (val.length >= 2)
            temp[index].error = ''
        if (temp[index].check) {
            let check = temp[index].check(val)
            if (check)
                temp[index].error = ''
            else
                temp[index].error = 'Invalid ' + temp[index].label
        }
        setFormData(temp)
    }



    // const submitModuleDetails = async () => {
    //     return
    //     let flag = false;
    //     if (
    //         data.customer_name.trim().length === 0 ||
    //         !checkForAlphabetsOnly(data.customer_name)
    //     ) {
    //         cogoToast.error("Please enter a valid name");
    //         flag = true;
    //     }

    //     if (
    //         data.office_address.trim() === "" ||
    //         !checkForAlphanumericAndSpecialCharacters(data.office_address)
    //     ) {
    //         cogoToast.error("Please enter a valid address");
    //         flag = true;
    //     }

    //     if (data.customer_email === "" || !checkForEmail(data.customer_email)) {
    //         cogoToast.error("Please enter a valid email");
    //         flag = true;
    //     }

    //     if (
    //         data.customer_mobile_number === "" ||
    //         !checkForNumeric(data.customer_mobile_number)
    //     ) {
    //         cogoToast.error("Please enter a valid number");
    //         flag = true;
    //     }

    //     if (flag) return;

    //     try {
    //         let response = await api.post(`/case/live/create-case-connection/`, data);

    //         if (response.data.case_id) {
    //             cogoToast.success("Case uploaded successfully.");
    //         }
    //     } catch (error) {
    //         console.log("error in posting live cases", error);
    //     }
    // };


    async function setRequestData(data) {
        let reqData = {}
        reqData.client = clientConfig.client
        reqData.process = processList[processType].id
        reqData.case_type = processList[processType].case_type
        basicFields.map(item => {
            reqData[item.name] = item.value
        })
        data.map(item => {
            if (item.value !== '')
                reqData[item.name] = item.value
        })
        if (partner && partners.length > 0) reqData.partner = partner
        setLoading(true)
        // CEQPK4956K
        try {
            let response = await api.post(`/case/live/create-case-connection/`, reqData);

            if (response.data.case_id) {
                cogoToast.success("Case uploaded successfully.");
                resetForm()
            }
            setLoading(false)
        }
        catch (e) {
            cogoToast.error('Something went wrong while creating case!')
            setLoading(false)

        }
    }

    function setProcess(e) {
        basicError.process = ''
        setBasicError(basicError)
        setProcessType(e.target.value)
    }
    function validateCaseDetails() {
        let isValid = true
        let temp = [...formData]
        temp.map(item => {
            if ((item.isRequired && item.value.length < 2) || item.error.length > 0) {
                item.error = 'Enter valid ' + item.label
                isValid = false
            }
        })

        basicFields.map(item => {
            if (item.isRequired && item.value.length < 2) {
                item.error = 'Enter valid ' + item.label
                isValid = false
            }
        })

        if (!processType) {
            isValid = false
            basicError.process = 'Select Process'
        }
        if (partners.length > 0 && !partner) {
            isValid = false
            basicError.partners = 'Select Partner'
        }
        setBasicError(basicError)
        setFormData(temp)
        setBasicFields(basicFields)
        if (isValid) {
            setRequestData(temp)
        }
        else cogoToast.error('Please fill all inputs with valid data!')
    }
    function handleBasicFieldChange(val, index) {
        let temp = [...basicFields]
        temp[index].value = val
        if (val.length >= 2)
            temp[index].error = ''
        if (temp[index].check) {
            let check = temp[index].check(val)
            if (check)
                temp[index].error = ''
            else
                temp[index].error = 'Invalid ' + temp[index].label
        }
        setBasicFields(temp)
    }

    function handlePartner(e) {
        if (e.target.value) {
            basicError.partners = ''
            setBasicError(basicError)
        }
        setPartner(e.target.value)
    }
    function resetForm() {
        getCaseFields()
        getBasicField()
        setProcessType('')
        setPartner(null)
    }
    return (
        <div className='container-fluid p-2'>
            {loading ? <Loader /> : null}
            <div className='upload-form mt-2'>
                <div className='row'>
                    {basicFields.map((item, index) => {
                        return <div key={index} className='col-md-3'>
                            <WithInputType {...item} onChange={e => handleBasicFieldChange(e, index)} />
                        </div>
                    })}
                </div>
                <div className='row'>
                    <div className='col-md-3'>
                        <ProcessDrop processType={processType} list={processList} setProcess={setProcess} error={basicError.process} />
                    </div>
                    <div className='col-md-3'>
                        <PartnerDrop label={'Partner'} error={basicError.partners} list={partners} selected={partner} onSelect={handlePartner} />
                    </div>
                </div>

                <div className='row'>
                    {formData.map((item, index) => {
                        return <div key={index} className='col-md-3'>
                            <WithInputType {...item} onChange={e => handleChange(e, index)} />
                        </div>
                    })}
                </div>

                <div className='row'>
                    <div className='col-md-2 mt-2'>
                        <button className='btn btn-primary' onClick={validateCaseDetails}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UploadLiveHFCL;
