import { ActionTypes } from "./login.types";

const initialState = {
  userInfo: {
    accessToken: "",
    roleName: "",
    userId: "",
    userFirstName: "",
    userLastName: "",
    roleId: "",
    clientId: "",
    clientName: "",
    email: "",
    mobilenumber:null,
    hasUserChanagedPasswordBefore: "",
    image:''
  },
  clientInfo:{
    case_module: null,
    client: null,
    email_config: null,
    facematch_threshold: null,
    id: null,
    live_case: null,
    logo_path: "",
    mandatory_screenshots: null,
    mf_authentication: false,
    name_validation: false,
    on_call_docs: null,
    pan_validation: false,
    rescheduled_attempts: null,
    s3_config: null,
    sms_gateway: null,
    url_shorten_service: null
  },
  clientName:''
};

export default function rootReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        userInfo: {
          accessToken: state.userInfo.accessToken,
          roleName: payload.role,
          userId: payload.id,
          userFirstName: payload.first_name,
          userLastName: payload.last_name,
          mobilenumber:payload.mobile_number,
          image:payload.image,
          roleId: "",
          email: payload.email,
          clientId: payload.client,
          clientName: "",
          isAgent: payload.isAgent,
          isAuditor: payload.isAuditor,
          isClientSuperuser: payload.isClientSuperuser,
          isAdminAuditor: payload.isAdminAuditor,
          hasUserChanagedPasswordBefore:
            payload.has_changed_pass_after_first_login,
        },
      };
    case ActionTypes.LOGIN_FAIL:
      return initialState;
    case ActionTypes.LOGOUT:
      return initialState
    case ActionTypes.UPDATE:
      return {
        ...state,
        userInfo: {
          accessToken: payload.token,
          roleName: "",
          userId: "",
          userFirstName: "",
          userLastName: "",
          roleId: "",
          clientId: "",
          clientName: "",
          email: "",
          isAgent: "",
          isAuditor: "",
          isClientSuperuser: "",
          hasUserChanagedPasswordBefore: "",
        },
      };
      case ActionTypes.UPDATE_CLIENT_CONFIG:
        return {
          ...state,
          clientInfo :{...payload}
        };
      case ActionTypes.UPDATE_CLIENT_NAME:
        return {
          ...state,
          clientName :payload.name
        };    

    default:
      return state;
  }
}
