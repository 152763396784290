import React from "react";
import { ReactComponent as VideoIcon } from "../../../../../assets/icons/video-icon.svg";
import { ReactComponent as NotificationIconWhite } from "../../../../../assets/icons/notification-icon-white.svg";
import { ReactComponent as NameIcon } from "../../../../../assets/icons/name.svg";
import { ReactComponent as PinWhiteIcon } from "../../../../../assets/icons/pin-white.svg";
import { Popover, IconButton, Badge } from "@material-ui/core";
import { GET_NOTIFICATION } from "../../../../../apiurl";
import api from "../../../../../apiBaseConfig";
import { connect } from "react-redux";

class DashboardHeader extends React.Component {
  interval = 0;
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      notification: [],
      newNotification: [],
      notificationNumber: 0
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.getNotification();
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getNotification = async () => {
    const res = await api.get(
      GET_NOTIFICATION + `?notification_for=${this.props.userInfo.userId}`
    );
    const notificationNumber = this.state.notification.length
      ? res.data.results.findIndex(
        notification => notification.id === this.state.notification[0].id
      )
      : res.data.results.length;
    this.setState({
      ...this.state,
      newNotification: res.data.results,
      notificationNumber
    });
  };

  handleOnClick = (notification) => {
    let case_type = notification.case_type === "video_kyc" ? 0 : 1;
    const case_status_map = new Map([["assigned",0],["scheduled",1],["completed",2]])
    let case_status = case_status_map.get(notification.action_tab);
    if(case_status != undefined) {
      this.props.handleNotificationClick(case_type, case_status, notification.case);
    }
  }

   handleClick = event => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
      notification: this.state.newNotification,
      notificationNumber: 0
    });
  };

   handleClose = () => {
    this.setState({ ...this.state, anchorEl: null });
  };


  render() {
    const { profileOptionClicked, userInfo, clientInfo } = this.props;
    const open = Boolean(this.state.anchorEl);

    return (
      <header className="bg-blue">
        <nav className="navbar navbar-expand-lg navbar-light pt-0 pb-0 cstm-navbar">
          <a href="#" className="mr-5" style={{ width: "50px" }}>
            <VideoIcon
              width="38"
              style={{ marginTop: "-6px", marginLeft: "0px" }}
              fill="#fff"
            />
          </a>
          <span className="fnt-1-25rem fnt-roboto-bld text-white">
           {clientInfo.case_module === 5 ? 'PIVC' : 'Video KYC' }
          </span>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul
              className={
                !profileOptionClicked
                  ? "navbar-nav ml-auto h-60"
                  : "navbar-nav ml-auto h-60 show"
              }
            >
              <li className="nav-item position-relative  d-flex align-items-center pl-2">
              <div className="dashboard-client-image" style={{backgroundImage:`url(${this.props.clientInfo.logo_path})`}}>
                {/* <img src={this.props.clientInfo.logo_path} alt="client logo"></img> */}
              </div>
              </li>

              <li
                className="nav-item pl-3 pr-3 border-left border-right d-flex align-items-center dropdown show "
                // id="navbarDropdown"
                // role="button"
                // data-toggle="dropdown"
                onClick={this.props.handleProfileOptions}
              >
                <table
                  width="100%"
                  className="dropdown-toggle d-flex align-items-center"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <tr>
                    <td valign="top" width="40">
                      <div className="light-blue-sml-circle rounded-circle p-3 bg-purple text-white">
                        {userInfo
                          ? userInfo.userFirstName.charAt(0) +
                          userInfo.userLastName.charAt(0)
                          : ""}
                      </div>
                    </td>
                    <td valign="top">
                      <div className="d-flex flex-column">
                        <small className="text-white fnt-bld fnt-8rem">
                          Hi
                        </small>
                        <small className="txt-italic fnt-sml text-white">
                          {userInfo
                            ? userInfo.userFirstName +
                            " " +
                            userInfo.userLastName
                            : ""}
                        </small>
                      </div>
                    </td>
                  </tr>
                </table>
                {/* </a> */}

                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                   <a
                    class="dropdown-item p-1"
                    href="#"
                    onClick={() => this.props.handleProfile()}
                  >
                    Profile
                  </a>
                  {/*
                  <a class="dropdown-item p-1" href="#">
                    Settings
                  </a> */}
                  <a
                    class="dropdown-item p-1"
                    href="#"
                    onClick={() => {
                      this.props.handleLogout();
                      console.log("loggin out");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Log out
                  </a>
                </div>

              </li>
              <li className="nav-item position-relative  d-flex align-items-center pl-2">
                {/* <a href="#" className="nav-link">
                  <NotificationIconWhite width="20" />
                </a> */}
                <IconButton
                  aria-describedby="notification"
                  onClick={this.handleClick}
                >
                  <Badge
                    color="error"
                    badgeContent={this.state.notificationNumber}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left"
                    }}
                  >
                    <NotificationIconWhite width="25" height="25" />
                  </Badge>
                </IconButton>
                <Popover
                  id="notification"
                  open={open}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                >
                  <div class="notification-container">
                    {
                      <React.Fragment>
                        <h3 class="fnt-1-5 fnt-bld txt-blue">Notification</h3>
                        {this.state.notification.map(notification => (
                          <React.Fragment>
                            <hr class="dashed-brdr brdr-blue"></hr>
                            <div onClick={() => this.handleOnClick(notification)} style={{ padding: "0 1em",cursor: "pointer" }}>
                              <h4 class=" fnt-1rem txt-light-blue m-0 fnt-bld font-italic">
                                {notification.notification_type}
                              </h4>
                              <span class="font-italic fnt-8rem">
                                {notification.notification_text}
                              </span>
                              <p class="txt-italic fnt-sml">
                                {new Date(
                                  notification.created_at
                                ).toLocaleString()}{" "}
                                by {notification.notification_by}
                              </p>
                            </div>
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    }
                  </div>
                </Popover>
                <div
                  className="notification-msg position-absolute rounded-15 p-2 text-white"
                  style={{ right: 0, width: "270px", display: "none" }}
                >
                  <div className="d-flex fnt-sml">
                    <NameIcon width="15" className="mr-2" />
                    Customer is uploading Aadhaar using UIDAI
                  </div>
                </div>
                <div
                  className="notification-msg bg-ab position-absolute rounded-15 p-2 text-white"
                  style={{
                    right: 0,
                    width: "270px",
                    bottom: "-65px",
                    display: "none"
                  }}
                >
                  <div className="d-flex fnt-sml">
                    <PinWhiteIcon width="10" className="mr-2" />
                    Customer has provided access to Geo location
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}
const mapStateToProps = (state) => ({
  clientInfo:state.login.clientInfo
});

export default connect(mapStateToProps, ()=>{})(DashboardHeader);
