import React from "react";
import moment from "moment";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelIcon from "@material-ui/icons/Cancel";

class VerificationInputAadhaar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputEditable: true,
      inputValue: this.props.defaultValue,
      inputConfirmed: false,
      hasError: false,
    };
  }

  handleEditInput = (editable) => {
    if (editable) {
      this.props.onChange({ type: this.props.preInput, data: null });
    } else {
      this.props.onChange({
        type: this.props.preInput,
        data: this.state.inputValue,
      });
    }
    this.setState({ inputEditable: editable, inputConfirmed: !editable });
  };

  handleInputChange = (inputValue, preInput) => {
    if (preInput === "DOB") {
      let dateVal = moment(inputValue, "YYYY-MM-DD").format("DD/MM/YYYY");
      this.setState({ inputValue: dateVal });
    } else if (preInput === "PAN") {
      var pattern = new RegExp(/^[a-zA-Z0-9]+$/);
      if (pattern.test(inputValue)) {
        this.setState({ inputValue: inputValue, hasError: false });
      } else {
        this.setState({ hasError: true });
      }
    } else {
      this.setState({ inputValue: inputValue });
    }
  };
  render() {
    const { inputEditable, inputValue, hasError } = this.state;
    const {
      preInput,
      readOnly,
      inputValueDefault,
      isTick,
      isCancel,
      inputKey,
      digilockerData,
    } = this.props;
    const checkCircleIconColor = inputEditable ? "#c5c5c5" : "#21A947";
    const currentDate = moment().format("YYYY-MM-DD");
    return (
      <React.Fragment>
        <div
          className="input-with-label-group radius-50 border brdr-ab"
          style={{ marginTop: readOnly ? "5px" : "" }}
        >
          <div className="left">
            <label htmlFor="" style={{ whiteSpace: "nowrap" }}>
              {preInput} |
            </label>
            <input
              className="form-control"
              type={!readOnly ? (preInput === "DOB" ? "date" : "text") : "text"}
              max={!readOnly ? (preInput === "DOB" ? currentDate : "") : ""}
              disabled={!inputEditable}
              value={
                !readOnly
                  ? preInput === "DOB"
                    ? moment(inputValue, "DD/MM/YYYY").format("YYYY-MM-DD")
                    : inputValue
                  : inputValueDefault
              }
              onChange={(e) => this.handleInputChange(e.target.value, preInput)}
              style={{ width: preInput === "DOB" ? "88%" : "70%" }}
              readOnly={readOnly || true}
            />
          </div>
          {readOnly ? (
            <React.Fragment>
              <CheckCircleRoundedIcon
                onClick={() =>
                  this.props.handleIsChecked(
                    { isTick: true, isCancel: false },
                    inputKey
                  )
                }
                style={{
                  color: isTick ? "#21a947" : "#c5c5c5",
                  cursor: "pointer",
                }}
              />
              <CancelIcon
                onClick={() =>
                  this.props.handleIsChecked(
                    { isTick: false, isCancel: true },
                    inputKey
                  )
                }
                style={{
                  color: isCancel ? "red" : "#c5c5c5",
                  cursor: "pointer",
                }}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <CheckCircleRoundedIcon
                onClick={() => this.handleEditInput(false)}
                style={{
                  color: digilockerData ? "#21A947" : checkCircleIconColor,
                  cursor: "pointer",
                  fontSize: "1.3rem",
                }}
              />
              {/* <EditIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleEditInput(true)}
                        /> */}
            </React.Fragment>
          )}
        </div>
        {hasError && <p style={{ color: "red" }}>Invalid input</p>}
      </React.Fragment>
    );
  }
}

export default VerificationInputAadhaar;
