import React, { useEffect, useState } from "react";
import { ReactComponent as UploadIcon } from "../../../assets/icons/upload.svg";
import { connect, useSelector } from "react-redux";
import api from "../../../apiBaseConfig";
import { UPLOAD, GET_DOCUMENTS } from "../../../apiurl";
import Loader from "../../../components/loader/Loader";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";
import { storeAnonymousUserConnectionInfo } from "../../state/user.actions";
import _get from "lodash/get";
import { ReactComponent as TickUnselected } from "../../../assets/icons/tick_unselected.svg";

const UploadDocsMulti = (props) => {

  const [isLoading, setLoading] = useState(false);
  const [skip, setSkip] = useState(true);
  const history = useHistory();
  const [processDoc, setProcessDoc] = useState([]);
  const [isMandatory, setIsMandatory] = useState(false)
  const clientConfig = useSelector(state => state.login.clientInfo)
  useEffect(() => {
    setDocsList()
    if(clientConfig.hasOwnProperty('documents_required') && !clientConfig.documents_required){
       handleNext()
  } 
  }, []);

  function setDocsList(){
    const doc = _get(
      props.userConnectionInfo,
      "case_details.process_details.documents",
      []
    ).filter((doc) => !doc.is_agent_uploaded && doc.is_active && doc.document_upload_event == "before_call")
    checkUploadedDoc(props.userConnectionInfo.id, props.userConnectionInfo.case_details.id, doc)
  }


  const checkUploadedDoc = async (connectionId, caseId, doc) => {
    const { data } = await api.get(`${GET_DOCUMENTS}?case=${caseId}&connection=${connectionId}`);
    const uploadedDoc = data.data.reduce((doc, upload) => {
      doc = { ...doc, [upload.document_details.id]: true };
      return doc;
    }, {})
    let checkMandatory = false;
    doc.forEach(element => {
      if (uploadedDoc[element.id]) {
        element.isUploaded = true;
        setSkip(false)
      }
      else if(element.is_mandatory && !uploadedDoc[element.id]){
        checkMandatory = true
        setSkip(false)
      }
    });
    setIsMandatory(checkMandatory)
    
    setProcessDoc(doc);
  }

  const readUploadedFileAsDataURL = (inputFile) => {
    const temporaryFileReader = new FileReader();
    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
  };

  const upload = async (event, index) => {
    if (!event.target || !event.target.files) {
      return;
    }
    const file = event.target.files[0];
    try {
      setLoading(true);
      const fileContent = await readUploadedFileAsDataURL(file);
      let responseFront = await api.put(UPLOAD, {
        connection: props.userConnectionInfo.id,
        case: props.userConnectionInfo.case_details.id,
        front_upload: fileContent,
        document: _get(
          props.userConnectionInfo,
          "case_details.process_details.documents",
          []
        )[index].id, //payload.data.front.documentId,
        corrected_ocr: {},
        extracted_ocr: {},
        document_upload_event: "on_call"
      });
      let doclist = processDoc;
      doclist[index].isUploaded = true;
      setProcessDoc(processDoc)
      let isRequired = false
      doclist.map(item=>{
        if(item.isMandatory && !item.isUploaded){
          setSkip(false);
          isRequired = true
          setIsMandatory(isRequired)
        }
      })
      props.storeAnonymousUserConnectionInfo(props.userConnectionInfo);
      setTimeout(()=>setDocsList(),400) 
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleNext = () => {
    history.push("../instruction");
  };

  return (
    <React.Fragment>
      {isLoading && <Loader title={"Please wait. Uploading documents"} />}

      <section className="main-body m-auto" style={{ maxWidth: "460px" }}>
        <div className="container-fluid mt-4">
          <div className="row">
            <div className="col-md-12">
              <h4 className="txt-light-blue ltr-spc-1">
                Please provide the required document
              </h4>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              {processDoc.map((document, index) => {
                return (
                  <React.Fragment key={index}>
                    <input
                      type="file"
                      hidden
                      onChange={(e) => upload(e, index)}
                      id={`customField-${index}`}
                      className="form-control"
                      style={{ height: "60px", left: "0" }}
                    // disabled={document.isUploaded}
                    />
                    <label
                      htmlFor={`customField-${index}`}
                      className="d-block cursor-p mt-3"
                    >
                      <div
                        className={`white-box p-5-15  position-relative ${
                          document.isUploaded ? "active" : ""
                          }`}
                      >
                        <div className="left float-left d-flex align-items-center">
                          <span className="upload-icon icon-light-blue">
                            <UploadIcon />
                          </span>
                          <span className="txt-light-black fnt-sml ml-3 ltr-spc-0-5 capitalize">
                            {document.name}{document.is_mandatory && <span className="text-danger fnt-1rem">*</span>}
                          </span>
                        </div>
                        <div className="right float-left d-flex align-items-center justify-content-end">
                          <span className="tick-icon icon-green mt-2">
                            <TickUnselected />
                          </span>
                        </div>
                        <div className="right float-left d-flex align-items-center justify-content-end">
                          <span
                            className="trash-icon ml-3"
                            style={{ display: "none" }}
                          >
                            <img src="img/trash-icon.svg" alt="" />
                          </span>
                        </div>
                      </div>
                    </label>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-md-12 text-right">
          {!props.isCallOngoing && (
            <Button variant="contained" disabled={isMandatory} onClick={()=>handleNext()} color="secondary">
              {skip ? "Skip" : "Next"}
            </Button>
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  userConnectionInfo: state.userDocs.anonymousUserConnectionInfo,
});
const mapDispatchToProps = (dispatch) => ({
  storeAnonymousUserConnectionInfo: (payload) =>
    dispatch(storeAnonymousUserConnectionInfo(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocsMulti);
