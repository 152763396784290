import React from "react";
import { connect } from "react-redux";
import { varify } from "../../state/user.actions";
import UserHeader from "../../userHeader/userHeader";
import VerificationInputAadhaar from "./VerificationInputAadhaar";
import "../verifyPan/VerifyPan.scss";
import Loader from "../../../components/loader/Loader";

class VerifyFront extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verifiedFront: {
        Name: "",
        YOB: "",
        Gender: "",
        Aadhaar: "",
      },
      nextDisabled: true,
    };
  }
  verifyDetails = ({ type, data }) => {
    let verifiedFront = this.state.verifiedFront;
    verifiedFront[type] = data;
    let nextDisabled = false;
    for (var i in verifiedFront) {
      if (verifiedFront[i] === "") {
        nextDisabled = true;
      }
    }
    this.setState({
      verifiedFront: verifiedFront,
      nextDisabled: nextDisabled,
    });
  };
  handleNext = () => {
    this.props.updateFrontVerified(true, this.state.verifiedFront);
  };

  render() {
    const { userInfo, lang } = this.props;
    const { verifiedFront, nextDisabled } = this.state;
    return (
      <React.Fragment>
        {/* {loading && <Loader title={"Validating your details, please wait"} />} */}
        {userInfo && userInfo.aadharData && (
          <div className="wrapper light-blue-customer-template">
            <UserHeader />
            <section className="main-body m-auto customer-flow-section">
              <div className="container-fluid mt-4">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="txt-light-blue ltr-spc-1">
                      Aadhaar Front Upload
                    </h4>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="border border-1 dashed-brdr  rounded p-4 m mb-2">
                      <img
                        src={userInfo?.aadharData?.image?.front?.data}
                        alt=""
                        className="img-fluid rounded"
                      />
                      {userInfo.aadharData.document_upload_type ===
                        "ocr_upload" && (
                        <React.Fragment>
                          <div className="txt-81 fnt-sml mt-2 ltr-spc-1 mt-3 mb-3">
                            Information Extracted from Image
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-12">
                              <VerificationInputAadhaar
                                preInput="Name"
                                onChange={this.verifyDetails}
                                defaultValue={
                                  userInfo.aadharData.data
                                    ? userInfo.aadharData.data.name
                                    : ""
                                }
                                width={"100%"}
                                verificationKey={0}
                                readOnly={false}
                                isChecked={
                                  verifiedFront["Name"] !== "" ? true : false
                                }
                                digilockerData={false}
                              />
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-12">
                              <VerificationInputAadhaar
                                preInput="YOB"
                                onChange={this.verifyDetails}
                                defaultValue={
                                  userInfo.aadharData.data
                                    ? userInfo.aadharData.data.yob
                                    : ""
                                }
                                width={"100%"}
                                verificationKey={1}
                                readOnly={false}
                                isChecked={
                                  verifiedFront["YOB"] !== "" ? true : false
                                }
                                digilockerData={false}
                              />
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-12">
                              {/* <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6"> */}
                              <VerificationInputAadhaar
                                preInput="Gender"
                                onChange={this.verifyDetails}
                                defaultValue={
                                  userInfo.aadharData.data
                                    ? userInfo.aadharData.data.gender
                                    : ""
                                }
                                width={"100%"}
                                verificationKey={2}
                                readOnly={false}
                                isChecked={
                                  verifiedFront["Gender"] !== "" ? true : false
                                }
                                digilockerData={false}
                              />
                            </div>
                            {false && (
                              <div className="row mb-2 w-100 errorTxt">
                                <span className=" fnt-sml text-center col-md-12">
                                  <i>&nbsp;PAN detail not valid</i>
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="row mb-2">
                            <div className="col-md-12">
                              {/* <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6"> */}
                              <VerificationInputAadhaar
                                preInput="Aadhaar"
                                onChange={this.verifyDetails}
                                defaultValue={
                                  userInfo.aadharData.data
                                    ? userInfo.aadharData.data.doc_id
                                    : ""
                                }
                                width={"100%"}
                                verificationKey={3}
                                readOnly={false}
                                isChecked={
                                  verifiedFront["Aadhaar"] !== "" ? true : false
                                }
                                digilockerData={false}
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      )}

                      {userInfo.aadharData.document_upload_type ===
                        "digilocker_upload" && (
                        <React.Fragment>
                          <div className="txt-81 fnt-sml mt-2 ltr-spc-1 mt-3 mb-3">
                            Information Extracted from Image
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-12">
                              <VerificationInputAadhaar
                                preInput="Name"
                                preInputMultiLingual="Name"
                                // onChange={this.verifyDetailsDigilocker}
                                defaultValue={
                                  userInfo.aadharData.digilocker_data
                                    ? userInfo.aadharData.digilocker_data.name
                                    : ""
                                }
                                width={"100%"}
                                verificationKey={0}
                                readOnly={false}
                                isChecked={true}
                                digilockerData={true}
                              />
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-12">
                              <VerificationInputAadhaar
                                preInput="DOB"
                                preInputMultiLingual="DOB"
                                // onChange={this.verifyDetailsDigilocker}
                                defaultValue={
                                  userInfo.aadharData.digilocker_data
                                    ? userInfo.aadharData.digilocker_data.dob
                                    : ""
                                }
                                width={"100%"}
                                verificationKey={1}
                                readOnly={false}
                                isChecked={true}
                                digilockerData={true}
                              />
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-12">
                              {/* <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6"> */}
                              <VerificationInputAadhaar
                                preInput="Gender"
                                preInputMultiLingual="Gender"
                                // onChange={this.verifyDetailsDigilocker}
                                defaultValue={
                                  userInfo.aadharData.digilocker_data
                                    ? userInfo.aadharData.digilocker_data.gender
                                    : ""
                                }
                                width={"100%"}
                                verificationKey={2}
                                readOnly={false}
                                isChecked={true}
                                digilockerData={true}
                              />
                            </div>
                          </div>

                          <div className="row mb-2">
                            <div className="col-md-12">
                              {/* <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6"> */}
                              <VerificationInputAadhaar
                                preInput="Aadhaar"
                                preInputMultiLingual="Aadhaar"
                                // onChange={this.verifyDetailsDigilocker}
                                defaultValue={
                                  userInfo.aadharData.digilocker_data
                                    ? userInfo.aadharData.digilocker_data
                                        .aadhar_number
                                    : ""
                                }
                                width={"100%"}
                                verificationKey={3}
                                readOnly={false}
                                isChecked={true}
                                digilockerData={true}
                              />
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-12">
                              {/* <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6"> */}
                              <VerificationInputAadhaar
                                preInput="Address"
                                preInputMultiLingual="Address"
                                // onChange={this.verifyDetailsDigilocker}
                                defaultValue={
                                  userInfo.aadharData.digilocker_data
                                    ? userInfo.aadharData.digilocker_data
                                        .address
                                    : ""
                                }
                                width={"100%"}
                                verificationKey={3}
                                readOnly={false}
                                isChecked={true}
                                digilockerData={true}
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-3 mb-3">
                  <div className="col-md-12  d-flex justify-content-between">
                    <button
                      onClick={this.props.retry}
                      className="btn btn-custom btn-dark btn-light-black w-100-p"
                      // style={{ visibility: "hidden" }}
                    >
                      Retry
                    </button>
                    {userInfo &&
                    userInfo.aadharData &&
                    userInfo.aadharData.document_upload_type ===
                      "digilocker_upload" ? (
                      <button
                        disabled={false}
                        onClick={this.props.handleNextDigilocker}
                        className="btn btn-custom btn-light-blue w-100-p"
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        disabled={nextDisabled}
                        onClick={this.handleNext}
                        className="btn btn-custom btn-light-blue w-100-p"
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userDocs.userDocInfo,
});

const mapDispatchToProps = (dispatch) => ({
  varify: (payload) => dispatch(varify(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyFront);
