import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { rootReducer } from "./rootReducer";
import rootSaga from "./rootSaga";
import { Colors } from "./constants";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { PersistGate } from "redux-persist/integration/react";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import App from "./App";
import { ThemeProvider } from "styled-components";
import {
  MeetingProvider,
  lightTheme
} from "amazon-chime-sdk-component-library-react";

const sagaMiddleware = createSagaMiddleware();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.lightGreen
    },
    secondary: {
      main: Colors.lightBlue
    },
    info: {
      main: Colors.grey
    },
    typography: {
      useNextVariants: true,
      suppressDeprecationWarnings: true
    }
  }
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["login", "userDocs"]
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,

  applyMiddleware(sagaMiddleware)
);
let persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <ThemeProvider theme={lightTheme}>
              <MeetingProvider>
                <App />
              </MeetingProvider>
            </ThemeProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();