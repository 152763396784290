import React from 'react'
import Moment from "react-moment";
import GreenTick2 from "../../assets/icons/green-tick-2.png";
import { useSelector } from 'react-redux';
function CaseDetails({ reportData, isHFCL, isPivc }) {
  const { has_partners } = useSelector(state => state.login.clientInfo)
  return (
    <table
      width="100%"
      style={{ border: "1px solid #b1b1b1" }}
      className="m-auto bg-white"
    >
      <tr>
        <td style={{ padding: "0px" }}>
          <table width="100%">
            <tr>
              <td style={{ padding: "15px 15px 15px 15px" }}>
                <h4>
                  Call Start Date and Time/ Call End Date and Time
                </h4>
                {isHFCL ? <table
                  className="gray-table-rows bg-f8"
                  width="100%"
                  style={{ marginTop: "15px" }}
                >
                  <tr>
                    <td width="20%" style={{ paddingRight: "0px" }}>
                      Conference ID
                    </td>
                    <td width="1%" align="center">
                      :
                    </td>
                    <td width="79%">{reportData.session.id}</td>
                  </tr>
                </table> : null}
                <table
                  className="gray-table-rows bg-f8"
                  width="100%"
                  style={{ marginTop: "15px" }}
                >
                  <tr>
                    <td width="20%" style={{ paddingRight: "0px" }}>
                      Case ID
                    </td>
                    <td width="1%" align="center">
                      :
                    </td>
                    <td width="79%">{reportData.case.id}</td>
                  </tr>
                </table>

                {!isPivc ? <table width="100%" style={{ marginTop: "8px" }}>
                  <tr>
                    <td
                      width="365"
                      style={{ padding: "0px 10px 0px 0px" }}
                    >
                      <table
                        className="gray-table-rows bg-f8"
                        width="365"
                      >
                        <tr>
                          <td
                            width="135"
                            style={{ paddingRight: "0px" }}
                          >
                            Customer ID
                          </td>
                          <td width="10" align="center">
                            :
                          </td>
                          <td width="175">
                            {reportData.customers[0].id}
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td
                      width="365"
                      style={{ padding: "0px 0px 0px 10px" }}
                    >
                      <table
                        className="gray-table-rows bg-f8"
                        width="365"
                      >
                        <tr>
                          <td
                            width="135"
                            style={{ paddingRight: "0px" }}
                          >
                            Agent ID
                          </td>
                          <td width="10" align="center">
                            :
                          </td>
                          <td width="175">{reportData.case.agent}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table> : null}
                <table width="100%" style={{ marginTop: "8px" }}>
                  <tr>
                    <td
                      width="365"
                      style={{ padding: "0px 10px 0px 0px" }}
                    >
                      <table
                        className="gray-table-rows bg-f8"
                        width="365"
                      >
                        <tr>
                          <td
                            width="135"
                            style={{ paddingRight: "0px" }}
                          >
                            Call start time
                          </td>
                          <td width="10" align="center">
                            :
                          </td>
                          <td width="175">
                            <Moment format="DD MMM YYYY LT">
                              {reportData.session.joined_at}
                            </Moment>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td
                      width="365"
                      style={{ padding: "0px 0px 0px 10px" }}
                    >
                      <table
                        className="gray-table-rows bg-f8"
                        width="365"
                      >
                        <tr>
                          <td
                            width="135"
                            style={{ paddingRight: "0px" }}
                          >
                            Call end time
                          </td>
                          <td width="10" align="center">
                            :
                          </td>
                          <td width="175">
                            <Moment format="DD MMM YYYY LT">
                              {reportData.session.ended_at}
                            </Moment>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <hr
                  style={{
                    borderTop: "1px dashed #707070 !important",
                    borderBottom: "0px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    width: "100%",
                  }}
                />
                <h4>Case Details</h4>
                <table width="100%" style={{ marginTop: "15px" }}>
                  <tr>
                    <td
                      width="365"
                      style={{ padding: "0px 10px 0px 0px" }}
                    >
                      <table
                        className="gray-table-rows bg-f8"
                        width="365"
                      >
                        <tr>
                          <td
                            width="125"
                            style={{ paddingRight: "0px" }}
                          >
                            Case Assigned on
                          </td>
                          <td
                            width="10"
                            align="center"
                            style={{
                              paddingRight: "10px",
                              paddingLeft: "10px",
                            }}
                          >
                            :
                          </td>
                          <td width="185">
                            <Moment format="DD MMM YYYY LT">
                              {reportData.case.assigned_at}
                            </Moment>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td
                      width="365"
                      style={{ padding: "0px 0px 0px 10px" }}
                    >
                      <table
                        className="gray-table-rows bg-f8"
                        width="365"
                      >
                        <tr>
                          <td
                            width="125"
                            style={{ paddingRight: "0px" }}
                          >
                            Case Scheduled on
                          </td>
                          <td width="10" align="center">
                            :
                          </td>
                          <td width="185">
                            {reportData.session.scheduled_time ? (
                              <Moment format="DD MMM YYYY LT">
                                {reportData.session.scheduled_time}
                              </Moment>
                            ) : (
                              "N.A."
                            )}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <table width="100%" style={{ marginTop: "8px" }}>
                  <tr>
                    <td
                      width="365"
                      style={{ padding: "0px 10px 0px 0px" }}
                    >
                      <table
                        className="gray-table-rows bg-f8"
                        width="365"
                      >
                        <tr>
                          <td
                            width="125"
                            style={{ paddingRight: "0px" }}
                          >
                            Case Completed on
                          </td>
                          <td
                            width="10"
                            align="center"
                            style={{
                              paddingRight: "10px",
                              paddingLeft: "10px",
                            }}
                          >
                            :
                          </td>
                          <td width="185">
                            <Moment format="DD MMM YYYY LT">
                              {reportData.case.completed_at}
                            </Moment>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td
                      width="365"
                      style={{ padding: "0px 0px 0px 10px" }}
                    >
                      <table
                        className="green-table-rows"
                        width="365"
                        style={{ backgroundColor: "#ddf2e3" }}
                      >
                        <tr>
                          <td
                            width="125"
                            style={{
                              paddingRight: "0px",
                              color: "#24a749",
                            }}
                          >
                            Case Status
                          </td>
                          <td width="10" align="center">
                            :
                          </td>
                          <td width="185" style={{ padding: "0px" }}>
                            <table width="100%">
                              <tr>
                                <td
                                  width="30"
                                  style={{
                                    padding: "0px",
                                    paddingRight: "0px",
                                  }}
                                >
                                  <img
                                    src={GreenTick2}
                                    width="20"
                                    alt=""
                                  />
                                </td>
                                <td
                                  style={{
                                    padding: "0px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <span style={{ color: "#24a749" }}>
                                    {reportData.case.status}
                                  </span>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                {isPivc ? <table width="100%" style={{ marginTop: "8px" }}>
                  <tr>
                    <td
                      width="365"
                      style={{ padding: "0px 10px 0px 0px" }}
                    >
                      <table
                        className="gray-table-rows bg-f8"
                        width="365"
                      >
                        <tr>
                          <td
                            width="125"
                            style={{ paddingRight: "0px" }}
                          >
                            Speech To Text Score
                          </td>
                          <td
                            width="10"
                            align="center"
                            style={{
                              paddingRight: "10px",
                              paddingLeft: "10px",
                            }}
                          >
                            :
                          </td>
                          <td width="185">
                            {reportData.case.audio_text_similarity_score}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                  : null
                }
                {has_partners ? <table width="100%" style={{ marginTop: "8px" }}>
                  <tr>
                    <td
                      width="365"
                      style={{ padding: "0px 10px 0px 0px" }}
                    >
                      <table
                        className="gray-table-rows bg-f8"
                        width="365"
                      >
                        <tr>
                          <td
                            width="125"
                            style={{ paddingRight: "0px" }}
                          >
                            Partner
                          </td>
                          <td
                            width="10"
                            align="center"
                            style={{
                              paddingRight: "10px",
                              paddingLeft: "10px",
                            }}
                          >
                            :
                          </td>
                          <td width="185">
                            {reportData.case?.partner_name}
                          </td>
                        </tr>
                      </table>
                    </td>

                  </tr>
                </table> : null}
                {isHFCL ? <table>
                  <tr>
                    <td
                      width="365"
                      style={{ padding: "10px 10px 0px 0px" }}
                    >
                      <table
                        className="gray-table-rows bg-f8"
                        width="365"
                      >
                        <tr>
                          <td
                            width="125"
                            style={{ paddingRight: "0px" }}
                          >
                            Process Type
                          </td>
                          <td
                            width="10"
                            align="center"
                            style={{
                              paddingRight: "10px",
                              paddingLeft: "10px",
                            }}
                          >
                            :
                          </td>
                          <td width="185">
                            {
                              reportData?.customers[0]?.case_details
                                ?.process_details.name
                            }
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table> : null}
                <hr
                  style={{
                    borderTop: "1px dashed #707070 !important",
                    borderBottom: "0px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    width: "100%",
                  }}
                />
                {!isPivc ? <>
                  <h4>Agent Details</h4>
                  <table width="100%" style={{ marginTop: "15px" }}>
                    <tr>
                      <td
                        width="365"
                        style={{ padding: "0px 10px 0px 0px" }}
                      >
                        <table
                          className="gray-table-rows bg-f8"
                          width="365"
                        >
                          <tr>
                            <td
                              width="125"
                              style={{ paddingRight: "0px" }}
                            >
                              Full Name
                            </td>
                            <td
                              width="10"
                              align="center"
                              style={{
                                paddingRight: "10",
                                paddingLeft: "10px",
                              }}
                            >
                              :
                            </td>
                            <td width="185">
                              {reportData.case.agent_name}
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td
                        width="365"
                        style={{ padding: "0px 0px 0px 10px" }}
                      >
                        <table
                          className="gray-table-rows bg-f8"
                          width="365"
                        >
                          <tr>
                            <td
                              width="135"
                              style={{ paddingRight: "0px" }}
                            >
                              Identification Number
                            </td>
                            <td width="10" align="center">
                              :
                            </td>
                            <td width="185">{reportData.case.agent}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <table width="100%" style={{ marginTop: "8px" }}>
                    <tr>
                      <td
                        width="365"
                        style={{ padding: "0px 10px 0px 0px" }}
                      >
                        <table
                          className="gray-table-rows bg-f8"
                          width="365"
                        >
                          <tr>
                            <td
                              width="125"
                              style={{ paddingRight: "0px" }}
                            >
                              Mobile Number
                            </td>
                            <td
                              width="10"
                              align="center"
                              style={{
                                paddingRight: "10px",
                                paddingLeft: "10px",
                              }}
                            >
                              :
                            </td>
                            <td width="185">
                              {reportData.case.agent_mobile_number
                                ? reportData.case.agent_mobile_number
                                : "N.A."}
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td
                        width="365"
                        style={{ padding: "0px 0px 0px 10px" }}
                      >
                        <table
                          className="gray-table-rows bg-f8"
                          width="365"
                        >
                          <tr>
                            <td
                              width="135"
                              style={{ paddingRight: "0px" }}
                            >
                              Email Address
                            </td>
                            <td width="10" align="center">
                              :
                            </td>
                            <td width="185">
                              {reportData.case.agent_email}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  {reportData.notes && reportData.notes.length > 0 &&
                    <table
                      className="gray-table-rows bg-f8"
                      width="100%"
                      style={{ marginTop: "15px" }}
                    >
                      <tr>
                        <td
                          width="5%"
                          style={{ paddingRight: "0px" }}
                        >
                          Notes
                        </td>
                        <td width="1%" align="center">
                          :
                        </td>
                        <td
                          width="94%"
                          style={{
                            color: "#1c6697",
                            fontStyle: "italic",
                            lineHeight: "18px",
                          }}
                        >
                          <p
                            style={{
                              maxWidth: "400px",
                              overflowWrap: "break-word",
                              marginBottom: 0
                            }}
                          >
                            {reportData.notes.map(item => {
                              return <>{item.note},</>
                            })}
                          </p>
                        </td>
                      </tr>
                    </table>
                  }
                <table
                  className="gray-table-rows bg-f8"
                  width="100%"
                  style={{ marginTop: "15px" }}
                >
                  <tr>
                    <td
                      width="5%"
                      style={{ paddingRight: "0px" }}
                    >
                      Agent Comments
                    </td>
                    <td width="1%" align="center">
                      :
                    </td>
                    <td
                      width="94%"
                      style={{
                        color: "#1c6697",
                        fontStyle: "italic",
                        lineHeight: "18px",
                      }}
                    >
                      <p
                        style={{
                          maxWidth: "400px",
                          overflowWrap: "break-word",
                        }}
                      >
                        {reportData.customers[0]?.case_details?.case_history && reportData.customers[0]?.case_details?.case_history.filter(
                          (history) =>
                            history.type === "comment" &&
                            history.user ===
                            reportData.case.agent
                        ).length !== 0
                          ? reportData.customers[0]?.case_details.case_history.filter(
                            (history) =>
                              history.type === "comment" &&
                              history.user ===
                              reportData.case.agent
                          )[
                            reportData.customers[0]?.case_details.case_history.filter(
                              (history) =>
                                history.type === "comment" &&
                                history.user ===
                                reportData.case.agent
                            ).length - 1
                          ].re_text
                          : ""}
                      </p>
                    </td>
                  </tr>
                </table>
                </> : null}
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  )
}

export default CaseDetails