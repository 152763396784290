import React from "react";
import VerifyFront from "./VerifyFront";
import VerifyBack from "./VerifyBack";
import { connect } from "react-redux";
import { verifyAadhaar, deleteAadhar } from "../../state/user.actions";
import Loader from "../../../components/loader/Loader";
import { UPDATEDOCUMENT } from "../../../apiurl";
import api from "../../../apiBaseConfig";

class VerifyAadhaarOCR extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      frontVerified: false,
      verifiedDetails: {},
      loading: false,
    };
  }

  updateFrontVerified = (frontVerified, verifiedFront) => {
    let verifiedDetails = { ...verifiedFront };
    this.setState({
      frontVerified: frontVerified,
      verifiedDetails: verifiedDetails,
    });
  };

  handleBackVerified = (verifiedBack) => {
    const { userInfo } = this.props;
    let verifiedDetailsUpdated = {
      ...this.state.verifiedDetails,
      ...verifiedBack,
    };
    this.setState({ loading: true });
    const ocrData = {
      ...userInfo.aadharData.data,
      name: verifiedDetailsUpdated["Name"],
      gender: verifiedDetailsUpdated["Gender"],
      doc_id: verifiedDetailsUpdated["Aadhaar"],
      yob: verifiedDetailsUpdated["YOB"],
      address: verifiedDetailsUpdated["Address"],
    };
    this.verifyAadhaarOCR(ocrData);
    // this.props.verifyAadhaar({
    //   id: userInfo.aadharData.id,
    //   type: "aadharData",
    //   upload_type: userInfo.aadharData.document_upload_type,
    //   doc_id: verifiedDetailsUpdated["Aadhaar"],
    //   data: {
    //     ...userInfo.aadharData.data,
    //     name: verifiedDetailsUpdated["Name"],
    //     gender: verifiedDetailsUpdated["Gender"],
    //     doc_id: verifiedDetailsUpdated["Aadhaar"],
    //     yob: verifiedDetailsUpdated["YOB"],
    //     address: verifiedDetailsUpdated["Address"],
    //   },
    // });
  };

  verifyAadhaarOCR = async (ocrData) => {
    const { userInfo, clientInfo, userDocs } = this.props;
    const userToken = userDocs.anonymousUserConnectionInfo.connection
    const case_type = clientInfo.case_module === 5 ? 'pivc' : ''
    console.log("props.........", this.props);
    try {
      let resp = await api.patch(
        UPDATEDOCUMENT + `${userInfo.aadharData.id}/`,
        {
          corrected_ocr: ocrData,
          is_verified: true,
        }
      );
      if (case_type === 'pivc')
        this.props.history.push('/user/pivc/' + userToken);
      else
        this.props.history.push("/user");
    } catch (err) {
      console.log(err);
    }
    this.setState({ loading: false });
  };

  retry = () => {
    this.props.history.goBack();
    this.props.deleteAadhar();
  };
  handleNextDigilocker = () => {
    this.verifyAadhaarOCR(this.props.userInfo.aadharData);
  };
  render() {
    const { frontVerified, verifiedDetails, nextDisabled, loading } =
      this.state;
    return (
      <>
        {loading && <Loader title={"Validating your details, please wait"} />}
        {!frontVerified ? (
          <VerifyFront
            updateFrontVerified={this.updateFrontVerified}
            verifiedDetails={verifiedDetails}
            verifyDetails={this.verifyDetails}
            nextDisabled={nextDisabled}
            retry={this.retry}
            handleNextDigilocker={this.handleNextDigilocker}
          />
        ) : (
          <VerifyBack
            verifiedDetails={verifiedDetails}
            updateFrontVerified={this.updateFrontVerified}
            handleBackVerified={this.handleBackVerified}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userDocs.userDocInfo,
  userDocs: state.userDocs,
  clientInfo: state.login.clientInfo

});

const mapDispatchToProps = (dispatch) => ({
  verifyAadhaar: (payload) => dispatch(verifyAadhaar(payload)),
  deleteAadhar: () => dispatch(deleteAadhar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAadhaarOCR);
