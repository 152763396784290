import React, { useState } from 'react'
import add from "../../assets/icons/add-icon.svg";
import remove from "../../assets/icons/remove-icon.png";
import api from "../../apiBaseConfig";
import { ADD_PARTICIPANTS } from "../../apiurl";
import cogoToast from "cogo-toast";

function AddParticipant(props) {


    const [showForm, setShowForm] = useState(false);
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [ucic, setUcic] = useState('');
    const [guarantor, setGuarantor] = useState('');
    const [error, setError] = useState({ name: false, email: false, mobile: false });

    const saveParticipant = async (e) => {
        e.preventDefault();
        if(props.allConnections && props.allConnections > 7) {
            if(!window.confirm("Adding more users may impact the performance and quality of the video call, do you still wish to add?")) {
                return;
            }
        }
        console.log('working');
        try {
            let body = {
                "name": name,
                "email": email,
                "mobile_number": mobile,
                "participant_tag": role
            }
            // if(role !== "resparticipant") {
            //     body["source_system_ucic"] = ucic;
            //     body["guarantor_coapplicant_id"] = guarantor;
            // }
            let response =  await api.post(ADD_PARTICIPANTS + `${props.caseId}/`, body);
            if(response && response.data && response.data.detail){
                cogoToast.success(response.data.detail)
            }
            props.addConnection();
            setName('');
            setMobile('')
            setEmail('')
            setRole('')
            // setUcic('')
            // setGuarantor('')
        } catch (err) {
            console.log(err);
        }
    };


    return (
        <div className="bg-light p-2 rounded border border-2 brdr-d4 part-gray-box">
            <div className="inner">
                {/* <h5 className="txt-blue ltr-spc-1">Verification Step 1</h5> */}
                <hr className="dashed-brdr brdr-blue" />
                <div className="row add-user-form-buttons"
                style={{textAlign: 'right'}}
                >
                    <div className="col-md-10">
                        {
                            !showForm ?
                                <button className="btn btn-light-blue-secondary poppins-rglr mr-3 align-items-center add-btn"
                                    onClick={() => setShowForm(true)}>
                                    <img src={add} className="mr-1" alt="" />
                            Add another User</button>
                                :
                                <button className="btn btn-light-blue-secondary poppins-rglr mr-3 align-items-center remove-btn" onClick={() => setShowForm(false)} >
                                    <img src={remove} className="mr-1" alt="" />
                  Close Add another User</button>
                        }
                    </div>
                </div>
                {/* <!-- Pop up form starts here --> */}
                {
                    showForm &&
                    <div id="add-user-form" className="row mt-3 add-user-form pt-3" >
                        <div className="col-md-12">
                            <h5 className="mb-0 font-weight-bold">Add another user</h5>
                            <hr className="dashed-brdr brdr-blue" />
                            <div className="add-user-form">
                                <div className="row">
                                    <div className="col-md-6 d-flex align-items-center">
                                        <label className={error.name ? "error txt-blue-dull" : "txt-blue-dull"} for="">User name</label>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" value={name} onChange={(e) => {
                                                setName(e.target.value);
                                                if (!new RegExp(/^[A-Za-z]{2,60}[A-Za-z\s]{0,60}$/i).test(e.target.value)) {
                                                    setError({ ...error, name: true })
                                                } else {
                                                    setError({ ...error, name: false })
                                                }
                                            }}
                                                className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 d-flex align-items-center">
                                        <label className={error.mobile ? "error txt-blue-dull" : "txt-blue-dull"} for="">User Mobile Number</label>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" value={mobile} onChange={(e) => {
                                                if(e.target.value.length>10) return
                                                setMobile(e.target.value)
                                                if (!new RegExp(/^[0-9]{10}$/i).test(e.target.value)) {
                                                    setError({ ...error, mobile: true })
                                                } else {
                                                    setError({ ...error, mobile: false })
                                                }
                                            }} className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 d-flex align-items-center">
                                        <label className={error.email ? "error txt-blue-dull" : "txt-blue-dull"} for="">User Email Address</label>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="email" value={email} onChange={(e) => {
                                                setEmail(e.target.value)
                                                if (!new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i).test(e.target.value)) {
                                                    setError({ ...error, email: true })
                                                } else {
                                                    setError({ ...error, email: false })
                                                }
                                            }} className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 d-flex align-items-center">
                                        <label className="txt-blue-dull" for="">User Role</label>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <select name="" id="" value={role} onChange={(e) => {
                                                if(e.target.value === "resparticipant") {
                                                    setUcic("");
                                                    setGuarantor("");
                                                }
                                                setRole(e.target.value)}
                                             } className="form-control">
                                                <option value="">Select</option>
                                                <option value="applicant">Applicant</option>
                                                <option value="coapplicant">Co-Applicant</option>
                                                <option value="resparticipant">RE's Participant</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {/* {
                                    role &&
                                    role !== "resparticipant" &&
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-md-6 d-flex align-items-center">
                                                <label className="txt-blue-dull" for="">User System UCIC</label>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" value={ucic} onChange={(e) => setUcic(e.target.value)} className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 d-flex align-items-center">
                                                <label className="txt-blue-dull" for="">Guarantor co-applicant</label>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" value={guarantor} onChange={(e) => setGuarantor(e.target.value)} className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                } */}
                                <hr className="dashed-brdr brdr-blue" />
                                <div className="row mt-3 mb-3">
                                    <div className="col-md-6">
                                        <button className="btn btn-custom btn-dark btn-light-black w-100-p" disabled=""
                                            onClick={() => {
                                                setName('');
                                                setMobile('')
                                                setEmail('')
                                                setRole('')
                                                // setUcic('')
                                                // setGuarantor('')
                                            }} style={{ width: '94px' }}>Reset</button>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <button className="btn btn-custom btn-light-blue w-100-p"
                                            onClick={(e) => saveParticipant(e)}
                                            disabled={!(name && mobile && email && role.length )
                                                || error.email || error.mobile || error.name}
                                            style={{ width: '94px' }}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {/* <!-- Pop up form ends here --> */}
            </div>
        </div>
    )
}

export default AddParticipant
