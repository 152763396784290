import React, { useState } from 'react';
import UpperTab from '../../../../../agent/screens/agentDashboard/components/upperTab/UpperTab';
import api from "../../../../../apiBaseConfig";
import { CSV_DOWNLOAD } from "../../../../../apiurl";
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import DataCharts from '../dataCharts';


const TabList = {1:["VKYC"],2:["Group Discussion"],3:["VKYC", "Group Discussion"]}
function Home(props) {
    const {case_module} = useSelector(state => state.login.clientInfo)
    const [activeUpperTab, setActiveUpperTab] = useState(0);
    const [filter, setFilter] = useState(null);

    const handleDownload = async () => {

        const startDate = moment(new Date()).subtract(filter, 'days').format('YYYY-MM-DD');
        // @Todo ask number of possible case_type
        const case_type = case_module === 2 ? 'Personal Discussion' : activeUpperTab === 0 ? "Video KYC" : "Personal Discussion";
        // const case_type = activeUpperTab === 0 ? 'Video KYC' : 'Personal Discussion'
        const res = await api.get(
            CSV_DOWNLOAD + `?case_type=${case_type}&case_updated_at_low=${startDate}`
        );
        download('report.csv', res.data);
    }

    const download = (filename, text) => {
        var pom = document.createElement('a');
        pom.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
        pom.setAttribute('download', filename);
        pom.click();
    }

    return (
        <div className="container-fluid p-0">
            <div className="row no-gutters bg-light pt-2 header-2">
                <div className="col-md-6">
                    <ul className="nav nav-tabs big-nav-tabs-2 ml-30 mt-3 border-0" id="myTab">
                        {TabList[case_module] && TabList[case_module].map((tab, index) => {
                            return <UpperTab
                                tabKey={index}
                                activeUpperTab={activeUpperTab}
                                changeActiveUpperTab={setActiveUpperTab}
                                upperTabText={tab}
                            />
                        })}
                    </ul>
                </div>
                <div className="col-md-6 text-right top-right">
                </div>
            </div>
            <div className="row no-gutters">
                <div className="col-md-12">
                    <div className="tab-content ml-30 mr-30 mt-4" id="myTab">
                        <div className="tab-pane fade show active" id="vkyc">
                            <div className="container-fluid" style={{ 'padding': 0 }}>
                                <div className="row mb-3">
                                    <div className="col-md-5 d-flex">
                                        <select name="" id="vkyc" value={filter} onChange={(e) => setFilter(e.target.value)} className="form-control fnt-sml">
                                            <option value="0">Today</option>
                                            <option value="7">Last 7 days</option>
                                            <option value="30">Last 30 days</option>
                                            <option value="90">Last 90 days</option>
                                        </select>
                                        <button onClick={handleDownload} class="btn btn-light-blue poppins-rglr ml-3">
                                            Download
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DataCharts activeUpperTab={activeUpperTab} case_module = {case_module} />
        </div>
    );
}

export default Home;