import React, { useEffect, useState } from 'react'
import UserHeader from "../../userHeader/userHeader";
import api, { instance2 } from "../../../apiBaseConfig";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { getClientConfig, updateClientName } from "../../../login/state/login.actions";
import _get from "lodash/get";
import SessionDetails from "../sessionDetails/SessionDetails";
import Loader from "../../../components/loader/Loader";
import CustomerConsent from "./Consent";
import UploadDocs from './UploadDocs'
import UploadDocsMulti from "../uploadDocsMulti/UploadDocsMulti";

import {
  storeAnonymousUserConnectionInfo,
  resetUserDocs,
  presetUpload,
  isLives,
} from "../../state/user.actions";

import {
  UPLOAD,
  GET_CASE_CONNECTIONS_BLOCK,
  GET_DOCUMENTS,
} from "../../../apiurl";

const STEPS = {
  "video_kyc" :[
    { name : 'Consent',
      isActive : true,
      Component : CustomerConsent,
      defaultProps:{}
    },
    { name : 'UploadDocs',
      isActive : true,
      Component : UploadDocs,
      defaultProps:{}
    },
  ],
  "personal_discussion":[
    { name : 'Consent',
      isActive : true,
      Component : CustomerConsent,
      defaultProps:{}
    },
    { name : 'UploadDocs',
      isActive : true,
      // Component : UploadDocsMulti,
      Component : UploadDocs,
      defaultProps:{isCallOngoing : false }
    },
  ]
}

export default function CustomerLanding() {
  let userInfo = useSelector(state => state.userDocs.anonymousUserConnectionInfo)
  const [showLoader, setLoader] = useState(true);
  const [activeStep, setActiveStep] = useState(0)
  const [caseType, setCaseType] = useState(null)
  const [errorMsg, setErrorMsg] = useState({
    title: "Error",
    description: "Please try again 1",
  });
  const dispatch = useDispatch()
  const { userToken } = useParams();

  useEffect(() => {
    let isLive = (window.location.href).includes('live')
    if(userToken)
     dispatch(isLives(isLive))
    if(userInfo?.case_details?.case_type)
     setCaseType(userInfo.case_details.case_type)
    if (userToken && userToken !== userInfo.connection) validateUserToken(true)
    else if (userInfo.connection) {
      if (userToken)  validateUserToken()
      else {
        // Todo check if this can be removed
        userInfo.isTokenValid = true;
        dispatch(storeAnonymousUserConnectionInfo(userInfo))
        dispatch(getClientConfig(userInfo.case_details.client))
        setLoader(false);
        if(userInfo.isConsent && activeStep == 0){
          setActiveStep(activeStep+1)
        }
      }
    } else {
      userInfo.isTokenValid = false;
      dispatch(storeAnonymousUserConnectionInfo(userInfo))
      setLoader(false);

    }
  
  }, [])



  async function validateUserToken (newToken) {
    let old = userInfo
    try {
      let config = {
        headers: {
          AuthorizationX: `Token ${userToken}`,
        },
      };
      const response = await instance2.get(
        `${GET_CASE_CONNECTIONS_BLOCK}${userToken}/`,
        config
      );

      let newUserInfo = response.data;
      if(old && old.connection && !newToken){
        if(old.connection === userToken && old.isConsent) newUserInfo.isConsent = true
      }
      dispatch(resetUserDocs());

      newUserInfo.isTokenValid = true;
      
      newUserInfo.multiUser =
      newUserInfo.case_details.case_type === "video_kyc"
          ? false
          : true;
      setCaseType(newUserInfo.case_details.case_type)    
      dispatch(storeAnonymousUserConnectionInfo(newUserInfo)); 
      dispatch(getClientConfig(newUserInfo.case_details.client))
      getClientDetails(newUserInfo.case_details.client)

      if(!newToken && newUserInfo.isConsent && activeStep == 0){
        setActiveStep(activeStep+1)
      }

      setLoader(false);
    } catch (error) {
      console.log("error", error);
    
      setErrorMsg({
        title: _get(error, "response.data.title", "Error"),
        description: _get(
          error,
          "response.data.description",
          "Please try again."
        ),
      });
      userInfo.isTokenValid = false;
      dispatch(storeAnonymousUserConnectionInfo(userInfo)); //

      setLoader(false);
    }
  };

  async function getClientDetails(){
    const response = await instance2.get(
      `/client/clients/`,
      {
        headers: {
          AuthorizationX: `Token ${userToken}`,
        },
      }
    );
    let payload = {}
    payload.name = response.data[0].name
    dispatch(updateClientName(payload))
  
  }

  function handleNext (e){
     setActiveStep(old=>old+1)
  }
  function handleBackClick(e){
    if(activeStep === 1){
      userInfo.isConsent = false
      dispatch(storeAnonymousUserConnectionInfo(userInfo)); 
      setTimeout(()=>window.location.reload(),400)
    } else{
      e.goBack()
    }
  }
  return (
    <>
    {showLoader ? (
        <Loader />
      ) : userInfo.isTokenValid ? <div className="wrapper light-blue-customer-template">
         <UserHeader handleBackClick={(e)=> handleBackClick(e)} />
         {caseType ? <WithComponent Component={STEPS[caseType][activeStep].Component} {...STEPS[caseType][activeStep].defaultProps} handleNext = {(e)=>handleNext(e)}  /> : null}
      </div> :<SessionDetails errorMsg={errorMsg} /> }
    </>
  )
}

const WithComponent =({Component, ...props}) =>{
  return <Component {...props} />
}
