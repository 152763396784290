import React from "react";
import GreenTick from "../../assets/icons/green-tick.png";
class Screenshots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enlargeImage: false,
    };
  }
  render() {
    const { screenshotData } = this.props;
    const { enlargeImage } = this.state;

    return (
      <table
        className="gray-table-rows bg-f8"
        width="750"
        style={{
          marginTop: "15px",
          marginLeft: "0px",
          marginBottom: "15px",
        }}
      >
        <tr>
          <td width="30.5%" className="capitalize" style={{ paddingRight: "0px" }}>
            {screenshotData.document_details.name}:
          </td>
          <td width="38.5%" style={{ paddingLeft: "0px" }}>
            <img
              src={screenshotData.front_upload}
              onClick={() =>
                this.setState({
                  enlargeImage: !enlargeImage,
                })
              }
              crossOrigin="anonymous"
              alt=""
              width="100"
              style={{
                marginLeft: "15px",
                cursor: "pointer",
              }}
              className="img-fluid rounded"
            />
            {enlargeImage && (
              <img
                src={screenshotData.front_upload}
                className="rounded border doc-enlarge"
                alt=""
                crossOrigin="anonymous"
                onClick={() =>
                  this.setState({
                    enlargeImage: !enlargeImage,
                  })
                }
                style={{ cursor: "pointer" }}
              />
            )}
          </td>
          <td align="right" width="31%" style={{ padding: "0px" }}>
            <table width="100%">
              <tr>
                <td
                  width="30"
                  style={{
                    paddingRight: "0px",
                    padding: "0px",
                  }}
                >
                  <img src={GreenTick} width="20" alt="" />
                </td>
                <td
                  style={{
                    padding: "0px",
                    paddingLeft: "0px",
                    paddingRight: "10px",
                  }}
                >
                  <span
                    style={{
                      color: "#24a749",
                      fontStyle: "italic",
                    }}
                  >
                    Screenshot captured successfully
                  </span>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    );
  }
}

export default Screenshots;
