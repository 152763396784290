import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    RESCHEDULE_SAME_AGENT, RESCHEDULE_SAME_AGENT_NORMAL
} from "../../../apiurl";
import { useHistory } from "react-router";
import api from "../../../apiBaseConfig";
import moment from "moment";
import Loader from '../../../components/loader/Loader';
import { ReactComponent as VideoIcon } from "../../../assets/icons/video-icon.svg";

function RescheduleAgent(props) {

    const [scheduleTimes, setScheduleTimes] = useState([]);
    const [selectedScheduleTime, setSelectedScheduleTime] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const history = useHistory();
    const location = useLocation();

    useEffect(async () => {
        try {
            setLoading(true);
            const { data } = await api.get(location.state.livecase ? RESCHEDULE_SAME_AGENT : RESCHEDULE_SAME_AGENT_NORMAL)
            setScheduleTimes(data.time_slots.slice(0, 20));
            setLoading(false);
        } catch (e) {
            console.log(e)
        }
    }, [])

    const handleSchedule = async () => {
        const url = location.state.livecase ? RESCHEDULE_SAME_AGENT : RESCHEDULE_SAME_AGENT_NORMAL;
        try {
            setLoading(true);
            const { data } = await api.post(url, { schedule_time: selectedScheduleTime, agent: location.state.agentId })
            setLoading(false);
            history.push("/thankyou")
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }


    if (isLoading) {
        return <Loader />
    }
    return (
        <div className="wrapper light-blue-customer-template bg-dark-blue h-100">
            <section className="main-body m-auto">
                <div className="row m-0">
                    <div className="col-md-12">
                        <VideoIcon className="mt-4 mb-4" width="50" height="50" fill="#fff" />
                        <h4 className="text-white ltr-spc-1" style={{ lineHeight: '26px' }}>You choose to Reschedule the call with the same agent</h4>
                        <h6 className="text-white ltr-spc-1 mt-4 mb-4">Please schedule the call as per the availability below</h6>
                    </div>
                </div>
                <div className="container-fluid position-relative overflow-auto" style={{ height: '255px' }}>
                    {
                        <React.Fragment>
                            {scheduleTimes.map(timeSlot =>
                                <div className="row mt-4">
                                    <div className="col-12 mb-3">
                                        <div className={selectedScheduleTime === timeSlot[0] ? 'position-relative active' : 'position-relative'}>
                                            <div className="form-control pl-4 white-input align-items-center d-flex" style={{ height: '45px' }}>{moment(timeSlot[0]).format('Do MMM YYYY')} at {moment(timeSlot[0]).format('h:mm a')}</div>
                                            <span onClick={() => setSelectedScheduleTime(timeSlot[0])} className="circle-tick-icon icon-position-right mr-3"></span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    }
                </div>
                <div className="row mt-2 m-0">
                    <div className="col-12 text-right">
                        <button onClick={handleSchedule} className="btn btn-purple fnt-sml" disabled={!selectedScheduleTime} style={{ height: '45px', minWidth: '160px' }}>Schedule</button>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default RescheduleAgent;