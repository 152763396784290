import cogoToast from "cogo-toast";

export const constants = {};

export const Colors = {
  lightGreen: "#4ED960",
  lightBlue: "#5EB9FF",
  grey: "#343a40"
};

export const FaceMatchTypes = {
  1: 'MAX_FACEMATCH_SCORE',
  2: 'average_face_match_score',
  3: 'ALL_DOC_FACEMATCH_SCORE',
  4: 'aadhar_face_match_score',
  5: 'pan_face_match_score',
  6: 'driving_license_face_match_score',
  7: 'voter_id_face_match_score'
}
export const PAN_CHECK = (val) => {
  let result = new RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/).test(val)
  return result
}
export const NAME_CHECK = (val) => {
  let result = new RegExp(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g).test(val)
  return result
}
export const EMAIL_CHECK = (val) => {
  let result = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i).test(val)
  return result
}
export const MOBILE_CHECK = (val) => {
  let result = new RegExp(/^[1-9]+[0-9]{9}$/g).test(val)
  return result
}
export const ALPHA_NUM_CHECK = (val) => {
  let result = new RegExp(/^[a-zA-Z0-9_]*$/i).test(val)
  return result
}

export const NUM_CHECK = (val) => {
  let result = new RegExp(/^[1-9]+[0-9]*$/i).test(val)
  return result
}
export const ALPHA_CHECK = (val) => {
  let result = new RegExp(/^[ A-Za-z]{2,60}[A-Za-z\s]{0,60}$/i).test(val)
  return result
}
export const SYSTEM_UCIC_CHECK = (val) => {
  let result = new RegExp(/^[1-9]+[0-9]{5}$/g).test(val)
  return result
}
export const panTandC = [
  { isTick: true, isCancel: false, type: null },
  { isTick: true, isCancel: false, type: null },
  { isTick: true, isCancel: false, type: null },
  { isTick: true, isCancel: false, type: null },
]
export const aadhaarTandC = [
  { isTick: true, isCancel: false, type: null },
  { isTick: true, isCancel: false, type: null },
  { isTick: true, isCancel: false, type: null },
  { isTick: true, isCancel: false, type: null },
]
export const dlTandC = [
  { isTick: false, isCancel: false, type: null },
  { isTick: false, isCancel: false, type: null },
  { isTick: false, isCancel: false, type: null },
  { isTick: false, isCancel: false, type: null },
  { isTick: false, isCancel: false, type: null },
]
export const ErrorExtract = (error) => {
  let message = ''
  if (Object.keys(error).length > 0) {
    Object.keys(error).map(item => {
      if (Array.isArray(error[item])) {
        error[item].map(subItem => {
          message += subItem
        })
      }
      else {
        message = error[item]
      }
    })
  }
  cogoToast.error(message)
}
export const _ToNormal = (str) => {
  if (str === 'DoB') {
    return str
  }
  return CapitalizeFirstLetter(str.replace(/_/g, ' '))
}
export const CapitalizeFirstLetter = (string) => {
  string = string.toLowerCase();
  string = string.charAt(0).toUpperCase() + string.slice(1)
  return string.split(" ").reduce((s, c) => s + ' ' + (c.charAt(0).toUpperCase() + c.slice(1)));
}
export const CHECK_DOC_TYPE = (url) => {
  let parsedUrl = new URL(url)
  let fileType = 'image'
  if (/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(parsedUrl.pathname)) {
    fileType = 'image'
  }
  else if (/\.(pdf)$/.test(parsedUrl.pathname)) {
    fileType = 'pdf'
  }
  else if (/\.(html)$/.test(parsedUrl.pathname)) {
    fileType = 'html'
  }
  else {
    fileType = 'doc'
  }
  return fileType

}


