import React from "react";
import { connect } from "react-redux";
import { varify } from "../../state/user.actions";
import UserHeader from "../../userHeader/userHeader";
import VerificationInputDL from "./VerificationInputDL";
import "../verifyPan/VerifyPan.scss";

class VerifyFront extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verifiedFront: {
        Name: "",
        DOB: "",
        "Father's Name": "",
        "DL Number": "",
      },
      nextDisabled: true,
    };
  }
  verifyDetails = ({ type, data }) => {
    let verifiedFront = this.state.verifiedFront;
    verifiedFront[type] = data;
    let nextDisabled = false;
    for (var i in verifiedFront) {
      if (verifiedFront[i] === "") {
        nextDisabled = true;
      }
    }
    this.setState({
      verifiedFront: verifiedFront,
      nextDisabled: nextDisabled,
    });
  };
  handleNext = () => {
    this.props.updateFrontVerified(true, this.state.verifiedFront);
  };

  render() {
    const { userInfo } = this.props;
    const { verifiedFront, nextDisabled } = this.state;

    return (
      <React.Fragment>
        {userInfo && userInfo.dlData && (
          <div className="wrapper light-blue-customer-template">
            <UserHeader />
            <section className="main-body m-auto customer-flow-section">
              <div className="container-fluid mt-4">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="txt-light-blue ltr-spc-1">
                      Driving License Front Upload
                    </h4>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="border border-1 dashed-brdr  rounded p-4 m mb-2">
                      <img
                        src={userInfo.dlData.image.front.data}
                        alt=""
                        className="img-fluid rounded"
                      />
                      {userInfo.dlData.document_upload_type ===
                        "ocr_upload" && (
                        <React.Fragment>
                          <div className="txt-81 fnt-sml mt-2 ltr-spc-1 mt-3 mb-3">
                            Information Extracted from Image
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-12">
                              <VerificationInputDL
                                preInput="Name"
                                onChange={this.verifyDetails}
                                defaultValue={
                                  userInfo.dlData.data
                                    ? userInfo.dlData.data.name
                                    : ""
                                }
                                width={"100%"}
                                verificationKey={0}
                                readOnly={false}
                                isChecked={
                                  verifiedFront["Name"] !== "" ? true : false
                                }
                              />
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-12">
                              <VerificationInputDL
                                preInput="DOB"
                                onChange={this.verifyDetails}
                                defaultValue={
                                  userInfo.dlData.data
                                    ? userInfo.dlData.data.dob
                                    : ""
                                }
                                width={"100%"}
                                verificationKey={1}
                                readOnly={false}
                                isChecked={
                                  verifiedFront["DOB"] !== "" ? true : false
                                }
                              />
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-12">
                              <VerificationInputDL
                                preInput="Father's Name"
                                onChange={this.verifyDetails}
                                defaultValue={
                                  userInfo.dlData.data
                                    ? userInfo.dlData.data.father_name
                                    : ""
                                }
                                width={"100%"}
                                verificationKey={2}
                                readOnly={false}
                                isChecked={
                                  verifiedFront["Father's Name"] !== ""
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>

                          <div className="row mb-2">
                            <div className="col-md-12">
                              <VerificationInputDL
                                preInput="DL Number"
                                onChange={this.verifyDetails}
                                defaultValue={
                                  userInfo.dlData.data
                                    ? userInfo.dlData.data.doc_id
                                    : ""
                                }
                                width={"100%"}
                                verificationKey={3}
                                readOnly={false}
                                isChecked={
                                  verifiedFront["DL Number"] !== ""
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-3 mb-3">
                  <div className="col-md-12  d-flex justify-content-between">
                    <button
                      onClick={this.props.retry}
                      className="btn btn-custom btn-dark btn-light-black w-100-p"
                      // style={{ visibility: "hidden" }}
                    >
                      ReTry
                    </button>

                    {userInfo &&
                    userInfo.dlData &&
                    userInfo.dlData.document_upload_type ===
                      "digilocker_upload" ? (
                      <button
                        disabled={false}
                        onClick={this.props.handleNextDigilocker}
                        className="btn btn-custom btn-light-blue w-100-p"
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        disabled={nextDisabled}
                        onClick={this.handleNext}
                        className="btn btn-custom btn-light-blue w-100-p"
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userDocs.userDocInfo,
});

const mapDispatchToProps = (dispatch) => ({
  varify: (payload) => dispatch(varify(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyFront);
