import React from "react";
import Moment from "react-moment";
import { ReactComponent as MenuIcon } from "../../../../../assets/icons/menu-icon.svg";
import { ReactComponent as EyeIcon } from "../../../../../assets/icons/eye-icon.svg";
import { GET_CASES, GET_AGENT_LIST } from "../../../../../apiurl";
import api from "../../../../../apiBaseConfig";
import Scheduler from "../scheduler/Scheduler";
import Loader from "../../../../../components/loader/Loader";
import moment from "moment";
import SingleUserModal from "../singleUserModal/SingleUserModal";
import GroupModal from "../groupModal/GroupModal";
import { connect } from "react-redux"

class ScheduledCases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuClickedIndex: null,
      schedularClickedIndex: null,
      activeIndex: "",
      showModal: false,
      isLoading: false,
    };
  }


  handleSchedularClick = (index) => {
    let schedularClickedIndex = this.state.schedularClickedIndex;
    if (index === schedularClickedIndex) {
      schedularClickedIndex = null;
    } else {
      schedularClickedIndex = index;
    }
    this.setState({
      schedularClickedIndex: schedularClickedIndex,
      menuClickedIndex: null,
    });
  };

  handleMenuClick = (index) => {
    let menuClickedIndex = this.state.menuClickedIndex;
    if (index === menuClickedIndex) {
      menuClickedIndex = null;
    } else {
      menuClickedIndex = index;
    }
    this.setState({
      menuClickedIndex: menuClickedIndex,
      schedularClickedIndex: null,
    });
  };

  handleModal = (showModal, activeIndex) => {
    // this.props.modalOpen(showModal);
    this.setState({ showModal, activeIndex });
  };

  closeSchedulerModal = () => {
    // const {scheduledCases} = this.props;
    let schedularClickedIndex = null;
    this.setState({ schedularClickedIndex: schedularClickedIndex });
  };

  backToAssign = async (caseId) => {
    this.setState({ isLoading: true });
    await api.patch(`${GET_CASES}${caseId}/`, {
      status: "assigned",
    });

    this.setState({ isLoading: false });
    this.props.fetchUpdatedCases();
  };

  render() {
    const { scheduledCases, activeUpperTab, searchKeyObject, categoryColor, clientInfo } =
      this.props;
    const { has_partners } = clientInfo
    const {
      menuClickedIndex,
      schedularClickedIndex,
      isLoading,
      activeIndex,
      showModal,
    } = this.state;
    const inactiveMinute = activeUpperTab === 1 ? 60 : 30;

    return (
      <React.Fragment>
        {isLoading && <Loader />}

        <div
          className="tab-pane caseContainer show active"
          id="scheduled"
          role="tabpanel"
          aria-labelledby="assigned-tab"
        >
          <div className="row mt-2">
            <div className="col-md-10">
              <table className="table text-center sml-blue-header mb-0">
                <thead>
                  <tr>
                    <th width="5%">Photo</th>
                    <th width="10%">Meeting Id</th>
                    <th width="13%">
                      {activeUpperTab === 1 ? "Client name" : "Customer Name"}
                    </th>
                    {has_partners ? <th width="10%">Partner</th> : null}
                    <th width="15%">Scheduled Date</th>
                    <th width="11%">Process</th>
                    <th width="12%">Category</th>
                    <th width="17%">Comments</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div class="col-md-2">
              <table class="table sml-blue-header mb-0 text-left">
                <thead>
                  <tr>
                    <th width="19%">Actions</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>

          {Object.keys(searchKeyObject).length !== 0 &&
            scheduledCases.length === 0 && (
              <div style={{ textAlign: "center", marginTop: "50px" }}>
                <h4>No case found</h4>
              </div>
            )}

          {scheduledCases.map((selectedCase, index) => {
           let session = selectedCase.session
           return <div className="row" key={index}>
              <div className="col-md-10">
                <div className="tbl-row">
                  <table className="table mb-0 sml-blue-header">
                    <tbody
                      data-toggle="modal"
                      data-target="#cstmrDtls"
                      className="pointer"
                      onClick={(e) => {
                        this.handleModal(true, index);
                      }}
                    >
                      <tr style={{ textAlign: "center" }}>
                        <td width="5%">
                          <span className="user-blue-circle rounded-circle">
                            {selectedCase.name
                              ? selectedCase.name.charAt(0)
                              : ""}
                          </span>
                        </td>
                        <td width="10%">{selectedCase.id}</td>
                        <td width="13%">{selectedCase.name}</td>
                        {has_partners ? <td width="10%">{selectedCase?.partner_name}</td> : null}
                        <td width="15%">
                          <span className="txt-light-blue font-italic">
                            { session && session.length > 0 && (
                              [
                                session.length - 1
                              ]
                            ).scheduled_time !== null ? (
                              <Moment format="DD MMM YYYY LT">
                                {
                                  (
                                    session[
                                      session.length - 1
                                    ] || {}
                                  ).scheduled_time
                                }
                              </Moment>
                            ) : (
                              ""
                            )}
                          </span>
                        </td>

                        <td width="11%">
                          {selectedCase.process_details
                            ? selectedCase.process_details.name
                            : ""}
                        </td>
                        <td width="12%">
                          <span
                            className={"status"}
                            style={{
                              backgroundColor:
                                categoryColor[selectedCase.case_tag],
                              color: categoryColor[selectedCase.case_tag]
                                ? categoryColor[selectedCase.case_tag].slice(
                                    0,
                                    -2
                                  )
                                : "",
                            }}
                          >
                            {selectedCase.case_tag.includes("_")
                              ? ""
                              : selectedCase.case_tag}
                          </span>
                        </td>
                        <td width="17%" className="text-break">
                          <div className="font-italic">
                            {selectedCase.case_history.filter(
                              (history) => history.type === "comment"
                            ).length !== 0
                              ? selectedCase.case_history.filter(
                                  (history) => history.type === "comment"
                                )[
                                  selectedCase.case_history.filter(
                                    (history) => history.type === "comment"
                                  ).length - 1
                                ].re_text
                              : ""}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-2">
                <div className="">
                  <table className="table mb-0 sml-blue-header">
                    <tbody>
                      <tr>
                        <td
                          width="19%"
                          className={
                            menuClickedIndex === index
                              ? "gray-circle-btns show text-center"
                              : "gray-circle-btns text-center"
                          }
                        >
                          <span
                            className={
                              moment(
                                (
                                  selectedCase.session[
                                    selectedCase.session.length - 1
                                  ] || {}
                                ).scheduled_time
                              ).isBefore() &&
                              moment(
                                (
                                  selectedCase.session[
                                    selectedCase.session.length - 1
                                  ] || {}
                                ).scheduled_time
                              ).isAfter(
                                moment().subtract(inactiveMinute, "minutes")
                              ) &&
                              !this.props.isLiveCaseToggleOn
                                ? "m-0 call-white-icon bg-blue"
                                : activeUpperTab === 1
                                ? moment(
                                    (
                                      selectedCase.session[
                                        selectedCase.session.length - 1
                                      ] || {}
                                    ).scheduled_time
                                  ).isBefore()
                                  ? "m-0 call-white-icon bg-blue"
                                  : "m-0"
                                : "m-0"
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                (activeUpperTab === 1
                                  ? moment(
                                      (
                                        selectedCase.session[
                                          selectedCase.session.length - 1
                                        ] || {}
                                      ).scheduled_time
                                    ).isBefore()
                                  : moment(
                                      (
                                        selectedCase.session[
                                          selectedCase.session.length - 1
                                        ] || {}
                                      ).scheduled_time
                                    ).isAfter(
                                      moment().subtract(
                                        inactiveMinute,
                                        "minutes"
                                      )
                                    ) &&
                                    moment(
                                      (
                                        selectedCase.session[
                                          selectedCase.session.length - 1
                                        ] || {}
                                      ).scheduled_time
                                    ).isBefore()) &&
                                !this.props.isLiveCaseToggleOn
                              ) {
                                this.props.createAndStartSession(
                                  selectedCase.id,
                                  selectedCase.name,
                                  selectedCase.platform_type
                                );
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                            >
                              <defs>
                                <clipPath id="clip-Cancel_call">
                                  <rect width="22" height="22" />
                                </clipPath>
                              </defs>
                              <g
                                id="Cancel_call"
                                data-name="Cancel call"
                                clipPath="url(#clip-Cancel_call)"
                              >
                                <g
                                  id="cancel-phone-call-auricular-symbol-with-a-cross"
                                  transform="translate(-0.749 0.435)"
                                >
                                  <g
                                    id="Group_624"
                                    data-name="Group 624"
                                    transform="translate(1.187)"
                                  >
                                    <path
                                      id="Path_255"
                                      data-name="Path 255"
                                      d="M5.744,0A4.255,4.255,0,0,0,2.225,1.965C.617,4.379.714,9.026,3.675,12.326a77.052,77.052,0,0,0,6.88,6.486,8.4,8.4,0,0,0,5.285,1.957,7.521,7.521,0,0,0,.9-.054A5.683,5.683,0,0,0,21.773,16.9a1.469,1.469,0,0,0-.828-1.739L17,13.413a1.621,1.621,0,0,0-.654-.128,1.891,1.891,0,0,0-1.277.472l-1.243,1.2a1.242,1.242,0,0,1-.817.3.746.746,0,0,1-.331-.067A13.5,13.5,0,0,1,9,12.321,14.339,14.339,0,0,1,6.712,9.339,1.132,1.132,0,0,1,6.982,8.2L8.088,7.1a2.067,2.067,0,0,0,.471-1.926L7.315,1.223A1.772,1.772,0,0,0,5.744,0ZM7.59,6.6,6.483,7.7a1.834,1.834,0,0,0-.4,1.961,15.52,15.52,0,0,0,2.4,3.138,14.208,14.208,0,0,0,3.874,3.019,1.454,1.454,0,0,0,.648.139,1.935,1.935,0,0,0,1.306-.5l1.244-1.205a1.249,1.249,0,0,1,1.154-.205l3.945,1.753a.779.779,0,0,1,.449.862,5.009,5.009,0,0,1-4.448,3.342,6.969,6.969,0,0,1-.82.049,7.88,7.88,0,0,1-4.823-1.785A76.656,76.656,0,0,1,4.2,11.854C1.548,8.9,1.341,4.559,2.81,2.354A3.592,3.592,0,0,1,5.781.7a1.1,1.1,0,0,1,.861.73L7.886,5.382A1.366,1.366,0,0,1,7.59,6.6Z"
                                      transform="translate(-1.187)"
                                      fill="#c80950"
                                    />
                                    <path
                                      id="Path_256"
                                      data-name="Path 256"
                                      d="M184.488,117.23a1.316,1.316,0,0,0-.835-.289,1.355,1.355,0,0,0-1.04.473l-.159.193a.2.2,0,0,1-.317,0l-.158-.193a1.352,1.352,0,0,0-1.039-.473,1.308,1.308,0,0,0-1.322,1.319,1.337,1.337,0,0,0,.3.845l.353.43a.594.594,0,0,1,0,.726l-.354.431a1.332,1.332,0,0,0,2.059,1.691l.158-.194a.2.2,0,0,1,.318,0l.159.193a1.35,1.35,0,0,0,1.039.474,1.316,1.316,0,0,0,.835-.29,1.333,1.333,0,0,0,.184-1.874l-.353-.43a.6.6,0,0,1,0-.726l.353-.43a1.333,1.333,0,0,0-.184-1.875Zm-1.082,2.3a.6.6,0,0,0,0,.726l.721.877a.627.627,0,0,1-.087.883.665.665,0,0,1-.883-.087l-.564-.685a.371.371,0,0,0-.6,0l-.563.686a.647.647,0,0,1-.883.087.628.628,0,0,1-.229-.486.62.62,0,0,1,.142-.4l.721-.877a.594.594,0,0,0,0-.726l-.721-.876a.629.629,0,0,1,.086-.883.627.627,0,0,1,.883.085l.564.686a.371.371,0,0,0,.6,0l.564-.686a.671.671,0,0,1,.883-.086.627.627,0,0,1,.085.883Z"
                                      transform="translate(-170.854 -111.196)"
                                      fill="#c80950"
                                    />
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </span>

                          <span
                            className="dropdown dropleft menu-icon"
                            id="dropdownMenuButton2"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.handleMenuClick(index);
                            }}
                            // data-toggle="dropdown"
                          >
                            <MenuIcon className="dropdown-toggle" />
                            <div
                              className={
                                menuClickedIndex === index
                                  ? "dropdown-menu border-0 menu-box fnt-sml show"
                                  : "dropdown-menu border-0 menu-box fnt-sml "
                              }
                            >
                              {/* <a className="dropdown-item" href="#">
<span className="reschedule-call-icon">
  <svg xmlns="http://www.w3.org/2000/svg" 
  xmlnsXlink="http://www.w3.org/1999/xlink" 
  width="100%" viewBox="0 0 24 23">
<defs>
<clipPath id="clip-Reschedule_calls">
<rect width="24" height="23"/>
</clipPath>
</defs>
<g id="Reschedule_calls" data-name="Reschedule calls" clipPath="url(#clip-Reschedule_calls)">
<g id="Page-1" transform="translate(-0.553)">
<g id="_056---Time-Zone-Change" data-name="056---Time-Zone-Change" transform="translate(1)">
  <path id="Shape" d="M6.148,24.574c.005.006.007.014.013.019s.013.008.019.013a7.353,7.353,0,0,0,5.2,2.148c.191,0,.381-.01.567-.024v.024a7.353,7.353,0,0,0,2.148,5.2c.005.006.007.014.013.019s.013.008.019.013a7.361,7.361,0,0,0,10.393,0c.006-.005.014-.007.019-.013s.008-.013.013-.019a7.361,7.361,0,0,0,0-10.393c-.005-.006-.007-.014-.013-.019s-.013-.008-.019-.013a7.353,7.353,0,0,0-5.2-2.148c-.192,0-.381.014-.567.029v-.029a7.353,7.353,0,0,0-2.148-5.2c-.005-.006-.007-.014-.013-.019s-.013-.008-.019-.013a7.361,7.361,0,0,0-10.393,0c-.006.005-.014.007-.019.013s-.008.013-.013.019a7.361,7.361,0,0,0,0,10.393Zm8.38-1.811.38.38a.567.567,0,1,0,.8-.8l-.38-.38a6.211,6.211,0,0,1,3.424-1.419v.539a.567.567,0,1,0,1.135,0v-.539a6.211,6.211,0,0,1,3.424,1.419l-.38.38a.567.567,0,1,0,.8.8l.38-.38a6.211,6.211,0,0,1,1.419,3.425H25a.567.567,0,0,0,0,1.135h.539a6.211,6.211,0,0,1-1.419,3.424l-.38-.38a.567.567,0,0,0-.8.8l.38.38a6.211,6.211,0,0,1-3.425,1.419v-.539a.567.567,0,1,0-1.135,0v.539a6.211,6.211,0,0,1-3.424-1.419l.38-.38a.567.567,0,1,0-.8-.8l-.38.38a6.211,6.211,0,0,1-1.419-3.425h.539a.567.567,0,1,0,0-1.135h-.539a6.211,6.211,0,0,1,1.419-3.424Zm3.081-3.178a7.371,7.371,0,0,0-3.484,1.939c-.006.005-.014.007-.019.013s-.008.013-.013.019a7.36,7.36,0,0,0-2.043,4.022A6.135,6.135,0,0,1,7.388,24.17l.38-.38a.567.567,0,1,0-.8-.8l-.38.38a6.211,6.211,0,0,1-1.421-3.423H5.7a.567.567,0,1,0,0-1.135H5.164a6.211,6.211,0,0,1,1.419-3.424l.38.38a.567.567,0,1,0,.8-.8l-.38-.38a6.211,6.211,0,0,1,3.425-1.419V13.7a.567.567,0,1,0,1.135,0v-.539a6.211,6.211,0,0,1,3.424,1.419l-.38.38a.567.567,0,1,0,.8.8l.38-.38a6.215,6.215,0,0,1,1.448,3.992c0,.071-.009.139-.011.209Z" transform="translate(-4 -12)" fill="#929292"/>
  <path id="Shape-2" data-name="Shape" d="M29.7,37.107a1.7,1.7,0,0,0,1.7-1.7c0-.037-.009-.072-.011-.108l2.5-1.072a.567.567,0,0,0-.447-1.043l-2.5,1.072a1.7,1.7,0,0,0-.681-.446V32.567a.567.567,0,1,0-1.135,0v1.239a1.7,1.7,0,0,0,.567,3.3Zm0-2.27a.567.567,0,1,1-.567.567A.567.567,0,0,1,29.7,34.837Z" transform="translate(-14.382 -20.651)" fill="#929292"/>
  <path id="Shape-3" data-name="Shape" d="M20.2,21.344a.567.567,0,0,0-.745-.3l-2.5,1.072a1.7,1.7,0,1,0-2.405,2.391l-.5,1.135a.568.568,0,0,0,1.041.454l.5-1.135c.04,0,.078.012.118.012a1.7,1.7,0,0,0,1.7-1.7c0-.037-.009-.072-.011-.108l2.5-1.072a.567.567,0,0,0,.3-.747ZM15.7,23.837a.567.567,0,1,1,.567-.567A.567.567,0,0,1,15.7,23.837Z" transform="translate(-8.325 -15.893)" fill="#929292"/>
  <path id="Shape-4" data-name="Shape" d="M30.567,14.135h2.27a1.7,1.7,0,0,1,1.7,1.7V17.3l-.166-.166a.567.567,0,0,0-.8.8l1.135,1.135a.567.567,0,0,0,.8,0l1.135-1.135a.567.567,0,0,0-.8-.8l-.166.166V15.837A2.841,2.841,0,0,0,32.837,13h-2.27a.567.567,0,1,0,0,1.135Z" transform="translate(-15.246 -12.433)" fill="#929292"/>
  <path id="Shape-5" data-name="Shape" d="M12.247,44.107H9.977a1.7,1.7,0,0,1-1.7-1.7V40.937l.166.166a.567.567,0,0,0,.8-.8L8.109,39.166a.567.567,0,0,0-.8,0L6.172,40.3a.567.567,0,0,0,.8.8l.166-.166V42.4a2.841,2.841,0,0,0,2.837,2.837h2.27a.567.567,0,0,0,0-1.135Z" transform="translate(-4.871 -23.679)" fill="#929292"/>
</g>
</g>
</g>
</svg>

</span>
<span>Reschedule</span>
</a> */}
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.backToAssign(selectedCase.id);
                                }}
                              >
                                <span className="back-assign-icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="100%"
                                    viewBox="0 0 20 22"
                                  >
                                    <defs>
                                      <clipPath id="clip-Tranfer">
                                        <rect width="20" height="22" />
                                      </clipPath>
                                    </defs>
                                    <g
                                      id="Tranfer"
                                      clipPath="url(#clip-Tranfer)"
                                    >
                                      <g
                                        id="send-file"
                                        transform="translate(-2.237 0.435)"
                                      >
                                        <path
                                          id="Path_241"
                                          data-name="Path 241"
                                          d="M16.879,15.886v2.806a.814.814,0,0,1-.813.813H4.688a.814.814,0,0,1-.813-.813V2.438a.814.814,0,0,1,.813-.813H16.066a.814.814,0,0,1,.813.813V5.244L18.5,6.653V2.438A2.439,2.439,0,0,0,16.066,0H4.688A2.439,2.439,0,0,0,2.25,2.438V18.692A2.439,2.439,0,0,0,4.688,21.13H16.066A2.439,2.439,0,0,0,18.5,18.692V14.477Z"
                                          transform="translate(0)"
                                          fill="#2fa5df"
                                        />
                                        <g
                                          id="Group_597"
                                          data-name="Group 597"
                                          transform="translate(9.126 5.243)"
                                        >
                                          <path
                                            id="Path_242"
                                            data-name="Path 242"
                                            d="M14.752,17.056s-.813.228-.813-.813V14.212H9.063A.814.814,0,0,1,8.25,13.4V10.148a.814.814,0,0,1,.813-.813h4.876V7.279c0-1.092.813-.788.813-.788l6.1,5.283Z"
                                            transform="translate(-8.25 -6.452)"
                                            fill="#2fa5df"
                                          />
                                        </g>
                                      </g>
                                    </g>
                                  </svg>
                                </span>
                                <span>Reschedule Later</span>
                              </a>
                              {/* <a className="dropdown-item" href="#">
                                <span className="transfer-menu-icon">
                                  <svg xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="100%" viewBox="0 0 20 22">
                                    <defs>
                                      <clipPath id="clip-Tranfer">
                                        <rect width="20" height="22" />
                                      </clipPath>
                                    </defs>
                                    <g id="Tranfer" clipPath="url(#clip-Tranfer)">
                                      <g id="send-file" transform="translate(-2.237 0.435)">
                                        <path id="Path_241" data-name="Path 241" d="M16.879,15.886v2.806a.814.814,0,0,1-.813.813H4.688a.814.814,0,0,1-.813-.813V2.438a.814.814,0,0,1,.813-.813H16.066a.814.814,0,0,1,.813.813V5.244L18.5,6.653V2.438A2.439,2.439,0,0,0,16.066,0H4.688A2.439,2.439,0,0,0,2.25,2.438V18.692A2.439,2.439,0,0,0,4.688,21.13H16.066A2.439,2.439,0,0,0,18.5,18.692V14.477Z" transform="translate(0)" fill="#2fa5df" />
                                        <g id="Group_597" data-name="Group 597" transform="translate(9.126 5.243)">
                                          <path id="Path_242" data-name="Path 242" d="M14.752,17.056s-.813.228-.813-.813V14.212H9.063A.814.814,0,0,1,8.25,13.4V10.148a.814.814,0,0,1,.813-.813h4.876V7.279c0-1.092.813-.788.813-.788l6.1,5.283Z" transform="translate(-8.25 -6.452)" fill="#2fa5df" />
                                        </g>
                                      </g>
                                    </g>
                                  </svg>

                                </span>
                                <span>Transfer</span>
                              </a>
                              <a className="dropdown-item" href="#">
                                <span className="back-to-call-icon">
                                  <svg xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="22" height="22" viewBox="0 0 22 22">
                                    <defs>
                                      <clipPath id="clip-Cancel_call">
                                        <rect width="22" height="22" />
                                      </clipPath>
                                    </defs>
                                    <g id="Cancel_call" data-name="Cancel call" clipPath="url(#clip-Cancel_call)">
                                      <g id="cancel-phone-call-auricular-symbol-with-a-cross" transform="translate(-0.749 0.435)">
                                        <g id="Group_624" data-name="Group 624" transform="translate(1.187)">
                                          <path id="Path_255" data-name="Path 255" d="M5.744,0A4.255,4.255,0,0,0,2.225,1.965C.617,4.379.714,9.026,3.675,12.326a77.052,77.052,0,0,0,6.88,6.486,8.4,8.4,0,0,0,5.285,1.957,7.521,7.521,0,0,0,.9-.054A5.683,5.683,0,0,0,21.773,16.9a1.469,1.469,0,0,0-.828-1.739L17,13.413a1.621,1.621,0,0,0-.654-.128,1.891,1.891,0,0,0-1.277.472l-1.243,1.2a1.242,1.242,0,0,1-.817.3.746.746,0,0,1-.331-.067A13.5,13.5,0,0,1,9,12.321,14.339,14.339,0,0,1,6.712,9.339,1.132,1.132,0,0,1,6.982,8.2L8.088,7.1a2.067,2.067,0,0,0,.471-1.926L7.315,1.223A1.772,1.772,0,0,0,5.744,0ZM7.59,6.6,6.483,7.7a1.834,1.834,0,0,0-.4,1.961,15.52,15.52,0,0,0,2.4,3.138,14.208,14.208,0,0,0,3.874,3.019,1.454,1.454,0,0,0,.648.139,1.935,1.935,0,0,0,1.306-.5l1.244-1.205a1.249,1.249,0,0,1,1.154-.205l3.945,1.753a.779.779,0,0,1,.449.862,5.009,5.009,0,0,1-4.448,3.342,6.969,6.969,0,0,1-.82.049,7.88,7.88,0,0,1-4.823-1.785A76.656,76.656,0,0,1,4.2,11.854C1.548,8.9,1.341,4.559,2.81,2.354A3.592,3.592,0,0,1,5.781.7a1.1,1.1,0,0,1,.861.73L7.886,5.382A1.366,1.366,0,0,1,7.59,6.6Z" transform="translate(-1.187)" fill="#c80950" />
                                          <path id="Path_256" data-name="Path 256" d="M184.488,117.23a1.316,1.316,0,0,0-.835-.289,1.355,1.355,0,0,0-1.04.473l-.159.193a.2.2,0,0,1-.317,0l-.158-.193a1.352,1.352,0,0,0-1.039-.473,1.308,1.308,0,0,0-1.322,1.319,1.337,1.337,0,0,0,.3.845l.353.43a.594.594,0,0,1,0,.726l-.354.431a1.332,1.332,0,0,0,2.059,1.691l.158-.194a.2.2,0,0,1,.318,0l.159.193a1.35,1.35,0,0,0,1.039.474,1.316,1.316,0,0,0,.835-.29,1.333,1.333,0,0,0,.184-1.874l-.353-.43a.6.6,0,0,1,0-.726l.353-.43a1.333,1.333,0,0,0-.184-1.875Zm-1.082,2.3a.6.6,0,0,0,0,.726l.721.877a.627.627,0,0,1-.087.883.665.665,0,0,1-.883-.087l-.564-.685a.371.371,0,0,0-.6,0l-.563.686a.647.647,0,0,1-.883.087.628.628,0,0,1-.229-.486.62.62,0,0,1,.142-.4l.721-.877a.594.594,0,0,0,0-.726l-.721-.876a.629.629,0,0,1,.086-.883.627.627,0,0,1,.883.085l.564.686a.371.371,0,0,0,.6,0l.564-.686a.671.671,0,0,1,.883-.086.627.627,0,0,1,.085.883Z" transform="translate(-170.854 -111.196)" fill="#c80950" />
                                        </g>
                                      </g>
                                    </g>
                                  </svg>

                                </span>
                                <span>Back to Queue</span>
                              </a> */}
                            </div>
                          </span>

                          <span
                            className="dropdown dropleft menu-icon align-top"
                            // id="dropdownMenuButton2" data-toggle="dropdown"
                          >
                            <span
                              className="m-0 icon-light-blue calendar"
                              onClick={(e) => {
                                e.stopPropagation();
                                this.handleSchedularClick(index);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                              >
                                <title id="unique-id">
                                  Click here to reschedule the call
                                </title>
                                <defs>
                                  <clipPath id="clip-Schedule_icon">
                                    <rect width="22" height="22" />
                                    <title>
                                      click here to reschedule call{" "}
                                    </title>
                                  </clipPath>
                                </defs>
                                <g
                                  id="Schedule_icon"
                                  data-name="Schedule icon"
                                  clipPath="url(#clip-Schedule_icon)"
                                >
                                  <g id="calendar" transform="translate(0.081)">
                                    <g id="Group_557" data-name="Group 557">
                                      <g id="Group_556" data-name="Group 556">
                                        <circle
                                          id="Ellipse_115"
                                          data-name="Ellipse 115"
                                          cx="1"
                                          cy="1"
                                          r="1"
                                          transform="translate(15.919 7.919)"
                                          fill="#929292"
                                        />
                                        <path
                                          id="Path_162"
                                          data-name="Path 162"
                                          d="M18.426,1.706H17.317V.853a.853.853,0,1,0-1.706,0v.853H11.729V.853a.853.853,0,0,0-1.706,0v.853H6.185V.853a.853.853,0,0,0-1.706,0v.853H3.412A3.416,3.416,0,0,0,0,5.118V18.426a3.416,3.416,0,0,0,3.412,3.412H9.938a.853.853,0,1,0,0-1.706H3.412a1.708,1.708,0,0,1-1.706-1.706V5.118A1.708,1.708,0,0,1,3.412,3.412H4.478v.853a.853.853,0,0,0,1.706,0V3.412h3.839v.853a.853.853,0,0,0,1.706,0V3.412h3.881v.853a.853.853,0,0,0,1.706,0V3.412h1.109a1.708,1.708,0,0,1,1.706,1.706V9.981a.853.853,0,1,0,1.706,0V5.118A3.416,3.416,0,0,0,18.426,1.706Z"
                                          fill="#929292"
                                        />
                                        <path
                                          id="Path_163"
                                          data-name="Path 163"
                                          d="M275.161,270a5.161,5.161,0,1,0,5.161,5.161A5.167,5.167,0,0,0,275.161,270Zm0,8.616a3.455,3.455,0,1,1,3.455-3.455A3.459,3.459,0,0,1,275.161,278.616Z"
                                          transform="translate(-258.484 -258.484)"
                                          fill="#929292"
                                        />
                                        <path
                                          id="Path_164"
                                          data-name="Path 164"
                                          d="M373.09,331.749h-.384v-.9a.853.853,0,0,0-1.706,0V332.6a.853.853,0,0,0,.853.853h1.237a.853.853,0,0,0,0-1.706Z"
                                          transform="translate(-355.176 -315.925)"
                                          fill="#929292"
                                        />
                                        <circle
                                          id="Ellipse_116"
                                          data-name="Ellipse 116"
                                          cx="1"
                                          cy="1"
                                          r="1"
                                          transform="translate(11.919 7.919)"
                                          fill="#929292"
                                        />
                                        <circle
                                          id="Ellipse_117"
                                          data-name="Ellipse 117"
                                          cx="1"
                                          cy="1"
                                          r="1"
                                          transform="translate(7.919 11.919)"
                                          fill="#929292"
                                        />
                                        <circle
                                          id="Ellipse_118"
                                          data-name="Ellipse 118"
                                          cx="1"
                                          cy="1"
                                          r="1"
                                          transform="translate(3.919 7.919)"
                                          fill="#929292"
                                        />
                                        <circle
                                          id="Ellipse_119"
                                          data-name="Ellipse 119"
                                          cx="1"
                                          cy="1"
                                          r="1"
                                          transform="translate(3.919 11.919)"
                                          fill="#929292"
                                        />
                                        <circle
                                          id="Ellipse_122"
                                          data-name="Ellipse 122"
                                          cx="1"
                                          cy="1"
                                          r="1"
                                          transform="translate(7.919 7.919)"
                                          fill="#929292"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </span>

                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              className="schedular-popup-box rounded p-3"
                              style={{
                                display:
                                  schedularClickedIndex === index ? "" : "none",
                              }}
                            >
                              {selectedCase.is_active && (
                                <Scheduler
                                  index={index}
                                  caseId={selectedCase.id}
                                  activeUpperTab={activeUpperTab}
                                  onScheduleComplete={(
                                    index,
                                    dateTime,
                                    customerToken
                                  ) =>
                                    this.props.onScheduleComplete(
                                      index,
                                      dateTime,
                                      customerToken
                                    )
                                  }
                                  closeSchedulerModal={this.closeSchedulerModal}
                                  setSchedulerLoader={
                                    this.props.setSchedulerLoader
                                  }
                                />
                              )}
                            </div>
                          </span>
                          {/* <span
                            className="icon-light-blue"
                            data-toggle="modal"
                            data-target="#cstmrDtls"
                          >
                            <EyeIcon
                              title="Click here to see the details"
                              onClick={() => this.handleModal(true, index)}
                            />
                             <?xml version="1.0" ?><!DOCTYPE svg  PUBLIC '-//W3C//DTD SVG 1.1//EN'  'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'><svg enable-background="new 0 0 32 32" height="36px" id="Layer_1" version="1.1" viewBox="0 0 32 32" width="36px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g><path d="M16.333,13.759L16.333,13.759c-0.006-0.001-0.012-0.001-0.018-0.001c-0.006,0-0.012,0-0.018,0v0.001    c-1.21,0.03-2.183,1.02-2.183,2.237s0.972,2.208,2.183,2.237v0.002c0.006,0,0.012-0.002,0.018-0.002    c0.005,0,0.011,0.002,0.018,0.002v-0.002c1.219-0.029,2.183-1.02,2.183-2.237S17.552,13.789,16.333,13.759z" fill="#515151"/><path d="M29.586,14.186c-2.76-2.246-7.407-5.51-13.253-5.621v0c-0.006,0-0.012,0-0.018,0c-0.006,0-0.012,0-0.018,0    v0C10.453,8.676,5.801,11.94,3.041,14.186c-0.132,0.107-1.036,0.8-1.051,1.686c-0.011,0.624,0.28,1.085,0.699,1.471    c2.255,2.181,7.176,6.059,13.609,6.087v0.002c0.006,0,0.012,0,0.018,0c0.005,0,0.011,0,0.018,0v-0.002    c6.445-0.028,11.351-3.906,13.607-6.087c0.419-0.386,0.709-0.847,0.697-1.471C30.621,14.986,29.719,14.293,29.586,14.186z     M16.315,21.752c-3.162-0.021-5.719-2.59-5.719-5.756s2.557-5.735,5.719-5.755c3.17,0.02,5.718,2.589,5.718,5.755    S19.485,21.73,16.315,21.752z" fill="#515151"/></g></g></svg> 
                          </span> */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
})}
        </div>
        {showModal && activeUpperTab === 0 && (
          <SingleUserModal
            handleModal={this.handleModal}
            assignedCases={scheduledCases[activeIndex]}
          />
        )}

        {showModal && activeUpperTab !== 0 && (
          <GroupModal
            handleModal={this.handleModal}
            updateCaseData={this.props.updateCaseData}
            assignedCases={scheduledCases[activeIndex]}
          />
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  clientInfo: state.login.clientInfo
});
export default connect(mapStateToProps)(ScheduledCases);
